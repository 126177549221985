import React, { memo, useEffect, useState } from 'react'
import {
  PageContainer,
  PageContentContainer,
  HeaderContainer,
  Title,
  ConfigurationsFormContainer,
  Subtitle,
} from './styles'
import {
  ButtonWithText,
  ButtonWithTextContainer,
  ButtonWithTextLeftBlock,
  FormContainer,
} from '../../../../../common/styles/form-styles'
import { useDispatch, useSelector } from 'react-redux'
import { selectServiceDetails } from '../../../../../../reducers/service/service-details'
import {
  selectConfigurations,
  changeConfiguration,
  setConfigurations,
  isConfigurationsFormLoaded,
} from '../../../../../../reducers/service/update-service-configuration-form'
import { RegularText } from '../../../../../common/styles/text'
import { Clear } from '../../../../../common/styles/styles'
import ConfigurationsForm from '../../../../../service/configurations-form/ConfigurationsForm'
import { selectProjectServices } from '../../../../../../reducers/project/project-services'
import { updateServiceConfigurations } from './slice'

function ProjectServiceConfigurations() {
  const dispatch = useDispatch()

  const [formWasChanged, changeForm] = useState(false)

  const service = useSelector(selectServiceDetails)
  const configurations = useSelector(selectConfigurations)
  const isFormLoaded = useSelector(isConfigurationsFormLoaded)

  const configurationKeys = Object.keys(service.configurations)

  const projectServices = useSelector(selectProjectServices)
  const projectService = projectServices.find((projectService) => projectService.uuid === service.uuid)

  const changeConfigurationProcess = (key, value) => {
    dispatch(changeConfiguration({ key, value }))
  }

  useEffect(() => {
    dispatch(setConfigurations({ configurations: projectService.inProject.configurations }))
  }, [])

  useEffect(() => {
    if (isFormLoaded) {
      for (const key of configurationKeys) {
        if (service.inProject.configurations[key] !== configurations[key]) {
          changeForm(true)
          break
        }
        changeForm(false)
      }
    }
  }, [configurations, isFormLoaded])

  const saveConfigurations = (event) => {
    event.preventDefault()
    dispatch(
      updateServiceConfigurations({
        projectUuid: service.projectUuid,
        serviceUuid: service.uuid,
        configurations: service.inProject.configurations,
      }),
    )
  }

  return (
    <PageContainer>
      <HeaderContainer>
        <Title>Configurations</Title>
      </HeaderContainer>

      <PageContentContainer>
        <Subtitle>
          <RegularText>Here you can change service configurations</RegularText>
        </Subtitle>

        {isFormLoaded && (
          <ConfigurationsFormContainer>
            <FormContainer onSubmit={saveConfigurations}>
              <fieldset>
                <ConfigurationsForm
                  changeConfigurationProcess={changeConfigurationProcess}
                  configurations={configurations}
                />

                {formWasChanged && (
                  <ButtonWithTextContainer>
                    <ButtonWithTextLeftBlock>
                      <ButtonWithText type="submit">Save</ButtonWithText>
                    </ButtonWithTextLeftBlock>
                    <Clear />
                  </ButtonWithTextContainer>
                )}
              </fieldset>
            </FormContainer>
          </ConfigurationsFormContainer>
        )}
      </PageContentContainer>
    </PageContainer>
  )
}

export default memo(ProjectServiceConfigurations)
