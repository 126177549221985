import React, { memo, useEffect, useRef } from 'react'
import { lightSilver } from '../../../../../common/styles/colors'
import StyledSVG from '../../../../../common/styled-svg/StyledSVG'
import { showPopup } from '../../../../../../reducers/popup'
import { PopupComponentKeys } from '../../../../../common/layouts/popup/Popup'
import { MoreButton } from './styles'
import { useDispatch } from 'react-redux'
import { Popover } from 'react-tiny-popover'
import { PopoverContainer, PopoverNavigationButton, PopoverNavigationItem } from '../../../../../common/popover/styles'
import MoreIcon from '../../../../../../assets/images/more-icon.svg'
import { useParams } from 'react-router'

function MorePopover({ parameter, parameterUuidWithPopover, setParameterUuidWithPopover }) {
  const dispatch = useDispatch()
  const morePopoverRef = useRef(null)

  const { functionUuid } = useParams()

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (morePopoverRef.current && !morePopoverRef.current.contains(event.target)) {
        setParameterUuidWithPopover(null)
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [morePopoverRef])

  const showDeleteConfirmationPopup = (event, parameterUuid, parameterName) => {
    event.preventDefault()
    setParameterUuidWithPopover(null)
    dispatch(
      showPopup({
        component: PopupComponentKeys.DELETE_FRONT_API_GLOBAL_PARAMETER_CONFIRMATION,
        extraParameters: [
          { name: 'parameterUuid', value: parameterUuid },
          { name: 'parameterName', value: parameterName },
        ],
        size: {
          width: '440px',
          height: 'auto',
        },
      }),
    )
  }

  const togglePopoverState = (event, parameterUuid) => {
    event.preventDefault()
    setParameterUuidWithPopover(parameterUuidWithPopover ? null : parameterUuid)
  }

  const showEditParameterFormHandler = (event) => {
    event.preventDefault()
    setParameterUuidWithPopover(null)
    dispatch(
      showPopup({
        component: PopupComponentKeys.FRONT_API_EDIT_FUNCTION_PARAMETER,
        extraParameters: [
          { name: 'parameterUuid', value: parameter.uuid },
          { name: 'functionUuid', value: functionUuid },
          { name: 'name', value: parameter.name },
          { name: 'isRequired', value: parameter.isRequired },
          { name: 'description', value: parameter.description },
        ],
        size: {
          width: '448px',
          height: 'auto',
        },
      }),
    )
  }

  return (
    <Popover
      isOpen={parameterUuidWithPopover === parameter.uuid}
      positions={['bottom']} // preferred positions by priority
      content={
        <PopoverContainer width={'200px'} ref={morePopoverRef}>
          <PopoverNavigationItem>
            <PopoverNavigationButton type={'button'} onClick={(event) => showEditParameterFormHandler(event)}>
              Edit
            </PopoverNavigationButton>
          </PopoverNavigationItem>
          <PopoverNavigationItem>
            <PopoverNavigationButton
              type={'button'}
              onClick={(event) => showDeleteConfirmationPopup(event, parameter.uuid, parameter.name)}
            >
              Delete
            </PopoverNavigationButton>
          </PopoverNavigationItem>
        </PopoverContainer>
      }
    >
      <MoreButton
        active={parameterUuidWithPopover === parameter.uuid}
        type={'button'}
        onClick={(event) => togglePopoverState(event, parameter.uuid)}
      >
        <StyledSVG width={17} height={4} color={lightSilver} src={MoreIcon} />
      </MoreButton>
    </Popover>
  )
}

export default memo(MorePopover)
