import { createAsyncThunk } from '@reduxjs/toolkit'
import { ProjectAgent } from '../../../../api/projectAgent'

export const deleteApiKey = createAsyncThunk('project-api-keys/delete-key', async ({ projectUuid, uuid }, thunkApi) => {
  try {
    await ProjectAgent.deleteApiKey(projectUuid, uuid)
    
    return { uuid }
  } catch (error) {
    return thunkApi.rejectWithValue(error)
  }
})
