import { createSlice } from '@reduxjs/toolkit'
import { ServiceSize } from '../../utils/utils'
import { addService } from '../../components/service/add-service-popup/slice'

const initialState = {
  settings: {
    size: ServiceSize.SMALL.value,
  },
  resources: [],
  configurations: {},
  step: 1,
}

const addServiceFormSlice = createSlice({
  name: 'addServiceForm',
  initialState,
  extraReducers(builder) {
    builder.addCase(addService.fulfilled, (state) => {
      console.log('Add service')
    })
  },
  reducers: {
    changeSize: (state, action) => {
      state.settings.size = action.payload.size
    },
    addResource: (state, action) => {
      const {resourceConfigs} = action.payload
      const existingResourceIndex = state.resources.findIndex(resource => resource.type === resourceConfigs.type)
      if (existingResourceIndex !== -1) {
        state.resources[existingResourceIndex] = resourceConfigs
      } else {
        state.resources.push(resourceConfigs)
      }
    },
    changeConfiguration: (state, action) => {
      state.configurations[action.payload.key] = action.payload.value
    },
    changeStep: (state, action) => {
      state.step = action.payload.step
    },
    resetForm: () => initialState,
  },
})

const selectAddServiceFormSlice = (state) => state.addServiceForm
export const selectResources = (state) => selectAddServiceFormSlice(state).resources
export const selectSettings = (state) => selectAddServiceFormSlice(state).settings
export const selectConfigurations = (state) => selectAddServiceFormSlice(state).configurations
export const selectStep = (state) => selectAddServiceFormSlice(state).step

const { reducer, actions } = addServiceFormSlice

export const { changeSize, addResource, changeStep, changeConfiguration, resetForm } = actions

export default reducer
