import { createAsyncThunk } from '@reduxjs/toolkit'
import { FrontApiAgent } from '../../../../../../api/frontApiAgent'

export const createFunctionsCollection = createAsyncThunk(
  'front-api/functions/folder/create-collection',
  async ({ projectUuid, name }, thunkApi) => {
    try {
      const collection = await FrontApiAgent.createFunctionsCollection(projectUuid, name)

      return { collection }
    } catch (error) {
      return thunkApi.rejectWithValue(error)
    }
  },
)
