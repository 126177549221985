import styled from 'styled-components'
import { layoutComponentsBorderColor, regularGray } from '../../styles/colors'

export const PopupContainer = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
`

export const PopupBackground = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: ${regularGray};
  opacity: 0.85;

  &:hover {
    cursor: pointer;
  }
`

export const PopupContent = styled.div`
  position: relative;
  width: ${(props) => (props.width ? props.width : '80%')};
  height: ${(props) => (props.height ? props.height : '80%')};

  margin: 0 auto;
  top: 10%;

  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;

  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;

  border: 1px solid ${layoutComponentsBorderColor};
  box-sizing: border-box;

  background: #fafbfc;
`

export const CloseButtonContainer = styled.div`
  position: absolute;
  width: 12px;
  height: 12px;
  top: 12px;
  right: 12px;

  line-height: 0;

  z-index: 99;
`

export const CloseButton = styled.button`
  position: relative;
  width: 12px;
  height: 12px;
  padding: 0;
  margin: 0;
  background: none;
  border: none;

  line-height: 0;

  transition: opacity 275ms ease;

  &:hover {
    cursor: pointer;
    opacity: 0.75;
  }
`
