import React, { memo, useState } from 'react'
import { NodeNameContainer, NodeName, NodeNameField } from './styles'

function NodeNameBlock({ nodeName, changeNameHandler = null }) {
  const [emptyNameError, setEmptyNameError] = useState(false)
  const handleNameFieldFocus = (event) => event.target.select()

  const changeNameHandlerProcess = (event) => {
    changeNameHandler(event.target.value)
    if (event.target.value.length > 0 && emptyNameError) {
      setEmptyNameError(false)
    }

    if (event.target.value.length === 0) {
      setEmptyNameError(true)
    }
  }

  return (
    <NodeNameContainer>
      {!changeNameHandler && <NodeName>{nodeName}</NodeName>}
      {changeNameHandler && (
        <NodeNameField
          onChange={changeNameHandlerProcess}
          value={nodeName}
          onFocus={handleNameFieldFocus}
          autoFocus
          placeholder={emptyNameError ? "Node name can't be empty" : null}
        />
      )}
    </NodeNameContainer>
  )
}

export default memo(NodeNameBlock)
