import React, { memo } from 'react'
import { Clear } from '../../common/styles/styles'
import {
  LeftContentContainer,
  ServicePreviewContainer,
  ServiceIconContainer,
  ServiceInfoContainer,
  ServiceName,
} from './styles'
import { useSelector } from 'react-redux'
import { selectProject } from '../../../reducers/project/project-details'
import ServiceIcon from '../service-icon/ServiceIcon'

function ServicePreview({ service }) {
  const project = useSelector(selectProject)

  return (
    <ServicePreviewContainer to={`/project/${project.uuid}/services/add/${service.uuid}`}>
      <LeftContentContainer>
        <ServiceIconContainer>
          <ServiceIcon serviceAlias={service.alias} />
        </ServiceIconContainer>
        <ServiceInfoContainer>
          <ServiceName>{service.name}</ServiceName>
        </ServiceInfoContainer>
        <Clear />
      </LeftContentContainer>
      <Clear />
    </ServicePreviewContainer>
  )
}

export default memo(ServicePreview)
