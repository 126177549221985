import React, { memo, useState } from 'react'
import { ConfigContainer, ConfigTitle } from '../styles'
import { useSelector } from 'react-redux'
import { selectPopupExtraParameters } from '../../../../../../../reducers/popup'
import SelectboxField from '../../../../../../common/form/selectbox/SelectboxField'
import { AUTHENTICATION_METHODS, AUTHENTICATION_METHODS_NAMES } from '../../../../constants'
import { ContentWrapperContainer, FlexContainer } from '../../../../../../common/layouts/content-container/styles'
import { TextInputForList } from '../../../../../../common/form/text-field/styles'

function AuthenticationMethod() {
  const popupExtraParameters = useSelector(selectPopupExtraParameters)

  const [authenticationMethod, setAuthenticationMethod] = useState(AUTHENTICATION_METHODS.NO_AUTHENTICATION)
  const [authenticationUserName, setAuthenticationUserName] = useState('')
  const [authenticationPassword, setAuthenticationPassword] = useState('')
  const [authenticationBearerToken, setAuthenticationBearerToken] = useState('')

  const params = {}
  popupExtraParameters.map((param) => {
    params[param.name] = param.value
  })

  const methods = Object.values(AUTHENTICATION_METHODS).map((method) => {
    return {
      name: AUTHENTICATION_METHODS_NAMES[method],
      value: method,
    }
  })

  return (
    <ConfigContainer>
      <ConfigTitle>Authentication</ConfigTitle>

      <FlexContainer>
        <ContentWrapperContainer marginTop={'0px'} marginBottom={'0px'} width={'200px'}>
          <SelectboxField
            selectedValue={authenticationMethod}
            options={methods}
            small={true}
            changeValueHandler={(value) => {
              setAuthenticationMethod(value)
            }}
          />
        </ContentWrapperContainer>
        {authenticationMethod === AUTHENTICATION_METHODS.BASIC_AUTH && (
          <ContentWrapperContainer marginTop={'0px'} marginBottom={'0px'} marginLeft={'12px'} width={'100%'}>
            <FlexContainer>
              <ContentWrapperContainer marginTop={'0px'} marginBottom={'0px'} width={'50%'}>
                <TextInputForList
                  value={authenticationUserName}
                  type="text"
                  placeholder="Username"
                  onChange={(event) => {
                    setAuthenticationUserName(event.target.value)
                  }}
                />
              </ContentWrapperContainer>
              <ContentWrapperContainer marginTop={'0px'} marginBottom={'0px'} marginLeft={'12px'} width={'50%'}>
                <TextInputForList
                  value={authenticationPassword}
                  type="password"
                  placeholder="Password"
                  onChange={(event) => {
                    setAuthenticationPassword(event.target.value)
                  }}
                />
              </ContentWrapperContainer>
            </FlexContainer>
          </ContentWrapperContainer>
        )}
        {authenticationMethod === AUTHENTICATION_METHODS.BEARER_TOKEN && (
          <ContentWrapperContainer marginTop={'0px'} marginBottom={'0px'} marginLeft={'12px'} width={'100%'}>
            <FlexContainer>
              <ContentWrapperContainer marginTop={'0px'} marginBottom={'0px'} width={'100%'}>
                <TextInputForList
                  value={authenticationBearerToken}
                  type="text"
                  placeholder="Bearer Token"
                  onChange={(event) => {
                    setAuthenticationBearerToken(event.target.value)
                  }}
                />
              </ContentWrapperContainer>
            </FlexContainer>
          </ContentWrapperContainer>
        )}
      </FlexContainer>
    </ConfigContainer>
  )
}

export default memo(AuthenticationMethod)
