import styled from 'styled-components'

export const ContentContainer = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 100%;
`

export const MainContent = styled.div`
  position: absolute;
  left: 0;
  top: 64px;
  bottom: 0;
  width: 100%;
`
