import React, { memo, useEffect, useState } from 'react'
import { EditButton, IconContainer, NameField } from './styles'
import StyledSVG from '../../../../common/styled-svg/StyledSVG'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { selectFunctionByUuid } from '../../../../../reducers/front-api/front-api'
import EditIcon from '../../../../../assets/images/edit-icon.svg'
import { layoutComponentsBorderColor } from '../../../../common/styles/colors'
import { HeaderContainer } from '../../../../common/layouts/content-block-header/styles'
import { updateFunctionNameHeader } from './slice'
import { resetState, selectCreatedFunctionUuid } from '../../../../../reducers/front-api/function/create-form'
import { FlexContainer } from '../../../../common/layouts/content-container/styles'
import { H2HeaderTitle } from '../../../../common/styles/text'

function FunctionDetailsHeader() {
  const dispatch = useDispatch()
  const { functionUuid, projectUuid } = useParams()

  const _function = useSelector((state) => selectFunctionByUuid(state, functionUuid))

  const [emptyNameError, setEmptyNameError] = useState(false)
  const [showChangeNameForm, setShowChangeNameForm] = useState(false)
  const [nameFieldValue, setNameFieldValue] = useState(_function ? _function.name : '')

  const createdFunctionUuid = useSelector(selectCreatedFunctionUuid)

  useEffect(() => {
    if (createdFunctionUuid) {
      dispatch(resetState())
    }
  })

  useEffect(() => {
    setNameFieldValue(_function ? _function.name : '')
  }, [functionUuid])

  const changeNameHandler = (event) => {
    setNameFieldValue(event.target.value)
    if (event.target.value.length > 0 && emptyNameError) {
      setEmptyNameError(false)
    }

    if (event.target.value.length === 0) {
      setEmptyNameError(true)
    }
  }

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      saveNameHandler()
    }
  }

  const saveNameHandler = () => {
    if (!emptyNameError) {
      if (nameFieldValue !== _function.name) {
        dispatch(updateFunctionNameHeader({ projectUuid, functionUuid, name: nameFieldValue }))
      }
    } else {
      setNameFieldValue(_function.name)
      setEmptyNameError(false)
    }

    setShowChangeNameForm(false)
  }

  const handleNameFieldFocus = (event) => event.target.select()

  return (
    <HeaderContainer>
      {!showChangeNameForm && (
        <FlexContainer>
          <H2HeaderTitle>{_function.name}</H2HeaderTitle>
          <EditButton
            onClick={(event) => {
              setShowChangeNameForm(true)
            }}
          >
            <IconContainer>
              <StyledSVG src={EditIcon} color={layoutComponentsBorderColor} width={16} height={16} />
            </IconContainer>
          </EditButton>
        </FlexContainer>
      )}
      {showChangeNameForm && (
        <NameField
          onChange={changeNameHandler}
          onBlur={saveNameHandler}
          value={nameFieldValue}
          onFocus={handleNameFieldFocus}
          onKeyUp={handleKeyPress}
          autoFocus
          placeholder={emptyNameError ? "Function name can't be empty" : null}
        />
      )}
    </HeaderContainer>
  )
}

export default memo(FunctionDetailsHeader)
