import { createAsyncThunk } from '@reduxjs/toolkit'
import { ServiceAgent } from '../../../../api/serviceAgent'

export const getProjectServices = createAsyncThunk(
  'project-services/get-services',
  async ({ projectUuid }, thunkApi) => {
    try {
      const services = await ServiceAgent.getProjectServices(projectUuid)

      return { services }
    } catch (error) {
      return thunkApi.rejectWithValue(error)
    }
  },
)
