import React, { memo, useState } from 'react'
import { ContentContainer } from '../../common/layouts/content-container/styles'
import AddNodeButton from './components/add-node-button/AddNodeButton'
import { LevelContainer, VerticalContainer } from './styles'
import AddNodePopup from './components/add-node-popup/AddNodePopup'
import { NODES_TYPES } from './constants'
import GenericNode from './components/nodes/generic-node/GenericNode'

const nodes = [
  {
    type: NODES_TYPES.REQUEST,
    name: 'First request',
    isValid: true,
    configs: {
      type: 'custom',
      data: {
        method: 'POST',
        url: 'https://myservice.com/api/update-user',
        body: {
          format: 'JSON',
          data: 'Test',
        },
        headers: [],
      },
      // authentication ???
    },
  },
  {
    type: NODES_TYPES.REQUEST,
    name: 'Second request',
    isValid: false,
    configs: {
      type: 'sk-service',
      serviceAlias: 'user-service',
      endpointUuid: '1e56f03a-4aba-491a-a1c2-f52184e1a9be',
      parameters: [],
      header: [],
    },
  },
]

function FlowBuilder() {
  const [addNodePopupParent, setAddNodePopupParent] = useState(null)

  const setAddNodePopupParentHandler = (key) => {
    setAddNodePopupParent(key)
  }

  const renderNodes = (nodes, depth) => {
    const addNodePopupKey = `add-node-${depth}`

    return (
      <>
        {nodes.map((node, nodeIndex) => {
          return (
            <LevelContainer key={`node-${nodeIndex}`}>
              <GenericNode node={node} />
            </LevelContainer>
          )
        })}
        <LevelContainer>
          <AddNodeButton
            setParent={() => {
              setAddNodePopupParentHandler(addNodePopupKey)
            }}
          />
          {addNodePopupParent === addNodePopupKey && <AddNodePopup closeHandler={setAddNodePopupParent} />}
        </LevelContainer>
      </>
    )
  }

  return <ContentContainer padding={'24px'}>{renderNodes(nodes)}</ContentContainer>
}

export default memo(FlowBuilder)
