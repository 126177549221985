import styled from 'styled-components'
import { superLightSilver } from '../../../../styles/colors'

export const UserBlockContainer = styled.div`
  position: relative;
  height: 63px;
  cursor: pointer;

  display: flex;
  align-items: center;
  flex-wrap: wrap;

  transition: opacity 275ms ease;

  &:hover {
    cursor: pointer;
    opacity: 0.75;
  }
`

export const UserName = styled.div`
  position: relative;
`

export const IconContainer = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  padding-top: 8px;
`

export const UserImageContainer = styled.div`
  position: relative;
  width: 32px;
  height: 32px;
  margin-left: 16px;

  background: ${superLightSilver};
  text-align: center;

  box-sizing: border-box;

  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
`

export const UserImageComponent = styled.img`
  position: relative;
  width: 32px;
  height: 32px;

  object-fit: cover;

  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
`
