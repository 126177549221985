import styled from 'styled-components'
import { lightGray, mainBlue } from '../../../common/styles/colors'
import { MoreButton } from './popovers/more/styles'
import { AddButton } from './popovers/add/styles'
import { SortableItemLinkContainer } from '../../../common/tree/styles'

export const FunctionIcon = styled.div`
  position: relative;
  width: 8px;
  height: 8px;

  margin: 2px;

  background: ${(props) => (props.active ? mainBlue : lightGray)};

  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
`

export const ItemLinkContainer = styled(SortableItemLinkContainer)`
  &:hover ${MoreButton} {
    display: block;
  }

  &:hover ${AddButton} {
    display: block;
  }
`
