import { createAsyncThunk } from '@reduxjs/toolkit'
import { ProjectAgent } from '../../../api/projectAgent'

export const createProject = createAsyncThunk(
  'project-create-form/create',
  async ({ name, image, AWSRegion, AWSAccessKeyId, AWSSecretAccessKey, frontApiSize }, thunkApi) => {
    try {
      const projectAndKey = await ProjectAgent.createProject(
        name,
        image,
        {
          region: AWSRegion,
          accessKeyId: AWSAccessKeyId,
          secretAccessKey: AWSSecretAccessKey,
        },
        {
          size: frontApiSize,
        },
      )

      const { project, apiKey } = projectAndKey

      return { project, apiKey }
    } catch (error) {
      return thunkApi.rejectWithValue(error)
    }
  },
)
