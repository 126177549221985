import React, { memo, useEffect, useRef, useState } from 'react'
import { SearchBarComponent, SearchField, SearchFieldContainer, SearchIconContainer } from './styles'
import SearchIcon from '../../../assets/images/search-icon.svg'
import { layoutComponentsBackgroundColor, lightGray, mainBlue } from '../styles/colors'
import StyledSVG from '../styled-svg/StyledSVG'

const SearchBar = ({ fieldPlaceholder, searchQuery, setSearchQuery, backgroundColor=layoutComponentsBackgroundColor }) => {
  const wrapperRef = useRef(null)

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setShowSearchField(false)
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [showSearchField])

  const [showSearchField, setShowSearchField] = useState(false)

  const toggleSearchField = () => {
    setShowSearchField(!showSearchField)
  }

  const changeSearchQuery = (event) => {
    setSearchQuery(event.target.value)
  }

  return (
    <SearchBarComponent>
      <SearchIconContainer onClick={toggleSearchField} backgroundColor={backgroundColor}>
        <StyledSVG width={16} height={16} src={SearchIcon} color={searchQuery ? mainBlue : lightGray} />
      </SearchIconContainer>
      {showSearchField && (
        <SearchFieldContainer ref={wrapperRef} backgroundColor={backgroundColor}>
          <fieldset>
            <SearchField
              type="text"
              placeholder={fieldPlaceholder}
              name="searchValue"
              value={searchQuery}
              onChange={changeSearchQuery}
            />
          </fieldset>
        </SearchFieldContainer>
      )}
    </SearchBarComponent>
  )
}

export default memo(SearchBar)
