import styled from 'styled-components'
import { darkGray, lightGray, redTextColor } from '../../../../common/styles/colors'

export const IconContainer = styled.div`
  position: relative;
  height: 22px;
  width: 22px;
  float: left;

  padding: 3px;

  box-sizing: border-box;
`

export const EditButton = styled.button`
  position: relative;

  padding: 0;
  margin: 13px 8px;

  background: none;
  border: none;

  &:hover {
    cursor: pointer;

    & svg {
      path {
        fill: ${lightGray};
      }
    }
  }
`

export const NameField = styled.input`
  position: relative;
  font-size: 16px;
  font-weight: 500;
  line-height: 32px;

  height: 32px;
  color: ${darkGray};

  min-width: 320px;

  background: none;
  padding: 0;
  margin: 0;
  margin-top: 8px;
  border: none;

  &::-webkit-input-placeholder {
    /* Edge */
    color: ${redTextColor};
    opacity: 0.6;
  }

  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: ${redTextColor};
    opacity: 0.6;
  }

  &::placeholder {
    color: ${redTextColor};
    opacity: 0.6;
  }
`
