import { regularGray } from '../styles/colors'

export const codeInputProps = {
  style: {
    margin: '4px',
    MozAppearance: 'textfield',
    width: '48px',
    height: '64px',
    borderRadius: '2px',
    fontSize: '24px',
    textAlign: 'center',
    padding: 0,
    boxSizing: 'border-box',
    color: regularGray,
    border: `1px solid #efefef`,
  },
}
