import styled from 'styled-components'
import { lightSilver, regularGray } from '../../../../../common/styles/colors'
import { Link } from 'react-router-dom'

export const HeaderBreadcrumbs = styled.span`
  padding-right: 8px;
`

export const HeaderBreadcrumbsLink = styled(Link)`
  color: ${lightSilver};
  text-decoration: none;

  padding: 0 8px;

  transition: color 275ms ease;

  &:hover {
    color: ${regularGray};
  }
`

export const HeaderBreadcrumbsSlash = styled.span`
  color: ${lightSilver};
`
