import styled from 'styled-components'
import { lightRedColor, superLightSilver } from '../../styles/colors'

export const UploadImageWithPreviewContainer = styled.div`
  position: relative;
  width: 100%;
`

export const UploadButtonContainer = styled.div`
  position: relative;
  width: 80px;
  height: 80px;

  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;

  background: ${superLightSilver};

  text-align: center;

  transition: opacity 275ms ease;

  &:hover {
    cursor: pointer;
    opacity: 0.75;
  }
`

export const UploadImageIconContainer = styled.div`
  position: relative;
  width: 32px;
  height: 32px;
  margin: 0 auto;
  padding-top: 24px;
`

export const PreviewImageContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 80px;
  height: 80px;
`

export const DeleteButtonContainer = styled.div`
  position: absolute;
  top: -4px;
  right: -4px;
  width: 18px;
  height: 18px;

  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;

  text-align: center;
  padding-top: 1px;

  box-sizing: border-box;

  background: #f26161;

  transition: background 275ms ease;

  &:hover {
    cursor: pointer;
    background: ${lightRedColor};
  }
`

export const PreviewImage = styled.img`
  position: relative;

  width: 100%;
  height: 100%;
  object-fit: cover;

  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
`
