import React, { memo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ErrorMessage from '../../common/error-message/ErrorMessage'
import { ButtonContainer, ButtonWithTextCenter, FieldContainer, FormContainer } from '../../common/styles/form-styles'
import {
  FormContainer as FormContentContainer,
  MiddleFormPageSubtitleContainer,
  MiddleFormPageTitleContainer,
  MiddleFormTopContainer,
} from '../../common/layouts/public-area/middle-form/styles'
import { H1TitleWithButton, SubtitleText } from '../../common/styles/text'
import PublicAreaMiddleFormLayout from '../../common/layouts/public-area/middle-form/PublicAreaMiddleFormLayout'
import { Clear } from '../../common/styles/styles'
import BackLink from '../../common/back-link/BackLink'
import TextField from '../../common/form/text-field/TextField'
import { resetPassword } from './slice'
import {
  selectForgotPasswordFormData,
  selectError,
  changeEmail,
  selectRequestStatus,
  changeRequestStatus,
} from '../../../reducers/user/forgot-password-form'
import { REQUEST_STATUSES } from '../../../reducers/reducer-helper'
import Loading from '../../common/loading/Loading'

function EmailForm() {
  const dispatch = useDispatch()

  const requestStatus = useSelector(selectRequestStatus)
  const formData = useSelector(selectForgotPasswordFormData)
  const error = useSelector(selectError)

  const changeEmailProcess = (event) => {
    event.preventDefault()
    dispatch(changeEmail({ email: event.target.value }))
  }

  const resetPasswordProcess = (event) => {
    event.preventDefault()
    dispatch(changeRequestStatus({ status: REQUEST_STATUSES.PENDING }))
    dispatch(resetPassword({ email: formData.email }))
  }

  return (
    <PublicAreaMiddleFormLayout>
      {requestStatus === REQUEST_STATUSES.PENDING && <Loading />}
      <MiddleFormTopContainer>
        <MiddleFormPageTitleContainer>
          <BackLink link={'/sign-in'} />
          <H1TitleWithButton>Forgot password?</H1TitleWithButton>
          <Clear />
        </MiddleFormPageTitleContainer>
        <MiddleFormPageSubtitleContainer>
          <SubtitleText>Please provide your email</SubtitleText>
        </MiddleFormPageSubtitleContainer>
      </MiddleFormTopContainer>

      <ErrorMessage error={error} />

      <FormContentContainer>
        <FormContainer onSubmit={resetPasswordProcess}>
          <FieldContainer>
            <fieldset>
              <TextField
                label={'Email'}
                value={formData.email}
                changeEvent={changeEmailProcess}
                placeholder={'youremail@email.com'}
              />
            </fieldset>
          </FieldContainer>
          <ButtonContainer>
            <ButtonWithTextCenter type="submit">Continue</ButtonWithTextCenter>
          </ButtonContainer>
        </FormContainer>
      </FormContentContainer>
    </PublicAreaMiddleFormLayout>
  )
}

export default memo(EmailForm)
