import React, { memo, useEffect, useState } from 'react'
import { ContentContainer, Subtitle, SettingsFormContainer, UserImageContainer, ContentBlockTitle } from './styles'
import {
  ButtonWithText,
  ButtonWithTextContainer,
  ButtonWithTextLeftBlock,
  FieldContainer,
  FormContainer,
} from '../../../common/styles/form-styles'
import TextField from '../../../common/form/text-field/TextField'
import { useDispatch, useSelector } from 'react-redux'
import { H2HeaderTitle, H2Title, RegularText } from '../../../common/styles/text'
import { Clear } from '../../../common/styles/styles'
import {
  changeEmail,
  changeEmailConfirmationPassword,
  changeName,
  changePassword,
  changeOldPassword,
  isSettingsFormLoaded,
  selectSettings,
  setSettings,
  selectError,
  selectRequestStatus,
  changeRequestStatus,
} from '../../../../reducers/user/user-general-settings-form'
import { selectUser } from '../../../../reducers/user/user'
import {
  updateProfileInformation,
  changePassword as changePasswordRequest,
  updateProfileImage,
  deleteProfileImage,
} from './slice'
import ErrorMessage from '../../../common/error-message/ErrorMessage'
import { REQUEST_STATUSES } from '../../../../reducers/reducer-helper'
import Loading from '../../../common/loading/Loading'
import UploadImageWithPreview from '../../../common/form/upload-image-with-preview/UploadImageWithPreview'
import AddUserImageIcon from '../../../../assets/images/add-user-image-icon.svg'
import { HeaderContainer } from '../../../common/layouts/content-block-header/styles'

function UserGeneralSettings() {
  const dispatch = useDispatch()

  const user = useSelector(selectUser)
  const settings = useSelector(selectSettings)
  const isFormLoaded = useSelector(isSettingsFormLoaded)
  const error = useSelector(selectError)
  const requestStatus = useSelector(selectRequestStatus)

  // Can't be stored in the Redux as file is not serializable
  const [userImage, setUserImage] = useState(user.image ? user.image : null)

  const changeNameProcess = (event) => {
    event.preventDefault()
    dispatch(changeName({ name: event.target.value }))
  }

  const changeEmailProcess = (event) => {
    event.preventDefault()
    dispatch(changeEmail({ email: event.target.value }))
  }

  const changeEmailConfirmationPasswordProcess = (event) => {
    event.preventDefault()
    dispatch(changeEmailConfirmationPassword({ emailConfirmationPassword: event.target.value }))
  }

  const changeOldPasswordProcess = (event) => {
    event.preventDefault()
    dispatch(changeOldPassword({ oldPassword: event.target.value }))
  }

  const changePasswordProcess = (event) => {
    event.preventDefault()
    dispatch(changePassword({ password: event.target.value }))
  }

  const changeImageProcess = (image) => {
    dispatch(changeRequestStatus({ status: REQUEST_STATUSES.PENDING }))
    dispatch(updateProfileImage({ image }))
  }

  const deleteImageProcess = () => {
    dispatch(changeRequestStatus({ status: REQUEST_STATUSES.PENDING }))
    dispatch(deleteProfileImage())
  }

  useEffect(() => {
    const { image, name, email } = user
    setUserImage(image)
    dispatch(setSettings({ name, email }))
  }, [user])

  const saveSettings = (event) => {
    event.preventDefault()
    dispatch(changeRequestStatus({ status: REQUEST_STATUSES.PENDING }))
    dispatch(
      updateProfileInformation({
        name: settings.name,
        email: settings.email,
        password: settings.emailConfirmationPassword,
      }),
    )
  }

  const savePassword = (event) => {
    event.preventDefault()
    dispatch(changeRequestStatus({ status: REQUEST_STATUSES.PENDING }))
    dispatch(changePasswordRequest({ newPassword: settings.password, oldPassword: settings.oldPassword }))
  }

  return (
    <>
      <HeaderContainer>
        <H2HeaderTitle>General settings</H2HeaderTitle>
      </HeaderContainer>
      <ContentContainer>
        <Subtitle>
          <RegularText>Here you can update your profile information</RegularText>
        </Subtitle>
        {requestStatus === REQUEST_STATUSES.PENDING && <Loading />}
        {isFormLoaded && (
          <>
            <SettingsFormContainer>
              <ErrorMessage error={error} />
              <FormContainer onSubmit={saveSettings}>
                <UserImageContainer>
                  <UploadImageWithPreview
                    addIcon={AddUserImageIcon}
                    existingImage={userImage ? userImage.key : userImage}
                    changeImageHandler={changeImageProcess}
                    deleteImageHandler={deleteImageProcess}
                    usePreviewImage={false}
                  />
                </UserImageContainer>

                <FieldContainer>
                  <fieldset>
                    <TextField
                      label={'Full name'}
                      value={settings.name}
                      changeEvent={changeNameProcess}
                      placeholder={'Your Name'}
                    />
                  </fieldset>
                </FieldContainer>

                <FieldContainer>
                  <fieldset>
                    <TextField
                      label={'Email'}
                      value={settings.email}
                      changeEvent={changeEmailProcess}
                      placeholder={'youremail@email.com'}
                    />
                  </fieldset>
                </FieldContainer>

                {user.email !== settings.email && (
                  <>
                    <FieldContainer>
                      <fieldset>
                        <TextField
                          label={'Confirmation password'}
                          value={settings.emailConfirmationPassword}
                          changeEvent={changeEmailConfirmationPasswordProcess}
                          placeholder={'●●●●●●'}
                          type={'password'}
                        />
                      </fieldset>
                    </FieldContainer>
                  </>
                )}

                {(user.email !== settings.email || user.name !== settings.name) && (
                  <ButtonWithTextContainer>
                    <ButtonWithTextLeftBlock>
                      <ButtonWithText type="submit">Save</ButtonWithText>
                    </ButtonWithTextLeftBlock>
                    <Clear />
                  </ButtonWithTextContainer>
                )}
              </FormContainer>
            </SettingsFormContainer>
            <ContentBlockTitle>
              <H2Title>Security</H2Title>
            </ContentBlockTitle>
            <SettingsFormContainer>
              <FormContainer onSubmit={savePassword}>
                <FieldContainer>
                  <fieldset>
                    <TextField
                      label={'New password'}
                      value={settings.password}
                      changeEvent={changePasswordProcess}
                      placeholder={'●●●●●●'}
                      type={'password'}
                    />
                  </fieldset>
                </FieldContainer>

                {settings.password !== '' && (
                  <>
                    <FieldContainer>
                      <fieldset>
                        <TextField
                          label={'Current password'}
                          value={settings.oldPassword}
                          changeEvent={changeOldPasswordProcess}
                          placeholder={'●●●●●●'}
                          type={'password'}
                        />
                      </fieldset>
                    </FieldContainer>

                    <ButtonWithTextContainer>
                      <ButtonWithTextLeftBlock>
                        <ButtonWithText type="submit">Save password</ButtonWithText>
                      </ButtonWithTextLeftBlock>
                      <Clear />
                    </ButtonWithTextContainer>
                  </>
                )}
              </FormContainer>
            </SettingsFormContainer>
          </>
        )}
      </ContentContainer>
    </>
  )
}

export default memo(UserGeneralSettings)
