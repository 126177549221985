import React, { memo, useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import { ContentContainer } from '../common/layouts/content-container/styles'
import { getFrontApiData } from './slice'
import { useDispatch, useSelector } from 'react-redux'
import { selectProject } from '../../reducers/project/project-details'
import { resetState, selectRequestStatus } from '../../reducers/front-api/front-api'
import { REQUEST_STATUSES } from '../../reducers/reducer-helper'
import Loading from '../common/loading/Loading'

function FrontApiDataWrapper() {
  const dispatch = useDispatch()

  const project = useSelector(selectProject)
  const requestStatus = useSelector(selectRequestStatus)

  useEffect(() => {
    const fetchFrontApiData = dispatch(getFrontApiData({ projectUuid: project.uuid }))

    return () => {
      dispatch(resetState())
      fetchFrontApiData.abort()
    }
  }, [])

  return (
    <ContentContainer>
      {requestStatus === REQUEST_STATUSES.PENDING && <Loading />}
      {requestStatus === REQUEST_STATUSES.DONE && <Outlet />}
    </ContentContainer>
  )
}

export default memo(FrontApiDataWrapper)
