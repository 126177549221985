import React, { memo } from 'react'
import { Content, ContentDescription } from './styles'
import { RegularText } from '../../../common/styles/text'
import { useDispatch, useSelector } from 'react-redux'
import { changeSize, selectSettings } from '../../../../reducers/service/add-service-form'
import SettingsForm from '../../settings-form/SettingsForm'

function Settings() {
  const dispatch = useDispatch()
  const settings = useSelector(selectSettings)

  const changeSizeProcess = (size) => {
    dispatch(changeSize({ size }))
  }

  return (
    <Content>
      <ContentDescription>
        <RegularText>Please set a service settings</RegularText>
      </ContentDescription>

      <SettingsForm
        changeSizeProcess={changeSizeProcess}
        settings={settings}
      />
    </Content>
  )
}

export default memo(Settings)
