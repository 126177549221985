import React, { memo, useState } from 'react'
import { ConfigContainer, ConfigTitle, EditorContainer } from '../styles'
import { useSelector } from 'react-redux'
import { selectPopupExtraParameters } from '../../../../../../../reducers/popup'
import { ContentWrapperContainer } from '../../../../../../common/layouts/content-container/styles'
import SelectboxField from '../../../../../../common/form/selectbox/SelectboxField'
import { BODY_FORMATS, BODY_FORMATS_NAMES, EDITOR_LANGUAGES_HIGHLIGHT } from '../../../../constants'
import KeyValueParameter from '../key-value-parameter/KeyValueParameter'
import CodemirrorField from '../codemirror-field/CodemirrorField'

function RequestBody() {
  const popupExtraParameters = useSelector(selectPopupExtraParameters)

  const [bodyFormat, setBodyFormat] = useState(BODY_FORMATS.JSON)
  const [json, setJson] = useState('{}')
  const [html, setHtml] = useState('<html></html>')
  const [xml, setXml] = useState('')
  const [custom, setCustom] = useState('')

  const params = {}
  popupExtraParameters.map((param) => {
    params[param.name] = param.value
  })

  const bodyFormats = Object.values(BODY_FORMATS).map((format) => {
    return {
      name: BODY_FORMATS_NAMES[format],
      value: format,
    }
  })

  return (
    <ConfigContainer>
      <ConfigTitle>Request Body</ConfigTitle>
      <ContentWrapperContainer marginTop={'0px'} marginBottom={'0px'} width={'420px'}>
        <SelectboxField
          selectedValue={bodyFormat}
          options={bodyFormats}
          small={true}
          changeValueHandler={(value) => {
            setBodyFormat(value)
          }}
        />
      </ContentWrapperContainer>
      {bodyFormat === BODY_FORMATS.JSON && (
        <ContentWrapperContainer marginTop={'12px'} marginBottom={'0px'} height={'320px'} width={'100%'}>
          <EditorContainer>
            <CodemirrorField
              language={EDITOR_LANGUAGES_HIGHLIGHT.JSON}
              value={json}
              isMinimalSetup={false}
              placeholder={'Describe JSON here...'}
              onChange={(value) => {
                setJson(value)
              }}
            />
          </EditorContainer>
        </ContentWrapperContainer>
      )}
      {bodyFormat === BODY_FORMATS.HTML && (
        <ContentWrapperContainer marginTop={'12px'} marginBottom={'0px'} height={'320px'} width={'100%'}>
          <EditorContainer>
            <CodemirrorField
              language={EDITOR_LANGUAGES_HIGHLIGHT.HTML}
              value={html}
              isMinimalSetup={false}
              placeholder={'Describe HTML here...'}
              onChange={(value) => {
                setHtml(value)
              }}
            />
          </EditorContainer>
        </ContentWrapperContainer>
      )}
      {bodyFormat === BODY_FORMATS.XML && (
        <ContentWrapperContainer marginTop={'12px'} marginBottom={'0px'} height={'320px'} width={'100%'}>
          <EditorContainer>
            <CodemirrorField
              language={EDITOR_LANGUAGES_HIGHLIGHT.XML}
              value={xml}
              isMinimalSetup={false}
              placeholder={'Describe XML here...'}
              onChange={(value) => {
                setXml(value)
              }}
            />
          </EditorContainer>
        </ContentWrapperContainer>
      )}
      {bodyFormat === BODY_FORMATS.CUSTOM && (
        <ContentWrapperContainer marginTop={'12px'} marginBottom={'0px'} height={'320px'} width={'100%'}>
          <EditorContainer>
            <EditorContainer>
              <CodemirrorField
                value={custom}
                isMinimalSetup={false}
                placeholder={'Add your custom data here...'}
                onChange={(value) => {
                  setCustom(value)
                }}
              />
            </EditorContainer>
          </EditorContainer>
        </ContentWrapperContainer>
      )}
      {bodyFormat === BODY_FORMATS.FORM_URL && (
        <ContentWrapperContainer marginTop={'12px'} marginBottom={'0px'} height={'320px'} width={'100%'}>
          <KeyValueParameter />
        </ContentWrapperContainer>
      )}
      {bodyFormat === BODY_FORMATS.FORM_DATA && (
        <ContentWrapperContainer marginTop={'12px'} marginBottom={'0px'} height={'320px'} width={'100%'}>
          <KeyValueParameter />
        </ContentWrapperContainer>
      )}
    </ConfigContainer>
  )
}

export default memo(RequestBody)
