import { ResourceTypes } from '../utils/utils'
import { requests } from './agent'

export const ProjectAgent = {
  createProject: (name, image, aws, frontApi) => {
    const formData = new FormData()

    formData.append('name', name)
    formData.append('image', image)
    formData.append('awsRegion', aws.region)
    formData.append('awsAccessKeyId', aws.accessKeyId)
    formData.append('awsSecretAccessKey', aws.secretAccessKey)
    formData.append('frontApiSize', frontApi.size)

    return requests.post('/1_0/projects/create', formData, { 'Content-Type': 'multipart/form-data' })
  },
  updateGeneralSettings: (projectUuid, name) => {
    return requests.put(`/1_0/projects/${projectUuid}`, { name })
  },
  updateProjectImage: (projectUuid, image) => {
    const formData = new FormData()

    formData.append('image', image)

    return requests.post(`/1_0/projects/${projectUuid}/update-image`, formData, {
      'Content-Type': 'multipart/form-data',
    })
  },
  deleteProjectImage: (projectUuid) => {
    return requests.post(`/1_0/projects/${projectUuid}/delete-image`)
  },
  updateFrontAPI: (projectUuid, size) => {
    return requests.put(`/1_0/projects/${projectUuid}/front-api`, { size })
  },
  addResource: (projectUuid, type, size) => {
    return requests.post(`/1_0/projects/${projectUuid}/resources/add`, { type, configuration: { size } })
  },
  updateResource: (projectUuid, type, size) => {
    return requests.post(`/1_0/projects/${projectUuid}/resources/update`, { type, configuration: { size } })
  },
  deleteResource: (projectUuid, size) => {
    return requests.post(`/1_0/projects/${projectUuid}/resource/delete`, { type: ResourceTypes.DB })
  },
  addApiKey: (projectUuid) => {
    return requests.post(`/1_0/projects/${projectUuid}/api-keys/add`)
  },
  deleteApiKey: (projectUuid, apiUuidKey) => {
    return requests.post(`/1_0/projects/${projectUuid}/api-keys/${apiUuidKey}/delete`)
  },
  addIpAddress: (projectUuid, ipAddress) => {
    return requests.post(`/1_0/projects/${projectUuid}/ip-addresses`, { ipAddress })
  },
  setOpenForAllSettings: (projectUuid, openForAll) => {
    return requests.post(`/1_0/projects/${projectUuid}/ip-addresses/open-for-all`, { openForAll })
  },
  deleteIpAddress: (projectUuid, uuid) => {
    return requests.delete(`/1_0/projects/${projectUuid}/ip-addresses/${uuid}`)
  },
  getAllProjects: () => {
    return requests.get('/1_0/projects')
  },
  getProjectIpAddressesSettings: (projectUuid) => {
    return requests.get(`/1_0/projects/${projectUuid}/ip-addresses`)
  },
  getProjectApiKeys: (projectUuid) => {
    return requests.get(`/1_0/projects/${projectUuid}/api-keys`)
  },
  getProject: (uuid) => {
    return requests.get(`/1_0/projects/${uuid}`)
  },
}
