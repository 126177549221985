import { requests } from './agent'

export const UserAgent = {
  signIn: (email, password) => {
    return requests.post('/1_0/user/account/sign-in', { email, password })
  },
  createAccount: (email) => {
    return requests.post('/1_0/user/account/sign-up', { email })
  },
  createProfile: (name, password, token) => {
    return requests.post('/1_0/user/account/sign-up/complete', { name, password, token })
  },
  resendEmailConfirmationCode: (email) => {
    return requests.post('/1_0/user/account/sign-up/resend-token', { email })
  },
  confirmSignUpEmail: (token) => {
    return requests.post('/1_0/user/account/sign-up/confirm-email', { token })
  },
  confirmNewEmail: (token) => {
    return requests.post('/1_0/user/account/change-email/confirm', { token })
  },
  update: (name, email, password) => {
    return requests.post('/1_0/user/update', { name, email, password })
  },
  updateProfileImage: (image) => {
    const formData = new FormData();

    formData.append("image", image);

    return requests.post('/1_0/user/profile/update-image', formData, { "Content-Type": "multipart/form-data" })
  },
  deleteProfileImage: () => {
    return requests.delete('/1_0/user/profile/delete-image')
  },
  changePassword: (newPassword, oldPassword) => {
    return requests.post('/1_0/user/account/change-password', { newPassword, oldPassword })
  },
  forgotPasswordSetNewPassword: (token, password) => {
    return requests.post('/1_0/user/forgot-password/restore', { token, newPassword: password })
  },
  forgotPasswordResetPassword: (email) => {
    return requests.post('/1_0/user/forgot-password/reset', { email })
  },
  forgotPasswordConfirmEmail: (token) => {
    return requests.post('/1_0/user/forgot-password/confirm-email', { code: token })
  },
  forgotPasswordResendConfirmEmailToken: (email) => {
    return requests.post('/1_0/user/forgot-password/resend-code', { email })
  },
  logOut: () => {
    return requests.post('/1_0/user/logout')
  },
}
