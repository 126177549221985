import React, { memo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  FormContainer as FormContentContainer,
  MiddleFormPageSubtitleContainer,
  MiddleFormPageTitleContainer,
  MiddleFormTopContainer,
} from '../../common/layouts/public-area/middle-form/styles'
import { H1Title, RegularText, SubtitleText } from '../../common/styles/text'
import ErrorMessage from '../../common/error-message/ErrorMessage'
import {
  ButtonWithText,
  ButtonWithTextContainer,
  ButtonWithTextLeftBlock,
  ButtonWithTextRightBlock,
  FieldContainer,
  FormContainer,
} from '../../common/styles/form-styles'
import TextField from '../../common/form/text-field/TextField'
import { ExtraLink } from '../styles'
import { Clear } from '../../common/styles/styles'
import PublicAreaMiddleFormLayout from '../../common/layouts/public-area/middle-form/PublicAreaMiddleFormLayout'
import {
  changeName,
  changePassword,
  changeRequestStatus,
  selectError,
  selectRequestStatus,
  selectSignupFormData,
} from '../../../reducers/user/sign-up-form'
import { createProfile } from './slice'
import { REQUEST_STATUSES } from '../../../reducers/reducer-helper'
import Loading from '../../common/loading/Loading'
import { lightGray } from '../../common/styles/colors'

function ProfileDataForm() {
  const dispatch = useDispatch()

  const requestStatus = useSelector(selectRequestStatus)
  const { name, password, token } = useSelector(selectSignupFormData)
  const error = useSelector(selectError)

  const createUserProfile = (event) => {
    event.preventDefault()
    dispatch(changeRequestStatus({ status: REQUEST_STATUSES.PENDING }))
    dispatch(createProfile({ name, password, token }))
  }

  const changeNameProcess = (event) => {
    event.preventDefault()
    dispatch(changeName({ name: event.target.value }))
  }

  const changePasswordProcess = (event) => {
    event.preventDefault()
    dispatch(changePassword({ password: event.target.value }))
  }

  return (
    <PublicAreaMiddleFormLayout>
      {requestStatus === REQUEST_STATUSES.PENDING && <Loading />}
      <MiddleFormTopContainer>
        <MiddleFormPageTitleContainer>
          <H1Title>Personal information</H1Title>
        </MiddleFormPageTitleContainer>
        <MiddleFormPageSubtitleContainer>
          <SubtitleText>Please provide your name and set a new password</SubtitleText>
        </MiddleFormPageSubtitleContainer>
      </MiddleFormTopContainer>

      <ErrorMessage error={error} />

      <FormContentContainer>
        <FormContainer onSubmit={createUserProfile}>
          <fieldset>
            <FieldContainer>
              <fieldset>
                <TextField label={'Full name'} value={name} changeEvent={changeNameProcess} placeholder={'Your Name'} />
              </fieldset>
            </FieldContainer>
            <FieldContainer>
              <fieldset>
                <TextField
                  label={'Password'}
                  value={password}
                  changeEvent={changePasswordProcess}
                  placeholder={'●●●●●●'}
                  type={'password'}
                />
              </fieldset>
            </FieldContainer>

            <ButtonWithTextContainer>
              <ButtonWithTextLeftBlock>
                <RegularText color={lightGray}>By clicking you accept our</RegularText>
                <ExtraLink to="/terms-and-conditions">Terms and conditions</ExtraLink>
              </ButtonWithTextLeftBlock>
              <ButtonWithTextRightBlock>
                <ButtonWithText disabled={requestStatus === REQUEST_STATUSES.PENDING} type="submit">
                  Create account
                </ButtonWithText>
              </ButtonWithTextRightBlock>
              <Clear />
            </ButtonWithTextContainer>
          </fieldset>
        </FormContainer>
      </FormContentContainer>
    </PublicAreaMiddleFormLayout>
  )
}

export default memo(ProfileDataForm)
