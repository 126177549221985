import styled from 'styled-components'
import { layoutComponentsBackgroundColor, layoutComponentsBorderColor, lightGray, regularGray } from '../styles/colors'
import { Link } from 'react-router-dom'

export const PopoverContainer = styled.div`
  position: relative;
  width: ${(props) => props.width};
  box-sizing: border-box;
  padding: 0 4px;
  border: 1px solid ${layoutComponentsBorderColor};
  background: #ffffff;
  z-index: 1;

  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
`

export const PopoverNavigationItem = styled.div`
  position: relative;
  width: 100%;
  height: 32px;
  line-height: 32px;
  margin: 4px 0;
`

export const PopoverNavigationItemTitle = styled.div`
  position: relative;
  width: 100%;
  height: 28px;
  font-size: 12px;
  padding: 0 12px;
  box-sizing: border-box;
  border-bottom: 1px solid ${layoutComponentsBorderColor};
  color: ${lightGray};
  margin: 4px 0;
  text-align: left;
`

export const PopoverNavigationButton = styled.button`
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  background: ${(props) => (props.active ? layoutComponentsBackgroundColor : 'none')};
  border: none;

  color: ${regularGray};

  font-size: 14px;
  font-weight: 500;

  padding: 0 12px;
  box-sizing: border-box;
  text-align: left;

  text-decoration: none;

  &:hover {
    cursor: pointer;
    background: ${layoutComponentsBackgroundColor};
  }
`

export const PopoverNavigationLink = styled(Link)`
  position: relative;
  display: block;
  width: 100%;
  padding: 0 24px;
  box-sizing: border-box;
  text-align: left;

  font-size: 14px;

  color: ${regularGray};
  text-decoration: none;

  &:hover {
    cursor: pointer;
    background: ${layoutComponentsBackgroundColor};
  }
`

export const PopoverDivider = styled.div`
  position: relative;
  width: 216px;
  height: 1px;
  margin: 12px 12px;
  background: ${layoutComponentsBorderColor};
`
