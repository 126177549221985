import styled from 'styled-components'
import { layoutComponentsBorderColor, lightGray } from '../../common/styles/colors'
import { TableRow } from '../../common/table/styles'
import { MoreButton } from './more-popover/styles'

export const HeaderContainer = styled.div`
  position: absolute;
  width: 100%;
  top: 0;
  height: 48px;
  left: 0;
  right: 0;
  padding: 0 24px;
  box-sizing: border-box;

  border-bottom: 1px solid ${layoutComponentsBorderColor};
`

export const CreateParameterButtonContainer = styled.div`
  position: relative;
  width: 220px;
  height: 40px;
`

export const NameColumn = styled.div`
  position: relative;
  width: 220px;
`

export const ValueColumn = styled.div`
  position: relative;
  width: 220px;
`

export const DescriptionColumn = styled.div`
  position: relative;
  width: 180px;
`

export const LastColumn = styled.div`
  position: relative;
  display: flex;
  justify-content: end;
  width: 104px;
  height: 100%;
`

export const ParameterTableRow = styled(TableRow)`
  &:hover ${MoreButton} {
    display: block;
  }
`

export const Subtitle = styled.div`
  position: relative;
  margin-bottom: 32px;
`

export const NoDescriptionMessage = styled.span`
  color: ${lightGray};
`
