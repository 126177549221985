import React, { memo } from 'react'
import { ContentContainer, MainContent } from './styles'
import Header from './header/Header'

const PrivateArea = ({ children }) => {
  return (
    <ContentContainer>
      <Header />
      <MainContent>{children}</MainContent>
    </ContentContainer>
  )
}

export default memo(PrivateArea)
