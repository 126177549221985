import React, { memo } from 'react'
import { FieldContainer } from '../../common/styles/form-styles'
import SelectboxField from '../../common/form/selectbox/SelectboxField'
import { ServiceSize } from '../../../utils/utils'
import { SettingsFormContainer } from './styles'

function SettingsForm({ changeSizeProcess, settings }) {
  const sizeOptions = Object.values(ServiceSize)

  return (
    <SettingsFormContainer>
      <FieldContainer>
        <fieldset>
          <SelectboxField
            label={'Service size'}
            selectedValue={settings.size}
            placeholder={'Select size'}
            options={sizeOptions}
            changeValueHandler={changeSizeProcess}
          />
        </fieldset>
      </FieldContainer>
    </SettingsFormContainer>
  )
}

export default memo(SettingsForm)
