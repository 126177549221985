import { createSlice } from '@reduxjs/toolkit'
import { resetErrors, resetStatus } from '../../reducer-helper'
import { deleteGlobalParameter } from '../../../components/front-api/global-parameters/delete-popup/slice'

const initialState = {
  error: null,
  status: null,
}

const deleteSlice = createSlice({
  name: 'frontApiDeleteGlobalParameter',
  initialState,
  reducers: {
    resetState: () => initialState,
    changeRequestStatus: (state, action) => {
      state.status = action.payload.status
    },
  },
  extraReducers(builder) {
    builder.addCase(deleteGlobalParameter.fulfilled, (state) => {
      resetStatus(state)
      resetErrors(state)
    })
    builder.addCase(deleteGlobalParameter.rejected, (state) => {
      resetStatus(state)
      resetErrors(state)
    })
  },
})

const currentSlice = (state) => state.frontApiDeleteGlobalParameter
export const selectRequestStatus = (state) => currentSlice(state).status

const { reducer, actions } = deleteSlice

export const { resetForm, changeRequestStatus } = actions

export default reducer
