import styled from 'styled-components'
import { layoutComponentsBorderColor, lightGray, regularGray } from '../../../../common/styles/colors'
import { RegularText } from '../../../../common/styles/text'

export const DescriptionContainer = styled.div`
  position: relative;
  width: fit-content;
`

export const IconContainer = styled.div`
  position: relative;
  height: 22px;
  width: 22px;
  float: left;

  padding: 3px;

  box-sizing: border-box;
`

export const EditButton = styled.button`
  position: relative;

  display: none;

  padding: 0;
  margin: 0 8px;

  background: none;
  border: none;

  transition: opacity 275ms ease;

  ${DescriptionContainer}:hover & {
    display: block;
  }

  &:hover {
    cursor: pointer;
    opacity: 0.75;

    & svg {
      path {
        fill: ${lightGray};
      }
    }
  }
`

export const DescriptionText = styled(RegularText)`
  position: relative;
  width: 480px;
  float: left;
  max-height: 320px;
  padding: 4px 0;
  line-height: 24px;
  white-space: pre-line;
`

export const DescriptionPlaceholder = styled(DescriptionText)`
  color: ${lightGray};
`

export const DescriptionTextarea = styled.textarea`
  position: relative;
  width: 480px;
  color: ${regularGray};
  max-height: 320px;

  padding: 12px;
  box-sizing: border-box;
  border: 1px solid ${layoutComponentsBorderColor};

  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;

  &:focus {
    border: 1px solid ${lightGray};
  }

  overflow: auto;
  outline: none;

  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;

  resize: none; /*remove the resize handle on the bottom right*/
`
