import { PARAMETERS_FOR_INSERT_SOURCES } from './constants'

export const colorsByType = (sourceType) => {
  switch (sourceType) {
    default:
    case PARAMETERS_FOR_INSERT_SOURCES.GLOBAL_PARAMETERS:
      return {
        border: '#154b16',
        background: 'rgba(194, 255, 194, 0.15)',
      }

    case PARAMETERS_FOR_INSERT_SOURCES.FUNCTION:
      return {
        border: '#35154b',
        background: 'rgba(195,167,238,0.15)',
      }
  }
}
