import React, { Suspense, useEffect } from 'react'
import { Route, Routes } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { initApp } from './slice'
import agent from '../../api/agent'
import SignIn from '../user/sign-in/SignIn'
import SignUp from '../user/sign-up/SignUp'
import { memo } from 'react'
import { clearRedirect } from '../../reducers/common'
import { useNavigate } from 'react-router'
import Dashboard from '../dashboard/Dashboard'
import ProtectedRoute from '../common/ProtectedRoute'
import ForgotPassword from '../user/forgot-password/ForgotPassword'
import CreateProject from '../project/create/CreateProject'
import UserGeneralSettings from '../user/settings/general/UserGeneralSettings'
import Popup from '../common/layouts/popup/Popup'
import ProjectDetails from '../project/details/ProjectDetails'
import ProjectServices from '../project/details/services/ProjectServices'
import AddService from '../project/details/services/add-service/AddService'
import AddServiceDetails from '../project/details/services/add-service/service-details/AddServiceDetails'
import ProjectServiceDetails from '../project/details/services/details/ProjectServiceDetails'
import ProjectServiceOverview from '../project/details/services/details/overview/ProjectServiceOverview'
import ProjectServiceConfigurations from '../project/details/services/details/configurations/ProjectServiceConfigurations'
import ProjectServiceSettings from '../project/details/services/details/settings/ProjectServiceSettings'
import ProjectSettings from '../project/details/settings/ProjectSettings'
import ProjectSettingsGeneral from '../project/details/settings/general/ProjectSettingsGeneral'
import DatabaseSettings from '../project/details/settings/resources/database/DatabaseSettings'
import RedisSettings from '../project/details/settings/resources/redis/RedisSettings'
import UserSettings from '../user/settings/UserSettings'
import Loading from '../common/loading/Loading'
import FrontApiOverview from '../front-api/overview/Overview'
import ApiKeys from '../front-api/api-keys/ApiKeys'
import GlobalParameters from '../front-api/global-parameters/GlobalParameters'
import Functions from '../front-api/functions/Functions'
import FunctionDetails from '../front-api/functions/details/FunctionDetails'
import FunctionOverview from '../front-api/functions/details/overview/FunctionOverview'
import FunctionParameters from '../front-api/functions/details/parameters/FunctionParameters'
import ResultObject from '../front-api/functions/details/result-object/ResultObject'
import FunctionScenario from '../front-api/functions/details/flow/FunctionFlow'
import Builder from '../front-api/functions/details/flow/builder/Builder'
import FrontApiLayout from '../front-api/FrontApiLayout'
import FrontApiDataWrapper from '../front-api/FrontApi'

function App() {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const redirectTo = useSelector((state) => state.common.redirectTo)
  const appLoaded = useSelector((state) => state.common.appLoaded)
  const showPopup = useSelector((state) => state.popup.showPopup)

  useEffect(() => {
    if (redirectTo) {
      navigate(redirectTo)
      dispatch(clearRedirect())
    }
  }, [redirectTo])

  useEffect(() => {
    const token = window.localStorage.getItem('api-token')
    agent.setApiToken(token)
    dispatch(initApp())
  }, [])

  if (appLoaded) {
    return (
      <>
        {showPopup && <Popup />}
        <Suspense fallback={<p>Loading...</p>}>
          <Routes>
            <Route exact path="/sign-in" element={<SignIn />} />
            <Route exact path="/sign-up" element={<SignUp />} />
            <Route exact path="/forgot-password" element={<ForgotPassword />} />
            <Route
              exact
              path="/dashboard"
              element={
                <ProtectedRoute>
                  <Dashboard />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/project/create"
              element={
                <ProtectedRoute>
                  <CreateProject />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/project/:projectUuid"
              element={
                <ProtectedRoute>
                  <ProjectDetails />
                </ProtectedRoute>
              }
            >
              <Route path="front-api" element={<FrontApiDataWrapper />}>
                <Route path={'functions/:functionUuid/flow/builder'} element={<Builder />} />
                <Route element={<FrontApiLayout />}>
                  <Route path="" element={<FrontApiOverview />} />
                  <Route path="api-keys" element={<ApiKeys />} />
                  <Route path="global-parameters" element={<GlobalParameters />} />
                  <Route path="functions" element={<Functions />}>
                    <Route path=":functionUuid" element={<FunctionDetails />}>
                      <Route path="" element={<FunctionOverview />} />
                      <Route path="parameters" element={<FunctionParameters />} />
                      <Route path="result-object" element={<ResultObject />} />
                      <Route path="flow" element={<FunctionScenario />} />
                    </Route>
                  </Route>
                </Route>
              </Route>
              <Route exact path="services" element={<ProjectServices />}>
                <Route exact path="add" element={<AddService />}>
                  <Route exact path=":serviceUuid" element={<AddServiceDetails />} />
                </Route>
                <Route path=":serviceUuid" element={<ProjectServiceDetails />}>
                  <Route path="" element={<ProjectServiceOverview />} />
                  <Route path="configurations" element={<ProjectServiceConfigurations />} />
                  <Route path="settings" element={<ProjectServiceSettings />} />
                </Route>
              </Route>
              <Route exact path="settings" element={<ProjectSettings />}>
                <Route exact path="" element={<ProjectSettingsGeneral />} />
                <Route exact path="database" element={<DatabaseSettings />} />
                <Route exact path="redis" element={<RedisSettings />} />
              </Route>
            </Route>
            <Route
              exact
              path="/user/settings"
              element={
                <ProtectedRoute>
                  <UserSettings />
                </ProtectedRoute>
              }
            >
              <Route exact path="general" element={<UserGeneralSettings />} />
              {/* Todo create payment system */}
              {/*<Route exact path="billing">*/}
              {/*  <Route*/}
              {/*    exact*/}
              {/*    path="cards"*/}
              {/*    element={*/}
              {/*      <ProtectedRoute>*/}
              {/*        <Cards />*/}
              {/*      </ProtectedRoute>*/}
              {/*    }*/}
              {/*  />*/}
              {/*  <Route*/}
              {/*    exact*/}
              {/*    path="invoices"*/}
              {/*    element={*/}
              {/*      <ProtectedRoute>*/}
              {/*        <Invoices />*/}
              {/*      </ProtectedRoute>*/}
              {/*    }*/}
              {/*  />*/}
              {/*</Route>*/}
            </Route>
          </Routes>
        </Suspense>
      </>
    )
  } else {
    return <Loading />
  }
}

export default memo(App)
