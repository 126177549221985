import React, { memo } from 'react'
import BackIcon from '../../../assets/images/back-icon.svg'
import { BackLink as BackLinkComponent } from './styles'
import { lightSilver } from '../styles/colors'
import StyledSVG from '../styled-svg/StyledSVG'

const BackLink = ({ link }) => {
  return (
    <BackLinkComponent to={link}>
      <StyledSVG width={12} height={12} color={lightSilver} src={BackIcon} />
    </BackLinkComponent>
  )
}

export default memo(BackLink)
