import { createAsyncThunk } from '@reduxjs/toolkit'
import { FrontApiAgent } from '../../../../../api/frontApiAgent'

export const updateFunctionDescription = createAsyncThunk(
  'front-api/function/update-description',
  async ({ projectUuid, functionUuid, description }, thunkApi) => {
    try {
      await FrontApiAgent.updateFunctionDescription(projectUuid, functionUuid, description)

      return { functionUuid, description }
    } catch (error) {
      return thunkApi.rejectWithValue(error)
    }
  },
)
