import { requests } from './agent'

export const ServiceAgent = {
  addService: (projectUuid, serviceUuid, resources, settings, configurations) => {
    return requests.post(`/1_0/projects/${projectUuid}/services/${serviceUuid}/add`, {
      size: settings.size,
      resources,
      configurations,
    })
  },
  updateProjectServicesSettings: (projectUuid, serviceUuid, size, autoscaling) => {
    return requests.put(`/1_0/projects/${projectUuid}/services/${serviceUuid}/settings`, { size, autoscaling })
  },
  updateProjectServicesConfigurations: (projectUuid, serviceUuid, configurations) => {
    return requests.put(`/1_0/projects/${projectUuid}/services/${serviceUuid}/configuration`, { configurations })
  },
  getAllServices: () => {
    return requests.get('/1_0/system-services')
  },
  getProjectServices: (uuid) => {
    return requests.get(`/1_0/projects/${uuid}/services`)
  },
  getService: (serviceUuid) => {
    return requests.get(`/1_0/system-services/${serviceUuid}`)
  },
}
