import { createSlice } from '@reduxjs/toolkit'
import { failed } from '../../../reducer-helper'
import { createParameter } from '../../../../components/front-api/functions/details/parameters/add-parameter-form/slice'

const initialState = {
  formData: {
    name: '',
    isRequired: false,
    description: '',
  },
  error: null,
  status: null,
}

const AddFormSlice = createSlice({
  name: 'frontApiAddFunctionParameterForm',
  initialState,
  reducers: {
    resetState: () => initialState,
    changeName: (state, action) => {
      state.formData.name = action.payload.name
    },
    changeIsRequired: (state, action) => {
      state.formData.isRequired = action.payload.isRequired
    },
    changeDescription: (state, action) => {
      state.formData.description = action.payload.description
    },
    changeRequestStatus: (state, action) => {
      state.status = action.payload.status
    },
  },
  extraReducers(builder) {
    builder.addCase(createParameter.fulfilled, () => initialState)
    builder.addCase(createParameter.rejected, failed)
  },
})

const currentSlice = (state) => state.frontApiAddFunctionParameterForm
export const selectRequestStatus = (state) => currentSlice(state).status
export const selectFormData = (state) => currentSlice(state).formData

const { reducer, actions } = AddFormSlice

export const { resetState, changeRequestStatus, changeName, changeIsRequired, changeDescription } = actions

export default reducer
