import React, { memo } from 'react'
import { Clear, SecondaryActionButton } from '../styles/styles'
import {
  MiddleFormPageSubtitleContainer,
  MiddleFormPageTitleContainer,
  MiddleFormTopContainer,
} from '../layouts/public-area/middle-form/styles'
import { REQUEST_STATUSES } from '../../../reducers/reducer-helper'
import Loading from '../loading/Loading'
import { H2Title, SubtitleText } from '../styles/text'
import { ButtonsContainer, ConfirmButton, ConfirmPopupContainer } from './styles'

const ConfirmPopup = ({ cancelHandler, deleteParameterHandler, confirmButtonText, requestStatus, title, subtitle }) => {
  return (
    <ConfirmPopupContainer>
      <MiddleFormTopContainer>
        {requestStatus === REQUEST_STATUSES.PENDING && <Loading />}
        <MiddleFormPageTitleContainer>
          <H2Title>{title}</H2Title>
        </MiddleFormPageTitleContainer>
        <MiddleFormPageSubtitleContainer>
          <SubtitleText>{subtitle}</SubtitleText>
        </MiddleFormPageSubtitleContainer>
        <ButtonsContainer>
          <SecondaryActionButton onClick={cancelHandler}>Cancel</SecondaryActionButton>
          <ConfirmButton onClick={deleteParameterHandler}>{confirmButtonText}</ConfirmButton>
        </ButtonsContainer>
      </MiddleFormTopContainer>
      <Clear />
    </ConfirmPopupContainer>
  )
}

export default memo(ConfirmPopup)
