import { createAsyncThunk } from '@reduxjs/toolkit'
import { FrontApiAgent } from '../../../../../../api/frontApiAgent'

export const renameFunctionFolder = createAsyncThunk(
  'front-api/functions/folder/rename',
  async ({ projectUuid, uuid, name }, thunkApi) => {
    try {
      await FrontApiAgent.renameTreeFolder(projectUuid, uuid, name)

      return { folderUuid: uuid, name }
    } catch (error) {
      return thunkApi.rejectWithValue(error)
    }
  },
)
