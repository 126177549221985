import { createAsyncThunk } from '@reduxjs/toolkit'
import { FrontApiAgent } from '../../../../../api/frontApiAgent'

export const createFunctionResultObject = createAsyncThunk(
  'front-api/functions/result-objects/create',
  async ({ projectUuid, functionUuid }, thunkApi) => {
    try {
      const resultObject = await FrontApiAgent.createFunctionResultObject(projectUuid, functionUuid)

      return { functionUuid, resultObject }
    } catch (error) {
      return thunkApi.rejectWithValue(error)
    }
  },
)

export const cloneFunctionResultObject = createAsyncThunk(
  'front-api/functions/result-objects/clone',
  async ({ projectUuid, functionUuid, sourceResultObjectUuid }, thunkApi) => {
    try {
      const resultObject = await FrontApiAgent.cloneResultObjectForFunction(
        projectUuid,
        functionUuid,
        sourceResultObjectUuid,
      )

      return { functionUuid, resultObject }
    } catch (error) {
      return thunkApi.rejectWithValue(error)
    }
  },
)

export const createResultObjectParameter = createAsyncThunk(
  'front-api/result-objects/parameters/create',
  async ({ projectUuid, resultObjectUuid, name, type, description, parentUuid }, thunkApi) => {
    try {
      const parameter = await FrontApiAgent.createResultObjectParameter(
        projectUuid,
        resultObjectUuid,
        name,
        type,
        parentUuid,
        description,
      )

      return { resultObjectUuid, parameter }
    } catch (error) {
      return thunkApi.rejectWithValue(error)
    }
  },
)
