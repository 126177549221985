import styled from 'styled-components'

export const ContentContainer = styled.div`
  position: absolute;
  left: ${(props) => (props.left ? props.left : 0)};
  right: ${(props) => (props.right ? props.right : 0)};
  top: ${(props) => (props.top ? props.top : 0)};
  bottom: ${(props) => (props.bottom ? props.bottom : 0)};
  width: ${(props) => (props.width ? props.width : 'auto')};
  padding: ${(props) => (props.padding ? props.padding : 0)};
  margin: ${(props) => (props.margin ? props.margin : 0)};
  box-sizing: border-box;
  overflow: ${(props) => (props.scroll ? 'scroll' : 'auto')};
`

export const ContentWrapperContainer = styled.div`
  position: relative;
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  ${(props) => {
    if (props.alignCenter) {
      return `
				margin: 0 auto;
			`
    } else {
      return `
				margin-left: ${(props) => (props.marginLeft ? props.marginLeft : '0')};
				margin-right: ${(props) => (props.marginRight ? props.marginRight : '0')};
  		`
    }
  }}
  margin-top: ${(props) => (props.marginTop ? props.marginTop : '24px')};
  margin-bottom: ${(props) => (props.marginBottom ? props.marginBottom : '24px')};
  padding: ${(props) => (props.padding ? props.padding : 0)};
  box-sizing: border-box;
  line-height: normal;
`

export const CenteredContent = styled(ContentContainer)`
  text-align: center;
`

export const FlexContainer = styled.div`
  display: flex;
  flex-wrap: nowrap !important;
  align-items: ${(props) => (props.alignItems ? props.alignItems : 'center')};
`
