import React, { memo } from 'react'
import { useSelector } from 'react-redux'
import {
  Content,
  ContentBlock,
  ContentTitle,
  DependencyContainer,
  DependencyLink,
  Description,
  EndpointMethod,
  EndpointName,
  LightTitle,
  PageContainer,
  PageContentContainer,
} from './styles'
import Header from './header/Header'
import { selectRequestStatus, selectServiceDetails } from '../../../reducers/service/service-details'
import { RegularText } from '../../common/styles/text'
import { Clear } from '../../common/styles/styles'
import Loading from '../../common/loading/Loading'
import { REQUEST_STATUSES } from '../../../reducers/reducer-helper'
import { TableColumn, TableContainer, TableRow } from '../../common/table/styles'

function ServiceOverview() {
  const service = useSelector(selectServiceDetails)
  const requestStatus = useSelector(selectRequestStatus)

  return (
    <PageContainer>
      {requestStatus === REQUEST_STATUSES.PENDING && <Loading />}
      {requestStatus === REQUEST_STATUSES.DONE && service != null && (
        <>
          <Header service={service} />
          <PageContentContainer>
            <ContentBlock>
              <Description>
                <RegularText lineHeight={'175%'}>{service.description}</RegularText>
              </Description>
            </ContentBlock>
            {service.dependencies && service.dependencies.length > 0 && (
              <ContentBlock>
                <ContentTitle>
                  Dependencies <LightTitle>(Recommended to add)</LightTitle>
                </ContentTitle>
                <Content>
                  {service.dependencies.map((dependency) => (
                    <DependencyContainer key={`${service.uuid}:${dependency.uuid}`}>
                      <DependencyLink to={`#${dependency.uuid}`}>{dependency.name}</DependencyLink>
                    </DependencyContainer>
                  ))}
                  <Clear />
                </Content>
              </ContentBlock>
            )}
            {service.endpoints && service.endpoints.length > 0 && (
              <ContentBlock>
                <ContentTitle>Endpoints</ContentTitle>
                <Content>
                  <TableContainer width={'540px'}>
                    {service.endpoints.map((endpoint) => (
                      <TableRow bottomMargin={'16px'} key={endpoint.uuid}>
                        <TableColumn lineHeight={'64px'} width={'80px'}>
                          <EndpointMethod method={endpoint.method}>{endpoint.method}</EndpointMethod>
                        </TableColumn>
                        <TableColumn>
                          <EndpointName>{endpoint.name}</EndpointName>
                        </TableColumn>
                      </TableRow>
                    ))}
                  </TableContainer>
                  <Clear />
                </Content>
              </ContentBlock>
            )}
          </PageContentContainer>
        </>
      )}
    </PageContainer>
  )
}

export default memo(ServiceOverview)
