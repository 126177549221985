import styled from 'styled-components'
import { darkGray, layoutComponentsBorderColor } from '../../../../common/styles/colors'
import { H2Title } from '../../../../common/styles/text'

export const ProjectServiceDetailsContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
`

export const Content = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 240px;
  right: 0;
`

export const SidebarContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 240px;
  box-sizing: border-box;

  border-right: 1px solid ${layoutComponentsBorderColor};
`

export const HeaderContainer = styled.div`
  position: absolute;
  width: 100%;
  top: 0;
  height: 48px;
  left: 0;
  right: 0;
  padding: 0 24px;
  box-sizing: border-box;

  border-bottom: 1px solid ${layoutComponentsBorderColor};
`
