import styled from 'styled-components'

export const UserSettingsContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
`

export const Content = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 400px;
  right: 0;
`
