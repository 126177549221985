import styled from 'styled-components'
import { CallToActionButton } from '../styles/styles'

export const ConfirmPopupContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  padding: 12px 24px;
  box-sizing: border-box;
`

export const ButtonsContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: end;
  width: 100%;
  margin-top: 32px;
`

export const ConfirmButton = styled(CallToActionButton)`
  margin-left: 12px;
`
