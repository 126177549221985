import React, { memo } from 'react'
import { FieldLabel } from '../styles'
import { CheckboxContainer, CheckboxContent, CheckboxFill } from './styles'

const Checkbox = ({ content, isEnabled, onClickEvent }) => {
  return (
    <FieldLabel onClick={onClickEvent}>
      <CheckboxContainer>{isEnabled && <CheckboxFill />}</CheckboxContainer>
      <CheckboxContent>{content}</CheckboxContent>
    </FieldLabel>
  )
}

export default memo(Checkbox)
