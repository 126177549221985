import { createSlice } from '@reduxjs/toolkit'
import { REQUEST_STATUSES, failed, resetErrors, resetStatus } from './../reducer-helper'
import { getProjectServices } from '../../components/project/details/services/slice'

const initialState = {
  services: null,
  error: null,
  status: REQUEST_STATUSES.PENDING,
}

const projectServicesSlice = createSlice({
  name: 'projectServices',
  initialState,
  reducers: {
    servicesUnloaded: () => initialState,
  },
  extraReducers(builder) {
    builder.addCase(getProjectServices.fulfilled, (state, action) => {
      state.services = action.payload.services
      resetStatus(state)
      resetErrors(state)
    })
    builder.addCase(getProjectServices.rejected, failed)
  },
})

const selectProjectServicesSlice = (state) => state.projectServices
export const selectRequestStatus = (state) => selectProjectServicesSlice(state).status
export const selectProjectServices = (state) => selectProjectServicesSlice(state).services

const { reducer, actions } = projectServicesSlice

export const { servicesUnloaded } = actions

export default reducer
