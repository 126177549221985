import React, { memo, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { resetForm, selectStep } from '../../../reducers/user/sign-up-form'
import EmailForm from './EmailForm'
import ConfirmEmailForm from './ConfirmEmailForm'
import ProfileDataForm from './ProfileDataForm'

function SignUp() {
  const dispatch = useDispatch()
  const step = useSelector(selectStep)

  useEffect(() => {
    return () => {
      dispatch(resetForm())
    }
  }, [])

  return (
    <>
      {step === 1 && <EmailForm />}
      {step === 2 && <ConfirmEmailForm />}
      {step === 3 && <ProfileDataForm />}
    </>
  )
}

export default memo(SignUp)
