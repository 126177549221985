import React, { memo } from 'react'
import { IconContainer, UserBlockContainer, UserImageComponent, UserImageContainer, UserName } from './styles'
import UserIcon from '../../../../../../assets/images/user-icon.svg'
import { useSelector } from 'react-redux'
import { selectUser } from '../../../../../../reducers/user/user'
import { RegularText } from '../../../../styles/text'
import { CDN_URL } from '../../../../../../configs'
import StyledSVG from '../../../../styled-svg/StyledSVG'

function UserBlock({ showPopupCallback }) {
  const user = useSelector(selectUser)

  return (
    <UserBlockContainer onClick={showPopupCallback}>
      <RegularText>{user.name}</RegularText>
      <UserImageContainer>
        {user.image && <UserImageComponent src={`${CDN_URL}/${user.image.key}`} />}
        {!user.image && (
          <IconContainer>
            <StyledSVG width={16} height={16} color={'#ffffff'} src={UserIcon} />
          </IconContainer>
        )}
      </UserImageContainer>
    </UserBlockContainer>
  )
}

export default memo(UserBlock)
