import React, { memo, useEffect, useRef } from 'react'
import { lightSilver } from '../../../../../common/styles/colors'
import StyledSVG from '../../../../../common/styled-svg/StyledSVG'
import { showPopup } from '../../../../../../reducers/popup'
import { PopupComponentKeys } from '../../../../../common/layouts/popup/Popup'
import { MoreButton } from './styles'
import { useDispatch } from 'react-redux'
import { Popover } from 'react-tiny-popover'
import { PopoverContainer, PopoverNavigationButton, PopoverNavigationItem } from '../../../../../common/popover/styles'
import MoreIcon from '../../../../../../assets/images/more-icon.svg'

function MorePopover({ item, showButton, itemUuidWithPopover, setRenameItemIndex, setItemUuidWithPopover }) {
  const dispatch = useDispatch()
  const morePopoverRef = useRef(null)

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (morePopoverRef.current && !morePopoverRef.current.contains(event.target)) {
        setItemUuidWithPopover(null)
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [morePopoverRef])

  const showDeleteConfirmationPopup = (event) => {
    event.preventDefault()

    setItemUuidWithPopover(null)
    dispatch(
      showPopup({
        component: PopupComponentKeys.FRONT_API_DELETE_AFC_CONFIRMATION,
        extraParameters: [
          { name: 'uuid', value: item.data.uuid },
          { name: 'name', value: item.data.title },
          { name: 'type', value: item.data.type },
        ],
        size: {
          width: '440px',
          height: 'auto',
        },
      }),
    )
  }

  const showRenameForm = (event) => {
    event.preventDefault()
    event.stopPropagation()

    setRenameItemIndex(item.index)
    setItemUuidWithPopover(null)
  }

  const togglePopoverState = (event, itemUuid) => {
    event.preventDefault()
    event.stopPropagation()
    setItemUuidWithPopover(itemUuidWithPopover ? null : itemUuid)
  }

  return (
    <Popover
      isOpen={itemUuidWithPopover === item.index}
      positions={['bottom']} // preferred positions by priority
      content={
        <PopoverContainer onClick={(event) => event.stopPropagation()} width={'200px'} ref={morePopoverRef}>
          <PopoverNavigationItem>
            <PopoverNavigationButton type={'button'} onClick={(event) => showRenameForm(event)}>
              Rename
            </PopoverNavigationButton>
          </PopoverNavigationItem>
          <PopoverNavigationItem>
            <PopoverNavigationButton type={'button'} onClick={(event) => showDeleteConfirmationPopup(event)}>
              Delete
            </PopoverNavigationButton>
          </PopoverNavigationItem>
        </PopoverContainer>
      }
    >
      <MoreButton active={showButton} type={'button'} onClick={(event) => togglePopoverState(event, item.index)}>
        <StyledSVG width={17} height={4} color={lightSilver} src={MoreIcon} />
      </MoreButton>
    </Popover>
  )
}

export default memo(MorePopover)
