import React, { memo } from 'react'
import { Outlet } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import {
  Sidebar,
  SidebarContent,
  SidebarDivider,
  SidebarHeader,
  SidebarNavigation,
  SidebarNavigationItem,
  SidebarNavigationLink,
  SidebarGroupHeader,
  SidebarGroupName,
} from '../../../common/layouts/sidebar-content/styles'
import { H2HeaderTitle } from '../../../common/styles/text'
import { Clear } from '../../../common/styles/styles'
import { ContentContainer, ContentWrapperContainer } from '../../../common/layouts/content-container/styles'

function ProjectSettings() {
  const { projectUuid } = useParams()

  return (
    <ContentContainer>
      <Sidebar background={true} border={true} width={'240px'}>
        <SidebarHeader>
          <H2HeaderTitle>Project settings</H2HeaderTitle>
        </SidebarHeader>
        <SidebarContent>
          <ContentWrapperContainer width={'100%'} marginBottom={'0'} marginTop={'12px'}>
            <ContentWrapperContainer padding={'0 24px'} marginTop={'0'} marginBottom={'0'}>
              <SidebarNavigation>
                <ContentWrapperContainer marginTop={'12px'} marginBottom={'0'}>
                  <SidebarNavigationItem>
                    <SidebarNavigationLink end to={`/project/${projectUuid}/settings`}>
                      General
                    </SidebarNavigationLink>
                  </SidebarNavigationItem>
                </ContentWrapperContainer>
                <ContentWrapperContainer marginTop={'12px'} marginBottom={'0'}>
                  <SidebarGroupHeader>
                    <SidebarGroupName>Resources</SidebarGroupName>
                    <Clear />
                    <SidebarDivider />
                  </SidebarGroupHeader>
                  <ContentWrapperContainer marginTop={'12px'} marginBottom={'0'}>
                    <SidebarNavigationItem>
                      <SidebarNavigationLink to={`/project/${projectUuid}/settings/database`}>
                        Database
                      </SidebarNavigationLink>
                    </SidebarNavigationItem>
                    <SidebarNavigationItem>
                      <SidebarNavigationLink to={`/project/${projectUuid}/settings/redis`}>Redis</SidebarNavigationLink>
                    </SidebarNavigationItem>
                  </ContentWrapperContainer>
                </ContentWrapperContainer>
              </SidebarNavigation>
            </ContentWrapperContainer>
          </ContentWrapperContainer>
        </SidebarContent>
      </Sidebar>
      <ContentContainer left={'240px'}>
        <Outlet />
      </ContentContainer>
    </ContentContainer>
  )
}

export default memo(ProjectSettings)
