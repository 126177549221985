import React, { memo } from 'react'
import { useSelector } from 'react-redux'
import {
  changeName,
  changeRequestStatus,
  resetState,
  selectFormData,
} from '../../../../../../reducers/front-api/function/folder/rename-form'
import { renameFunctionFolder } from './slice'
import RenameForm from '../../../../../common/tree/form/rename-form/RenameForm'

function RenameFolderForm({ item }) {
  const formData = useSelector(selectFormData)

  return (
    <RenameForm
      item={item}
      placeholder={'Folder name...'}
      name={formData.name}
      changeName={changeName}
      changeRequestStatus={changeRequestStatus}
      resetState={resetState}
      renameProcess={renameFunctionFolder}
    />
  )
}

export default memo(RenameFolderForm)
