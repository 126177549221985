import React, { memo, useEffect, useState } from 'react'
import { ContentContainer } from '../../../../common/layouts/content-container/styles'
import { updateFunctionDescription } from './slice'
import {
  DescriptionContainer,
  DescriptionPlaceholder,
  DescriptionText,
  DescriptionTextarea,
  EditButton,
  IconContainer,
} from './styles'
import { Clear } from '../../../../common/styles/styles'
import StyledSVG from '../../../../common/styled-svg/StyledSVG'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { selectFunctionByUuid } from '../../../../../reducers/front-api/front-api'
import EditIcon from '../../../../../assets/images/edit-icon.svg'
import { layoutComponentsBorderColor } from '../../../../common/styles/colors'

function FunctionOverview() {
  const dispatch = useDispatch()

  const { functionUuid, projectUuid } = useParams()

  const _function = useSelector((state) => selectFunctionByUuid(state, functionUuid))

  const [showChangeDescriptionForm, setShowChangeDescriptionForm] = useState(false)
  const [descriptionFieldValue, setDescriptionFieldValue] = useState(_function ? _function.description : '')

  const changeDescriptionHandler = (event) => {
    setDescriptionFieldValue(event.target.value)
  }

  const saveDescriptionHandler = () => {
    if (descriptionFieldValue !== _function.description) {
      dispatch(updateFunctionDescription({ projectUuid, functionUuid, description: descriptionFieldValue }))
    }

    setShowChangeDescriptionForm(false)
  }

  useEffect(() => {
    setDescriptionFieldValue(_function ? _function.description : '')
  }, [functionUuid])

  const descriptionPlaceholder = <DescriptionPlaceholder>Add function description...</DescriptionPlaceholder>

  return (
    <ContentContainer padding={'24px'}>
      <DescriptionContainer
        onClick={(event) => {
          setShowChangeDescriptionForm(true)
        }}
      >
        {!showChangeDescriptionForm && (
          <>
            {_function.description ? (
              <DescriptionText as="div">{_function.description}</DescriptionText>
            ) : (
              descriptionPlaceholder
            )}
            <EditButton>
              <IconContainer>
                <StyledSVG src={EditIcon} color={layoutComponentsBorderColor} width={16} height={16} />
              </IconContainer>
            </EditButton>
            <Clear />
          </>
        )}
        {showChangeDescriptionForm && (
          <DescriptionTextarea
            value={descriptionFieldValue}
            autoFocus
            onChange={changeDescriptionHandler}
            onBlur={saveDescriptionHandler}
          />
        )}
      </DescriptionContainer>
    </ContentContainer>
  )
}

export default memo(FunctionOverview)
