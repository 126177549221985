import styled from 'styled-components'

export const LoadingContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;

  background: rgba(255, 255, 255, 0.9);

  padding-top: 10%;
  text-align: center;
  box-sizing: border-box;

  z-index: 1;
`