import styled from 'styled-components'
import { CallToActionButton, SecondaryActionButton } from '../../../common/styles/styles'

export const EditParameterFormContainer = styled.div`
  position: relative;
  width: 400px;
  padding: 24px;
`

export const ButtonsContainer = styled.div`
  position: relative;
  width: 240px;
  margin: 0 auto;
  margin-top: 40px;
`

export const FieldsContainer = styled.div`
  position: relative;
`

export const CancelButton = styled(SecondaryActionButton)`
  position: relative;
  float: left;
`

export const SaveButton = styled(CallToActionButton)`
  position: relative;
  float: right;
`
