import { createSlice } from '@reduxjs/toolkit'
import { addService } from '../components/service/add-service-popup/slice'
import { deleteGlobalParameter } from '../components/front-api/global-parameters/delete-popup/slice'
import { deleteApiKey } from '../components/front-api/api-keys/delete-popup/slice'
import { deleteFunction } from '../components/front-api/functions/sidebar/popups/delete/slice'
import { createGlobalParameter } from '../components/front-api/global-parameters/add-parameter-form/slice'
import { updateGlobalParameter } from '../components/front-api/global-parameters/edit-parameter-form/slice'
import { createParameter } from '../components/front-api/functions/details/parameters/add-parameter-form/slice'
import { updateParameter } from '../components/front-api/functions/details/parameters/edit-parameter-form/slice'

const initialState = {
  showPopup: false,
  component: null,
  extraParameters: [],
  size: {
    width: '80%',
    height: '80%',
  },
}

const popupSlice = createSlice({
  name: 'popup',
  initialState,
  reducers: {
    showPopup(state, action) {
      state.showPopup = true
      state.component = action.payload.component
      state.extraParameters = action.payload.extraParameters ? action.payload.extraParameters : null

      if (action.payload.size) {
        state.size.width = action.payload.size.width
        state.size.height = action.payload.size.height
      }
    },
    closePopup: () => initialState,
  },
  extraReducers(builder) {
    builder.addCase(addService.fulfilled, () => initialState)
    builder.addCase(deleteGlobalParameter.fulfilled, () => initialState)
    builder.addCase(deleteApiKey.fulfilled, () => initialState)
    builder.addCase(deleteFunction.fulfilled, () => initialState)
    builder.addCase(createGlobalParameter.fulfilled, () => initialState)
    builder.addCase(createParameter.fulfilled, () => initialState)
    builder.addCase(updateParameter.fulfilled, () => initialState)
    builder.addCase(updateGlobalParameter.fulfilled, () => initialState)
  },
})

const { reducer, actions } = popupSlice

export const { showPopup, closePopup } = actions

const selectPopupSlice = (state) => state.popup
export const selectChildComponent = (state) => selectPopupSlice(state).component
export const selectPopupExtraParameters = (state) => selectPopupSlice(state).extraParameters
export const selectPopupSize = (state) => selectPopupSlice(state).size

export default reducer
