import styled from 'styled-components'
import { layoutComponentsBorderColor } from '../../../styles/colors'
import { Link } from 'react-router-dom'

export const HeaderContainer = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 64px;
  border-bottom: solid 1px ${layoutComponentsBorderColor};
  box-sizing: border-box;
`

export const LeftContentContainer = styled.div`
  position: relative;
  width: 40px;
  height: 20px;
  margin-top: 22px;
  margin-left: 24px;
  float: left;
`

export const HomeLink = styled(Link)`
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
`

export const LogoContainer = styled.img`
  position: relative;
  width: 40px;
  height: 20px;

  transition: opacity 275ms ease;

  &:hover {
    opacity: 0.75;
  }
`

export const RightContentContainer = styled.div`
  position: relative;
  height: 100%;
  float: right;
  padding-right: 24px;
`

export const NotificationContainer = styled.div`
  position: relative;
  float: right;
  margin-right: 40px;
`

export const NotificationIconContainer = styled.div`
  position: relative;
  width: 16px;
  height: 18px;
  margin-top: 23px;
  cursor: pointer;
`

export const NotificationIconComponent = styled.img`
  position: relative;
  width: 16px;
  height: 18px;
`
