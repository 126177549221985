import React, { memo, useEffect, useRef } from 'react'
import { lightSilver } from '../../../../../common/styles/colors'
import StyledSVG from '../../../../../common/styled-svg/StyledSVG'
import { Popover } from 'react-tiny-popover'
import { PopoverContainer, PopoverNavigationButton, PopoverNavigationItem } from '../../../../../common/popover/styles'
import AddIcon from '../../../../../../assets/images/add-icon.svg'
import { AddButton } from './styles'

function AddPopover({
  item,
  showButton,
  itemUuidWithPopover,
  setItemUuidWithPopover,
  showCreateFolderFormHandler,
  showCreateFunctionFormHandler,
  isExpanded,
}) {
  const addPopoverRef = useRef(null)

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (addPopoverRef.current && !addPopoverRef.current.contains(event.target)) {
        setItemUuidWithPopover(null)
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [addPopoverRef])

  const togglePopoverState = (event, itemUuid) => {
    event.preventDefault()
    event.stopPropagation()
    setItemUuidWithPopover(itemUuidWithPopover ? null : itemUuid)
  }

  const addFunctionHandler = (event) => {
    event.preventDefault()
    if (isExpanded) {
      // Don't expand if it is expanded
      event.stopPropagation()
    }
    showCreateFunctionFormHandler(item.index, item.data.uuid)
    setItemUuidWithPopover(null)
  }

  const addFolderHandler = (event) => {
    event.preventDefault()
    if (isExpanded) {
      // Don't expand if it is expanded
      event.stopPropagation()
    }
    showCreateFolderFormHandler(item.index, item.data.uuid)
    setItemUuidWithPopover(null)
  }

  return (
    <Popover
      isOpen={itemUuidWithPopover === item.index}
      positions={['bottom']} // preferred positions by priority
      content={
        <PopoverContainer width={'200px'} ref={addPopoverRef}>
          <PopoverNavigationItem>
            <PopoverNavigationButton type={'button'} onClick={(event) => addFunctionHandler(event)}>
              Add function
            </PopoverNavigationButton>
          </PopoverNavigationItem>
          <PopoverNavigationItem>
            <PopoverNavigationButton
              active={itemUuidWithPopover === item.index}
              type={'button'}
              onClick={(event) => addFolderHandler(event)}
            >
              Add folder
            </PopoverNavigationButton>
          </PopoverNavigationItem>
        </PopoverContainer>
      }
    >
      <AddButton active={showButton} type={'button'} onClick={(event) => togglePopoverState(event, item.index)}>
        <StyledSVG width={10} height={10} color={lightSilver} src={AddIcon} />
      </AddButton>
    </Popover>
  )
}

export default memo(AddPopover)
