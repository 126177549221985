import React, { memo } from 'react'
import { useSelector } from 'react-redux'
import {
  changeName,
  changeRequestStatus,
  resetState,
  selectFormData,
} from '../../../../../../reducers/front-api/function/create-form'
import { createFunction } from './slice'
import CreateForm from '../../../../../common/tree/form/create-form/CreateForm'

function CreateFunctionForm({ parentUuid, parentIndex }) {
  const formData = useSelector(selectFormData)

  return (
    <CreateForm
      placeholder={'New function'}
      name={formData.name}
      changeName={changeName}
      parentUuid={parentUuid}
      parentIndex={parentIndex}
      changeRequestStatus={changeRequestStatus}
      resetState={resetState}
      createProcess={createFunction}
    />
  )
}

export default memo(CreateFunctionForm)
