import React, { memo, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  FormContainer as FormContentContainer,
  MiddleFormPageSubtitleContainer,
  MiddleFormPageTitleContainer,
  MiddleFormTopContainer,
} from '../../common/layouts/public-area/middle-form/styles'
import { H1Title, RegularText, SubtitleText } from '../../common/styles/text'
import ErrorMessage from '../../common/error-message/ErrorMessage'
import {
  ButtonWithText,
  ButtonWithTextContainer,
  ButtonWithTextLeftBlock,
  ButtonWithTextRightBlock,
  FieldContainer,
  FormContainer,
} from '../../common/styles/form-styles'
import TextField from '../../common/form/text-field/TextField'
import { ExtraLink } from '../styles'
import { Clear } from '../../common/styles/styles'
import PublicAreaMiddleFormLayout from '../../common/layouts/public-area/middle-form/PublicAreaMiddleFormLayout'
import { createAccount } from './slice'
import {
  changeEmail,
  changeRequestStatus,
  selectError,
  selectRequestStatus,
  selectSignupFormData,
} from '../../../reducers/user/sign-up-form'
import { REQUEST_STATUSES } from '../../../reducers/reducer-helper'
import Loading from '../../common/loading/Loading'
import { lightGray } from '../../common/styles/colors'
function EmailForm() {
  const dispatch = useDispatch()

  const requestStatus = useSelector(selectRequestStatus)
  const formData = useSelector(selectSignupFormData)
  const error = useSelector(selectError)

  const changeEmailProcess = (event) => {
    event.preventDefault()
    dispatch(changeEmail({ email: event.target.value }))
  }

  const createAccountProcess = (event) => {
    event.preventDefault()
    dispatch(changeRequestStatus({ status: REQUEST_STATUSES.PENDING }))
    dispatch(createAccount({ email: formData.email }))
  }

  useEffect(() => {
    return () => {
      dispatch(changeRequestStatus({ status: REQUEST_STATUSES.NOT_TRIGGERED }))
    }
  }, [])

  return (
    <PublicAreaMiddleFormLayout>
      {requestStatus === REQUEST_STATUSES.PENDING && <Loading />}
      <MiddleFormTopContainer>
        <MiddleFormPageTitleContainer>
          <H1Title>Create your new account</H1Title>
        </MiddleFormPageTitleContainer>
        <MiddleFormPageSubtitleContainer>
          <SubtitleText>Please provide your email to continue</SubtitleText>
        </MiddleFormPageSubtitleContainer>
      </MiddleFormTopContainer>

      <ErrorMessage error={error} />

      <FormContentContainer>
        <FormContainer onSubmit={createAccountProcess}>
          <fieldset>
            <FieldContainer>
              <fieldset>
                <TextField
                  label={'Email'}
                  value={formData.email}
                  changeEvent={changeEmailProcess}
                  placeholder={'youremail@email.com'}
                />
              </fieldset>
            </FieldContainer>

            <ButtonWithTextContainer>
              <ButtonWithTextLeftBlock>
                <RegularText color={lightGray}>Already have an account?</RegularText>
                <ExtraLink to="/sign-in">Sign In</ExtraLink>
              </ButtonWithTextLeftBlock>
              <ButtonWithTextRightBlock>
                <ButtonWithText disabled={requestStatus === REQUEST_STATUSES.PENDING} type="submit">
                  Continue
                </ButtonWithText>
              </ButtonWithTextRightBlock>
              <Clear />
            </ButtonWithTextContainer>
          </fieldset>
        </FormContainer>
      </FormContentContainer>
    </PublicAreaMiddleFormLayout>
  )
}

export default memo(EmailForm)
