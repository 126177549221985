import React, { memo } from 'react'
import { Outlet } from 'react-router-dom'
import {
  Sidebar,
  SidebarContent,
  SidebarHeader,
  SidebarNavigation,
  SidebarNavigationItem,
  SidebarNavigationLink,
} from '../../common/layouts/sidebar-content/styles'
import { H2HeaderTitle } from '../../common/styles/text'
import { Content, UserSettingsContainer } from './styles'
import { ContentWrapperContainer } from '../../common/layouts/content-container/styles'

function UserSettings() {
  return (
    <UserSettingsContainer>
      <Sidebar background={true} border={true}>
        <SidebarHeader>
          <H2HeaderTitle>User settings</H2HeaderTitle>
        </SidebarHeader>
        <SidebarContent>
          <ContentWrapperContainer width={'100%'} marginBottom={'0'} marginTop={'12px'}>
            <ContentWrapperContainer padding={'0 24px'} marginTop={'0'} marginBottom={'0'}>
              <SidebarNavigation>
                <SidebarNavigationItem>
                  <SidebarNavigationLink to={`/user/settings/general`}>General</SidebarNavigationLink>
                </SidebarNavigationItem>
                {/* <SidebarNavigationItem>
              <SidebarNavigationLink to={`/user/settings/billing`}>Billing</SidebarNavigationLink>
            </SidebarNavigationItem> */}
              </SidebarNavigation>
            </ContentWrapperContainer>
          </ContentWrapperContainer>
        </SidebarContent>
      </Sidebar>
      <Content>
        <Outlet />
      </Content>
    </UserSettingsContainer>
  )
}

export default memo(UserSettings)
