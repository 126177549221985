import styled from 'styled-components'
import {
  darkGray,
  layoutComponentsBackgroundColor,
  layoutComponentsBorderColor,
  lightGray,
  lightSilver,
  mainBlue,
  regularGray,
  superLightSilver,
} from '../styles/colors'
import StyledSVG from '../styled-svg/StyledSVG'
import { RegularText } from '../styles/text'

////// Create item button
export const CreateItemButtonIconContainer = styled.div`
  position: relative;
  width: 18px;
  height: 18px;

  padding: 4px;
  box-sizing: border-box;

  float: left;
  margin: 7px 0;
  margin-right: 8px;

  line-height: 0;

  background: ${layoutComponentsBorderColor};

  transition: background 275ms ease;

  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
`

export const CreateItemButtonText = styled(RegularText)`
  position: relative;
  float: left;
  font-weight: 600;
  color: ${lightGray};

  transition: color 275ms ease;
`

export const CreateItemButton = styled.button`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 32px;
  padding: 0;
  margin: 0;
  background: none;
  border: none;

  line-height: 32px;

  &:hover {
    cursor: pointer;

    ${CreateItemButtonIconContainer} {
      background: ${regularGray};
    }

    ${CreateItemButtonText} {
      color: ${regularGray};
    }
  }
`

export const CreateItemButtonIcon = styled(StyledSVG)`
  position: relative;
`

/////////////////////////////////////////////////////////////////

export const ItemTitle = styled.span`
  font-size: 14px;
  color: ${regularGray};
`

export const IconContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 14px;
  height: 14px;
`

export const RotatedIconContainer = styled.div`
  transition: transform 275ms ease;
  transform: rotateZ(-90deg);
  ${(props) => props.isExpanded && `transform: rotateZ(0deg);`}
`

export const SortableItemContainer = styled.li`
  position: relative;
  width: 100%;
  min-height: 32px;

  list-style: none;
`

export const SortableItemButtonsContainer = styled.div`
  position: relative;
  width: 60px;
  height: 24px;

  float: right;
  margin-right: 24px;
  margin-top: 4px;

  line-height: 1;

  display: block;
`

export const SortableItemButton = styled.div`
  position: relative;
  width: 100%;
  height: 32px;

  background: ${(props) => (props.isDraggingOver ? layoutComponentsBorderColor : 'none')};
`

export const SortableItemLinkContainer = styled.div`
  position: relative;
  width: 100%;
  height: 32px;
  line-height: 32px;
  box-sizing: border-box;
  padding: 0;
  padding-left: ${(props) => {
    return props.paddingLeft
  }};
  margin: 0;
  border: none;

  cursor: pointer;

  transition: background 275ms ease;

  background: ${(props) => (props.active ? layoutComponentsBackgroundColor : 'none')};

  &:hover {
    background: ${(props) => (props.active ? 'none' : layoutComponentsBackgroundColor)};
  }

  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
`

export const SortableItemIconContainer = styled.div`
  position: relative;
  width: 14px;
  margin-right: 12px;
  line-height: 32px;
  height: 100%;
  float: left;

  display: flex;
  align-items: center;
  justify-content: center;

  margin-left: 24px;
`

export const SortableItemTitleContainer = styled.div`
  position: relative;
  height: 100%;
  float: left;
`

export const DragBetweenLine = styled.div`
  position: absolute;
  width: 100%;
  right: 0;
  top: 0;
  height: 2px;
  background-color: ${mainBlue};
`
