import styled from 'styled-components'
import { layoutComponentsBorderColor } from '../styles/colors'

export const BackButton = styled.button`
  position: relative;
  width: 32px;
  height: 32px;

  float: left;

  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;

  border: 0;

  background: none;
  padding: 0;
  margin: 0;
  margin-right: 8px;

  line-height: normal;

  box-sizing: border-box;

  transition: background-color 275ms ease;

  &:hover {
    cursor: pointer;
    background: ${layoutComponentsBorderColor};

    svg {
      path {
        fill: #ffffff;
      }
    }
  }
`
