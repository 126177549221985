import React, { memo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ErrorMessage from '../../common/error-message/ErrorMessage'
import { ButtonContainer } from '../../common/styles/form-styles'
import {
  FormContainer as FormContentContainer,
  MiddleFormPageSubtitleContainer,
  MiddleFormPageTitleContainer,
  MiddleFormTopContainer,
} from '../../common/layouts/public-area/middle-form/styles'
import { H1Title, SubtitleText } from '../../common/styles/text'
import PublicAreaMiddleFormLayout from '../../common/layouts/public-area/middle-form/PublicAreaMiddleFormLayout'
import { Clear } from '../../common/styles/styles'
import { CodeInputsContainer } from './styles'
import SixDigitsCode from '../../common/six-digits-code/SixDigitsCode'
import ButtonWithIcon from '../../common/button-with-icon/ButtonWithIcon'
import SendIcon from '../../../assets/images/send-icon.svg'
import {
  changeRequestStatus,
  changeToken,
  selectForgotPasswordFormData,
  selectRequestStatus,
  selectError,
} from '../../../reducers/user/forgot-password-form'
import { resendConfirmEmailToken, resetPasswordConfirmEmail } from './slice'
import { REQUEST_STATUSES } from '../../../reducers/reducer-helper'
import Loading from '../../common/loading/Loading'

const CODE_SIZE = 6

function ConfirmEmailForm() {
  const dispatch = useDispatch()

  const requestStatus = useSelector(selectRequestStatus)
  const formData = useSelector(selectForgotPasswordFormData)
  const error = useSelector(selectError)

  const changeTokenProcess = (token) => {
    dispatch(changeToken({ token }))
    if (token.length === CODE_SIZE) {
      dispatch(changeRequestStatus({ status: REQUEST_STATUSES.PENDING }))
      dispatch(resetPasswordConfirmEmail({ token }))
    }
  }

  const resendToken = (event) => {
    event.preventDefault()
    dispatch(resendConfirmEmailToken({ email: formData.email }))
  }

  return (
    <PublicAreaMiddleFormLayout>
      {requestStatus === REQUEST_STATUSES.PENDING && <Loading />}
      <MiddleFormTopContainer>
        <MiddleFormPageTitleContainer>
          <H1Title>Confirm your email</H1Title>
          <Clear />
        </MiddleFormPageTitleContainer>
        <MiddleFormPageSubtitleContainer>
          <SubtitleText>Please confirm your email to reset your password</SubtitleText>
        </MiddleFormPageSubtitleContainer>
      </MiddleFormTopContainer>

      <ErrorMessage error={error} />

      <FormContentContainer>
        <fieldset>
          <CodeInputsContainer>
            <fieldset>
              <SixDigitsCode handler={changeTokenProcess} />
            </fieldset>
          </CodeInputsContainer>

          <ButtonContainer>
            <ButtonWithIcon text={'Resend code'} icon={SendIcon} action={resendToken} />
          </ButtonContainer>
        </fieldset>
      </FormContentContainer>
    </PublicAreaMiddleFormLayout>
  )
}

export default memo(ConfirmEmailForm)
