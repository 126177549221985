import React, { memo } from 'react'
import StyledSVG from '../../../../common/styled-svg/StyledSVG'
import { lightSilver } from '../../../../common/styles/colors'
import AddIcon from '../../../../../assets/images/add-icon.svg'
import { AddNodeButtonComponent, ButtonText } from './styles'

function AddNodeButton({ setParent }) {
  return (
    <AddNodeButtonComponent onClick={setParent}>
      <StyledSVG width={18} height={18} color={lightSilver} src={AddIcon} />
      <ButtonText>Add node</ButtonText>
    </AddNodeButtonComponent>
  )
}

export default memo(AddNodeButton)
