import React, { memo, useEffect } from 'react'
import { AddServiceDetailsContainer } from './styles'
import ServiceOverview from '../../../../../service/overview/ServiceOverview'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { getServiceDetails } from '../../../../../service/overview/slice'
import { changeRequestStatus, serviceUnloaded } from '../../../../../../reducers/service/service-details'
import { REQUEST_STATUSES } from '../../../../../../reducers/reducer-helper'

function AddServiceDetails() {
  const dispatch = useDispatch()

  let { serviceUuid } = useParams()

  useEffect(() => {
    dispatch(changeRequestStatus({ status: REQUEST_STATUSES.PENDING }))
    const fetchService = dispatch(getServiceDetails({ serviceUuid }))

    return () => {
      fetchService.abort()
      dispatch(serviceUnloaded())
    }
  }, [serviceUuid])

  return (
    <AddServiceDetailsContainer>
      <ServiceOverview />
    </AddServiceDetailsContainer>
  )
}

export default memo(AddServiceDetails)
