import { createSlice } from '@reduxjs/toolkit'
import { Status } from '../../utils/utils'
import { signIn } from '../../components/user/sign-in/slice'
import { failed } from './../reducer-helper'
import {
  resendConfirmationToken,
  createAccount,
  confirmSignUpEmail,
  createProfile,
} from '../../components/user/sign-up/slice'
import { initApp } from '../../components/app/slice'
import {
  confirmNewEmail,
  deleteProfileImage,
  updateProfileImage,
  updateProfileInformation,
} from '../../components/user/settings/general/slice'

const initialState = {
  user: null,
  error: null,
  status: null,
}

function success(state) {
  state.status = Status.SUCCESS
  delete state.error
}

const userSlice = createSlice({
  name: 'user',
  initialState,
  extraReducers(builder) {
    builder.addCase(initApp.fulfilled, (state, action) => {
      state.user = action.payload.user
    })
    builder.addCase(createAccount.fulfilled, (state) => {
      success(state)
    })
    builder.addCase(createAccount.rejected, failed)
    builder.addCase(updateProfileInformation.fulfilled, (state, action) => {
      state.user.name = action.payload.user.name
    })
    builder.addCase(updateProfileImage.fulfilled, (state, action) => {
      state.user.image = action.payload.image
    })
    builder.addCase(deleteProfileImage.fulfilled, (state) => {
      state.user.image = null
    })
    builder.addCase(confirmNewEmail.fulfilled, (state, action) => {
      state.user.email = action.payload.email
    })
    builder.addCase(confirmSignUpEmail.fulfilled, (state) => {
      success(state)
    })
    builder.addCase(confirmSignUpEmail.rejected, failed)
    builder.addCase(createProfile.fulfilled, (state, action) => {
      success(state)
      state.user = action.payload.user
    })
    builder.addCase(createProfile.rejected, failed)
    builder.addCase(signIn.fulfilled, (state, action) => {
      success(state)
      state.user = action.payload.user
    })
    builder.addCase(signIn.rejected, failed)
    builder.addCase(resendConfirmationToken.rejected, failed)
    builder.addCase(resendConfirmationToken.fulfilled, success)

    builder.addMatcher(
      (action) => /user\/.*\/pending/.test(action.type),
      (state) => {
        state.status = Status.LOADING
      },
    )
  },
})

const selectUserSlice = (state) => state.user
export const selectUser = (state) => selectUserSlice(state).user
export const selectIsLoading = (state) => selectUserSlice(state).status === Status.LOADING
export const selectError = (state) => selectUserSlice(state).error

const { reducer } = userSlice

export default reducer
