import { memo } from 'react'
import styled from 'styled-components'
import SVG from 'react-inlinesvg'

const StyledSVG = styled(SVG)`
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
  line-height: 0;
  & path {
    transition: fill 275ms ease;
    fill: ${({ color }) => color};
  }
`

export default memo(StyledSVG)
