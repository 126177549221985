import styled from 'styled-components'

export const EditorContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`

export const EditorReference = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`

export const InsertParameterButtonContainer = styled.div`
  position: absolute;
  width: 24px;
  height: 24px;
  right: 4px;
  top: 4px;
`
