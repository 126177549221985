import React, { memo } from 'react'
import { useRef, useEffect, useState } from 'react'
import {
  DeleteButtonContainer,
  PreviewImage,
  PreviewImageContainer,
  UploadButtonContainer,
  UploadImageIconContainer,
  UploadImageWithPreviewContainer,
} from './styles'
import UploadIcon from '../../../../assets/images/add-image.svg'
import DeleteIcon from '../../../../assets/images/delete-icon.svg'
import StyledSVG from '../../styled-svg/StyledSVG'
import { CDN_URL } from '../../../../configs'

const UploadImageWithPreview = ({
  usePreviewImage = true,
  previewImage = null,

  existingImage = null,
  deleteImageHandler = null,

  changeImageHandler = null,

  addIcon = null,
  addIconWidth = 32,
  addIconHeight = 32,
}) => {
  const [selectedFile, setSelectedFile] = useState()
  const [preview, setPreview] = useState()
  const hiddenFileInput = useRef(null)

  const handleClick = (event) => {
    hiddenFileInput.current.click()
  }

  if (usePreviewImage) {
    if (previewImage && !selectedFile) {
      setPreview(URL.createObjectURL(previewImage))
      setSelectedFile(previewImage)
    }

    // create a preview as a side effect, whenever selected file is changed
    useEffect(() => {
      if (!selectedFile) {
        setPreview(undefined)
        return
      }

      const objectUrl = URL.createObjectURL(selectedFile)
      setPreview(objectUrl)

      // free memory when ever this component is unmounted
      return () => URL.revokeObjectURL(objectUrl)
    }, [selectedFile])
  }

  const onSelectFile = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined)
      return
    }

    // I've kept this example simple by using the first image instead of multiple
    const file = e.target.files[0]
    setSelectedFile(file)
    if (changeImageHandler) {
      changeImageHandler(file)
    }
  }

  const deleteImage = (event) => {
    event.preventDefault()
    event.stopPropagation()

    if (selectedFile) {
      setPreview(undefined)
      setSelectedFile(undefined)      
    }

    if (deleteImageHandler) {
      deleteImageHandler()
    }
  }

  return (
    <UploadImageWithPreviewContainer>
      <UploadButtonContainer onClick={handleClick}>
        {existingImage && (
          <PreviewImageContainer>
            <PreviewImage src={`${CDN_URL}/${existingImage}`} />
            <DeleteButtonContainer onClick={deleteImage}>
              <StyledSVG width={12} height={12} color={'#FFFFFF'} src={DeleteIcon} />
            </DeleteButtonContainer>
          </PreviewImageContainer>
        )}

        {!existingImage && selectedFile && (
          <PreviewImageContainer>
            <PreviewImage src={preview} />
            <DeleteButtonContainer onClick={deleteImage}>
              <StyledSVG width={12} height={12} color={'#FFFFFF'} src={DeleteIcon} />
            </DeleteButtonContainer>
          </PreviewImageContainer>
        )}
        <UploadImageIconContainer>
          <StyledSVG
            width={addIconWidth}
            height={addIconHeight}
            color={'#FFFFFF'}
            src={addIcon ? addIcon : UploadIcon}
          />
        </UploadImageIconContainer>
      </UploadButtonContainer>

      <input
        ref={hiddenFileInput}
        onClick={(event) => {
          event.target.value = null
        }}
        type="file"
        style={{ display: 'none' }}
        onChange={onSelectFile}
      />
    </UploadImageWithPreviewContainer>
  )
}

export default memo(UploadImageWithPreview)
