import styled from 'styled-components'
import { darkGray, redTextColor } from '../../../common/styles/colors'

export const FieldContainer = styled.div`
  position: relative;
  width: 251px;
  height: 32px;
  float: left;
`

export const RawInput = styled.input`
  position: relative;
  font-weight: 500;
  line-height: 32px;

  height: 32px;
  color: ${darkGray};

  background: none;
  padding: 0;
  margin: 0;
  border: none;

  font-size: 14px

  &::-webkit-input-placeholder {
    /* Edge */
    color: ${redTextColor};
    opacity: 0.6;
  }

  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: ${redTextColor};
    opacity: 0.6;
  }

  &::placeholder {
    color: ${redTextColor};
    opacity: 0.6;
  }
`
