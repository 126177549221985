import React, { memo, useEffect } from 'react'
import { SearchBarComponent, SearchIconContainer } from './styles'
import SearchIcon from '../../../assets/images/search-icon.svg'
import { layoutComponentsBackgroundColor, lightGray, mainBlue } from '../styles/colors'
import StyledSVG from '../styled-svg/StyledSVG'

const Search = ({
  searchFieldComponentRef,
  activeIcon,
  showSearchField,
  setShowSearchField,
  backgroundColor = layoutComponentsBackgroundColor,
}) => {
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchFieldComponentRef.current && !searchFieldComponentRef.current.contains(event.target)) {
        setShowSearchField(false)
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [showSearchField])

  const toggleSearchField = () => {
    setShowSearchField(!showSearchField)
  }

  return (
    <SearchBarComponent>
      <SearchIconContainer onClick={toggleSearchField} backgroundColor={backgroundColor}>
        <StyledSVG width={16} height={16} src={SearchIcon} color={activeIcon ? mainBlue : lightGray} />
      </SearchIconContainer>
    </SearchBarComponent>
  )
}

export default memo(Search)
