import styled from 'styled-components'
import {
  layoutComponentsBackgroundColor,
  layoutComponentsBorderColor,
  lightGray,
  mainBlue,
  regularGray,
} from '../../styles/colors'
import { NavLink } from 'react-router-dom'

export const Sidebar = styled.div`
  position: absolute;
  left: ${(props) => (props.left ? props.left : 0)};
  top: 0;
  bottom: 0;
  width: ${(props) => (props.width ? props.width : '400px')};

  box-sizing: border-box;

  ${(props) => {
    if (props.background) {
      return `background: ${layoutComponentsBackgroundColor};`
    }
  }}

  ${(props) => {
    if (props.border) {
      return `border-right: 1px solid ${layoutComponentsBorderColor};`
    }
  }}
`

export const SecondSidebar = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 240px;
`

export const SecondSidebarNavigationContainer = styled.div`
  position: relative;
  width: 100%;
  margin-top: 24px;
`

export const SecondSidebarNavigationLink = styled(NavLink)`
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  line-height: 32px;
  padding: 0 24px;
  box-sizing: border-box;
  text-decoration: none;
  color: ${regularGray};
  font-size: 14px;
  transition: color 275ms ease;

  &.active {
    color: ${mainBlue};
  }

  &:hover {
    cursor: pointer;
    color: ${mainBlue};
  }
`

export const SidebarHeader = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  height: 48px;
  border-bottom: solid 1px ${layoutComponentsBorderColor};
  box-sizing: border-box;
  padding: 0 24px;
`

export const SidebarContent = styled.div`
  position: absolute;
  left: 0;
  top: 48px;
  bottom: 0;
  width: 100%;
`

export const SidebarDivider = styled.div`
  position: relative;
  width: 100%;
  height: 1px;
  margin: 0 auto;
  background: ${layoutComponentsBorderColor};
`

export const SidebarNavigationLink = styled(NavLink)`
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  line-height: 32px;
  box-sizing: border-box;
  text-decoration: none;
  color: ${regularGray};
  font-size: 14px;
  transition: color 275ms ease;
  cursor: pointer;

  &.active {
    color: ${mainBlue};
  }

  &:hover {
    color: ${mainBlue};
  }
`

export const SidebarNavigation = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`

export const SidebarNavigationItem = styled.div`
  position: relative;
  width: 100%;
  height: 32px;
`

export const SidebarGroupHeader = styled.div`
  position: relative;
  width: 100%;
  height: 32px;
  line-height: 31px;
`

export const SidebarGroupName = styled.div`
  position: relative;
  height: 32px;
  line-height: 32px;
  font-size: 11px;
  color: ${lightGray};
  float: left;
  text-transform: uppercase;
`
