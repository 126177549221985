import { createSlice } from '@reduxjs/toolkit'
import {
  updateProfileInformation,
  changePassword as changePasswordSlice,
  confirmNewEmail,
  updateProfileImage,
  deleteProfileImage,
} from '../../components/user/settings/general/slice'
import { failed, resetErrors, resetStatus } from '../reducer-helper'

const initialState = {
  settings: {
    name: '',
    email: '',
    emailConfirmationPassword: '',
    password: '',
    oldPassword: '',
    confirmNewEmailToken: '',
  },
  changeEmailRequestSent: false,
  formLoaded: false,
  error: null,
  status: null,
}

const userGeneralSettingsFormSlice = createSlice({
  name: 'userGeneralSettingsForm',
  initialState,
  reducers: {
    changeName: (state, action) => {
      state.settings.name = action.payload.name
    },
    changeEmail: (state, action) => {
      state.settings.email = action.payload.email
    },
    changePassword: (state, action) => {
      state.settings.password = action.payload.password
    },
    changeEmailConfirmationPassword: (state, action) => {
      state.settings.emailConfirmationPassword = action.payload.emailConfirmationPassword
    },
    changeConfirmNewEmailToken: (state, action) => {
      state.settings.confirmNewEmailToken = action.payload.token
    },
    changeOldPassword: (state, action) => {
      state.settings.oldPassword = action.payload.oldPassword
    },
    changeRequestStatus: (state, action) => {
      state.status = action.payload.status
    },
    setSettings: (state, action) => {
      state.settings.name = action.payload.name
      state.settings.email = action.payload.email

      state.formLoaded = true
    },
    resetForm: () => initialState,
  },
  extraReducers(builder) {
    builder.addCase(updateProfileInformation.fulfilled, (state, action) => {
      state.changeEmailRequestSent = action.payload.emailChanged
      resetStatus(state)
      resetErrors(state)
    })
    builder.addCase(updateProfileImage.fulfilled, (state) => {
      resetStatus(state)
      resetErrors(state)
    })
    builder.addCase(deleteProfileImage.fulfilled, (state) => {
      resetStatus(state)
      resetErrors(state)
    })
    builder.addCase(updateProfileInformation.rejected, failed)
    builder.addCase(changePasswordSlice.fulfilled, (state, action) => {
      resetStatus(state)
      resetErrors(state)
      state.settings.password = ''
      state.settings.oldPassword = ''
    })
    builder.addCase(changePasswordSlice.rejected, failed)
    builder.addCase(confirmNewEmail.fulfilled, (state, action) => {
      resetStatus(state)
      resetErrors(state)
      state.changeEmailRequestSent = false
      state.settings.emailConfirmationPassword = ''
    })
    builder.addCase(confirmNewEmail.rejected, failed)
  },
})

const selectUserGeneralSettingsFormSlice = (state) => state.userGeneralSettingsForm
export const selectSettings = (state) => selectUserGeneralSettingsFormSlice(state).settings
export const isSettingsFormLoaded = (state) => selectUserGeneralSettingsFormSlice(state).formLoaded

export const selectChangeEmailRequestSent = (state) => selectUserGeneralSettingsFormSlice(state).changeEmailRequestSent
export const selectError = (state) => selectUserGeneralSettingsFormSlice(state).error
export const selectRequestStatus = (state) => selectUserGeneralSettingsFormSlice(state).status

const { reducer, actions } = userGeneralSettingsFormSlice

export const {
  changeName,
  changeEmail,
  changePassword,
  changeEmailConfirmationPassword,
  changeOldPassword,
  setSettings,
  resetForm,
  changeRequestStatus,
  changeConfirmNewEmailToken,
} = actions

export default reducer
