import React, { memo } from 'react'
import { AddButtonContainer, StatusContainer, StatusText } from './styles'
import { CallToActionButton, Clear } from '../../../common/styles/styles'
import { showPopup } from '../../../../reducers/popup'
import { useDispatch, useSelector } from 'react-redux'
import { selectServiceDetails } from '../../../../reducers/service/service-details'
import { ServiceStatusLabels } from '../../../../utils/utils'
import { PopupComponentKeys } from '../../../common/layouts/popup/Popup'
import { selectProjectServices } from '../../../../reducers/project/project-services'
import { H2HeaderTitle } from '../../../common/styles/text'
import StatusLabel from '../../../common/status-label/StatusLabel'
import { HeaderContainer } from '../../../common/layouts/content-block-header/styles'

function Header() {
  const dispatch = useDispatch()

  const service = useSelector(selectServiceDetails)
  const projectServices = useSelector(selectProjectServices)
  const projectService = projectServices
    ? projectServices.find((projectService) => projectService.uuid === service.uuid)
    : null

  const showAddServiceForm = () => {
    dispatch(showPopup({ component: PopupComponentKeys.ADD_SERVICE }))
  }

  return (
    <HeaderContainer>
      <div>
        {!projectService && <H2HeaderTitle>{service.name}</H2HeaderTitle>}
        {projectService && (
          <StatusContainer>
            <StatusLabel status={projectService.inProject.status} />
            <StatusText>{ServiceStatusLabels[projectService.inProject.status]}</StatusText>
          </StatusContainer>
        )}
      </div>

      <div>
        {!projectService && (
          <AddButtonContainer>
            <CallToActionButton onClick={showAddServiceForm}>Add to project</CallToActionButton>
          </AddButtonContainer>
        )}
      </div>
    </HeaderContainer>
  )
}

export default memo(Header)
