import styled from 'styled-components'
import {
  darkGray,
  greenTextColor,
  lightGray,
  mainBlue,
  orangeTextColor,
  redTextColor,
  regularGray,
  yellowTextColor,
} from '../../common/styles/colors'
import { Link } from 'react-router-dom'
import { RegularText } from '../../common/styles/text'

export const PageContainer = styled.div`
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  bottom: 0;
`

export const HeaderContainer = styled.div`
  position: absolute;
  width: 100%;
  top: 0;
  height: 48px;
  left: 0;
  right: 0;
`

export const PageContentContainer = styled.div`
  position: absolute;
  top: 48px;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 24px;
  overflow: scroll;
  box-sizing: border-box;
`

export const Description = styled.div`
  position: relative;
  width: 100%;
  line-height: 150%;
`

export const ContentBlock = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: 48px;
`

export const ContentTitle = styled.h3`
  position: relative;
  width: 100%;
  font-size: 16px;
  color: ${darkGray};
  font-weight: 500;
`

export const LightTitle = styled.span`
  color: ${regularGray};
`

export const Content = styled.div`
  position: relative;
  width: 100%;
  padding: 24px 0;
`

export const DependencyContainer = styled.div`
  position: relative;
  float: left;
  margin-right: 24px;
`

export const DependencyLink = styled(Link)`
  position: relative;
  float: left;
  font-size: 14px;
  color: ${regularGray};

  transition: color 275ms ease;

  &:hover {
    color: ${lightGray};
  }
`

export const EndpointMethod = styled.div`
  position: relative;

  padding: 4px 6px;

  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  line-height: 1;

  font-size: 12px;
  font-weight: 600;

  width: fit-content;

  color: #ffffff;

  background: ${(props) => {
    if (props.method) {
      switch (props.method) {
        case 'GET':
          return greenTextColor
        case 'POST':
          return yellowTextColor
        case 'PATCH':
          return orangeTextColor
        case 'DELETE':
          return redTextColor
      }
    }

    return mainBlue
  }};
`

export const EndpointName = styled(RegularText)`
  position: relative;
`
