import React, { memo, useEffect, useState } from 'react'
import { Content, ProjectServicesContainer } from './styles'
import { Sidebar, SidebarContent, SidebarHeader } from '../../../common/layouts/sidebar-content/styles'
import { H2HeaderTitle, RegularText } from '../../../common/styles/text'
import { Link, Outlet, useParams } from 'react-router-dom'
import { CallToActionButton } from '../../../common/styles/styles'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectProjectServices,
  selectRequestStatus,
  servicesUnloaded,
} from '../../../../reducers/project/project-services'
import { getProjectServices } from './slice'
import { selectProject } from '../../../../reducers/project/project-details'
import ProjectServiceItem from './ProjectServiceItem'
import Loading from '../../../common/loading/Loading'
import { REQUEST_STATUSES } from '../../../../reducers/reducer-helper'
import { ContentWrapperContainer } from '../../../common/layouts/content-container/styles'
import PlusIcon from '../../../../assets/images/add-icon.svg'
import StyledSVG from '../../../common/styled-svg/StyledSVG'

function ProjectServices() {
  const dispatch = useDispatch()

  const projectServices = useSelector(selectProjectServices)
  const project = useSelector(selectProject)

  const [skServices, setSkServices] = useState([])

  const requestStatus = useSelector(selectRequestStatus)

  let filteredSkServices = []

  let { projectUuid } = useParams()

  useEffect(() => {
    const fetchServices = dispatch(getProjectServices({ projectUuid }))

    return () => {
      dispatch(servicesUnloaded())
      fetchServices.abort()
    }
  }, [])

  useEffect(() => {
    if (projectServices) {
      // Todo remove api_gateway and move aliases to the const
      filteredSkServices = projectServices.filter(
        (service) => service.alias !== 'front_api_application' && service.alias !== 'api_gateway',
      )

      setSkServices(filteredSkServices)
    }
  }, [projectServices])

  return (
    <ProjectServicesContainer>
      <Sidebar border={true} background={true}>
        {requestStatus === REQUEST_STATUSES.PENDING && <Loading />}
        <SidebarHeader>
          <H2HeaderTitle>Project services</H2HeaderTitle>
          <CallToActionButton as={Link} to={`/project/${project.uuid}/services/add`}>
            <StyledSVG width={12} height={12} color={'#FFFFFF'} src={PlusIcon} />
            <span>Add new service</span>
          </CallToActionButton>
        </SidebarHeader>

        {requestStatus === REQUEST_STATUSES.DONE && projectServices != null && (
          <SidebarContent>
            {skServices.length === 0 && (
              <ContentWrapperContainer padding={'0 24px'} marginTop={'12px'}>
                <RegularText>There are no services found</RegularText>
              </ContentWrapperContainer>
            )}
            <ContentWrapperContainer marginTop={'16px'}>
              {skServices.map((service) => (
                <ProjectServiceItem service={service} key={service.uuid} />
              ))}
            </ContentWrapperContainer>
          </SidebarContent>
        )}
      </Sidebar>
      {requestStatus === REQUEST_STATUSES.DONE && projectServices != null && (
        <Content>
          <Outlet />
        </Content>
      )}
    </ProjectServicesContainer>
  )
}

export default memo(ProjectServices)
