import React, { memo } from 'react'
import { ContentContainer, ContentWrapperContainer } from '../../../../common/layouts/content-container/styles'
import StyledSVG from '../../../../common/styled-svg/StyledSVG'
import { lightSilver } from '../../../../common/styles/colors'
import FlowIcon from '../../../../../assets/images/flow-icon.svg'
import { ButtonContainer, InfoMessage } from './styles'
import { CallToActionButton } from '../../../../common/styles/styles'
import { Link, useParams } from 'react-router-dom'

function FunctionFlow() {
  const { projectUuid, functionUuid } = useParams()

  return (
    <ContentContainer padding={'24px'}>
      <ContentWrapperContainer marginTop={'64px'} width={'360px'} alignCenter={true}>
        <ContentWrapperContainer marginTop={'0px'} width={'72px'} alignCenter={true}>
          <StyledSVG width={72} height={72} color={lightSilver} src={FlowIcon} />
        </ContentWrapperContainer>
        <InfoMessage>Here you can manage function flow</InfoMessage>
        <ButtonContainer>
          <CallToActionButton as={Link} to={`/project/${projectUuid}/front-api/functions/${functionUuid}/flow/builder`}>
            Manage flow
          </CallToActionButton>
        </ButtonContainer>
      </ContentWrapperContainer>
    </ContentContainer>
  )
}

export default memo(FunctionFlow)
