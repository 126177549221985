import { createAsyncThunk } from '@reduxjs/toolkit'
import { FrontApiAgent } from '../../../../../../api/frontApiAgent'

export const createParameter = createAsyncThunk(
  'front-api/functions/parameters/create',
  async ({ projectUuid, functionUuid, name, isRequired, description }, thunkApi) => {
    try {
      const parameter = await FrontApiAgent.createFunctionParameter(
        projectUuid,
        functionUuid,
        name,
        isRequired,
        description,
      )

      return { functionUuid, parameter }
    } catch (error) {
      return thunkApi.rejectWithValue(error)
    }
  },
)
