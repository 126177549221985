import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectUser } from '../../reducers/user/user'
import { useNavigate } from 'react-router'
import PrivateArea from '../common/layouts/private-area/PrivateArea'

const ProtectedRoute = ({ children }) => {
  const [authChecked, setAuthChecked] = useState(false)
  const user = useSelector(selectUser)
  const navigate = useNavigate()

  useEffect(() => {
    if (!user) {
      navigate('/sign-in')
    }

    setAuthChecked(true)
  }, [])

  return authChecked ? <PrivateArea>{children}</PrivateArea> : <span>Checking</span>
}

export default ProtectedRoute
