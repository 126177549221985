import styled from 'styled-components'
import { darkGray, regularGray } from './colors'

export const H1Title = styled.h1`
  font-size: 24px;
  color: ${darkGray};
`

export const H2Title = styled.h2`
  font-size: 14px;
  font-weight: 600;
  color: ${darkGray};
`

export const H2HeaderTitle = styled.h2`
  font-size: 14px;
  font-weight: 600;
  line-height: normal;
  color: ${darkGray};
`

export const H1TitleWithButton = styled(H1Title)`
  line-height: 32px;
`

export const SubtitleText = styled.p`
  font-size: 14px;
  color: ${regularGray};
  line-height: 150%;
`

export const RegularText = styled.p`
  font-size: 14px;
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : 'normal')};
  color: ${(props) => (props.color ? props.color : regularGray)};
`
