import React, { memo } from 'react'
import { Content, ContentDescription } from './styles'
import { H2Title, RegularText } from '../../../common/styles/text'
import { useDispatch, useSelector } from 'react-redux'
import { addResource, selectResources } from '../../../../reducers/service/add-service-form'
import { ContentWrapperContainer } from '../../../common/layouts/content-container/styles'
import { ResourceSizes, ResourceTypes } from '../../../../utils/utils'
import SelectboxField from '../../../common/form/selectbox/SelectboxField'
import { FieldContainer } from '../../../common/styles/form-styles'

function Resources({ resourcesToSetup }) {
  const dispatch = useDispatch()
  const resources = useSelector(selectResources)

  const resourceName = (resourceTypes) => {
    switch (resourceTypes) {
      case ResourceTypes.DB:
        return 'Database'

      case ResourceTypes.REDIS:
        return 'Redis'
    }
  }

  const resourceSizePlaceholder = (resourceTypes) => {
    switch (resourceTypes) {
      case ResourceTypes.DB:
        return 'DB size'

      case ResourceTypes.REDIS:
        return 'Redis size'
    }
  }

  const changeResourceSizeProcess = (resourceTypes, value) => {
    dispatch(addResource({ resourceConfigs: { type: resourceTypes, size: value } }))
  }

  const resourceSizeOptions = Object.values(ResourceSizes)

  return (
    <Content>
      <ContentDescription>
        <RegularText>Please provide a configurations for the resources that are required for this service</RegularText>
      </ContentDescription>

      {resourcesToSetup.map((resourceType) => {
        const resourceSettings = resources.find((s) => s.type === resourceType)

        return (
          <ContentWrapperContainer key={resourceType} marginBottom={'48px'}>
            <H2Title>{resourceName(resourceType)}</H2Title>
            <ContentWrapperContainer width={'360px'} height={'auto'} marginBottom={'0'}>
              <FieldContainer>
                <fieldset>
                  <SelectboxField
                    label={'Size'}
                    selectedValue={resourceSettings ? resourceSettings.size : null}
                    placeholder={resourceSizePlaceholder(resourceType)}
                    options={resourceSizeOptions}
                    changeValueHandler={(value) => {
                      changeResourceSizeProcess(resourceType, value)
                    }}
                  />
                </fieldset>
              </FieldContainer>
            </ContentWrapperContainer>
          </ContentWrapperContainer>
        )
      })}
    </Content>
  )
}

export default memo(Resources)
