import React, { memo } from 'react'
import { TextInput, TextInputForList } from './styles'
import { FieldLabel, Label } from '../styles'

const TextField = ({ label, value, changeEvent, placeholder, disabled = false, type = 'text', isInList = false }) => {
  return (
    <FieldLabel>
      {label && <Label>{label}</Label>}
      {!isInList && (
        <TextInput type={type} placeholder={placeholder} value={value} disabled={disabled} onChange={changeEvent} />
      )}
      {isInList && (
        <TextInputForList
          type={type}
          placeholder={placeholder}
          value={value}
          disabled={disabled}
          onChange={changeEvent}
        />
      )}
    </FieldLabel>
  )
}

export default memo(TextField)
