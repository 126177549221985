import React, { memo, useEffect, useState } from 'react'
import ServicePreview from './ServicePreview'
import { useDispatch, useSelector } from 'react-redux'
import Loading from '../../common/loading/Loading'
import { Content } from './styles'
import {
  selectAllServices,
  selectRequestStatus as selectAllServicesRequestStatus,
  servicesUnloaded,
} from '../../../reducers/service/service-list'
import { getAllServices } from './slice'
import {
  selectProjectServices,
  selectRequestStatus as selectProjectServicesRequestStatus,
} from '../../../reducers/project/project-services'
import { REQUEST_STATUSES } from '../../../reducers/reducer-helper'
import { ContentWrapperContainer } from '../../common/layouts/content-container/styles'
import { RegularText } from '../../common/styles/text'

function AllServices({ excludeAdded = false, searchQuery = '' }) {
  const dispatch = useDispatch()

  const services = useSelector(selectAllServices)
  const projectServices = useSelector(selectProjectServices)
  const allServicesRequestStatus = useSelector(selectAllServicesRequestStatus)
  const projectServicesRequestStatus = useSelector(selectProjectServicesRequestStatus)

  const [filteredServices, setFilteredServices] = useState([])

  useEffect(() => {
    const fetchServices = dispatch(getAllServices())
    return () => {
      dispatch(servicesUnloaded())
      fetchServices.abort()
    }
  }, [])

  useEffect(() => {
    setFilteredServices(services)
    if (services && searchQuery) {
      // Todo remove api_gateway and move aliases to the const
      const filterResult = services.filter(
        (service) =>
          service.name.toLowerCase().search(searchQuery.toLowerCase()) !== -1 &&
          service.alias !== 'api_gateway' &&
          service.alias !== 'front_api_application',
      )
      setFilteredServices(filterResult)
    }
  }, [services, searchQuery])

  return (
    <Content>
      {(allServicesRequestStatus === REQUEST_STATUSES.PENDING ||
        projectServicesRequestStatus === REQUEST_STATUSES.PENDING) && <Loading />}
      {allServicesRequestStatus === REQUEST_STATUSES.DONE && projectServicesRequestStatus === REQUEST_STATUSES.DONE && (
        <>
          {filteredServices.length > 0 && (
            <ContentWrapperContainer marginTop={'16px'}>
              {filteredServices.map((service) => {
                const inProject =
                  projectServices.findIndex((projectService) => projectService.uuid === service.uuid) !== -1

                if (!excludeAdded || !inProject) {
                  return <ServicePreview service={service} key={service.uuid} />
                }
              })}
            </ContentWrapperContainer>
          )}
          {filteredServices.length === 0 && (
            <ContentWrapperContainer margin={0} marginTop={'0px'} padding={'24px'}>
              <RegularText>There are no services found</RegularText>
            </ContentWrapperContainer>
          )}
        </>
      )}
    </Content>
  )
}

export default memo(AllServices)
