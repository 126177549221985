import React, { memo } from 'react'
import { Button, ButtonIcon, ButtonText } from './styles'
import { Clear } from '../styles/styles'
import { mainBlue } from '../styles/colors'

const ButtonWithIcon = ({ text, icon, action, width = 24, height = 24, iconColor = mainBlue }) => {
  return (
    <Button onClick={action}>
      <ButtonIcon src={icon} width={width} height={height} color={iconColor} />
      <ButtonText>{text}</ButtonText>
      <Clear />
    </Button>
  )
}

export default memo(ButtonWithIcon)
