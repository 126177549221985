export const Status = {
  IDLE: 'idle',
  LOADING: 'loading',
  SUCCESS: 'success',
  FAILURE: 'failure',
}

export const ProjectStatuses = {
  CREATING: 'creating',
  DEPLOYED: 'deployed',
  FAILED: 'failed',
}

export const ResourceTypes = {
  DB: 'database',
  REDIS: 'redis',
}

export const ResourceStatuses = {
  NOT_CREATED: 'not-created',
  CREATING: 'creating',
  UPDATING: 'updating',
  DEPLOYED: 'deployed',
  DELETED: 'deleted',
  FAILED: 'failed',
}

export const ResourceStatusLabels = {
  [ResourceStatuses.NOT_CREATED]: 'Not created',
  [ResourceStatuses.CREATING]: 'Creating',
  [ResourceStatuses.UPDATING]: 'Updating',
  [ResourceStatuses.DEPLOYED]: 'Deployed',
  [ResourceStatuses.DELETED]: 'Deleted',
  [ResourceStatuses.FAILED]: 'Failed',
}

export const ServiceStatuses = {
  NOT_CREATED: 'not-created',
  WAITING_FOR_RESOURCES: 'waiting_for_resources',
  CREATING: 'creating',
  DEPLOYED: 'deployed',
  FAILED: 'failed',
}

export const ServiceStatusLabels = {
  [ServiceStatuses.NOT_CREATED]: 'Not created',
  [ServiceStatuses.WAITING_FOR_RESOURCES]: 'Waiting for resources',
  [ServiceStatuses.CREATING]: 'Creating',
  [ServiceStatuses.DEPLOYED]: 'Deployed',
  [ServiceStatuses.FAILED]: 'Failed',
}

export const FolderTreeItemTypes = {
  ROOT: 'root',
  FOLDER: 'folder',
  COLLECTION: 'collection',
  FUNCTION: 'action', // Todo change this to function
  SCENARIO: 'flow',
  RESULT_OBJECT: 'data-object', // Todo change this to result-object
  FOLDER_CREATE_FORM: 'folder:create-form',
  FUNCTION_CREATE_FORM: 'function:create-form',
  RESULT_OBJECT_CREATE_FORM: 'result-object:create-form',
  COLLECTION_CREATE_FORM: 'collection:create-form',
  SCENARIO_CREATE_FORM: 'flow:create-form',
}

export const ServiceSize = {
  SMALL: { name: 'Small', value: 'small' },
  MEDIUM: { name: 'Medium', value: 'medium' },
  BIG: { name: 'Big', value: 'big' },
}

export const ResourceSizes = {
  SMALL: { name: 'Small', value: 'small' },
  MEDIUM: { name: 'Medium', value: 'medium' },
  BIG: { name: 'Big', value: 'big' },
}

export const AWSRegions = {
  LONDON: { name: 'London', value: 'london' },
}

export const ConfigurationsFieldType = {
  NUMBER: 'number',
  STRING: 'string',
  INPUT: 'input',
  PASSWORD: 'password',
  CHECKBOX: 'checkbox',
  RADIO: 'radio',
}
