import styled from 'styled-components'
import { lightGray, regularGray } from '../../../../../../common/styles/colors'

export const ConfigsHeaderContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 12px;
`

export const NodeType = styled.div`
  position: relative;
  font-size: 14px;
  font-weight: 600;
  color: ${regularGray};
`

export const ConfigDescription = styled.div`
  position: relative;
  font-size: 12px;
  font-weight: 500;
  color: ${lightGray};
  margin-top: 8px;
`
