import { createSlice } from '@reduxjs/toolkit'
import { updateServiceConfigurations } from '../../components/project/details/services/details/configurations/slice'

const initialState = {
  settings: {},
  formLoaded: false,
  error: null,
  status: null,
}

const updateServiceSettingsFormSlice = createSlice({
  name: 'updateServiceSettingsForm',
  initialState,
  extraReducers(builder) {
    builder.addCase(updateServiceConfigurations.fulfilled, (state) => {
      console.log('Update service configuration')
    })
  },
  reducers: {
    changeSize: (state, action) => {
      state.settings.size = action.payload.size
    },
    changeAutoscaling: (state, action) => {
      state.settings.autoscaling = action.payload.autoscaling
    },
    setSettings: (state, action) => {
      state.settings.size = action.payload.size
      state.settings.autoscaling = action.payload.autoscaling

      state.formLoaded = true
    },
    resetForm: () => initialState,
  },
})

const selectUpdateServiceSettingsFormSlice = (state) => state.updateServiceSettingsForm
export const selectSettings = (state) => selectUpdateServiceSettingsFormSlice(state).settings
export const isSettingsFormLoaded = (state) => selectUpdateServiceSettingsFormSlice(state).formLoaded

const { reducer, actions } = updateServiceSettingsFormSlice

export const { changeSize, changeAutoscaling, setSettings, resetForm } = actions

export default reducer
