import styled from 'styled-components'

export const PageContainer = styled.div`
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  bottom: 0;
`

export const PageContentContainer = styled.div`
  position: absolute;
  top: 48px;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 24px;
  box-sizing: border-box;
`

export const SettingsFormContainer = styled.div`
  position: relative;
  width: 360px;
`

export const Subtitle = styled.div`
  position: relative;
  margin-bottom: 32px;
`
