import React, { memo, useEffect, useRef, useState } from 'react'
import { BUILDER_TYPES } from './constants'
import { RegularText } from '../../common/styles/text'
import { lightGray } from '../../common/styles/colors'
import StyledSVG from '../../common/styled-svg/StyledSVG'
import Search from '../../common/search/Search'
import { ContentWrapperContainer } from '../../common/layouts/content-container/styles'
import {
  TableContainer,
  TableRow,
  TableSearchField,
  TableSearchFieldContainer,
  TableSearchIconContainer,
} from '../../common/table/styles'
import {
  AddParameterFormTableRow,
  JsonSchemaBuilderContainer,
  DescriptionColumn,
  LastColumn,
  NameColumn,
  NoDescriptionMessage,
  TypeColumn,
  ExpandIconContainer,
  NameText,
  ParameterContainer,
  ParametersContainer,
  ParameterRow,
} from './styles'
import AddIcon from '../../../assets/images/add-icon.svg'
import FolderArrowIcon from '../../../assets/images/folder-arrow-icon.svg'
import {
  CreateItemButton,
  CreateItemButtonIcon,
  CreateItemButtonIconContainer,
  CreateItemButtonText,
  RotatedIconContainer,
} from '../../common/tree/styles'

import MorePopover from './more-popover/MorePopover'
import AddParameterForm from './forms/add-parameter/AddParameterForm'
import { sortAndNestItems } from '../../../utils/sorter'

function JsonSchemaBuilder({
  parameters,
  addParameterHandler,
  editParameterHandler,
  deleteParameterHandler,
  renameParameterHandler,
}) {
  const searchFieldRef = useRef(null)

  const [collapsedItems, setCollapsedItems] = useState([])
  const [filteredParameters, setFilteredParameters] = useState([])
  const [addParameterFormParentUuid, setAddParameterFormParentUuid] = useState(null)
  const [parameterUuidWithMorePopover, setParameterUuidWithMorePopover] = useState(null)
  const [searchQuery, setSearchQuery] = useState('')
  const [showSearchField, setShowSearchField] = useState(false)

  const typesForSelectbox = [
    { name: 'Some block title', isTitle: true },
    { name: 'String', value: BUILDER_TYPES.STRING },
    { name: 'Number', value: BUILDER_TYPES.NUMBER },
    { name: 'Boolean', value: BUILDER_TYPES.BOOLEAN },
    { name: 'Object', value: BUILDER_TYPES.OBJECT },
    {
      name: 'ArrayOf',
      value: BUILDER_TYPES.ARRAY_OF,
      children: [
        { name: 'String', value: `${BUILDER_TYPES.STRING}` },
        { name: 'Number', value: `${BUILDER_TYPES.NUMBER}` },
        { name: 'Boolean', value: `${BUILDER_TYPES.BOOLEAN}` },
        { name: 'Object', value: `${BUILDER_TYPES.OBJECT}` },
      ],
    },
  ]

  const changeSearchQuery = (event) => {
    setSearchQuery(event.target.value)
  }

  let filteredParams = []

  useEffect(() => {
    if (parameters !== null) {
      filteredParams = sortAndNestItems(parameters)
      if (searchQuery) {
        filteredParams = filteredParams.filter(
          (parameter) => parameter.name.toLowerCase().search(searchQuery.toLowerCase()) !== -1,
        )
      }

      setFilteredParameters(filteredParams)
    }
  }, [parameters, searchQuery])

  const noParametersMessage = (
    <ContentWrapperContainer padding={'0 12px'}>
      <RegularText>No parameters are here... yet.</RegularText>
    </ContentWrapperContainer>
  )

  const itemClickHandler = (event, item) => {
    event.preventDefault()
    if (item.children) {
      if (collapsedItems.includes(item.uuid)) {
        setCollapsedItems(collapsedItems.filter((collapsedItem) => collapsedItem !== item.uuid))
      } else {
        setCollapsedItems([...collapsedItems, item.uuid])
      }
    }
  }

  const parameterTypeName = (typeValue) => {
    const names = []

    let optionsForSearch = typesForSelectbox

    for (const value of typeValue.split(':')) {
      const option = optionsForSearch.find((option) => option.value === value)
      if (!option) {
        return 'Unknown'
      }

      names.push(option.name)
      optionsForSearch = option.children || []
    }

    return names.join(':')
  }

  const showParameters = (parentParameterUuid, parameters, active, depth) => (
    <ParametersContainer active={active} depth={depth}>
      {parameters &&
        parameters.map((parameter, index) => (
          <ParameterContainer key={`parameter-${depth}-${index}`}>
            <ParameterRow onClick={(event) => itemClickHandler(event, parameter)} hoverEffect={true}>
              <NameColumn>
                {parameter.children && (
                  <ExpandIconContainer>
                    <RotatedIconContainer isExpanded={!collapsedItems.includes(parameter.uuid)}>
                      <StyledSVG src={FolderArrowIcon} width={12} height={6} color={lightGray} />
                    </RotatedIconContainer>
                  </ExpandIconContainer>
                )}
                <NameText>{parameter.name}</NameText>
              </NameColumn>
              <TypeColumn>
                <RegularText>{parameterTypeName(parameter.type)}</RegularText>
              </TypeColumn>
              <DescriptionColumn padding={'7px 0'} lineHeight={'18px'}>
                <RegularText>
                  {parameter.description ? (
                    parameter.description
                  ) : (
                    <NoDescriptionMessage>No description yet...</NoDescriptionMessage>
                  )}
                </RegularText>
              </DescriptionColumn>
              <LastColumn>
                <MorePopover
                  parameter={parameter}
                  parameterUuidWithPopover={parameterUuidWithMorePopover}
                  setParameterUuidWithPopover={setParameterUuidWithMorePopover}
                />
              </LastColumn>
            </ParameterRow>
            {parameter.type === BUILDER_TYPES.OBJECT &&
              showParameters(parameter.uuid, parameter.children, !collapsedItems.includes(parameter.uuid), ++depth)}
          </ParameterContainer>
        ))}

      {addParameterFormParentUuid !== parentParameterUuid && (
        <AddParameterFormTableRow>
          <CreateItemButton onClick={() => setAddParameterFormParentUuid(parentParameterUuid)}>
            <CreateItemButtonIconContainer>
              <CreateItemButtonIcon width={10} height={10} color={'#FFFFFF'} src={AddIcon} />
            </CreateItemButtonIconContainer>
            <CreateItemButtonText>Add parameter</CreateItemButtonText>
          </CreateItemButton>
        </AddParameterFormTableRow>
      )}

      {addParameterFormParentUuid === parentParameterUuid && (
        <AddParameterForm
          typesForSelectbox={typesForSelectbox}
          parentUuid={parentParameterUuid}
          addParameterHandler={addParameterHandler}
          cancelCreation={() => {
            setAddParameterFormParentUuid(null)
          }}
        />
      )}
    </ParametersContainer>
  )

  return (
    <JsonSchemaBuilderContainer>
      <ContentWrapperContainer marginTop={'0px'}>
        <TableContainer width={'100%'}>
          <TableRow bottomMargin={'8px'} withBorder={true}>
            <NameColumn>
              <RegularText>
                <b>Name</b>
              </RegularText>
            </NameColumn>
            <TypeColumn>
              <RegularText>
                <b>Type</b>
              </RegularText>
            </TypeColumn>
            <DescriptionColumn>
              <RegularText>
                <b>Description</b>
              </RegularText>
            </DescriptionColumn>
            <LastColumn>
              <TableSearchIconContainer>
                <Search
                  searchFieldComponentRef={searchFieldRef}
                  activeIcon={!!searchQuery}
                  showSearchField={showSearchField}
                  setShowSearchField={setShowSearchField}
                  backgroundColor={'#FFFFFF'}
                />
              </TableSearchIconContainer>
            </LastColumn>
          </TableRow>
          {parameters.length === 0 && noParametersMessage}

          {showParameters('root', filteredParameters, true, 0)}

          {showSearchField && (
            <TableSearchFieldContainer ref={searchFieldRef}>
              <fieldset>
                <TableSearchField
                  type="text"
                  placeholder={'Search parameters...'}
                  name="searchValue"
                  value={searchQuery}
                  onChange={changeSearchQuery}
                />
              </fieldset>
            </TableSearchFieldContainer>
          )}
        </TableContainer>
      </ContentWrapperContainer>
    </JsonSchemaBuilderContainer>
  )
}

export default memo(JsonSchemaBuilder)
