import React, { memo } from 'react'
import {
  NavigationContainer,
  NavigationLinkContainer,
  NavigationLinkIconContainer,
  ProjectLogoComponent,
  ProjectLogoContainer,
  ProjectSidebarContainer,
} from './styles'
import { useSelector } from 'react-redux'
import { selectProject } from '../../../../reducers/project/project-details'
import ServicesIcon from '../../../../assets/images/services-icon.svg'
import FrontApiIcon from '../../../../assets/images/api-icon.svg'
import SettingsIcon from '../../../../assets/images/settings-icon.svg'
import StyledSVG from '../../../common/styled-svg/StyledSVG'
import { lightGray, mainBlue } from '../../../common/styles/colors'
import ProjectLogoPlaceholder1 from '../../../../assets/images/project-placeholder-1.svg'
import ProjectLogoPlaceholder2 from '../../../../assets/images/project-placeholder-2.svg'
import ProjectLogoPlaceholder3 from '../../../../assets/images/project-placeholder-3.svg'
import { CDN_URL } from '../../../../configs'
import { Tooltip } from 'react-tooltip'

function ProjectSidebar() {
  const project = useSelector(selectProject)

  let placeholder = ProjectLogoPlaceholder3

  const createdTime = new Date(project.createdTime).getTime()

  let lastDigit = createdTime + (1 % 10)
  if (lastDigit % 2 === 0) {
    placeholder = ProjectLogoPlaceholder1
  } else if (lastDigit % 3 === 0) {
    placeholder = ProjectLogoPlaceholder2
  }

  return (
    <ProjectSidebarContainer>
      <ProjectLogoContainer>
        {project.image && <ProjectLogoComponent src={`${CDN_URL}/${project.image.key}`} />}
        {!project.image && <ProjectLogoComponent src={placeholder} />}
      </ProjectLogoContainer>
      <NavigationContainer>
        <NavigationLinkContainer className="services-tooltip-anchor" to={`/project/${project.uuid}/services`}>
          {({ isActive }) => {
            return (
              <NavigationLinkIconContainer>
                <StyledSVG width={18} height={18} color={isActive ? mainBlue : lightGray} src={ServicesIcon} />
              </NavigationLinkIconContainer>
            )
          }}
        </NavigationLinkContainer>
        <NavigationLinkContainer className="front-api-tooltip-anchor" to={`/project/${project.uuid}/front-api`}>
          {({ isActive }) => {
            return (
              <NavigationLinkIconContainer>
                <StyledSVG width={18} height={18} color={isActive ? mainBlue : lightGray} src={FrontApiIcon} />
              </NavigationLinkIconContainer>
            )
          }}
        </NavigationLinkContainer>
        <NavigationLinkContainer className="settings-tooltip-anchor" to={`/project/${project.uuid}/settings`}>
          {({ isActive }) => {
            return (
              <NavigationLinkIconContainer>
                <StyledSVG width={18} height={18} color={isActive ? mainBlue : lightGray} src={SettingsIcon} />
              </NavigationLinkIconContainer>
            )
          }}
        </NavigationLinkContainer>
        <Tooltip anchorSelect=".services-tooltip-anchor" place="right">
          Services
        </Tooltip>
        <Tooltip anchorSelect=".front-api-tooltip-anchor" place="right">
          Front API
        </Tooltip>
        <Tooltip anchorSelect=".settings-tooltip-anchor" place="right">
          Settings
        </Tooltip>
      </NavigationContainer>
    </ProjectSidebarContainer>
  )
}

export default memo(ProjectSidebar)
