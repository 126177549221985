import React, { memo } from 'react'
import { Outlet } from 'react-router'
import { ContentContainer } from '../../common/layouts/content-container/styles'
import FunctionsSidebar from './sidebar/FunctionsSidebar'

function Functions() {
  return (
    <ContentContainer left={'0px'} right={'0px'}>
      <ContentContainer width={'320px'} scroll={true}>
        <FunctionsSidebar />
      </ContentContainer>
      <ContentContainer left={'320px'} right={'0px'}>
        <Outlet />
      </ContentContainer>
    </ContentContainer>
  )
}

export default memo(Functions)
