import React, { memo } from 'react'
import { ConfigContainer, ConfigTitle } from '../styles'
import KeyValueParameter from '../key-value-parameter/KeyValueParameter'

function RequestHeaders() {
  return (
    <ConfigContainer>
      <ConfigTitle>Headers</ConfigTitle>
      <KeyValueParameter />
    </ConfigContainer>
  )
}

export default memo(RequestHeaders)
