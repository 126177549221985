import React, { memo, useState } from 'react'
import { NodeContainer } from './styles'
import NodeItem from '../node-item/NodeItem'
import NodeDetails from '../node-details/NodeDetails'

function GenericNode({ node }) {
  const [showDetails, setShowDetails] = useState(false)

  return (
    <NodeContainer>
      <NodeItem nodeName={node.name} nodeType={node.type} openDetailsHandler={() => setShowDetails(true)} />
      {showDetails && <NodeDetails node={node} cancelHandler={() => setShowDetails(false)} />}
    </NodeContainer>
  )
}

export default memo(GenericNode)
