import React, { memo, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { signIn } from './slice'
import ErrorMessage from '../../common/error-message/ErrorMessage'
import { Clear } from '../../common/styles/styles'
import {
  ButtonWithText,
  ButtonWithTextContainer,
  ButtonWithTextLeftBlock,
  ButtonWithTextRightBlock,
  FieldContainer,
  FormContainer,
} from '../../common/styles/form-styles'
import PublicAreaMiddleFormLayout from '../../common/layouts/public-area/middle-form/PublicAreaMiddleFormLayout'
import { H1Title, RegularText, SubtitleText } from '../../common/styles/text'
import {
  FormContainer as FormContentContainer,
  MiddleFormPageSubtitleContainer,
  MiddleFormPageTitleContainer,
  MiddleFormTopContainer,
} from '../../common/layouts/public-area/middle-form/styles'
import { ForgotPasswordLink, ForgotPasswordLinkContainer } from './styles'
import { ExtraLink } from '../styles'
import TextField from '../../common/form/text-field/TextField'
import { REQUEST_STATUSES } from '../../../reducers/reducer-helper'
import Loading from '../../common/loading/Loading'
import {
  selectFormData,
  selectRequestStatus,
  selectError,
  changeEmailValue,
  changePasswordValue,
  changeRequestStatus,
  resetForm,
} from '../../../reducers/user/sign-in-form'
import { lightGray } from '../../common/styles/colors'

function SignIn() {
  const dispatch = useDispatch()

  const error = useSelector(selectError)
  const requestStatus = useSelector(selectRequestStatus)
  const formData = useSelector(selectFormData)

  useEffect(() => {
    return () => {
      dispatch(resetForm())
    }
  }, [])

  const changeEmailFieldValue = (event) => {
    event.preventDefault()
    dispatch(changeEmailValue({ email: event.target.value }))
  }

  const changePasswordFieldValue = (event) => {
    event.preventDefault()
    dispatch(changePasswordValue({ password: event.target.value }))
  }

  const signInUser = (event) => {
    event.preventDefault()
    const { email, password } = formData
    dispatch(changeRequestStatus({ status: REQUEST_STATUSES.PENDING }))
    dispatch(signIn({ email, password }))
  }

  return (
    <PublicAreaMiddleFormLayout>
      {requestStatus === REQUEST_STATUSES.PENDING && <Loading />}
      <MiddleFormTopContainer>
        <MiddleFormPageTitleContainer>
          <H1Title>Welcome back</H1Title>
        </MiddleFormPageTitleContainer>
        <MiddleFormPageSubtitleContainer>
          <SubtitleText>Please provide your email and password</SubtitleText>
        </MiddleFormPageSubtitleContainer>
      </MiddleFormTopContainer>

      <ErrorMessage error={error} />

      <FormContentContainer>
        <FormContainer onSubmit={signInUser}>
          <fieldset>
            <FieldContainer>
              <fieldset>
                <TextField
                  label={'Email'}
                  value={formData.email}
                  changeEvent={changeEmailFieldValue}
                  placeholder={'youremail@email.com'}
                />
              </fieldset>
            </FieldContainer>

            <FieldContainer>
              <fieldset>
                <TextField
                  label={'Password'}
                  value={formData.password}
                  changeEvent={changePasswordFieldValue}
                  placeholder={'●●●●●●'}
                  type={'password'}
                />
              </fieldset>
              <ForgotPasswordLinkContainer>
                <ForgotPasswordLink to="/forgot-password">Forgot password?</ForgotPasswordLink>
              </ForgotPasswordLinkContainer>
            </FieldContainer>

            <ButtonWithTextContainer>
              <ButtonWithTextLeftBlock>
                <RegularText color={lightGray}>Don't have an account?</RegularText>
                <ExtraLink to="/sign-up">Sign Up</ExtraLink>
              </ButtonWithTextLeftBlock>
              <ButtonWithTextRightBlock>
                <ButtonWithText type="submit">Sign in</ButtonWithText>
              </ButtonWithTextRightBlock>
              <Clear />
            </ButtonWithTextContainer>
          </fieldset>
        </FormContainer>
      </FormContentContainer>
    </PublicAreaMiddleFormLayout>
  )
}

export default memo(SignIn)
