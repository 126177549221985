import React, { memo } from 'react'
import { CenteredContent, ContentWrapperContainer } from '../../common/layouts/content-container/styles'
import { ApiKeyContainer, GoDashboardButtonContainer, IconAnimationContainer, IconContainer } from './styles'
import {
  selectCreatedApiKey,
  selectProjectInformation,
} from '../../../reducers/project/project-create-form'
import { useSelector } from 'react-redux'
import { RegularText } from '../../common/styles/text'
import StyledSVG from '../../common/styled-svg/StyledSVG'
import ProcessingIcon from '../../../assets/images/processing-icon.svg'
import { mainBlue } from '../../common/styles/colors'
import CodeBlock from '../../common/code-block/CodeBlock'
import { SecondaryActionLink } from '../../common/styles/styles'

function Success() {
  const apiKey = useSelector(selectCreatedApiKey)
  const projectInformation = useSelector(selectProjectInformation)

  return (
    <CenteredContent>
      <IconContainer>
        <IconAnimationContainer>
          <StyledSVG width={80} height={80} color={mainBlue} src={ProcessingIcon} />
        </IconAnimationContainer>
      </IconContainer>
      <ContentWrapperContainer>
        <RegularText>
          Project <b>{projectInformation.name}</b> is creating.
        </RegularText>
      </ContentWrapperContainer>
      <ContentWrapperContainer marginTop={'48px'}>
        <RegularText>
          This is your API key that you need to use for a requests. You are able to manage API keys in the Front API builder.
        </RegularText>
      </ContentWrapperContainer>
      <ContentWrapperContainer>
        <ApiKeyContainer>
          <CodeBlock code={apiKey} title={'API key:'} width={'640px'} copy={true} />
        </ApiKeyContainer>
      </ContentWrapperContainer>
      <ContentWrapperContainer marginTop={'32px'}>
        <GoDashboardButtonContainer>
          <SecondaryActionLink to={'/dashboard'}>
            Go to dashboard
          </SecondaryActionLink>
        </GoDashboardButtonContainer>
      </ContentWrapperContainer>
    </CenteredContent>
  )
}

export default memo(Success)
