import { createSlice } from '@reduxjs/toolkit'
import { REQUEST_STATUSES, failed, resetErrors, resetStatus } from './../reducer-helper'
import { getAllProjects } from '../../components/project/list/slice'

const initialState = {
  projects: [],
  error: null,
  status: REQUEST_STATUSES.PENDING,
}

const projectListSlice = createSlice({
  name: 'projectList',
  initialState,
  reducers: {
    projectsUnloaded: () => initialState,
    changeRequestStatus: (state, action) => {
      state.status = action.payload.status
    },
  },
  extraReducers(builder) {
    builder.addCase(getAllProjects.fulfilled, (state, action) => {
      state.projects = action.payload.projects
      resetStatus(state)
      resetErrors(state)
    })
    builder.addCase(getAllProjects.rejected, failed)
  },
})

const selectProjectListSlice = (state) => state.projectList
export const selectRequestStatus = (state) => selectProjectListSlice(state).status
export const selectProjects = (state) => selectProjectListSlice(state).projects
export const selectError = (state) => selectProjectListSlice(state).error

const { reducer, actions } = projectListSlice

export const { projectsUnloaded } = actions

export default reducer
