import React, { memo } from 'react'
import { ProjectCreatingFailedContainer } from './styles'

function ProjectCreatingFailed() {
  return (
    <ProjectCreatingFailedContainer>
      Your project creating process was failed. Please contact our support and we will help you to get back on the track.
    </ProjectCreatingFailedContainer>
  )
}

export default memo(ProjectCreatingFailed)
