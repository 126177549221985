import styled from 'styled-components'
import { greenTextColor, lightSilver, orangeTextColor, redTextColor, yellowTextColor } from '../styles/colors'
import { ProjectStatuses, ServiceStatuses } from '../../../utils/utils'

export const StatusLabelBlock = styled.div`
  position: relative;
  float: left;
  width: 16px;
  height: 16px;

  background: ${(props) => {
    let color = lightSilver

    switch (props.status) {
      case ProjectStatuses.CREATING:
      case ServiceStatuses.CREATING:
        color = yellowTextColor
        break

      case ProjectStatuses.FAILED:
      case ServiceStatuses.FAILED:
        color = redTextColor
        break

      case ProjectStatuses.DEPLOYED:
      case ServiceStatuses.DEPLOYED:
        color = greenTextColor
        break

      case ServiceStatuses.WAITING_FOR_RESOURCES:
        color = orangeTextColor
        break
    }

    return color
  }};

  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
`
