import { createSlice } from '@reduxjs/toolkit'
import { failed } from '../reducer-helper'
import { AWSRegions, ServiceSize } from '../../utils/utils'
import { createProject } from '../../components/project/create/slice'

const initialState = {
  error: null,
  status: null,
  step: 1,
  createdApiKey: null,
  project: {
    name: '',
    aws: {
      region: AWSRegions.LONDON.value,
      accessKeyId: '',
      secretAccessKey: '',
    }
  },
  frontApi: {
    size: ServiceSize.SMALL.value
  },
}

const projectCreateFormSlice = createSlice({
  name: 'projectCreateForm',
  initialState,
  extraReducers(builder) {
    builder.addCase(createProject.rejected, failed)
    builder.addCase(createProject.fulfilled, (state, action) => {
      state.step = 5
      state.createdApiKey = action.payload.apiKey
    })
  },
  reducers: {
    changeName: (state, action) => {
      state.project.name = action.payload.name
    },
    changeAWSAccessKeyId: (state, action) => {
      state.project.aws.accessKeyId = action.payload.accessKeyId
    },
    changeAWSSecretAccessKey: (state, action) => {
      state.project.aws.secretAccessKey = action.payload.secretAccessKey
    },
    changeRegion: (state, action) => {
      state.project.aws.region = action.payload.region
    },
    changeFrontApiSize: (state, action) => {
      state.frontApi.size = action.payload.size
    },
    changeStep: (state, action) => {
      state.step = action.payload.step
    },
    changeRequestStatus: (state, action) => {
      state.status = action.payload.status
    },
    resetForm: () => initialState,
  },
})

const selectProjectCreateFormSlice = (state) => state.projectCreateForm
export const selectStep = (state) => selectProjectCreateFormSlice(state).step
export const selectRequestStatus = (state) => selectProjectCreateFormSlice(state).status
export const selectError = (state) => selectProjectCreateFormSlice(state).error
export const selectProjectInformation = (state) => selectProjectCreateFormSlice(state).project
export const selectFrontApiInformation = (state) => selectProjectCreateFormSlice(state).frontApi
export const selectCreatedApiKey = (state) => selectProjectCreateFormSlice(state).createdApiKey

const { reducer, actions } = projectCreateFormSlice
export const {
  changeRequestStatus,
  changeName,
  changeRegion,
  changeFrontApiSize,
  changeAWSAccessKeyId,
  changeAWSSecretAccessKey,
  changeStep,
  resetForm,
} = actions

export default reducer
