import { createAsyncThunk } from '@reduxjs/toolkit'
import { FrontApiAgent } from '../../../../api/frontApiAgent'

export const deleteGlobalParameter = createAsyncThunk(
  'front-api/global-parameters/delete',
  async ({ projectUuid, parameterUuid }, thunkApi) => {
    try {
      await FrontApiAgent.deleteGlobalParameter(projectUuid, parameterUuid)

      return { parameterUuid }
    } catch (error) {
      return thunkApi.rejectWithValue(error)
    }
  },
)
