import styled from 'styled-components'
import { darkGray, layoutComponentsBackgroundColor } from '../../common/styles/colors'
import { NavLink } from 'react-router-dom'

export const Content = styled.div`
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  bottom: 0;
`

export const ServicePreviewContainer = styled(NavLink)`
  position: relative;
  display: block;
  width: 100%;
  height: 40px;
  box-sizing: border-box;

  transition: background-color 275ms ease;

  &.active {
    background: ${layoutComponentsBackgroundColor};
  }

  &:hover {
    cursor: pointer;
    background: ${layoutComponentsBackgroundColor};
  }
`

export const LeftContentContainer = styled.div`
  position: relative;
  float: left;
`

export const ServiceIconContainer = styled.div`
  position: relative;
  float: left;
  width: 16px;
  height: 40px;
  text-align: center;

  margin-left: 24px;

  display: flex;
  align-items: center;
  justify-content: center;
`

export const ServiceInfoContainer = styled.div`
  position: relative;
  float: left;
  margin-left: 8px;
`

export const ServiceName = styled.div`
  position: relative;
  line-height: 40px;
  font-size: 14px;
  color: ${darkGray};
`
