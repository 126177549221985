import React, { memo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { closePopup, selectPopupExtraParameters } from '../../../../../../reducers/popup'
import { selectProject } from '../../../../../../reducers/project/project-details'
import { REQUEST_STATUSES } from '../../../../../../reducers/reducer-helper'
import { deleteFunction, deleteFunctionsCollection, deleteFunctionsFolder } from './slice'
import ConfirmPopup from '../../../../../common/confirm-popup/ConfirmPopup'
import {
  changeRequestStatus as changeFunctionRequestStatus,
  selectRequestStatus,
} from '../../../../../../reducers/front-api/function/delete'

function DeletePopup() {
  const dispatch = useDispatch()

  const project = useSelector(selectProject)
  const popupExtraParameters = useSelector(selectPopupExtraParameters)
  const requestStatus = useSelector(selectRequestStatus)

  const params = {}
  popupExtraParameters.map((param) => {
    params[param.name] = param.value
  })

  const deleteHandler = (event) => {
    event.preventDefault()

    switch (params.type) {
      case 'function':
        dispatch(changeFunctionRequestStatus({ status: REQUEST_STATUSES.PENDING }))
        dispatch(deleteFunction({ projectUuid: project.uuid, functionUuid: params.uuid }))
        break

      case 'folder':
        dispatch(deleteFunctionsFolder({ projectUuid: project.uuid, folderUuid: params.uuid }))
        break

      case 'collection':
        dispatch(deleteFunctionsCollection({ projectUuid: project.uuid, collectionUuid: params.uuid }))
        break
    }
  }

  const cancel = (event) => {
    event.preventDefault()
    dispatch(closePopup())
  }

  return (
    <ConfirmPopup
      title={`Delete ${params.type}`}
      subtitle={
        <>
          Are you sure you want to delete: <strong>{params.name}</strong> {params.type}?
        </>
      }
      cancelHandler={cancel}
      deleteParameterHandler={deleteHandler}
      confirmButtonText={'Delete'}
      requestStatus={requestStatus}
    />
  )
}

export default memo(DeletePopup)
