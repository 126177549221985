import styled from 'styled-components'
import { regularGray } from '../../common/styles/colors'
import { layoutComponentsBackgroundColor } from '../../common/styles/colors'
import { RegularText } from '../../common/styles/text'
import { Link } from 'react-router-dom'

export const ProjectsContainer = styled.div`
  position: relative;
  width: 100%;
`

export const ProjectPreviewContainer = styled.div`
  position: relative;
  width: 100%;
  height: 64px;

  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;

  transition: background-color 275ms ease;

  &:hover {
    cursor: pointer;
    background: ${layoutComponentsBackgroundColor};
  }
`

export const ProjectLink = styled(Link)`
  position: relative;
  width: 100%;
  height: 100%;

  text-decoration: none;

  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const LeftContentContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`

export const ProjectLogoContainer = styled.div`
  position: relative;
  width: 32px;
  height: 32px;
  text-align: center;

  margin: 0 12px;
`

export const ProjectLogoComponent = styled.img`
  position: relative;
  width: 32px;
  height: 32px;

  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
`

export const ProjectName = styled.div`
  position: relative;
  color: ${regularGray};
  font-size: 14px;
  font-weight: 600;
`

export const RightContentContainer = styled.div`
  position: relative;
  padding-right: 24px;
  color: ${regularGray};
  display: flex;
  align-items: center;
`

export const EmptyFolderIconContainer = styled.div`
  position: relative;
  width: 100%;
  text-align: center;
`

export const TextContainer = styled.div`
  position: relative;
  width: 100%;
  text-align: center;
  margin-top: 40px;
`

export const ButtonContainer = styled.div`
  position: relative;
  text-align: center;
  width: 160px;
  margin: 0 auto;
  margin-top: 48px;
`

export const StatusContainer = styled.div`
  position: relative;
  width: 16px;
  height: 16px;
  margin-left: 12px;
`

export const ServicesInfoText = styled(RegularText)`
  float: left;
`
