import { createAsyncThunk } from '@reduxjs/toolkit'
import { ServiceAgent } from '../../../api/serviceAgent'

export const getServiceDetails = createAsyncThunk('service-overview/get-service', async ({ serviceUuid }, thunkApi) => {
  try {
    const service = await ServiceAgent.getService(serviceUuid)

    return { service }
  } catch (error) {
    return thunkApi.rejectWithValue(error)
  }
})
