import styled from 'styled-components'
import { layoutComponentsBorderColor, lightSilver, mainBlue, regularGray } from '../../../styles/colors'
import { Link } from 'react-router-dom'

export const FooterContainer = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 48px;
`

export const LeftContentContainer = styled.div`
  position: relative;
  line-height: 48px;
  margin-left: 24px;
  float: left;
`

export const RightContentContainer = styled.div`
  position: relative;
  height: 100%;
  float: right;
  padding-right: 24px;
`

export const SocialLinks = styled.div`
  position: relative;
  height: 16px;
  margin-top: 12px;
  float: right;
  padding-right: 24px;
`

export const SocialLink = styled(Link)`
  position: relative;
  float: right;
  margin: 0 12px;
  font-size: 14px;
  color: ${regularGray};
  text-decoration: none;

  transition: color 275ms ease;

  &:hover {
    cursor: pointer;
    color: ${mainBlue};
  }
`

export const SocialLinkSeparator = styled.span`
  position: relative;
  float: right;
  width: 1px;
  height: 16px;
  background: ${lightSilver};
`
