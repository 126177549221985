export class CustomTreeDataProvider {
  data

  handlers = {}

  movingItemHandler

  setItemName

  constructor(items, setItemName, movingItemHandler = null) {
    this.movingItemHandler = movingItemHandler
    this.data = { items }
    this.setItemName = setItemName
  }

  async getTreeItem(itemId) {
    return this.data.items[itemId]
  }

  async onChangeItemChildren(itemIndex, newChildren) {
    this.data.items[itemIndex].children = newChildren
    Object.values(this.handlers).forEach((handler) => handler([itemIndex]))

    const childrenForRequest = []

    newChildren.forEach((childIndex) => {
      childrenForRequest.push({
        uuid: this.data.items[childIndex].data.uuid,
        type: this.data.items[childIndex].data.type,
      })
    })

    this.movingItemHandler(this.data.items[itemIndex].data.uuid, childrenForRequest)
  }

  onDidChangeTreeData(listener) {
    const id = (Math.random() + 1).toString(36).substring(7)
    this.handlers[id] = listener
    return { dispose: () => delete this.handlers[id] }
  }

  async onRenameItem(item, name) {
    if (this.setItemName) {
      this.data.items[item.index] = this.setItemName(item, name)
    }
  }

  updateItems(items, parentIndex) {
    this.data = { items }

    Object.values(this.handlers).forEach((handler) => handler(parentIndex))
  }
}
