import styled from 'styled-components'
import { layoutComponentsBorderColor, lightGray } from '../../../../../common/styles/colors'

export const NodeItemContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  width: 260px;
  height: 74px;
  border: 1px solid ${layoutComponentsBorderColor};
  box-sizing: border-box;

  padding: 8px 0;

  transition: border-color 275ms ease;

  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;

  &:hover {
    cursor: pointer;
    border-color: ${lightGray};
  }
`

export const NodeTypeName = styled.span`
  font-size: 12px;
  font-weight: 500;
  color: ${lightGray};
  padding-left: 8px;
`

export const Header = styled.header`
  position: relative;
  display: flex;
  align-items: center;

  padding: 0 12px;
  box-sizing: border-box;

  width: 100%;
  height: 28px;
`

export const Content = styled.div`
  position: relative;
  width: 100%;
  height: 28px;

  display: flex;
  align-items: center;

  padding: 0 12px;
  box-sizing: border-box;
`
