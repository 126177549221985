import React, { memo, useEffect, useState } from 'react'
import { PageContainer, PageContentContainer, SettingsFormContainer, Subtitle } from './styles'
import {
  ButtonWithText,
  ButtonWithTextContainer,
  ButtonWithTextLeftBlock,
  FormContainer,
} from '../../../../../common/styles/form-styles'
import { useDispatch, useSelector } from 'react-redux'
import { selectServiceDetails } from '../../../../../../reducers/service/service-details'
import { H2HeaderTitle, RegularText } from '../../../../../common/styles/text'
import { Clear } from '../../../../../common/styles/styles'
import SettingsForm from '../../../../../service/settings-form/SettingsForm'
import {
  changeAutoscaling,
  changeSize,
  isSettingsFormLoaded,
  selectSettings,
  setSettings,
} from '../../../../../../reducers/service/update-service-settings-form'
import { selectProjectServices } from '../../../../../../reducers/project/project-services'
import { selectProject } from '../../../../../../reducers/project/project-details'
import { updateServiceSettings } from './slice'
import { HeaderContainer } from '../../../../../common/layouts/content-block-header/styles'

function ProjectServiceSettings() {
  const dispatch = useDispatch()

  const service = useSelector(selectServiceDetails)
  const settings = useSelector(selectSettings)
  const isFormLoaded = useSelector(isSettingsFormLoaded)
  const projectServices = useSelector(selectProjectServices)
  const project = useSelector(selectProject)

  const [formWasChanged, changeForm] = useState(false)

  const changeAutoscalingProcess = () => {
    dispatch(changeAutoscaling({ autoscaling: !settings.autoscaling }))
  }

  const changeSizeProcess = (size) => {
    dispatch(changeSize({ size }))
  }

  useEffect(() => {
    const projectService = projectServices.find((projectService) => projectService.uuid === service.uuid)
    const { autoscaling, size } = projectService.inProject.settings

    dispatch(setSettings({ autoscaling, size }))
  }, [])

  useEffect(() => {
    if (isFormLoaded) {
      const projectService = projectServices.find((projectService) => projectService.uuid === service.uuid)
      const { autoscaling, size } = projectService.inProject.settings

      changeForm(autoscaling !== settings.autoscaling || size !== settings.size)
    }
  }, [isFormLoaded, settings])

  const saveSettings = (event) => {
    event.preventDefault()
    dispatch(
      updateServiceSettings({
        projectUuid: project.uuid,
        serviceUuid: service.uuid,
        autoscaling: settings.autoscaling,
        size: settings.size,
      }),
    )
  }

  return (
    <PageContainer>
      <HeaderContainer>
        <H2HeaderTitle>Settings</H2HeaderTitle>
      </HeaderContainer>

      <PageContentContainer>
        <Subtitle>
          <RegularText>Here you can change service size and another settings</RegularText>
        </Subtitle>

        {isFormLoaded && (
          <SettingsFormContainer>
            <FormContainer onSubmit={saveSettings}>
              <fieldset>
                <SettingsForm
                  changeSizeProcess={changeSizeProcess}
                  changeAutoscalingProcess={changeAutoscalingProcess}
                  settings={settings}
                />

                {formWasChanged && (
                  <ButtonWithTextContainer>
                    <ButtonWithTextLeftBlock>
                      <ButtonWithText type="submit">Save</ButtonWithText>
                    </ButtonWithTextLeftBlock>
                    <Clear />
                  </ButtonWithTextContainer>
                )}
              </fieldset>
            </FormContainer>
          </SettingsFormContainer>
        )}
      </PageContentContainer>
    </PageContainer>
  )
}

export default memo(ProjectServiceSettings)
