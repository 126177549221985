import { createAsyncThunk } from '@reduxjs/toolkit'
import { FrontApiAgent } from '../../../../../../api/frontApiAgent'

export const updateParameter = createAsyncThunk(
  'front-api/functions/parameters/update',
  async ({ projectUuid, functionUuid, parameterUuid, name, isRequired, description }, thunkApi) => {
    try {
      const parameter = await FrontApiAgent.updateFunctionParameter(
        projectUuid,
        functionUuid,
        parameterUuid,
        name,
        isRequired,
        description,
      )

      return { functionUuid, parameterUuid, parameter }
    } catch (error) {
      return thunkApi.rejectWithValue(error)
    }
  },
)
