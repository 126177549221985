import React, { memo } from 'react'
import { Content720 } from '../common/styles/styles'
import ProjectList from '../project/list/ProjectList'

function Dashboard() {
  return (
    <Content720>
      <ProjectList />
    </Content720>
  )
}

export default memo(Dashboard)
