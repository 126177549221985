import { createAsyncThunk } from '@reduxjs/toolkit'
import { FrontApiAgent } from '../../../../../../api/frontApiAgent'

export const createFunction = createAsyncThunk(
  'front-api/functions/create',
  async ({ parentUuid, parentIndex, projectUuid, name }, thunkApi) => {
    try {
      const _function = await FrontApiAgent.createFunction(projectUuid, parentUuid, name)

      return { function: _function, parentUuid, parentIndex }
    } catch (error) {
      return thunkApi.rejectWithValue(error)
    }
  },
)
