import React, { memo } from 'react'
import IconButton from '../../../../../../../common/icon-button/IconButton'
import ObjectIcon from '../../../../../../../../assets/images/object-icon.svg'

function InsertParameterButton({ onClick }) {
  return (
    <IconButton
      icon={ObjectIcon}
      action={(event) => {
        event.preventDefault()
        onClick()
      }}
    />
  )
}

export default memo(InsertParameterButton)
