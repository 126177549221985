import React, { memo } from 'react'
import { useParams } from 'react-router-dom'
import { ContentWrapperContainer } from '../../common/layouts/content-container/styles'
import {
  Sidebar,
  SidebarContent,
  SidebarDivider,
  SidebarHeader,
  SidebarNavigation,
  SidebarNavigationItem,
  SidebarNavigationLink,
} from '../../common/layouts/sidebar-content/styles'
import { H2HeaderTitle } from '../../common/styles/text'

function Navigation() {
  const { projectUuid } = useParams()

  return (
    <Sidebar width={'240px'} background={true} border={true}>
      <SidebarHeader>
        <H2HeaderTitle>Front API</H2HeaderTitle>
      </SidebarHeader>
      <SidebarContent>
        <ContentWrapperContainer width={'100%'} marginBottom={'0'} marginTop={'12px'}>
          <ContentWrapperContainer padding={'0 24px'} marginTop={'0'} marginBottom={'0'}>
            <SidebarNavigation>
              <ContentWrapperContainer marginTop={'12px'} marginBottom={'12px'}>
                <ContentWrapperContainer marginTop={'0'} marginBottom={'0'}>
                  <SidebarNavigationItem>
                    <SidebarNavigationLink to={`/project/${projectUuid}/front-api/api-keys`}>
                      Application
                    </SidebarNavigationLink>
                  </SidebarNavigationItem>
                </ContentWrapperContainer>
              </ContentWrapperContainer>
              <SidebarDivider />
              <ContentWrapperContainer marginTop={'12px'} marginBottom={'12px'}>
                <SidebarNavigationItem>
                  <SidebarNavigationLink end to={`/project/${projectUuid}/front-api/global-parameters`}>
                    Global parameters
                  </SidebarNavigationLink>
                </SidebarNavigationItem>
                <SidebarNavigationItem>
                  <SidebarNavigationLink to={`/project/${projectUuid}/front-api/functions`}>
                    Functions
                  </SidebarNavigationLink>
                </SidebarNavigationItem>
                <SidebarNavigationItem>
                  <SidebarNavigationLink end to={`/project/${projectUuid}/front-api/endpoints`}>
                    Endpoints
                  </SidebarNavigationLink>
                </SidebarNavigationItem>
                <SidebarNavigationItem>
                  <SidebarNavigationLink end to={`/project/${projectUuid}/front-api/cron-jobs`}>
                    Events and Cron jobs
                  </SidebarNavigationLink>
                </SidebarNavigationItem>
                <SidebarNavigationItem>
                  <SidebarNavigationLink end to={`/project/${projectUuid}/front-api/logs`}>
                    Logs
                  </SidebarNavigationLink>
                </SidebarNavigationItem>
              </ContentWrapperContainer>
              <SidebarDivider />
              <ContentWrapperContainer marginTop={'12px'} marginBottom={'0'}>
                <ContentWrapperContainer marginTop={'0'} marginBottom={'0'}>
                  <SidebarNavigationItem>
                    <SidebarNavigationLink to={`/project/${projectUuid}/front-api/api-keys`}>
                      API keys
                    </SidebarNavigationLink>
                  </SidebarNavigationItem>
                </ContentWrapperContainer>
              </ContentWrapperContainer>
            </SidebarNavigation>
          </ContentWrapperContainer>
        </ContentWrapperContainer>
      </SidebarContent>
    </Sidebar>
  )
}

export default memo(Navigation)
