import styled from 'styled-components'
import { regularGray } from '../../../common/styles/colors'

export const AddButtonContainer = styled.div`
  position: relative;
  float: right;
  height: 32px;
  margin: 8px 0;
  margin-left: 24px;
`

export const StatusContainer = styled.div`
  display: flex;

  position: relative;
  height: 12px;
`

export const StatusText = styled.div`
  position: relative;
  height: 24px;

  font-size: 14px;
  color: ${regularGray};
  padding-left: 8px;
`
