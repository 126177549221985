export const sort = (a, b, key = 'createdTime') => {
  if (a[key] < b[key]) {
    return -1
  }
  if (a[key] > b[key]) {
    return 1
  }
  return 0
}

export const sortAndNestItems = (items, parentId = null, parentKey = 'parentUuid', sortingKey = 'createdTime') => {
  const copiedItems = JSON.parse(JSON.stringify(items))
  const nestedItems = []

  for (const item of copiedItems) {
    if (item[parentKey] === parentId) {
      const children = sortAndNestItems(items, item.uuid)
      if (children.length) {
        item.children = children
        item.children
      }
      nestedItems.push(item)
    }
  }

  return nestedItems.sort((a, b) => {
    const createTimeA = new Date(a[sortingKey])
    const createTimeB = new Date(b[sortingKey])

    return createTimeA - createTimeB
  })
}
