import { createAsyncThunk } from '@reduxjs/toolkit'
import { ServiceAgent } from '../../../../../../api/serviceAgent'

export const updateServiceSettings = createAsyncThunk(
  'project-services-update-settings-form/update',
  async ({ projectUuid, serviceUuid, size, autoscaling }, thunkApi) => {
    try {
      await ServiceAgent.updateProjectServicesSettings(projectUuid, serviceUuid, size, autoscaling)
    } catch (error) {
      return thunkApi.rejectWithValue(error)
    }
  },
)
