import React, { memo, useEffect, useRef } from 'react'
import { Header, NodeTypeName } from './styles'
import { lightGray } from '../../../../../common/styles/colors'
import {
  NODES_ICONS,
  NODES_NAMES,
  NODES_TYPES,
  PARAMETERS_FOR_INSERT_SOURCES,
  PARAMETERS_FOR_INSERT_SOURCES_NAMES,
} from '../../../constants'
import StyledSVG from '../../../../../common/styled-svg/StyledSVG'
import { NodeConfigurationsPopup } from './styles'
import RequestNodeDetails from './request-node/RequestNodeDetails'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { selectFunctionByUuid, selectGlobalParameters } from '../../../../../../reducers/front-api/front-api'
import { setParametersForInsert } from '../../../../../../reducers/front-api/flow-builder/flow-builder-context'

function NodeDetails({ node, cancelHandler }) {
  const { functionUuid } = useParams()
  const dispatch = useDispatch()
  const popoverRef = useRef(null)

  const globalParameters = useSelector(selectGlobalParameters)

  const parametersForInsert = [
    {
      sourceAlias: PARAMETERS_FOR_INSERT_SOURCES.GLOBAL_PARAMETERS,
      sourceName: PARAMETERS_FOR_INSERT_SOURCES_NAMES[PARAMETERS_FOR_INSERT_SOURCES.GLOBAL_PARAMETERS],
      parameters: globalParameters,
    },
  ]

  if (functionUuid) {
    const _function = useSelector((state) => selectFunctionByUuid(state, functionUuid))
    if (_function.parameters && _function.parameters.length > 0) {
      parametersForInsert.push({
        sourceAlias: PARAMETERS_FOR_INSERT_SOURCES.FUNCTION,
        sourceName: PARAMETERS_FOR_INSERT_SOURCES_NAMES[PARAMETERS_FOR_INSERT_SOURCES.FUNCTION],
        parameters: _function.parameters,
      })
    }
  }

  dispatch(setParametersForInsert({ parametersForInsert }))

  useEffect(() => {
    const handleClickOutside = (event) => {
      const target = event.target

      // Check if it is not popup
      if (!target.classList.contains('popup-component') && !target.closest('.popup-component')) {
        if (popoverRef.current && !popoverRef.current.contains(target)) {
          cancelHandler()
        }
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [popoverRef])

  const NodeDetailsByNodeType = () => {
    switch (node.type) {
      case NODES_TYPES.REQUEST:
        return <RequestNodeDetails node={node} cancelHandler={cancelHandler} />
    }
  }

  return (
    <NodeConfigurationsPopup ref={popoverRef}>
      <Header>
        <StyledSVG width={16} height={16} color={lightGray} src={NODES_ICONS[node.type]} />
        <NodeTypeName>{NODES_NAMES[node.type]}</NodeTypeName>
      </Header>
      {NodeDetailsByNodeType()}
    </NodeConfigurationsPopup>
  )
}

export default memo(NodeDetails)
