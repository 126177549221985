import React, { memo } from 'react'
import { Clear } from '../../../styles/styles'
import {
  FooterContainer,
  LeftContentContainer,
  RightContentContainer,
  SocialLink,
  SocialLinkSeparator,
  SocialLinks,
} from './styles'
import { RegularText } from '../../../styles/text'

function Footer() {
  return (
    <FooterContainer>
      <LeftContentContainer>
        <RegularText>© StartupKit</RegularText>
      </LeftContentContainer>
      <RightContentContainer>
        <SocialLinks>
          <SocialLink to="https://twitter.com">Twitter</SocialLink>
          <SocialLinkSeparator />
          <SocialLink to="https://youtube.com">YouTube</SocialLink>
          <SocialLinkSeparator />
          <SocialLink to="https://linkedin.com">LinkedIn</SocialLink>
        </SocialLinks>
      </RightContentContainer>
      <Clear />
    </FooterContainer>
  )
}

export default memo(Footer)
