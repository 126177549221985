import { NavLink } from 'react-router-dom'
import styled from 'styled-components'
import { layoutComponentsBorderColor, lightGray, mainBlue, regularGray } from '../../styles/colors'

export const UnderlineNavigationContainer = styled.div`
  position: relative;
  height: ${(props) => (props.height ? props.height : '32px')};
  line-height: ${(props) => (props.height ? props.height : '32px')};
`

export const UnderLineNavigationLink = styled(NavLink)`
  position: relative;
  display: block;
  float: left;
  height: calc(100% - 1px);
  padding: 0 24px;

  text-decoration: none;
  color: ${lightGray};
  font-size: 14px;

  transition: border-color 275ms ease, color 275ms ease;

  border-bottom: solid 1px ${layoutComponentsBorderColor};

  &:hover {
    border-color: ${mainBlue};
  }

  &.active {
    border-color: ${mainBlue};
    color: ${regularGray};
  }
`
