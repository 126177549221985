import { createAsyncThunk } from '@reduxjs/toolkit'
import { FrontApiAgent } from '../../../../../../api/frontApiAgent'

export const createFunctionsFolder = createAsyncThunk(
  'front-api/functions/folder/create-folder',
  async ({ projectUuid, parentUuid, parentIndex, name }, thunkApi) => {
    try {
      const folder = await FrontApiAgent.createFunctionsFolder(projectUuid, parentUuid, name)

      return { folder, parentUuid, parentIndex }
    } catch (error) {
      return thunkApi.rejectWithValue(error)
    }
  },
)
