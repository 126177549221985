import React, { memo, useEffect, useState } from 'react'
import { HeaderContainer, Title, Subtitle } from './../styles'
import {
  ButtonWithText,
  ButtonWithTextContainer,
  ButtonWithTextLeftBlock,
  FieldContainer,
  FormContainer,
} from '../../../../common/styles/form-styles'
import TextField from '../../../../common/form/text-field/TextField'
import {
  changeName,
  setSettings,
  isProjectGeneralSettingsFormLoaded,
  selectGeneralSettings,
  selectRequestStatus,
  changeRequestStatus,
} from '../../../../../reducers/project/project-general-settings-form'
import { useDispatch, useSelector } from 'react-redux'
import { selectProject } from '../../../../../reducers/project/project-details'
import { RegularText } from '../../../../common/styles/text'
import { Clear } from '../../../../common/styles/styles'
import { deleteProjectImage, updateGeneralSettings, updateProjectImage } from './slice'
import { REQUEST_STATUSES } from '../../../../../reducers/reducer-helper'
import Loading from '../../../../common/loading/Loading'
import UploadImageWithPreview from '../../../../common/form/upload-image-with-preview/UploadImageWithPreview'
import { ContentContainer, ContentWrapperContainer } from '../../../../common/layouts/content-container/styles'

function ProjectSettingsGeneral() {
  const dispatch = useDispatch()

  const project = useSelector(selectProject)
  const settings = useSelector(selectGeneralSettings)
  const isFormLoaded = useSelector(isProjectGeneralSettingsFormLoaded)
  const requestStatus = useSelector(selectRequestStatus)

  // Can't be stored in the Redux as file is not serializable
  const [projectImage, setProjectImage] = useState(project.image ? project.image : null)

  const [formWasChanged, changeForm] = useState(false)

  const changeNameProcess = (event) => {
    event.preventDefault()
    dispatch(changeName({ name: event.target.value }))
  }

  const changeImageProcess = (image) => {
    dispatch(changeRequestStatus({ status: REQUEST_STATUSES.PENDING }))
    dispatch(updateProjectImage({ projectUuid: project.uuid, image }))
  }

  const deleteImageProcess = () => {
    dispatch(changeRequestStatus({ status: REQUEST_STATUSES.PENDING }))
    dispatch(deleteProjectImage({ projectUuid: project.uuid }))
  }

  useEffect(() => {
    const { name, image } = project

    setProjectImage(image)
    dispatch(setSettings({ name }))
  }, [project])

  useEffect(() => {
    if (isFormLoaded) {
      const { name } = project

      changeForm(name !== settings.name)
    }
  }, [isFormLoaded, settings, project])

  const saveSettings = (event) => {
    event.preventDefault()
    dispatch(changeRequestStatus({ status: REQUEST_STATUSES.PENDING }))
    dispatch(
      updateGeneralSettings({
        uuid: project.uuid,
        image: settings.image,
        name: settings.name,
      }),
    )
  }

  return (
    <ContentContainer>
      <HeaderContainer>
        <Title>General settings</Title>
      </HeaderContainer>
      <ContentContainer top={'48px'} padding={'24px'}>
        <Subtitle>
          <RegularText>Here you can change project name and some other settings</RegularText>
        </Subtitle>
        {requestStatus === REQUEST_STATUSES.PENDING && <Loading />}
        {isFormLoaded && (
          <ContentWrapperContainer width={'360px'} height={'auto'} marginTop={'0'} marginBottom={'0'}>
            <FormContainer onSubmit={saveSettings}>
              <ContentWrapperContainer width={'80px'} height={'80px'} alignCenter={true} marginTop={'64px'}>
                <UploadImageWithPreview
                  existingImage={projectImage ? projectImage.key : projectImage}
                  changeImageHandler={changeImageProcess}
                  deleteImageHandler={deleteImageProcess}
                  usePreviewImage={false}
                />
              </ContentWrapperContainer>

              <FieldContainer>
                <fieldset>
                  <TextField
                    label={'Project name'}
                    value={settings.name}
                    changeEvent={changeNameProcess}
                    placeholder={'My new project'}
                  />
                </fieldset>
              </FieldContainer>

              {formWasChanged && (
                <ButtonWithTextContainer>
                  <ButtonWithTextLeftBlock>
                    <ButtonWithText type="submit">Save</ButtonWithText>
                  </ButtonWithTextLeftBlock>
                  <Clear />
                </ButtonWithTextContainer>
              )}
            </FormContainer>
          </ContentWrapperContainer>
        )}
      </ContentContainer>
    </ContentContainer>
  )
}

export default memo(ProjectSettingsGeneral)
