import React, { memo, useEffect, useRef } from 'react'
import { AddNodePopupComponent, TopContainer, BottomContainer, MoreButton, ItemButton, ItemName } from './styles'
import { NODES_ICONS, NODES_NAMES, NODES_TYPES } from '../../constants'
import StyledSVG from '../../../../common/styled-svg/StyledSVG'
import { lightGray } from '../../../../common/styles/colors'

function AddNodePopup({ closeHandler }) {
  const popoverRef = useRef(null)

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popoverRef.current && !popoverRef.current.contains(event.target)) {
        closeHandler(null)
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [popoverRef])

  return (
    <AddNodePopupComponent ref={popoverRef}>
      <TopContainer>
        {Object.values(NODES_TYPES).map((nodeType) => {
          return (
            <ItemButton key={`node-${nodeType}`}>
              <StyledSVG width={16} height={16} color={lightGray} src={NODES_ICONS[nodeType]} />
              <ItemName>{NODES_NAMES[nodeType]}</ItemName>
            </ItemButton>
          )
        })}
      </TopContainer>
      <BottomContainer>
        <MoreButton>More...</MoreButton>
      </BottomContainer>
    </AddNodePopupComponent>
  )
}

export default memo(AddNodePopup)
