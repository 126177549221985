import React, { memo } from 'react'
import { useSelector } from 'react-redux'
import {
  changeName,
  changeRequestStatus,
  resetState,
  selectFormData,
} from '../../../../../../reducers/front-api/function/folder/create-collection-form'
import { createFunctionsCollection } from './slice'
import CreateForm from '../../../../../common/tree/form/create-form/CreateForm'

function CreateCollectionForm() {
  const formData = useSelector(selectFormData)

  return (
    <CreateForm
      placeholder={'New collection'}
      name={formData.name}
      changeName={changeName}
      parentUuid={null}
      parentIndex={null}
      changeRequestStatus={changeRequestStatus}
      resetState={resetState}
      createProcess={createFunctionsCollection}
    />
  )
}

export default memo(CreateCollectionForm)
