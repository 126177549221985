import styled from 'styled-components'
import { CustomLink } from '../../common/styles/styles'

export const ForgotPasswordLinkContainer = styled.div`
  position: absolute;
  right: 0;
  top: 0;
`

export const ForgotPasswordLink = styled(CustomLink)`
  font-size: 12px;
`
