import styled from 'styled-components'
import { layoutComponentsBorderColor } from '../../styles/colors'

export const HeaderContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  height: 48px;
  border-bottom: solid 1px ${layoutComponentsBorderColor};
  box-sizing: border-box;
  padding: 0 24px;
`

export const SubHeaderContainer = styled(HeaderContainer)`
  padding: 0;
`
