import styled from 'styled-components'

export const SearchBarComponent = styled.div`
  width: 16px;
  height: 16px;
  line-height: 0;
`

export const SearchIconContainer = styled.div`
  width: 16px;
  height: 16px;

  transition: opacity 275ms ease;

  &:hover {
    cursor: pointer;
    opacity: 0.75;
  }

  background: ${(props) => props.backgroundColor};
`
