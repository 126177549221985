import styled from 'styled-components'
import { layoutComponentsBackgroundColor, layoutComponentsBorderColor, lightGray, lightSilver } from '../styles/colors'
import { TextInput } from '../form/text-field/styles'

export const TableRow = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  width: 100%;
  min-height: 32px;
  ${(props) => props.withBorder && `border-bottom: 1px solid ${layoutComponentsBorderColor};`}
  ${(props) => props.bottomMargin && `margin-bottom: ${props.bottomMargin};`}

  transition: background 275ms ease;

  padding: 0 12px;
  box-sizing: border-box;

  ${(props) =>
    props.hoverEffect &&
    `
    &:hover {
      background: ${layoutComponentsBackgroundColor};
      cursor: pointer;
    }
  `}
`

export const TableColumn = styled.div`
  position: relative;
  width: ${(props) => (props.width ? props.width : 'fit-content')};
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : '32px')};
  padding: ${(props) => (props.padding ? props.padding : 0)};
  height: 100%;
`

export const TableContainer = styled.div`
  position: relative;
  width: ${(props) => (props.width ? props.width : '100%')};
  min-width: 760px;
  height: 100%;
`

// Search
export const TableSearchIconContainer = styled.div`
  width: 16px;
  height: 16px;
`

export const TableSearchFieldContainer = styled.div`
  position: absolute;
  top: -1px;
  left: 0;
  height: 32px;
  line-height: 32px;
  width: calc(100% - 96px);

  background: #ffffff;
`

export const TableSearchField = styled(TextInput)`
  position: relative;
  width: 100%;
  height: 32px;
  line-height: 32px;

  border: none;
  padding: 0;
  margin: 0;
  background: none;

  box-sizing: border-box;

  font-size: 14px;
  font-weight: 400;

  padding-left: 8px;

  transition: border 275ms ease, color 275ms ease;

  &::-webkit-input-placeholder {
    /* Edge */
    color: ${lightSilver};
  }

  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: ${lightSilver};
  }

  &::placeholder {
    color: ${lightSilver};
  }

  &:hover,
  &:focus {
    border: none;

    &::placeholder {
      color: ${lightGray};
    }
  }
`

//Form components
export const TableForm = styled.form`
  position: relative;
  padding: 4px 0;
`

export const TableFormFieldContainer = styled.div`
  position: relative;
  width: ${(props) => (props.width ? props.width : '100%')};
  height: 32px;
  padding-right: 12px;
  box-sizing: border-box;
`
