import { createSlice } from '@reduxjs/toolkit'

import { failed, resetErrors, resetStatus } from '../../../reducer-helper'
import { renameFunctionFolder } from '../../../../components/front-api/functions/sidebar/forms/rename-folder-form/slice'

const initialState = {
  formData: {
    name: 'New folder',
  },
  error: null,
  status: null,
}

const renameFormSlice = createSlice({
  name: 'frontApiRenameFunctionsFolderForm',
  initialState,
  reducers: {
    resetState: () => initialState,
    init: (state, action) => {
      state.formData.name = action.payload.name
    },
    changeName: (state, action) => {
      state.formData.name = action.payload.name
    },
    changeRequestStatus: (state, action) => {
      state.status = action.payload.status
    },
  },
  extraReducers(builder) {
    builder.addCase(renameFunctionFolder.fulfilled, (state) => {
      resetStatus(state)
      resetErrors(state)
    })
    builder.addCase(renameFunctionFolder.rejected, failed)
  },
})

const currentSlice = (state) => state.frontApiRenameFunctionsFolderForm
export const selectRequestStatus = (state) => currentSlice(state).status
export const selectFormData = (state) => currentSlice(state).formData

const { reducer, actions } = renameFormSlice

export const { resetState, init, changeRequestStatus, changeName } = actions

export default reducer
