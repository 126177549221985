import { createSlice } from '@reduxjs/toolkit'
import { failed } from './../reducer-helper'
import { ResourceTypes, Status } from '../../utils/utils'
import { getProject } from '../../components/project/details/slice'
import { addDatabase, updateDatabase } from '../../components/project/details/settings/resources/database/slice'
import {
  deleteProjectImage,
  updateGeneralSettings,
  updateProjectImage,
} from '../../components/project/details/settings/general/slice'
import { addRedis, updateRedis } from '../../components/project/details/settings/resources/redis/slice'

const initialState = {
  project: null,
  error: null,
  status: Status.IDLE,
}

const changeResourceHandler = (resourceType, state, action) => {
  const existingDbIndex = state.project.resources.findIndex((resource) => resource.type === resourceType)
  if (existingDbIndex !== -1) {
    state.project.resources[existingDbIndex] = action.payload.resource
  } else {
    state.project.resources.push(action.payload.resource)
  }
}

const projectDetailsSlice = createSlice({
  name: 'projectDetails',
  initialState,
  reducers: {
    projectUnloaded: () => initialState,
  },
  extraReducers(builder) {
    builder.addCase(getProject.fulfilled, (state, action) => {
      state.project = action.payload.project
    })
    builder.addCase(updateDatabase.fulfilled, (state, action) => {
      changeResourceHandler(ResourceTypes.DB, state, action)
    })
    builder.addCase(addDatabase.fulfilled, (state, action) => {
      changeResourceHandler(ResourceTypes.DB, state, action)
    })
    builder.addCase(updateRedis.fulfilled, (state, action) => {
      changeResourceHandler(ResourceTypes.REDIS, state, action)
    })
    builder.addCase(addRedis.fulfilled, (state, action) => {
      changeResourceHandler(ResourceTypes.REDIS, state, action)
    })
    builder.addCase(updateGeneralSettings.fulfilled, (state, action) => {
      state.project.name = action.payload.name
    })
    builder.addCase(updateProjectImage.fulfilled, (state, action) => {
      state.project.image = action.payload.image
    })
    builder.addCase(deleteProjectImage.fulfilled, (state, action) => {
      state.project.image = null
    })
    builder.addCase(getProject.rejected, failed)
  },
})

const selectProjectDetailsSlice = (state) => state.projectDetails
export const selectProject = (state) => selectProjectDetailsSlice(state).project

const { reducer, actions } = projectDetailsSlice

export const { projectUnloaded } = actions

export default reducer
