import styled from 'styled-components'
import { regularGray } from '../styles/colors'
import { SecondaryActionButton } from '../styles/styles'
import StyledSVG from '../styled-svg/StyledSVG'

export const Button = styled(SecondaryActionButton)`
  position: relative;
  display: block;
  height: 40px;
  width: fit-content;
  border: none;
  background: none;

  padding: 8px 12px;
  box-sizing: border-box;

  transition: color 275ms ease;

  margin: 0 auto;

  &:hover {
    cursor: pointer;
    color: ${regularGray};
    background: none;
  }
`

export const ButtonIcon = styled(StyledSVG)`
  position: relative;
  display: block;
  float: left;
  margin-right: 8px;
`

export const ButtonText = styled.span`
  position: relative;
  display: block;
  float: left;
  font-size: 14px;
  line-height: 24px;
  color: ${regularGray};
  font-weight: 500;
`
