import { createAsyncThunk } from '@reduxjs/toolkit'
import { ProjectAgent } from '../../../api/projectAgent'

export const getProject = createAsyncThunk('project-details/get-project', async ({ projectUuid }, thunkApi) => {
  try {
    const project = await ProjectAgent.getProject(projectUuid)

    return { project }
  } catch (error) {
    return thunkApi.rejectWithValue(error)
  }
})
