import React, { memo } from 'react'
import {
  FormContainer as FormContentContainer,
  MiddleFormPageSubtitleContainer,
  MiddleFormPageTitleContainer,
} from '../../common/layouts/public-area/middle-form/styles'
import BackButton from '../../common/back-button/BackButton'
import { H1TitleWithButton, SubtitleText } from '../../common/styles/text'
import { Clear, Content360 } from '../../common/styles/styles'
import { ButtonContainer, ButtonWithTextCenter, FormContainer } from '../../common/styles/form-styles'
import {
  ContentTitle,
  InformationLeftCol,
  InformationRightCol,
  InformationRow,
  InformationRowTitle,
  MiddleFormContainer,
  ProjectImageContainer,
  ProjectImagePreview,
} from './styles'
import {
  changeRequestStatus,
  changeStep,
  selectFrontApiInformation,
  selectProjectInformation,
  selectRequestStatus,
} from '../../../reducers/project/project-create-form'
import { useDispatch, useSelector } from 'react-redux'
import { createProject } from './slice'
import { AWSRegions } from '../../../utils/utils'
import { REQUEST_STATUSES } from '../../../reducers/reducer-helper'
import Loading from '../../common/loading/Loading'

function Confirmation({ projectImage }) {
  const dispatch = useDispatch()

  const requestStatus = useSelector(selectRequestStatus)
  const projectInformation = useSelector(selectProjectInformation)
  const frontApiInformation = useSelector(selectFrontApiInformation)

  const changeStepProcess = (step) => {
    dispatch(changeStep({ step }))
  }

  const createProjectProcess = (event) => {
    event.preventDefault()
    dispatch(changeRequestStatus({ status: REQUEST_STATUSES.PENDING }))
    dispatch(
      createProject({
        name: projectInformation.name,
        image: projectImage,
        AWSRegion: projectInformation.aws.region,
        AWSAccessKeyId: projectInformation.aws.accessKeyId,
        AWSSecretAccessKey: projectInformation.aws.secretAccessKey,
        frontApiSize: frontApiInformation.size,
      }),
    )
  }

  const selectedRegion = Object.values(AWSRegions).find((item) => item.value === projectInformation.aws.region)

  return (
    <Content360>
      <MiddleFormContainer>
        {requestStatus === REQUEST_STATUSES.PENDING && <Loading />}

        <MiddleFormPageTitleContainer>
          <BackButton action={() => changeStepProcess(3)} />
          <H1TitleWithButton>Create confirmation</H1TitleWithButton>
          <Clear />
        </MiddleFormPageTitleContainer>
        <MiddleFormPageSubtitleContainer>
          <SubtitleText>Please confirm project creation</SubtitleText>
        </MiddleFormPageSubtitleContainer>

        <FormContentContainer>
          <FormContainer onSubmit={createProjectProcess}>
            {projectImage && (
              <InformationRow>
                <ProjectImageContainer>
                  <ProjectImagePreview src={URL.createObjectURL(projectImage)} />
                </ProjectImageContainer>
              </InformationRow>
            )}

            <InformationRow>
              <InformationLeftCol>Name</InformationLeftCol>
              <InformationRightCol>{projectInformation.name}</InformationRightCol>
              <Clear />
            </InformationRow>
            <InformationRowTitle>
              <ContentTitle>AWS Configurations</ContentTitle>
            </InformationRowTitle>
            <InformationRow>
              <InformationLeftCol>Region</InformationLeftCol>
              <InformationRightCol>{selectedRegion.name}</InformationRightCol>
              <Clear />
            </InformationRow>
            <InformationRow>
              <InformationLeftCol>Access Key ID</InformationLeftCol>
              <InformationRightCol>{projectInformation.aws.accessKeyId}</InformationRightCol>
              <Clear />
            </InformationRow>
            <InformationRow>
              <InformationLeftCol>Secret Access Key</InformationLeftCol>
              <InformationRightCol>{projectInformation.aws.secretAccessKey}</InformationRightCol>
              <Clear />
            </InformationRow>
            <InformationRowTitle>
              <ContentTitle>Front API</ContentTitle>
            </InformationRowTitle>
            <InformationRow>
              <InformationLeftCol>Size</InformationLeftCol>
              <InformationRightCol>{frontApiInformation.size}</InformationRightCol>
              <Clear />
            </InformationRow>

            <ButtonContainer>
              <ButtonWithTextCenter disabled={requestStatus === REQUEST_STATUSES.PENDING} type="submit">
                Continue
              </ButtonWithTextCenter>
            </ButtonContainer>
          </FormContainer>
        </FormContentContainer>
      </MiddleFormContainer>
    </Content360>
  )
}

export default memo(Confirmation)
