import styled from 'styled-components'
import { CustomLink } from '../common/styles/styles'

export const ContentContainer = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;

  background: #ffffff;
`

export const ExtraLink = styled(CustomLink)`
  display: inline-block;
  padding-top: 8px;
`
