import styled from 'styled-components'
import { lightSilver } from '../../../common/styles/colors'

export const FooterContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 80px;
  bottom: 0;

  padding-top: 19px;
  box-sizing: border-box;

  border-top: 1px solid ${lightSilver};
`

export const LeftButtonsContainer = styled.div`
  position: relative;

  float: left;
  margin-left: 24px;
`

export const RightButtonsContainers = styled.div`
  position: relative;

  float: right;
  margin-right: 24px;
`

export const BackButtonContainer = styled.div`
  position: relative;

  float: right;
  margin-right: 24px;

  width: 160px;
`

export const CancelButtonContainer = styled.div`
  position: relative;

  float: left;
  margin-left: 24px;

  width: 160px;
`

export const ButtonContainer = styled.div`
  position: relative;

  float: right;

  height: 40px;
  margin-left: 24px;
`
