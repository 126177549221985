import styled from 'styled-components'
import { layoutComponentsBorderColor, lightGray, regularGray } from '../../../../../../common/styles/colors'

export const DetailsContainer = styled.div`
  position: relative;
`

export const MoreButton = styled.button`
  width: 100%;
  height: 100%;
  background: ${layoutComponentsBorderColor};
  padding: 0;
  margin: 0;
  border: 0;

  font-size: 13px;
  font-weight: 600;
  color: ${lightGray};

  transition: background-color 275ms ease, color 275ms ease;

  &:hover {
    cursor: pointer;
    background: #eeeeee;
    color: ${regularGray};
  }
`

export const ConfigurationContainer = styled.div`
  position: relative;
  width: 100%;
  height: 28px;

  display: flex;
  align-items: center;

  box-sizing: border-box;
`

export const RequestConfigurations = styled.div`
  position: relative;
  display: flex;
`

export const Footer = styled.footer`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 0 12px;
  box-sizing: border-box;
  margin-top: 24px;

  width: 100%;
`
