import React, { memo, useEffect } from 'react'
import { Content, ProjectServiceDetailsContainer, SidebarContainer } from './styles'
import { Outlet } from 'react-router-dom'
import {
  SecondSidebar,
  SecondSidebarNavigationContainer,
  SecondSidebarNavigationLink,
  SidebarContent,
  SidebarHeader,
} from '../../../../common/layouts/sidebar-content/styles'
import { useParams } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { getServiceDetails } from '../../../../service/overview/slice'
import {
  selectServiceDetails,
  serviceUnloaded,
  selectRequestStatus,
  changeRequestStatus,
} from '../../../../../reducers/service/service-details'
import { H2HeaderTitle } from '../../../../common/styles/text'
import { REQUEST_STATUSES } from '../../../../../reducers/reducer-helper'
import Loading from '../../../../common/loading/Loading'

function ProjectServiceDetails() {
  let { projectUuid, serviceUuid } = useParams()

  const dispatch = useDispatch()

  const service = useSelector(selectServiceDetails)

  const requestStatus = useSelector(selectRequestStatus)

  useEffect(() => {
    dispatch(changeRequestStatus({ status: REQUEST_STATUSES.PENDING }))
    const fetchService = dispatch(getServiceDetails({ serviceUuid }))

    return () => {
      fetchService.abort()
      serviceUnloaded()
    }
  }, [serviceUuid])

  const configurationKeys = service && service.configurations ? Object.keys(service.configurations) : []

  return (
    <ProjectServiceDetailsContainer>
      {requestStatus === REQUEST_STATUSES.PENDING && <Loading />}
      {requestStatus === REQUEST_STATUSES.DONE && service && (
        <>
          <SidebarContainer>
            <SidebarHeader>
              <H2HeaderTitle>{service.name}</H2HeaderTitle>
            </SidebarHeader>
            <SidebarContent>
              <SecondSidebar>
                <SecondSidebarNavigationContainer>
                  <SecondSidebarNavigationLink end to={`/project/${projectUuid}/services/${serviceUuid}`}>
                    Overview
                  </SecondSidebarNavigationLink>
                  {configurationKeys.length > 0 && (
                    <SecondSidebarNavigationLink to={`/project/${projectUuid}/services/${serviceUuid}/configurations`}>
                      Configurations
                    </SecondSidebarNavigationLink>
                  )}
                  <SecondSidebarNavigationLink to={`/project/${projectUuid}/services/${serviceUuid}/settings`}>
                    Service settings
                  </SecondSidebarNavigationLink>
                </SecondSidebarNavigationContainer>
              </SecondSidebar>
            </SidebarContent>
          </SidebarContainer>
          <Content>
            <Outlet />
          </Content>
        </>
      )}
    </ProjectServiceDetailsContainer>
  )
}

export default memo(ProjectServiceDetails)
