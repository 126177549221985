import React, { memo } from 'react'
import { Button, ButtonIcon } from './styles'
import { Clear } from '../styles/styles'
import { lightGray, regularGray, superLightSilver } from '../styles/colors'

const IconButton = ({
  icon,
  action,
  backgroundColor = superLightSilver,
  iconHoverColor = regularGray,
  backgroundHoverColor = superLightSilver,
  iconColor = lightGray,
  width = 10,
  height = 10,
}) => {
  return (
    <Button
      backgroundHoverColor={backgroundHoverColor}
      iconHoverColor={iconHoverColor}
      backgroundColor={backgroundColor}
      onClick={action}
    >
      <ButtonIcon width={width} height={height} color={iconColor} src={icon} />
      <Clear />
    </Button>
  )
}

export default memo(IconButton)
