import styled from 'styled-components'
import { darkGray } from '../../../common/styles/colors'
import { NavLink } from 'react-router-dom'

export const ProjectServicesContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
`

export const Content = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 400px;
  right: 0;
`

export const ItemInfoBlock = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`

export const ProjectServiceItemContainer = styled(NavLink)`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  height: 40px;
  padding: 0 24px;
  box-sizing: border-box;

  text-decoration: none;

  transition: background-color 275ms ease;

  &.active {
    background: #ffffff;
  }

  &:hover {
    cursor: pointer;
    background: #ffffff;
  }
`

export const ServiceIconContainer = styled.div`
  position: relative;
  width: 16px;
  height: 16px;
  text-align: center;
`

export const ServiceInfoContainer = styled.div`
  position: relative;
  margin-left: 8px;
`

export const ServiceName = styled.div`
  position: relative;
  line-height: normal;
  font-size: 14px;
  font-weight: 600;
  color: ${darkGray};
`

export const StatusContainer = styled.div`
  position: relative;
`
