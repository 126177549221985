import styled from 'styled-components'
import { NavLink } from 'react-router-dom'
import { lightGray, regularGray } from '../../../common/styles/colors'

export const ResultObjectLink = styled(NavLink)`
  position: relative;
  display: flex;
  align-items: center;
  text-decoration: none;

  font-size: 13px;
  font-weight: 600;
  padding-left: 8px;

  margin-right: 24px;

  &:hover,
  &.active {
    & svg {
      path {
        fill: ${regularGray};
      }
    }
  }
`
