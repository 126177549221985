import React, { memo } from 'react'
import { ListContainer, ListItem, NavigationContainer } from './styles'
import { useSelector } from 'react-redux'
import { selectStep } from '../../../../reducers/service/add-service-form'
import { selectServiceDetails } from '../../../../reducers/service/service-details'

function Navigation({resourcesToSetup}) {
  const step = useSelector(selectStep)
  const service = useSelector(selectServiceDetails)

  return (
    <NavigationContainer>
      <ListContainer>
        {resourcesToSetup.length > 0 && <ListItem activePage={step === 1}>Resources</ListItem>}
        <ListItem activePage={step === 2}>General settings</ListItem>
        {service.configuration && Object.keys(service.configuration).length > 0 && (
          <ListItem activePage={step === 3}>Configurations</ListItem>
        )}
      </ListContainer>
    </NavigationContainer>
  )
}

export default memo(Navigation)
