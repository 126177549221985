import React, { memo } from 'react'
import { StatusLabelBlock } from './styles'

const StatusLabel = ({ status }) => {
  return (
    <StatusLabelBlock status={status} />
  )
}

export default memo(StatusLabel)
