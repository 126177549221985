import styled from 'styled-components'
import { lightGray, regularGray } from '../styles/colors'

export const FieldLabel = styled.div`
  position: relative;
  font-size: 14px;
  color: ${regularGray};

  &:hover {
    cursor: pointer;
  }
`

export const Label = styled.div`
  position: relative;
  margin-bottom: 4px;
  color: ${lightGray};
  font-size: 14px;
  line-height: 1;
`
