import React, { memo, useEffect, useState } from 'react'
import { Sidebar, SidebarDivider, SidebarHeader } from '../../../common/layouts/sidebar-content/styles'
import { H2HeaderTitle } from '../../../common/styles/text'
import SearchBar from '../../../common/search-bar/SearchBar'
import { ContentContainer, ContentWrapperContainer } from '../../../common/layouts/content-container/styles'
import AddIcon from '../../../../assets/images/add-icon.svg'
import { useDispatch, useSelector } from 'react-redux'
import { selectProject } from '../../../../reducers/project/project-details'
import { useNavigate, useParams } from 'react-router'
import { REQUEST_STATUSES } from '../../../../reducers/reducer-helper'
import Loading from '../../../common/loading/Loading'
import StyledSVG from '../../../common/styled-svg/StyledSVG'
import { lightGray } from '../../../common/styles/colors'
import { Tree, UncontrolledTreeEnvironment } from 'react-complex-tree'
import CollectionIcon from '../../../../assets/images/collection-icon.svg'
import FolderArrowIcon from '../../../../assets/images/folder-arrow-icon.svg'
import { selectRequestStatus } from '../../../../reducers/front-api/function/folder/create-collection-form'
import { selectCreatedFunctionUuid } from '../../../../reducers/front-api/function/create-form'
import MorePopover from './popovers/more/MorePopover'
import AddPopover from './popovers/add/AddPopover'
import {
  resetUpdatedName,
  selectFunction,
  selectFunctionsFolders,
  selectUpdatedName,
} from '../../../../reducers/front-api/front-api'
import { FolderTreeItemTypes } from '../../../../utils/utils'
import { updateFunctionsFolderChildren } from './slice'
import CreateCollectionForm from './forms/create-collection/CreateCollectionForm'
import CreateFolderForm from './forms/create-folder/CreateFolderForm'
import CreateFunctionForm from './forms/create-function/CreateFunctionForm'
import RenameFolderForm from './forms/rename-folder-form/RenameFolderForm'
import RenameFunctionForm from './forms/rename/RenameFunctionForm'
import { CustomTreeDataProvider } from '../../../common/tree/DataProvider'
import { FunctionIcon, ItemLinkContainer } from './styles'
import {
  CreateItemButton,
  CreateItemButtonIcon,
  CreateItemButtonIconContainer,
  CreateItemButtonText,
  DragBetweenLine,
  IconContainer,
  ItemTitle,
  RotatedIconContainer,
  SortableItemButton,
  SortableItemButtonsContainer,
  SortableItemContainer,
  SortableItemIconContainer,
  SortableItemTitleContainer,
} from '../../../common/tree/styles'

function FunctionsSidebar() {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { functionUuid } = useParams()

  const project = useSelector(selectProject)
  const createdFunctionUuid = useSelector(selectCreatedFunctionUuid)
  const createCollectionRequestStatus = useSelector(selectRequestStatus)
  const functions = useSelector(selectFunction)
  const foldersAndCollection = useSelector(selectFunctionsFolders)
  const updatedName = useSelector(selectUpdatedName)

  const [searchQuery, setSearchQuery] = useState('')
  const [itemUuidWithMorePopover, setItemUuidWithMorePopover] = useState(null)
  const [itemUuidWithAddPopover, setItemUuidWithAddPopover] = useState(null)
  const [expandedItems, setExpandedItems] = useState([])
  const [renameItemIndex, setRenameItemIndex] = useState(null)
  const [uniqueTreeIndex, setUniqueTreeIndex] = useState(Date.now())

  const movingItemHandler = (folderUuid, newChildren) => {
    dispatch(
      updateFunctionsFolderChildren({
        projectUuid: project.uuid,
        folderUuid,
        newChildren,
      }),
    )
  }

  const [treeDataProvider, setTreeDataProvider] = useState(
    new CustomTreeDataProvider({}, (item, data) => ({ ...item, data }), movingItemHandler),
  )

  const [sortedItems, setSortedItems] = useState({
    [FolderTreeItemTypes.ROOT]: {
      // Need to be here to avoid issue with dnd
      index: [FolderTreeItemTypes.ROOT],
    },
  })

  const showCreateFormHandler = (formType, formTitle, parentIndex, parentUuid) => {
    const index = `${formType}`

    const updatedList = {
      ...sortedItems,
      [index]: {
        index,
        isFolder: false,
        data: {
          type: formType,
          title: formTitle,
          parentIndex,
          parentUuid,
        },
      },
    }

    updatedList[parentIndex].children.unshift(index)

    setSortedItems(updatedList)
    setUniqueTreeIndex(Date.now()) // Need to reset items
  }

  // Usage
  const showCreateCollectionFormHandler = () => {
    const parentIndex = FolderTreeItemTypes.ROOT
    showCreateFormHandler(FolderTreeItemTypes.COLLECTION_CREATE_FORM, 'Create Collection Form', parentIndex)
  }

  const showCreateFolderFormHandler = (parentIndex, parentUuid) => {
    showCreateFormHandler(FolderTreeItemTypes.FOLDER_CREATE_FORM, 'Create Folder Form', parentIndex, parentUuid)
  }

  const showCreateFunctionFormHandler = (parentIndex, parentUuid) => {
    showCreateFormHandler(FolderTreeItemTypes.FUNCTION_CREATE_FORM, 'Create Function Form', parentIndex, parentUuid)
  }

  useEffect(() => {
    // Prepare functions and folders for the Tree
    const items = {}
    for (const _function of functions) {
      const index = `${FolderTreeItemTypes.FUNCTION}:${_function.uuid}`

      items[index] = {
        index,
        isFolder: false,
        data: {
          uuid: _function.uuid,
          type: FolderTreeItemTypes.FUNCTION,
          title: _function.name,
        },
      }
    }

    for (const folder of foldersAndCollection) {
      const index =
        folder.type === FolderTreeItemTypes.ROOT ? FolderTreeItemTypes.ROOT : `${folder.type}:${folder.uuid}`

      items[index] = {
        index,
        isFolder: true,
        children: folder.children ? folder.children.map((child) => `${child.type}:${child.uuid}`) : [],
        data: {
          uuid: folder.uuid,
          type: folder.type,
          title: folder.name,
        },
      }
    }

    setSortedItems(items)
  }, [functions, foldersAndCollection])

  useEffect(() => {
    if (Object.keys(sortedItems).length > 0) {
      treeDataProvider.updateItems(sortedItems, [FolderTreeItemTypes.ROOT, ...expandedItems])
    }
  }, [sortedItems, expandedItems])

  useEffect(() => {
    setRenameItemIndex(null)
    setUniqueTreeIndex(Date.now())
    dispatch(resetUpdatedName())
  }, [updatedName])

  useEffect(() => {
    if (createdFunctionUuid) {
      navigate(`/project/${project.uuid}/front-api/functions/${createdFunctionUuid}`)
    }
  }, [createdFunctionUuid])

  const onSortableItemClickHandler = (event, item) => {
    if (renameItemIndex !== item.index) {
      event.preventDefault

      if (item.data.type === FolderTreeItemTypes.FUNCTION) {
        navigate(`/project/${project.uuid}/front-api/functions/${item.data.uuid}`)
      }
    }
  }

  return (
    <Sidebar background={false} width={'100%'} border={true}>
      {createCollectionRequestStatus === REQUEST_STATUSES.PENDING && <Loading />}
      <SidebarHeader>
        <H2HeaderTitle>Functions</H2HeaderTitle>
        <SearchBar
          fieldPlaceholder={'Search functions...'}
          backgroundColor={'#FFFFFF'}
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
        />
      </SidebarHeader>
      <ContentContainer top={'48px'} width={'100%'} marginTop={'12px'}>
        <ContentWrapperContainer marginTop={'12px'} padding={'0 24px'} marginBottom={'12px'}>
          <CreateItemButton onClick={showCreateCollectionFormHandler}>
            <CreateItemButtonIconContainer>
              <CreateItemButtonIcon width={10} height={10} color={'#FFFFFF'} src={AddIcon} />
            </CreateItemButtonIconContainer>
            <CreateItemButtonText>Create functions collection</CreateItemButtonText>
          </CreateItemButton>
        </ContentWrapperContainer>

        <ContentWrapperContainer marginTop={'0px'} marginBottom={'0px'} padding={'0 24px'}>
          <SidebarDivider />
        </ContentWrapperContainer>

        <ContentWrapperContainer marginTop={'12px'} marginBottom={'12px'} padding={'0'}>
          <UncontrolledTreeEnvironment
            key={uniqueTreeIndex}
            dataProvider={treeDataProvider}
            getItemTitle={(item) => item.data.title}
            viewState={{
              tree: {
                expandedItems,
              },
            }}
            canDragAndDrop={true}
            canReorderItems={true}
            canDropOnFolder={true}
            onExpandItem={(item) => {
              setExpandedItems([...expandedItems, item.index])
            }}
            onCollapseItem={(item) =>
              setExpandedItems(expandedItems.filter((expandedItemIndex) => expandedItemIndex !== item.index))
            }
            canSearchByStartingTyping={false}
            canDropAt={(items, target) => {
              const draggedItem = items[0]
              const canDrag = target.targetType === 'between-items' && target.parentItem === FolderTreeItemTypes.ROOT

              switch (draggedItem.data.type) {
                case FolderTreeItemTypes.COLLECTION:
                  return canDrag

                case FolderTreeItemTypes.FOLDER:
                case FolderTreeItemTypes.FUNCTION:
                  return !canDrag

                default:
                  return true
              }
            }}
            renderItemTitle={({ title }) => <ItemTitle>{title}</ItemTitle>}
            renderItemArrow={({ item, context }) => {
              const functionIcon = <FunctionIcon active={item.data.uuid === functionUuid} />
              const collectionIcon = <StyledSVG src={CollectionIcon} width={14} height={14} color={lightGray} />
              const folderIcon = (
                <RotatedIconContainer isExpanded={context.isExpanded}>
                  <StyledSVG src={FolderArrowIcon} width={14} height={7} color={lightGray} />
                </RotatedIconContainer>
              )

              const iconMap = {
                [FolderTreeItemTypes.COLLECTION]: collectionIcon,
                [FolderTreeItemTypes.COLLECTION_CREATE_FORM]: collectionIcon,
                [FolderTreeItemTypes.FUNCTION]: functionIcon,
                [FolderTreeItemTypes.FUNCTION_CREATE_FORM]: functionIcon,
                [FolderTreeItemTypes.FOLDER]: folderIcon,
                [FolderTreeItemTypes.FOLDER_CREATE_FORM]: folderIcon,
              }

              const icon = iconMap[item.data.type] || null

              return icon ? <IconContainer>{icon}</IconContainer> : null
            }}
            renderItem={({ item, title, arrow, depth, context, children }) => {
              const showButton = itemUuidWithAddPopover === item.index || itemUuidWithMorePopover === item.index
              const isCreateForm =
                item.data.type === FolderTreeItemTypes.COLLECTION_CREATE_FORM ||
                item.data.type === FolderTreeItemTypes.FOLDER_CREATE_FORM ||
                item.data.type === FolderTreeItemTypes.FUNCTION_CREATE_FORM

              return (
                <SortableItemContainer depth={depth} {...context.itemContainerWithChildrenProps}>
                  <SortableItemButton
                    isDraggingOver={context.isDraggingOver}
                    {...context.itemContainerWithoutChildrenProps}
                    {...(!isCreateForm && renameItemIndex !== item.index ? context.interactiveElementProps : {})}
                  >
                    <ItemLinkContainer
                      active={item.data.uuid === functionUuid}
                      paddingLeft={`${depth * 12}px`}
                      onClick={(event) => {
                        onSortableItemClickHandler(event, item)
                      }}
                    >
                      <SortableItemIconContainer>{arrow}</SortableItemIconContainer>

                      {!isCreateForm && renameItemIndex !== item.index && (
                        <>
                          <SortableItemTitleContainer>{title}</SortableItemTitleContainer>
                          <SortableItemButtonsContainer>
                            {item.data.type !== FolderTreeItemTypes.FUNCTION && (
                              <AddPopover
                                showButton={showButton}
                                item={item}
                                isExpanded={context.isExpanded}
                                itemUuidWithPopover={itemUuidWithAddPopover}
                                setItemUuidWithPopover={setItemUuidWithAddPopover}
                                showCreateFolderFormHandler={showCreateFolderFormHandler}
                                showCreateFunctionFormHandler={showCreateFunctionFormHandler}
                              />
                            )}
                            <MorePopover
                              showButton={showButton}
                              item={item}
                              itemUuidWithPopover={itemUuidWithMorePopover}
                              setRenameItemIndex={setRenameItemIndex}
                              setItemUuidWithPopover={setItemUuidWithMorePopover}
                            />
                          </SortableItemButtonsContainer>
                        </>
                      )}

                      {renameItemIndex === item.index && (
                        <>
                          {item.data.type !== FolderTreeItemTypes.FUNCTION && <RenameFolderForm item={item} />}
                          {item.data.type === FolderTreeItemTypes.FUNCTION && <RenameFunctionForm item={item} />}
                        </>
                      )}
                      {item.data.type === FolderTreeItemTypes.COLLECTION_CREATE_FORM && <CreateCollectionForm />}
                      {item.data.type === FolderTreeItemTypes.FOLDER_CREATE_FORM && (
                        <CreateFolderForm parentUuid={item.data.parentUuid} parentIndex={item.data.parentIndex} />
                      )}
                      {item.data.type === FolderTreeItemTypes.FUNCTION_CREATE_FORM && (
                        <CreateFunctionForm parentUuid={item.data.parentUuid} parentIndex={item.data.parentIndex} />
                      )}
                    </ItemLinkContainer>
                  </SortableItemButton>
                  {children}
                </SortableItemContainer>
              )
            }}
            renderTreeContainer={({ children, containerProps }) => <div {...containerProps}>{children}</div>}
            renderItemsContainer={({ children, containerProps }) => <ul {...containerProps}>{children}</ul>}
            renderDragBetweenLine={(draggingPosition) => (
              <DragBetweenLine
                style={{
                  left: `${draggingPosition.depth * 12}px`,
                }}
              />
            )}
          >
            <Tree treeId="tree" rootItem={FolderTreeItemTypes.ROOT} />
          </UncontrolledTreeEnvironment>
        </ContentWrapperContainer>
      </ContentContainer>
    </Sidebar>
  )
}

export default memo(FunctionsSidebar)
