import { createSlice } from '@reduxjs/toolkit'
import { failed } from '../../reducer-helper'
import { updateGlobalParameter } from '../../../components/front-api/global-parameters/edit-parameter-form/slice'

const initialState = {
  formData: {
    name: '',
    value: '',
    description: '',
  },
  error: null,
  status: null,
}

const EditFormSlice = createSlice({
  name: 'frontApiEditGlobalParameterForm',
  initialState,
  reducers: {
    resetState: () => initialState,
    changeName: (state, action) => {
      state.formData.name = action.payload.name
    },
    changeValue: (state, action) => {
      state.formData.value = action.payload.value
    },
    changeDescription: (state, action) => {
      state.formData.description = action.payload.description
    },
    changeRequestStatus: (state, action) => {
      state.status = action.payload.status
    },
    initForm: (state, action) => {
      state.formData.name = action.payload.name
      state.formData.value = action.payload.value
      state.formData.description = action.payload.description
    },
  },
  extraReducers(builder) {
    builder.addCase(updateGlobalParameter.fulfilled, () => initialState)
    builder.addCase(updateGlobalParameter.rejected, failed)
  },
})

const currentSlice = (state) => state.frontApiEditGlobalParameterForm
export const selectRequestStatus = (state) => currentSlice(state).status
export const selectFormData = (state) => currentSlice(state).formData
export const selectShowForm = (state) => currentSlice(state).showForm

const { reducer, actions } = EditFormSlice

export const { resetState, initForm, changeRequestStatus, changeName, changeValue, changeDescription } =
  actions

export default reducer
