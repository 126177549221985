import { createSlice } from '@reduxjs/toolkit'
import { failed, REQUEST_STATUSES } from '../reducer-helper'
import { signIn } from '../../components/user/sign-in/slice'

const initialState = {
  data: {
    email: '',
    password: '',
  },
  error: null,
  status: REQUEST_STATUSES.NOT_TRIGGERED,
}

const signInFormSlice = createSlice({
  name: 'signInForm',
  initialState,
  extraReducers(builder) {
    builder.addCase(signIn.rejected, failed)
  },
  reducers: {
    changeRequestStatus: (state, action) => {
      state.status = action.payload.status
    },
    changeEmailValue: (state, action) => {
      state.data.email = action.payload.email
    },
    changePasswordValue: (state, action) => {
      state.data.password = action.payload.password
    },
    resetForm: () => initialState,
  },
})

const selectSignInFormSlice = (state) => state.signInForm
export const selectRequestStatus = (state) => selectSignInFormSlice(state).status
export const selectError = (state) => selectSignInFormSlice(state).error
export const selectFormData = (state) => selectSignInFormSlice(state).data

const { reducer, actions } = signInFormSlice

export const { changeRequestStatus, changeEmailValue, changePasswordValue, resetForm } = actions

export default reducer
