import React, { memo, useEffect } from 'react'
import { ContentWrapperContainer } from '../../../common/layouts/content-container/styles'
import {
  changeDescription,
  changeName,
  changeRequestStatus,
  changeValue,
  selectFormData,
  initForm,
  resetState,
} from '../../../../reducers/front-api/global-parameter/edit-form'
import { useDispatch, useSelector } from 'react-redux'
import { updateGlobalParameter } from './slice'
import { selectProject } from '../../../../reducers/project/project-details'
import { Clear } from '../../../common/styles/styles'
import { ButtonsContainer, CancelButton, EditParameterFormContainer, FieldsContainer, SaveButton } from './styles'
import TextField from '../../../common/form/text-field/TextField'
import { REQUEST_STATUSES } from '../../../../reducers/reducer-helper'
import { closePopup, selectPopupExtraParameters } from '../../../../reducers/popup'
import { FieldContainer } from '../../../common/styles/form-styles'
import { H2Title } from '../../../common/styles/text'

function EditParameterForm() {
  const dispatch = useDispatch()

  const formData = useSelector(selectFormData)
  const project = useSelector(selectProject)
  const popupExtraParameters = useSelector(selectPopupExtraParameters)

  const params = {}
  popupExtraParameters.map((param) => {
    params[param.name] = param.value
  })

  useEffect(() => {
    dispatch(
      initForm({
        name: params.name,
        value: params.value,
        description: params.description,
      }),
    )
  }, [])

  const changeNameFieldValue = (event) => {
    dispatch(changeName({ name: event.target.value }))
  }

  const changeValueFieldValue = (event) => {
    dispatch(changeValue({ value: event.target.value }))
  }

  const changeDescriptionFieldValue = (event) => {
    dispatch(changeDescription({ description: event.target.value }))
  }

  const updateParameterHandler = (event) => {
    event.preventDefault()
    dispatch(changeRequestStatus({ status: REQUEST_STATUSES.PENDING }))
    dispatch(
      updateGlobalParameter({
        projectUuid: project.uuid,
        parameterUuid: params.parameterUuid,
        name: formData.name,
        value: formData.value,
        description: formData.description,
      }),
    )
  }

  useEffect(() => {
    return () => {
      dispatch(resetState())
    }
  }, [])

  const cancelHandler = () => {
    dispatch(closePopup())
  }

  return (
    <EditParameterFormContainer>
      <ContentWrapperContainer marginTop={'0px'} marginBottom={'32px'}>
        <H2Title>
          Edit <b>{formData.name}</b> parameter
        </H2Title>
      </ContentWrapperContainer>
      <FieldsContainer>
        <FieldContainer>
          <fieldset>
            <TextField
              label={'Name'}
              placeholder={'new-parameter'}
              value={formData.name}
              changeEvent={changeNameFieldValue}
            />
          </fieldset>
        </FieldContainer>
        <FieldContainer>
          <fieldset>
            <TextField
              placeholder={'New parameter value'}
              label={'Value'}
              value={formData.value}
              changeEvent={changeValueFieldValue}
            />
          </fieldset>
        </FieldContainer>
        <FieldContainer>
          <fieldset>
            <TextField
              placeholder={'Small description...'}
              label={'Description'}
              value={formData.description}
              changeEvent={changeDescriptionFieldValue}
            />
          </fieldset>
        </FieldContainer>
      </FieldsContainer>

      <ButtonsContainer>
        <SaveButton type={'button'} onClick={updateParameterHandler}>
          Save parameter
        </SaveButton>
        <CancelButton type={'button'} onClick={cancelHandler}>
          Cancel
        </CancelButton>
        <Clear />
      </ButtonsContainer>
    </EditParameterFormContainer>
  )
}

export default memo(EditParameterForm)
