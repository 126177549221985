import styled from 'styled-components'
import { layoutComponentsBorderColor, lightGray, lightSilver } from '../../../../common/styles/colors'
import { TextField } from '../../../../common/styles/form-styles'
import { TableRow } from '../../../../common/table/styles'
import { MoreButton } from './more-popover/styles'

export const HeaderContainer = styled.div`
  position: absolute;
  width: 100%;
  top: 0;
  height: 48px;
  left: 0;
  right: 0;
  padding: 0 24px;
  box-sizing: border-box;

  border-bottom: 1px solid ${layoutComponentsBorderColor};
`

export const CreateParameterButtonContainer = styled.div`
  position: relative;
  width: 220px;
  height: 40px;
`

export const NameColumn = styled.div`
  position: relative;
  width: 220px;
`

export const ValueColumn = styled.div`
  position: relative;
  width: 220px;
`

export const DescriptionColumn = styled.div`
  position: relative;
  width: 180px;
`

export const LastColumn = styled.div`
  position: relative;
  display: flex;
  justify-content: end;
  width: 104px;
  height: 100%;

  line-height: normal;
`

export const ParameterTableRow = styled(TableRow)`
  &:hover ${MoreButton} {
    display: block;
  }
`

export const SearchIconContainer = styled.div`
  width: 16px;
  height: 16px;
`

export const SearchFieldContainer = styled.div`
  position: absolute;
  top: -1px;
  left: 0;
  height: 32px;
  line-height: 32px;
  width: calc(100% - 96px);

  background: #ffffff;
`

export const SearchField = styled(TextField)`
  position: relative;
  width: 100%;
  height: 32px;
  line-height: 32px;

  border: none;
  padding: 0;
  margin: 0;
  background: none;

  box-sizing: border-box;

  padding-left: 8px;

  font-size: 14px;
  font-weight: 400;

  transition: border 275ms ease, color 275ms ease;

  &::-webkit-input-placeholder {
    /* Edge */
    color: ${lightSilver};
  }

  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: ${lightSilver};
  }

  &::placeholder {
    color: ${lightSilver};
  }

  &:hover,
  &:focus {
    border: none;

    &::placeholder {
      color: ${lightGray};
    }
  }
`

export const Subtitle = styled.div`
  position: relative;
  margin-bottom: 32px;
`
