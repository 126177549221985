import React, { memo } from 'react'
import { LoadingContainer } from './styles'
import { BlocksShuffleTwo } from 'react-svg-spinners'
import { mainBlue } from '../styles/colors'

const Loading = () => {
  return (
    <LoadingContainer>
      <BlocksShuffleTwo color={mainBlue} width={'32px'} height={'32px'} />
    </LoadingContainer>
  )
}

export default memo(Loading)
