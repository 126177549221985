import React, { memo } from 'react'
import BackIcon from '../../../assets/images/back-icon.svg'
import { BackButton as BackButtonComponent } from './styles'
import StyledSVG from '../styled-svg/StyledSVG'
import { lightSilver } from '../styles/colors'

const BackButton = ({ action }) => {
  return (
    <BackButtonComponent onClick={action}>
      <StyledSVG width={12} height={12} color={lightSilver} src={BackIcon} />
    </BackButtonComponent>
  )
}

export default memo(BackButton)
