import { createAsyncThunk } from '@reduxjs/toolkit'
import { FrontApiAgent } from '../../../../api/frontApiAgent'

export const updateGlobalParameter = createAsyncThunk(
  'front-api/global-parameters/update',
  async ({ projectUuid, parameterUuid, name, value, description }, thunkApi) => {
    try {
      const parameter = await FrontApiAgent.updateGlobalParameter(projectUuid, parameterUuid, name, value, description)

      return { parameterUuid, parameter }
    } catch (error) {
      return thunkApi.rejectWithValue(error)
    }
  },
)
