import { createAsyncThunk } from '@reduxjs/toolkit'
import { FrontApiAgent } from '../../../../api/frontApiAgent'

export const createGlobalParameter = createAsyncThunk(
  'front-api/global-parameters/create',
  async ({ projectUuid, name, value, description }, thunkApi) => {
    try {
      const parameter = await FrontApiAgent.createGlobalParameter(projectUuid, name, value, description)

      return { parameter }
    } catch (error) {
      return thunkApi.rejectWithValue(error)
    }
  },
)
