import styled from 'styled-components'
import { layoutComponentsBackgroundColor, layoutComponentsBorderColor } from '../../../common/styles/colors'
import { NavLink } from 'react-router-dom'

export const ProjectSidebarContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 80px;
  background: ${layoutComponentsBackgroundColor};
  border-right: 1px solid ${layoutComponentsBorderColor};
  box-sizing: border-box;
  z-index: 1;
`

export const ProjectLogoContainer = styled.div`
  position: relative;
  width: 32px;
  height: 32px;
  margin: 0 auto;
  margin-top: 24px;
`

export const ProjectLogoComponent = styled.img`
  position: relative;
  width: 32px;
  height: 32px;

  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
`

export const NavigationContainer = styled.div`
  position: relative;
  width: 80px;
  margin-top: 80px;
`

export const NavigationLinkContainer = styled(NavLink)`
  position: relative;
  display: block;
  width: 48px;
  height: 48px;
  margin-bottom: 24px;
  margin-left: 16px;
  transition: background-color 275ms ease, opacity 275ms ease;
  opacity: 1;

  &:hover {
    cursor: pointer;
    opacity: 0.75;
  }
`

export const NavigationLinkIconContainer = styled.div`
  position: relative;
  width: 18px;
  height: 18px;
  padding-top: 16px;
  padding-left: 16px;
  box-sizing: border-box;
`
