import styled from 'styled-components'
import { lightSilver } from '../../styles/colors'

export const TextInput = styled.input`
  position: relative;
  width: 100%;
  height: 40px;
  line-height: 40px;
  padding: 0 12px;
  background: #fdfdfd;
  border: solid 1px #efefef;
  box-sizing: border-box;

  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;

  font-size: 14px;
  font-weight: 400;

  transition: border 275ms ease;

  &::-webkit-input-placeholder {
    /* Edge */
    color: ${lightSilver};
  }

  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: ${lightSilver};
  }

  &::placeholder {
    color: ${lightSilver};
  }

  &:disabled {
    opacity: 0.4;
  }

  &:hover,
  &:focus {
    border: solid 1px ${lightSilver};
  }
`

export const TextInputForList = styled(TextInput)`
  font-size: 13px;
  height: 32px;
  line-height: 32px;
`
