import React, { memo } from 'react'
import {
  ButtonContainer,
  CancelButtonContainer,
  FooterContainer,
  LeftButtonsContainer,
  RightButtonsContainers,
} from './styles'
import { CallToActionButton, Clear, DangerousButton, SecondaryActionButton } from '../../../common/styles/styles'
import { useDispatch, useSelector } from 'react-redux'
import { closePopup } from '../../../../reducers/popup'
import {
  selectStep,
  changeStep,
  selectSettings,
  selectConfigurations,
  selectResources,
} from '../../../../reducers/service/add-service-form'
import { selectServiceDetails } from '../../../../reducers/service/service-details'
import { addService } from '../slice'
import { selectProject } from '../../../../reducers/project/project-details'

function Footer({ resourcesToSetup }) {
  const dispatch = useDispatch()

  const { size } = useSelector(selectSettings)
  const configurations = useSelector(selectConfigurations)
  const resources = useSelector(selectResources)
  const step = useSelector(selectStep)
  const service = useSelector(selectServiceDetails)
  const project = useSelector(selectProject)

  const cancel = (event) => {
    event.preventDefault()
    dispatch(closePopup())
  }

  const changeStepProcess = (step) => {
    dispatch(changeStep({ step }))
  }

  const addServiceProcess = (event) => {
    event.preventDefault()

    dispatch(
      addService({
        projectUuid: project.uuid,
        serviceUuid: service.uuid,
        resources,
        settings: { size },
        configurations,
      }),
    )
  }

  return (
    <FooterContainer>
      <LeftButtonsContainer>
        <CancelButtonContainer>
          <DangerousButton onClick={cancel}>Cancel</DangerousButton>
        </CancelButtonContainer>
      </LeftButtonsContainer>
      <RightButtonsContainers>
        {step === 1 && (
          <ButtonContainer>
            <CallToActionButton
              disabled={resources.length < resourcesToSetup.length}
              onClick={() => changeStepProcess(2)}
            >
              Continue
            </CallToActionButton>
          </ButtonContainer>
        )}
        {step === 2 && (
          <>
            {service.configuration === null && (
              <ButtonContainer>
                <CallToActionButton onClick={addServiceProcess}>Add service</CallToActionButton>
              </ButtonContainer>
            )}
            {service.configuration !== null && Object.keys(service.configuration).length > 0 && (
              <ButtonContainer>
                <CallToActionButton onClick={() => changeStepProcess(3)}>Continue</CallToActionButton>
              </ButtonContainer>
            )}
          </>
        )}
        {step === 3 && (
          <>
            <CancelButtonContainer>
              <SecondaryActionButton onClick={() => changeStepProcess(1)}>Back</SecondaryActionButton>
            </CancelButtonContainer>
            <ButtonContainer>
              <CallToActionButton onClick={addServiceProcess}>Add service</CallToActionButton>
            </ButtonContainer>
          </>
        )}
      </RightButtonsContainers>
      <Clear />
    </FooterContainer>
  )
}

export default memo(Footer)
