import styled, { css } from 'styled-components'
import { mainBlue, regularGray } from '../../../common/styles/colors'

export const NavigationContainer = styled.div`
  position: absolute;
  left: 0;
  width: 240px;
  top: 0;
  bottom: 0;

  padding: 24px 0;
  box-sizing: border-box;
`

export const ListContainer = styled.ul`
  position: relative;
  padding: 0;
  margin: 0;
  list-style: none;
`

export const ListItem = styled.li`
  position: relative;
  height: 32px;
  line-height: 32px;
  padding: 0 24px;

  box-sizing: border-box;

  font-size: 14px;
  font-weight: 700;
  color: ${regularGray};

  transition: color 275ms ease;

  ${(props) =>
    props.activePage &&
    css`
      color: ${mainBlue};
    `}
`
