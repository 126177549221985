import React, { memo, useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import { ProjectContent, ProjectDetailsContainer, ProjectSidebarContainer } from './styles'
import ProjectSidebar from './sidebar/ProjectSidebar'
import { useDispatch, useSelector } from 'react-redux'
import { projectUnloaded, selectProject } from '../../../reducers/project/project-details'
import { getProject } from './slice'
import { useParams } from 'react-router-dom'
import Loading from '../../common/loading/Loading'
import { ProjectStatuses } from '../../../utils/utils'
import ProjectCreating from './ProjectCreating'
import ProjectCreatingFailed from './ProjectCreatingFailed'

function ProjectDetails() {
  const dispatch = useDispatch()
  const project = useSelector(selectProject)

  const { projectUuid } = useParams()

  useEffect(() => {
    const fetchProject = dispatch(getProject({ projectUuid }))

    return () => {
      dispatch(projectUnloaded())
      fetchProject.abort()
    }
  }, [])

  if (!project) {
    return <Loading />
  }

  return (
    <ProjectDetailsContainer>
      {project.status === ProjectStatuses.CREATING && <ProjectCreating/>}
      {project.status === ProjectStatuses.FAILED && <ProjectCreatingFailed/>}
      {project.status === ProjectStatuses.DEPLOYED && (
        <>
          <ProjectSidebarContainer>
            <ProjectSidebar />
          </ProjectSidebarContainer>
          <ProjectContent>
            <Outlet />
          </ProjectContent>
        </>
      )}
    </ProjectDetailsContainer>
  )
}

export default memo(ProjectDetails)
