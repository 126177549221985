import React, { memo } from 'react'
import { ContentComponent, LogoImageComponent } from './styles'
import LogoImage from '../../../assets/images/logo-full.svg'

const LogoFull = () => {
  return (
    <ContentComponent>
      <LogoImageComponent src={LogoImage} alt="Startup Kit Cloud" />
    </ContentComponent>
  )
}

export default memo(LogoFull)
