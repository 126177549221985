import styled from 'styled-components'
import { greenTextColor, lightGray, redTextColor, yellowTextColor } from '../../common/styles/colors'
import { TableRow } from '../../common/table/styles'

export const CreatedKeyContainer = styled.div`
  position: relative;
  float: left;
  height: 32px;
`

export const Title = styled.h2`
  position: relative;
  font-size: 16px;
  line-height: 48px;
  float: left;
  color: ${lightGray};
`

export const Subtitle = styled.div`
  position: relative;
  margin-bottom: 32px;
`

export const NewKeyContainer = styled.div`
  position: relative;
  width: 100%;
  height: 40px;
`

export const AddKeyButtonContainer = styled.div`
  position: relative;
  float: left;
  margin-right: 24px;
`

export const KeyPlaceholderContainer = styled.div`
  position: relative;
  width: 240px;
`

export const CreatedDateContainer = styled.div`
  position: relative;
  width: 240px;
`

export const DeleteButtonContainer = styled.div`
  position: relative;
  width: 18px;
  height: 18px;
  display: none;
`

export const DeleteButtonColumnContainer = styled.div`
  position: relative;
  width: 60px;
  box-sizing: border-box;
`

export const GreenText = styled.span`
  color: ${greenTextColor};
  font-weight: 700;
`

export const YellowText = styled.span`
  color: ${yellowTextColor};
  font-weight: 700;
`

export const RedText = styled.span`
  color: ${redTextColor};
  font-weight: 700;
`

export const KeyTableRow = styled(TableRow)`
  &:hover ${DeleteButtonContainer} {
    display: block;
  }
`
