import agent from '../api/agent'
import { signIn } from '../components/user/sign-in/slice'
import { createProfile } from '../components/user/sign-up/slice'
import { logout } from '../components/common/layouts/private-area/header/user-popover/slice'

const localStorageMiddleware = (store) => (next) => (action) => {
  switch (action.type) {
    case createProfile.fulfilled.type:
    case signIn.fulfilled.type:
      window.localStorage.setItem('api-token', action.payload.apiToken)
      agent.setApiToken(action.payload.apiToken)
      break

    case logout.fulfilled.type:
      window.localStorage.removeItem('api-token')
      agent.setApiToken(undefined)
      break
  }

  return next(action)
}

export { localStorageMiddleware }
