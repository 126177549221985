import React, { memo } from 'react'
import { ContentContainer, FlexContainer } from '../../../../../common/layouts/content-container/styles'
import { HeaderContainer } from '../../../../../common/layouts/content-block-header/styles'
import { H2HeaderTitle, RegularText } from '../../../../../common/styles/text'
import { Link, useParams } from 'react-router-dom'
import BackLink from '../../../../../common/back-link/BackLink'
import FlowBuilder from '../../../../flow-builder/FlowBuilder'
import { useSelector } from 'react-redux'
import { selectFunctionByUuid } from '../../../../../../reducers/front-api/front-api'
import { HeaderBreadcrumbs, HeaderBreadcrumbsLink, HeaderBreadcrumbsSlash } from './styles'

function Builder() {
  const { projectUuid, functionUuid } = useParams()
  const _function = useSelector((state) => selectFunctionByUuid(state, functionUuid))

  return (
    <ContentContainer>
      <HeaderContainer>
        <FlexContainer>
          <BackLink link={`/project/${projectUuid}/front-api/functions/${functionUuid}/flow`} />
          <H2HeaderTitle>
            <HeaderBreadcrumbs>
              <HeaderBreadcrumbsLink to={`/project/${projectUuid}/front-api/functions`}>
                Functions
              </HeaderBreadcrumbsLink>{' '}
              <HeaderBreadcrumbsSlash>/</HeaderBreadcrumbsSlash>
              <HeaderBreadcrumbsLink to={`/project/${projectUuid}/front-api/functions/${functionUuid}`}>
                {_function.name}
              </HeaderBreadcrumbsLink>
              <HeaderBreadcrumbsSlash>/</HeaderBreadcrumbsSlash>
            </HeaderBreadcrumbs>
            Flow builder
          </H2HeaderTitle>
        </FlexContainer>
      </HeaderContainer>
      <ContentContainer top={'48px'}>
        <FlowBuilder />
      </ContentContainer>
    </ContentContainer>
  )
}

export default memo(Builder)
