import React, { memo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { closePopup, selectPopupExtraParameters } from '../../../../reducers/popup'
import { selectProject } from '../../../../reducers/project/project-details'
import { changeRequestStatus, selectRequestStatus } from '../../../../reducers/front-api/global-parameter/delete'
import { REQUEST_STATUSES } from '../../../../reducers/reducer-helper'
import { deleteGlobalParameter } from './slice'
import ConfirmPopup from '../../../common/confirm-popup/ConfirmPopup'

function DeleteParameterPopup() {
  const dispatch = useDispatch()

  const project = useSelector(selectProject)
  const popupExtraParameters = useSelector(selectPopupExtraParameters)
  const requestStatus = useSelector(selectRequestStatus)

  const params = {}
  popupExtraParameters.map((param) => {
    params[param.name] = param.value
  })

  const deleteParameterHandler = (event) => {
    event.preventDefault()
    dispatch(changeRequestStatus({ status: REQUEST_STATUSES.PENDING }))
    dispatch(
      deleteGlobalParameter({
        projectUuid: project.uuid,
        parameterUuid: params.parameterUuid,
      }),
    )
  }

  const cancel = (event) => {
    event.preventDefault()
    dispatch(closePopup())
  }

  return (
    <ConfirmPopup
      title={'Delete parameter'}
      subtitle={
        <>
          Are you sure you want to delete: <strong>{params.parameterName}</strong>?
        </>
      }
      cancelHandler={cancel}
      deleteParameterHandler={deleteParameterHandler}
      confirmButtonText={'Delete'}
      requestStatus={requestStatus}
    />
  )
}

export default memo(DeleteParameterPopup)
