import styled from 'styled-components'
import { lightBlue, lightSilver, mainBlue, superLightSilver } from '../../styles/colors'

export const CheckboxContent = styled.div`
  position: relative;

  font-size: 14px;
  float: left;
  margin-left: 8px;

  line-height: 24px;
`

export const CheckboxContainer = styled.div`
  position: relative;
  width: 24px;
  height: 24px;

  background: #ffffff;

  border: 1px solid ${lightSilver};

  box-sizing: border-box;

  float: left;

  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;

  transition: background 275ms ease;

  &:hover {
    background: ${superLightSilver};
    cursor: pointer;
  }
`

export const CheckboxFill = styled.div`
  position: relative;
  width: 16px;
  height: 16px;

  background: ${mainBlue};

  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;

  margin-top: 3px;
  margin-left: 3px;

  transition: background 275ms ease;

  &:hover {
    background: ${lightBlue};
  }
`
