import { createSlice } from '@reduxjs/toolkit'
import {
  resetPassword,
  resetPasswordConfirmEmail,
  changePassword as changePasswordSlice,
} from '../../components/user/forgot-password/slice'
import { failed, REQUEST_STATUSES, resetErrors, resetStatus } from '../reducer-helper'

const initialState = {
  step: 1,
  data: {
    email: '',
    token: '',
    password: '',
  },
  error: null,
  status: REQUEST_STATUSES.NOT_TRIGGERED,
}

const forgotPasswordFormSlice = createSlice({
  name: 'forgotPasswordForm',
  initialState,
  extraReducers(builder) {
    builder.addCase(resetPassword.fulfilled, (state, action) => {
      state.step = 2
      resetStatus(state)
      resetErrors(state)
    })
    builder.addCase(resetPassword.rejected, failed)
    builder.addCase(resetPasswordConfirmEmail.fulfilled, (state, action) => {
      state.data.token = action.payload.token
      state.step = 3
      resetStatus(state)
      resetErrors(state)
    })
    builder.addCase(resetPasswordConfirmEmail.rejected, failed)
    builder.addCase(changePasswordSlice.fulfilled, (state, action) => {
      state.step = 4
      resetStatus(state)
      resetErrors(state)
    })
    builder.addCase(changePasswordSlice.rejected, failed)
  },
  reducers: {
    changeRequestStatus: (state, action) => {
      state.status = action.payload.status
    },
    changeEmail: (state, action) => {
      state.data.email = action.payload.email
    },
    changeToken: (state, action) => {
      state.data.token = action.payload.token
    },
    changePassword: (state, action) => {
      state.data.password = action.payload.password
    },
    resetForm: () => initialState,
  },
})

const selectForgotPasswordFormSlice = (state) => state.forgotPasswordForm
export const selectStep = (state) => selectForgotPasswordFormSlice(state).step
export const selectError = (state) => selectForgotPasswordFormSlice(state).error
export const selectForgotPasswordFormData = (state) => selectForgotPasswordFormSlice(state).data
export const selectRequestStatus = (state) => selectForgotPasswordFormSlice(state).status

const { reducer, actions } = forgotPasswordFormSlice

export const { changeRequestStatus, changeEmail, changeToken, changePassword, resetForm } = actions

export default reducer
