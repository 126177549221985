import React, { memo } from 'react'
import FooterButtons from '../footer-buttons/FooterButtons'
import { ConfigsFooterContainer } from './styles'
import { useDispatch } from 'react-redux'
import { closePopup } from '../../../../../../../reducers/popup'

function ConfigsFooter({ saveHandler }) {
  const dispatch = useDispatch()
  const cancelHandler = (event) => {
    event.preventDefault()
    dispatch(closePopup())
  }

  return (
    <ConfigsFooterContainer>
      <FooterButtons saveHandler={saveHandler} cancelHandler={cancelHandler} />
    </ConfigsFooterContainer>
  )
}

export default memo(ConfigsFooter)
