import React, { memo, useState } from 'react'
import { Outlet } from 'react-router-dom'
import { AddServiceContainer, AddServiceSidebarContainer, ServiceDetailsContainer } from './styles'
import AllServices from '../../../../service/all-services/AllServices'
import { H2HeaderTitle } from '../../../../common/styles/text'
import { SidebarContent, SidebarHeader } from '../../../../common/layouts/sidebar-content/styles'
import SearchBar from '../../../../common/search-bar/SearchBar'

function AddService() {
  const [searchQuery, setSearchQuery] = useState('')

  return (
    <AddServiceContainer>
      <AddServiceSidebarContainer>
        <SidebarHeader>
          <H2HeaderTitle>Available services</H2HeaderTitle>
          <SearchBar
            fieldPlaceholder={'Search service...'}
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            backgroundColor={'#FFFFFF'}
          />
        </SidebarHeader>
        <SidebarContent>
          <AllServices excludeAdded={true} searchQuery={searchQuery} />
        </SidebarContent>
      </AddServiceSidebarContainer>
      <ServiceDetailsContainer>
        <Outlet />
      </ServiceDetailsContainer>
    </AddServiceContainer>
  )
}

export default memo(AddService)
