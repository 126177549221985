import React, { memo } from 'react'
import {
  LeftContentContainer,
  ProjectLink,
  ProjectLogoComponent,
  ProjectLogoContainer,
  ProjectName,
  ProjectPreviewContainer,
  RightContentContainer,
  ServicesInfoText,
  StatusContainer,
} from './styles'
import ProjectLogoPlaceholder1 from '../../../assets/images/project-placeholder-1.svg'
import ProjectLogoPlaceholder2 from '../../../assets/images/project-placeholder-2.svg'
import ProjectLogoPlaceholder3 from '../../../assets/images/project-placeholder-3.svg'
import StatusLabel from '../../common/status-label/StatusLabel'
import { CDN_URL } from '../../../configs'

function ProjectPreview({ project }) {
  let placeholder = ProjectLogoPlaceholder3

  const createdTime = new Date(project.createdTime).getTime()

  let lastDigit = createdTime + (1 % 10)
  if (lastDigit % 2 === 0) {
    placeholder = ProjectLogoPlaceholder1
  } else if (lastDigit % 3 === 0) {
    placeholder = ProjectLogoPlaceholder2
  }

  return (
    <ProjectPreviewContainer>
      <ProjectLink to={`/project/${project.uuid}/services`}>
        <LeftContentContainer>
          <ProjectLogoContainer>
            {project.image && <ProjectLogoComponent src={`${CDN_URL}/${project.image.key}`} />}
            {!project.image && <ProjectLogoComponent src={placeholder} />}
          </ProjectLogoContainer>
          <ProjectName>{project.name}</ProjectName>
        </LeftContentContainer>
        <RightContentContainer>
          <ServicesInfoText>
            <b>{project.servicesCounter ? project.servicesCounter : 0}</b>{' '}
            {project.servicesCounter === 1 ? 'service' : 'services'} running
          </ServicesInfoText>
          <StatusContainer>
            <StatusLabel status={project.status} />
          </StatusContainer>
        </RightContentContainer>
      </ProjectLink>
    </ProjectPreviewContainer>
  )
}

export default memo(ProjectPreview)
