import React, { memo } from 'react'
import { CloseButton, CloseButtonContainer, PopupBackground, PopupContainer, PopupContent } from './styles'
import CloseImage from '../../../../assets/images/close-icon.svg'
import { useDispatch, useSelector } from 'react-redux'
import { closePopup as closePopupEvent, selectChildComponent, selectPopupSize } from '../../../../reducers/popup'
import AddServicePopup from '../../../service/add-service-popup/AddServicePopup'
import DeleteParameterPopup from '../../../front-api/global-parameters/delete-popup/DeleteParameterPopup'
import DeleteApiKeyPopup from '../../../front-api/api-keys/delete-popup/DeleteApiKeyPopup'
import { regularGray } from '../../styles/colors'
import StyledSVG from '../../styled-svg/StyledSVG'
import AddGlobalParameterForm from '../../../front-api/global-parameters/add-parameter-form/AddParameterForm'
import AddActionParameterForm from '../../../front-api/functions/details/parameters/add-parameter-form/AddParameterForm'
import EditActionParameterForm from '../../../front-api/functions/details/parameters/edit-parameter-form/EditParameterForm'
import EditGlobalParameterForm from '../../../front-api/global-parameters/edit-parameter-form/EditParameterForm'
import DeletePopup from '../../../front-api/functions/sidebar/popups/delete/DeletePopup'
import RequestNodeConfigurations from '../../../front-api/flow-builder/components/nodes/node-details/request-node/RequestNodeConfigurations'

export const PopupComponentKeys = {
  ADD_SERVICE: 'add-service',
  FRONT_API_DELETE_GLOBAL_PARAMETER_CONFIRMATION: 'front-api-delete-global-parameter',
  FRONT_API_DELETE_API_KEY_CONFIRMATION: 'front-api-delete-api-key',
  FRONT_API_CREATE_GLOBAL_PARAMETER: 'front-api-create-global-parameter',
  FRONT_API_CREATE_FUNCTION_PARAMETER: 'front-api-create-function-parameter',
  FRONT_API_EDIT_FUNCTION_PARAMETER: 'front-api-edit-function-parameter',
  FRONT_API_EDIT_GLOBAL_PARAMETER: 'front-api-edit-global-parameter',
  FRONT_API_DELETE_AFC_CONFIRMATION: 'front-api-delete-function-folder-collection',
  FRONT_API_FLOW_BUILDER_REQUESTS_CONFIGS: 'front-api-flow-builder-requests-configs',
}

const Popup = () => {
  const componentKey = useSelector(selectChildComponent)
  const size = useSelector(selectPopupSize)

  const dispatch = useDispatch()

  const closePopup = (event) => {
    event.preventDefault()

    dispatch(closePopupEvent())
  }

  let popupComponent = null
  switch (componentKey) {
    case PopupComponentKeys.ADD_SERVICE:
      popupComponent = <AddServicePopup />
      break

    case PopupComponentKeys.FRONT_API_DELETE_GLOBAL_PARAMETER_CONFIRMATION:
      popupComponent = <DeleteParameterPopup />
      break

    case PopupComponentKeys.FRONT_API_DELETE_API_KEY_CONFIRMATION:
      popupComponent = <DeleteApiKeyPopup />
      break

    case PopupComponentKeys.FRONT_API_CREATE_GLOBAL_PARAMETER:
      popupComponent = <AddGlobalParameterForm />
      break

    case PopupComponentKeys.FRONT_API_CREATE_FUNCTION_PARAMETER:
      popupComponent = <AddActionParameterForm />
      break

    case PopupComponentKeys.FRONT_API_EDIT_FUNCTION_PARAMETER:
      popupComponent = <EditActionParameterForm />
      break

    case PopupComponentKeys.FRONT_API_EDIT_GLOBAL_PARAMETER:
      popupComponent = <EditGlobalParameterForm />
      break

    case PopupComponentKeys.FRONT_API_DELETE_AFC_CONFIRMATION:
      popupComponent = <DeletePopup />
      break

    case PopupComponentKeys.FRONT_API_FLOW_BUILDER_REQUESTS_CONFIGS:
      popupComponent = <RequestNodeConfigurations />
      break
  }

  if (!popupComponent) {
    return <>Sorry, but something went wrong...</>
  }

  return (
    <PopupContainer className={'popup-component'}>
      <PopupBackground onClick={closePopup} />\
      <PopupContent width={size.width} height={size.height}>
        <CloseButtonContainer>
          <CloseButton onClick={closePopup}>
            <StyledSVG width={12} height={12} color={regularGray} src={CloseImage} />
          </CloseButton>
        </CloseButtonContainer>
        {popupComponent}
      </PopupContent>
    </PopupContainer>
  )
}

export default memo(Popup)
