import { Decoration, EditorView, MatchDecorator, useCodeMirror, ViewPlugin } from '@uiw/react-codemirror'
import React, { memo, useEffect, useRef, useState } from 'react'
import { lightSilver, superLightSilver } from '../../../../../../common/styles/colors'
import { EditorContainer, EditorReference, InsertParameterButtonContainer } from './styles'
import InsertParameterButton from './insert-parameter-button/InsertParameterButton'
import InsertParameterPopover from './insert-parameter-popover/InsertParameterPopover'
import { PlaceholderWidget } from './PlaceholderWidget'
import { json } from '@codemirror/lang-json'
import { html } from '@codemirror/lang-html'
import { xml } from '@codemirror/lang-xml'
import { Tooltip } from 'react-tooltip'
import { EDITOR_LANGUAGES_HIGHLIGHT } from '../../../../constants'

// Need to manage delete operation for inserted parameters
function _optionalChain(ops) {
  let lastAccessLHS = undefined
  let value = ops[0]
  let i = 1
  while (i < ops.length) {
    const op = ops[i]
    const fn = ops[i + 1]
    i += 2
    if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) {
      return undefined
    }
    if (op === 'access' || op === 'optionalAccess') {
      lastAccessLHS = value
      value = fn(value)
    } else if (op === 'call' || op === 'optionalCall') {
      value = fn((...args) => value.call(lastAccessLHS, ...args))
      lastAccessLHS = undefined
    }
  }
  return value
}

function CodemirrorField({ value, onChange, placeholder, isMinimalSetup = true, language = null }) {
  const editor = useRef()

  const placeholderMatcher = new MatchDecorator({
    regexp: /\{\{(\$([a-zA-Z]+)[._a-zA-Z0-9-]*)\}\}/g,
    decoration: (match) => {
      return Decoration.replace({
        widget: new PlaceholderWidget(match[1], match[2]),
      })
    },
  })

  const placeholders = ViewPlugin.fromClass(
    class {
      constructor(view) {
        this.placeholders = placeholderMatcher.createDeco(view)
      }

      update(update) {
        this.placeholders = placeholderMatcher.updateDeco(update, this.placeholders)
      }
    },
    {
      decorations: (instance) => instance.placeholders,
      // Need to manage delete operation for inserted parameters
      provide: (plugin) =>
        EditorView.atomicRanges.of((view) => {
          return (
            _optionalChain([
              view,
              'access',
              (_) => _.plugin,
              'call',
              (_2) => _2(plugin),
              'optionalAccess',
              (_3) => _3.placeholders,
            ]) || Decoration.none
          )
        }),
    },
  )

  const [showInsertParameterPopover, setShowInsertParameterPopover] = useState(false)
  const theme = EditorView.theme({
    '*': {
      fontFamily: 'Lato, sans-serif',
      fontSize: '14px',
      cursor: 'text',
    },
    '&': {
      background: '#FFFFFF',
      border: `1px solid ${superLightSilver}`,
      borderRadius: '4px',
      outline: 'none !important',
      transition: 'border 275ms ease',
      paddingRight: '32px',
      cursor: 'text',
    },
    '&:hover, &:focus': {
      borderColor: lightSilver,
    },
    '.cm-placeholder': {
      color: lightSilver,
    },
    '.cm-activeLine': {
      backgroundColor: 'rgba(99,166,255,0.05)',
    },
    '.cm-selectionMatch': {
      backgroundColor: 'rgba(99,166,255,0.5)',
    },
  })

  const getLanguage = () => {
    let lang = null
    switch (language) {
      case EDITOR_LANGUAGES_HIGHLIGHT.JSON:
        lang = json()
        break
      case EDITOR_LANGUAGES_HIGHLIGHT.HTML:
        lang = html()
        break
      case EDITOR_LANGUAGES_HIGHLIGHT.XML:
        lang = xml()
        break
    }

    return lang
  }

  let basicSetup = false
  const extensions = [placeholders]
  const lang = getLanguage()

  if (lang) {
    extensions.push(lang)
  }
  if (!isMinimalSetup) {
    basicSetup = {
      tabSize: 4,
      lineNumbers: false,
      highlightActiveLineGutter: false,
      foldGutter: false,
      allowMultipleSelections: false,
    }
  }

  const { setContainer, view } = useCodeMirror({
    basicSetup,
    theme,
    container: editor.current,
    minHeight: isMinimalSetup ? '32px' : '100%',
    maxHeight: isMinimalSetup ? '120px' : '320px',
    extensions,
    placeholder,
    value,
    onChange(value, viewUpdate) {
      onChange(value)
    },
  })

  useEffect(() => {
    if (editor.current) {
      setContainer(editor.current)
    }
  }, [editor.current])

  const insertParameter = (insertedValue) => {
    const spec = view.state.replaceSelection(insertedValue)
    view.dispatch(spec)
  }

  return (
    <EditorContainer>
      <EditorReference ref={editor} />
      <InsertParameterButtonContainer className={'insert-parameter-button'}>
        <InsertParameterButton
          onClick={() => {
            setShowInsertParameterPopover(true)
          }}
        />
        {showInsertParameterPopover && (
          <InsertParameterPopover
            insertParameterHandler={(insertedValue) => {
              insertParameter(insertedValue)
            }}
            cancelHandler={() => {
              setShowInsertParameterPopover(false)
            }}
          />
        )}
      </InsertParameterButtonContainer>

      <Tooltip anchorSelect=".insert-parameter-button" place="top">
        Insert parameter
      </Tooltip>
    </EditorContainer>
  )
}

export default memo(CodemirrorField)
