import { createAsyncThunk } from '@reduxjs/toolkit'
import { ServiceAgent } from '../../../api/serviceAgent'

export const addService = createAsyncThunk(
  'add-service-form/add',
  async ({ projectUuid, serviceUuid, settings, resources, configurations }, thunkApi) => {
    try {
      const resource = await ServiceAgent.addService(projectUuid, serviceUuid, resources, settings, configurations)
      
      return resource
    } catch (error) {
      return thunkApi.rejectWithValue(error)
    }
  },
)
