import { createSlice } from '@reduxjs/toolkit'
import { createFunctionsCollection } from '../../../../components/front-api/functions/sidebar/forms/create-collection/slice'
import { failed, resetErrors, resetStatus } from '../../../reducer-helper'

const initialState = {
  formData: {
    name: 'New collection',
  },
  error: null,
  status: null,
}

const createCollectionFormSlice = createSlice({
  name: 'frontApiCreateFunctionsCollectionForm',
  initialState,
  reducers: {
    resetState: () => initialState,
    changeName: (state, action) => {
      state.formData.name = action.payload.name
    },
    changeRequestStatus: (state, action) => {
      state.status = action.payload.status
    },
  },
  extraReducers(builder) {
    builder.addCase(createFunctionsCollection.fulfilled, (state, action) => {
      resetStatus(state)
      resetErrors(state)
    })
    builder.addCase(createFunctionsCollection.rejected, failed)
  },
})

const currentSlice = (state) => state.frontApiCreateFunctionsCollectionForm
export const selectRequestStatus = (state) => currentSlice(state).status
export const selectFormData = (state) => currentSlice(state).formData

const { reducer, actions } = createCollectionFormSlice

export const { resetState, changeRequestStatus, changeName } = actions

export default reducer
