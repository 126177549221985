import React, { memo, useState } from 'react'
import { ConfigurationContainer, Footer, RequestConfigurations } from './styles'
import NodeNameBlock from '../../node-name-block/NodeNameBlock'
import { SecondaryActionButton } from '../../../../../../common/styles/styles'
import StyledSVG from '../../../../../../common/styled-svg/StyledSVG'
import { mainBlue } from '../../../../../../common/styles/colors'
import SettingsIcon from '../../../../../../../assets/images/settings-icon.svg'
import FooterButtons from '../footer-buttons/FooterButtons'
import { DetailsContainer } from './styles'
import SelectboxField from '../../../../../../common/form/selectbox/SelectboxField'
import { ContentWrapperContainer } from '../../../../../../common/layouts/content-container/styles'
import { TextInputForList } from '../../../../../../common/form/text-field/styles'
import { showPopup } from '../../../../../../../reducers/popup'
import { PopupComponentKeys } from '../../../../../../common/layouts/popup/Popup'
import { useDispatch } from 'react-redux'
import { HTTP_METHODS, HTTP_METHODS_NAMES } from '../../../../constants'

function RequestNodeDetails({ node, cancelHandler, saveDetailsHandler }) {
  const dispatch = useDispatch()
  const methods = Object.values(HTTP_METHODS).map((method) => {
    return {
      name: HTTP_METHODS_NAMES[method],
      value: method,
    }
  })

  const [selectedMethod, setSelectedMethod] = useState(null)
  const [url, setUrl] = useState('')

  const showConfigurationsPopup = () => {
    dispatch(
      showPopup({
        component: PopupComponentKeys.FRONT_API_FLOW_BUILDER_REQUESTS_CONFIGS,
        extraParameters: [{ name: 'node', value: node }],
        size: {
          width: '820px',
          height: 'auto',
        },
      }),
    )
  }

  return (
    <DetailsContainer>
      <ConfigurationContainer>
        <NodeNameBlock nodeName={node.name} changeNameHandler={true} />
      </ConfigurationContainer>
      <RequestConfigurations>
        <ContentWrapperContainer marginTop={'12px'} marginBottom={'12px'} padding={'0 0 0 12px'} width={'120px'}>
          <SelectboxField
            selectedValue={selectedMethod}
            placeholder={'Method'}
            options={methods}
            small={true}
            changeValueHandler={(value) => {
              setSelectedMethod(value)
            }}
          />
        </ContentWrapperContainer>
        <ContentWrapperContainer marginTop={'12px'} marginBottom={'12px'} padding={'0 0 0 12px'} width={'346px'}>
          <TextInputForList
            value={url}
            type="text"
            placeholder="Request URL"
            onChange={(value) => {
              setUrl(value)
            }}
          />
        </ContentWrapperContainer>
      </RequestConfigurations>
      <Footer>
        <SecondaryActionButton onClick={showConfigurationsPopup}>
          <StyledSVG width={12} height={12} color={mainBlue} src={SettingsIcon} />
          <span>Configure request</span>
        </SecondaryActionButton>
        <FooterButtons cancelHandler={cancelHandler} saveHandler={saveDetailsHandler} />
      </Footer>
    </DetailsContainer>
  )
}

export default memo(RequestNodeDetails)
