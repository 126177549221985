import { createSlice } from '@reduxjs/toolkit'
import { addDatabase, updateDatabase } from '../../components/project/details/settings/resources/database/slice'
import { resetErrors, resetStatus } from './../reducer-helper'

const initialState = {
  settings: {
    size: null,
  },
  error: null,
  status: null,
  formLoaded: false,
}

const projectDatabaseSettingsFormSlice = createSlice({
  name: 'projectDatabaseSettingsForm',
  initialState,
  reducers: {
    setSettings: (state, action) => {
      state.settings.size = action.payload.size
      state.formLoaded = true
    },
    changeSize: (state, action) => {
      state.settings.size = action.payload.size
    },
    changeRequestStatus: (state, action) => {
      state.status = action.payload.status
    },
    resetForm: () => initialState,
  },
  extraReducers(builder) {
    builder.addCase(updateDatabase.fulfilled, (state, action) => {
      resetStatus(state)
      resetErrors(state)
    })
    builder.addCase(addDatabase.fulfilled, (state, action) => {
      resetStatus(state)
      resetErrors(state)
    })
  },
})

const selectDatabaseSettingsFormSlice = (state) => state.projectDatabaseSettingsForm
export const selectDatabaseSettings = (state) => selectDatabaseSettingsFormSlice(state).settings
export const isDatabaseSettingsFormLoaded = (state) => selectDatabaseSettingsFormSlice(state).formLoaded
export const selectRequestStatus = (state) => selectDatabaseSettingsFormSlice(state).status

const { reducer, actions } = projectDatabaseSettingsFormSlice

export const { setSettings, changeSize, resetForm, changeRequestStatus } = actions

export default reducer
