import styled from 'styled-components'
import { greenTextColor, layoutComponentsBackgroundColor, lightGray } from '../styles/colors'

export const CodeBlockComponent = styled.div`
  position: relative;
  width: ${(props) => (props.width ? props.width : 'fit-content')};
  height: 32px;
  line-height: 32px;
  padding: 0 12px;
  box-sizing: border-box;

  background: ${layoutComponentsBackgroundColor};

  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
`

export const Title = styled.div`
  position: relative;
  float: left;
  font-size: 14px;
  color: ${lightGray};
  padding-right: 12px;
  font-weight: 700;
`

export const Code = styled.div`
  position: relative;
  float: left;
  font-size: 14px;
  color: ${lightGray};
`

export const CopyButtonContainer = styled.div`
  position: relative;
  float: right;
  margin-left: 12px;
  width: 16px;
  height: 20px;
  line-height: 0;
  margin-top: 6px;

  transition: opacity 275ms ease;

  &:hover {
    cursor: pointer;
    opacity: 0.75;
  }
`

export const CopiedText = styled.div`
  position: relative;
  float: right;
  margin-left: 12px;
  font-size: 14px;
  color: ${greenTextColor};
`
