import styled from 'styled-components'
import { layoutComponentsBorderColor, lightGray, lightSilver } from '../styles/colors'
import { TextField } from '../styles/form-styles'

export const SearchBarComponent = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

export const SearchIconContainer = styled.div`
  position: absolute;
  right: 24px;
  top: 16px;
  width: 16px;
  height: 16px;

  transition: opacity 275ms ease;

  &:hover {
    cursor: pointer;
    opacity: 0.75;
  }

  background: ${(props) => props.backgroundColor};
`

export const SearchFieldContainer = styled.div`
  position: absolute;
  left: 0;
  top: 0;

  border-bottom: solid 1px ${layoutComponentsBorderColor};
  box-sizing: border-box;

  width: calc(100% - 72px);
  height: 48px;

  padding: 0 24px;

  box-sizing: border-box;

  background: ${(props) => props.backgroundColor};
`

export const SearchField = styled(TextField)`
  position: relative;
  width: 100%;
  height: 47px;
  line-height: 47px;

  border: none;
  padding: 0;
  margin: 0;
  background: none;

  box-sizing: border-box;

  font-size: 14px;
  font-weight: 400;

  transition: border 275ms ease, color 275ms ease;

  &::-webkit-input-placeholder {
    /* Edge */
    color: ${lightSilver};
  }

  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: ${lightSilver};
  }

  &::placeholder {
    color: ${lightSilver};
  }

  &:hover,
  &:focus {
    border: none;

    &::placeholder {
      color: ${lightGray};
    }
  }
`

export const SearchIconComponent = styled.img`
  position: relative;
  width: 16px;
  height: 16px;
`