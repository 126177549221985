import styled from 'styled-components'
import { SecondaryActionButton } from '../styles/styles'
import StyledSVG from '../styled-svg/StyledSVG'
import { layoutComponentsBorderColor } from '../styles/colors'

export const ButtonIcon = styled(StyledSVG)`
  position: relative;
  display: block;
  margin: 0 auto;
`

export const Button = styled(SecondaryActionButton)`
  position: relative;
  display: block;

  height: 100%;
  width: 100%;
  background: ${(props) => props.backgroundColor};

  transition: background 275ms ease, border 275ms ease;
  border: 1px solid ${layoutComponentsBorderColor};

  margin: 0 auto;
  padding: 0;

  line-height: 0;

  &:hover {
    cursor: pointer;
    background: ${(props) => props.backgroundHoverColor};
    border: 1px solid ${layoutComponentsBorderColor};
    ${ButtonIcon} {
      path {
        fill: ${(props) => props.iconHoverColor};
      }
    }
  }
`
