import { createAsyncThunk } from '@reduxjs/toolkit'
import { FrontApiAgent } from '../../../../../../api/frontApiAgent'

export const deleteFunction = createAsyncThunk(
  'front-api/function/delete',
  async ({ projectUuid, functionUuid }, thunkApi) => {
    try {
      await FrontApiAgent.deleteFunction(projectUuid, functionUuid)

      return { functionUuid }
    } catch (error) {
      return thunkApi.rejectWithValue(error)
    }
  },
)

export const deleteFunctionsCollection = createAsyncThunk(
  'front-api/function/collection/delete',
  async ({ projectUuid, collectionUuid }, thunkApi) => {
    try {
      await FrontApiAgent.deleteFunctionsCollection(projectUuid, collectionUuid)

      return { collectionUuid }
    } catch (error) {
      return thunkApi.rejectWithValue(error)
    }
  },
)

export const deleteFunctionsFolder = createAsyncThunk(
  'front-api/function/folder/delete',
  async ({ projectUuid, folderUuid }, thunkApi) => {
    try {
      await FrontApiAgent.deleteFunctionsFolder(projectUuid, folderUuid)

      return { folderUuid }
    } catch (error) {
      return thunkApi.rejectWithValue(error)
    }
  },
)
