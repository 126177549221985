import React, { memo } from 'react'
import { BottomContainer, ContentContainer, LogoContainer, MiddleContainer, TopContainer } from './styles'
import LogoFull from '../../../logo-full/LogoFull'
import { Content360 } from '../../../styles/styles'
import Footer from '../footer/Footer'

const PublicAreaMiddleFormLayout = ({ children }) => {
  return (
    <ContentContainer>
      <TopContainer>
        <LogoContainer>
          <LogoFull />
        </LogoContainer>
      </TopContainer>
      <MiddleContainer>
        <Content360>{children}</Content360>
      </MiddleContainer>
      <BottomContainer>
        <Footer/>
      </BottomContainer>
    </ContentContainer>
  )
}

export default memo(PublicAreaMiddleFormLayout)
