import React, { memo } from 'react'
import { UserSettingsGeneralContainer } from './styles'
import { useSelector } from 'react-redux'
import { selectChangeEmailRequestSent } from '../../../../reducers/user/user-general-settings-form'
import GeneralSettingsForm from './GeneralSettingsForm'
import ConfirmEmailForm from './ConfirmEmailForm'

function UserGeneralSettings() {
  const changeEmailRequestSent = useSelector(selectChangeEmailRequestSent)

  return (
    <UserSettingsGeneralContainer>
      {!changeEmailRequestSent ? <GeneralSettingsForm /> : <ConfirmEmailForm />}
    </UserSettingsGeneralContainer>
  )
}

export default memo(UserGeneralSettings)
