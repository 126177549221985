import { createAsyncThunk } from '@reduxjs/toolkit'
import { ServiceAgent } from '../../../../../../api/serviceAgent'

export const updateServiceConfigurations = createAsyncThunk(
  'project-services-update-configuration-form/update',
  async ({ projectUuid, serviceUuid, configurations }, thunkApi) => {
    try {
      await ServiceAgent.updateProjectServicesConfigurations(projectUuid, serviceUuid, configurations)
    } catch (error) {
      return thunkApi.rejectWithValue(error)
    }
  },
)
