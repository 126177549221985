import React, { memo } from 'react'
import { ConfigDescription, ConfigsHeaderContainer, NodeType } from './styles'
import { NODES_DESCRIPTION, NODES_NAMES } from '../../../../constants'

function ConfigsHeader({ node }) {
  return (
    <ConfigsHeaderContainer>
      <NodeType>{NODES_NAMES[node.type]}</NodeType>
      <ConfigDescription>{NODES_DESCRIPTION[node.type]}</ConfigDescription>
    </ConfigsHeaderContainer>
  )
}

export default memo(ConfigsHeader)
