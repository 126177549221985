import React, { memo, useState } from 'react'
import { Code, CodeBlockComponent, CopiedText, CopyButtonContainer, Title } from './styles'
import { Clear } from '../styles/styles'
import StyledSVG from '../styled-svg/StyledSVG'
import CopyIcon from '../../../assets/images/copy-icon.svg'
import { lightGray } from '../styles/colors'

const CodeBlock = ({ title, code, copy, width }) => {
  const [copied, setCopied] = useState(false)

  const SHOW_MESSAGE_TIME = 4000
  const copyCode = () => {
    navigator.clipboard.writeText(code)
    setCopied(true)
    setTimeout(() => {
      setCopied(false)
    }, SHOW_MESSAGE_TIME)
  }

  return (
    <CodeBlockComponent width={width ? width : null}>
      {title && <Title>{title}</Title>}
      <Code>{code}</Code>
      {copy && (
        <>
          {copied && <CopiedText>Copied to clipboard</CopiedText>}
          {!copied && (
            <CopyButtonContainer onClick={copyCode}>
              <StyledSVG width={16} height={20} color={lightGray} src={CopyIcon} />
            </CopyButtonContainer>
          )}
        </>
      )}
      <Clear />
    </CodeBlockComponent>
  )
}

export default memo(CodeBlock)
