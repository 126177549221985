import React, { memo } from 'react'
import { Header, NodeItemContainer, NodeTypeName } from './styles'
import { lightGray } from '../../../../../common/styles/colors'
import { NODES_ICONS, NODES_NAMES } from '../../../constants'
import StyledSVG from '../../../../../common/styled-svg/StyledSVG'
import NodeNameBlock from '../node-name-block/NodeNameBlock'

function NodeItem({ nodeType, nodeName, openDetailsHandler, changeNameHandler = null }) {
  return (
    <NodeItemContainer onClick={() => openDetailsHandler()}>
      <Header>
        <StyledSVG width={16} height={16} color={lightGray} src={NODES_ICONS[nodeType]} />
        <NodeTypeName>{NODES_NAMES[nodeType]}</NodeTypeName>
      </Header>
      <NodeNameBlock nodeName={nodeName} />
    </NodeItemContainer>
  )
}

export default memo(NodeItem)
