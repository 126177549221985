import { createSlice } from '@reduxjs/toolkit'
import {
  createAccount,
  confirmSignUpEmail,
  resendConfirmationToken,
  createProfile,
} from '../../components/user/sign-up/slice'
import { failed, REQUEST_STATUSES, resetErrors, resetStatus } from './../reducer-helper'

const initialState = {
  step: 1,
  data: {
    email: '',
    token: '',
    name: '',
    password: '',
  },
  error: null,
  status: REQUEST_STATUSES.NOT_TRIGGERED,
}

const signUpFormSlice = createSlice({
  name: 'signUpForm',
  initialState,
  extraReducers(builder) {
    builder.addCase(createAccount.fulfilled, (state) => {
      state.step = 2
      resetStatus(state)
      resetErrors(state)
    })
    builder.addCase(createAccount.rejected, failed)
    builder.addCase(confirmSignUpEmail.fulfilled, (state) => {
      state.step = 3
      resetStatus(state)
      resetErrors(state)
    })
    builder.addCase(confirmSignUpEmail.rejected, failed)
    builder.addCase(resendConfirmationToken.fulfilled, resetStatus)
    builder.addCase(resendConfirmationToken.rejected, resetStatus)
    builder.addCase(createProfile.rejected, failed)
  },
  reducers: {
    changeRequestStatus: (state, action) => {
      state.status = action.payload.status
    },
    changeEmail: (state, action) => {
      state.data.email = action.payload.email
    },
    changeToken: (state, action) => {
      state.data.token = action.payload.token
    },
    changeName: (state, action) => {
      state.data.name = action.payload.name
    },
    changePassword: (state, action) => {
      state.data.password = action.payload.password
    },
    resetForm: () => initialState,
  },
})

const selectSignUpFormSlice = (state) => state.signUpForm
export const selectRequestStatus = (state) => selectSignUpFormSlice(state).status
export const selectStep = (state) => selectSignUpFormSlice(state).step
export const selectError = (state) => selectSignUpFormSlice(state).error
export const selectSignupFormData = (state) => selectSignUpFormSlice(state).data

const { reducer, actions } = signUpFormSlice

export const { changeRequestStatus, changeEmail, changeToken, changeName, changePassword, resetForm } = actions

export default reducer
