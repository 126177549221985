import { createAsyncThunk } from '@reduxjs/toolkit'
import { UserAgent } from '../../../../api/userAgent'

export const changePassword = createAsyncThunk(
  'user/account/change-password',
  async ({ newPassword, oldPassword }, thunkApi) => {
    try {
      await UserAgent.changePassword(newPassword, oldPassword)

      return {status: 'Ok'}
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data)
    }
  },
)

export const updateProfileImage = createAsyncThunk(
  'user/profile/update-image',
  async ({ image }, thunkApi) => {
    try {
      const imageResponse = await UserAgent.updateProfileImage(image)

      return { image: imageResponse }
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data)
    }
  },
)

export const deleteProfileImage = createAsyncThunk(
  'user/profile/delete-image',
  async (undefined, thunkApi) => {
    try {
      await UserAgent.deleteProfileImage()
      return { status: 'Ok' }
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data)
    }
  },
)

export const updateProfileInformation = createAsyncThunk(
  'user/update',
  async ({ name, email, password }, thunkApi) => {
    try {
      await UserAgent.update(name, email, password)

      return { user: { name }, emailChanged: !!password }
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data)
    }
  },
)

export const confirmNewEmail = createAsyncThunk(
  'user/account/confirm-new-email',
  async ({ token, email }, thunkApi) => {
    try {
      await UserAgent.confirmNewEmail(token)

      return { email }
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data)
    }
  },
)
