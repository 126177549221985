import React, { memo, useEffect } from 'react'
import {
  Subtitle,
  AddKeyButtonContainer,
  KeyPlaceholderContainer,
  CreatedDateContainer,
  DeleteButtonContainer,
  GreenText,
  RedText,
  YellowText,
  DeleteButtonColumnContainer,
  CreatedKeyContainer,
  NewKeyContainer,
  KeyTableRow,
} from './styles'
import { useDispatch, useSelector } from 'react-redux'
import { selectProject } from '../../../reducers/project/project-details'
import { H2HeaderTitle, RegularText } from '../../common/styles/text'
import { TableColumn, TableContainer, TableRow } from '../../common/table/styles'
import { CallToActionButton, Clear } from '../../common/styles/styles'
import {
  changeRequestStatus,
  resetState,
  selectCreatedKey,
  selectKeys,
  selectRequestStatus,
} from '../../../reducers/front-api/api-keys/api-keys'
import { addApiKey, getProjectApiKeys } from './slice'
import IconButton from '../../common/icon-button/IconButton'
import DeleteIcon from '../../../assets/images/delete-icon.svg'
import { ContentContainer, ContentWrapperContainer } from '../../common/layouts/content-container/styles'
import { REQUEST_STATUSES } from '../../../reducers/reducer-helper'
import Loading from '../../common/loading/Loading'
import CodeBlock from '../../common/code-block/CodeBlock'
import { PopupComponentKeys } from '../../common/layouts/popup/Popup'
import { showPopup } from '../../../reducers/popup'
import { HeaderContainer } from '../../common/layouts/content-block-header/styles'
import StyledSVG from '../../common/styled-svg/StyledSVG'
import PlusIcon from '../../../assets/images/add-icon.svg'

function ApiKeys() {
  const dispatch = useDispatch()

  const project = useSelector(selectProject)
  const keys = useSelector(selectKeys)
  const createdKey = useSelector(selectCreatedKey)
  const requestStatus = useSelector(selectRequestStatus)

  useEffect(() => {
    dispatch(changeRequestStatus({ status: REQUEST_STATUSES.PENDING }))
    const fetchKeys = dispatch(getProjectApiKeys({ projectUuid: project.uuid }))

    return () => {
      dispatch(resetState())
      fetchKeys.abort()
    }
  }, [])

  const showDeleteConfirmationPopup = (event, keyUuid) => {
    event.preventDefault()
    dispatch(
      showPopup({
        component: PopupComponentKeys.FRONT_API_DELETE_API_KEY_CONFIRMATION,
        extraParameters: [{ name: 'apiKeyUuid', value: keyUuid }],
        size: {
          width: '440px',
          height: 'auto',
        },
      }),
    )
  }

  const addNewKeyProcess = (event) => {
    event.preventDefault()
    dispatch(changeRequestStatus({ status: REQUEST_STATUSES.PENDING }))
    dispatch(addApiKey({ projectUuid: project.uuid }))
  }

  return (
    <ContentContainer>
      <HeaderContainer>
        <H2HeaderTitle>API keys</H2HeaderTitle>
      </HeaderContainer>
      {requestStatus === REQUEST_STATUSES.PENDING && <Loading />}
      {requestStatus === REQUEST_STATUSES.DONE && (
        <ContentContainer top={'48px'} padding={'24px'} scroll={true}>
          <Subtitle>
            <RegularText>Here you can manage your API keys</RegularText>
          </Subtitle>
          <ContentWrapperContainer>
            <NewKeyContainer>
              <AddKeyButtonContainer>
                <CallToActionButton onClick={addNewKeyProcess}>
                  <StyledSVG width={12} height={12} color={'#FFFFFF'} src={PlusIcon} />
                  <span>Create new API key</span>
                </CallToActionButton>
              </AddKeyButtonContainer>
              {createdKey && (
                <CreatedKeyContainer>
                  <CodeBlock code={createdKey} title={'Created API Key:'} copy={true} />
                </CreatedKeyContainer>
              )}
              <Clear />
            </NewKeyContainer>
            {keys.length === 0 && (
              <ContentWrapperContainer>
                <RegularText>No keys are here... yet.</RegularText>
              </ContentWrapperContainer>
            )}

            <ContentWrapperContainer marginTop={'24px'}>
              <TableContainer width={'540px'}>
                <TableRow bottomMargin={'8px'} withBorder={true}>
                  <TableColumn lineHeight={'32px'}>
                    <KeyPlaceholderContainer>
                      <RegularText>
                        <b>Key</b>
                      </RegularText>
                    </KeyPlaceholderContainer>
                  </TableColumn>
                  <TableColumn lineHeight={'32px'}>
                    <CreatedDateContainer>
                      <RegularText>
                        <b>Created date</b>
                      </RegularText>
                    </CreatedDateContainer>
                  </TableColumn>
                  <Clear />
                </TableRow>
                {keys.map((key) => {
                  const createdDate = new Date(key.createdTime)
                  const currentDate = new Date()

                  const diffTime = Math.abs(currentDate - createdDate)
                  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))

                  let daysWithColors = <GreenText>{diffDays} days</GreenText>
                  if (diffDays > 30 && diffDays < 60) {
                    daysWithColors = <YellowText>{diffDays} days</YellowText>
                  } else if (diffDays > 60) {
                    daysWithColors = <RedText>{diffDays} days</RedText>
                  }

                  return (
                    <KeyTableRow hoverEffect={true} key={key.uuid}>
                      <TableColumn>
                        <KeyPlaceholderContainer>
                          <RegularText>{key.keyPlaceholder}</RegularText>
                        </KeyPlaceholderContainer>
                      </TableColumn>
                      <TableColumn>
                        <CreatedDateContainer>
                          <RegularText>
                            {createdDate.toISOString().split('T')[0]} ({daysWithColors})
                          </RegularText>
                        </CreatedDateContainer>
                      </TableColumn>
                      <TableColumn>
                        <DeleteButtonColumnContainer>
                          <DeleteButtonContainer>
                            <IconButton
                              icon={DeleteIcon}
                              action={(event) => {
                                showDeleteConfirmationPopup(event, key.uuid)
                              }}
                            />
                          </DeleteButtonContainer>
                        </DeleteButtonColumnContainer>
                      </TableColumn>
                    </KeyTableRow>
                  )
                })}
              </TableContainer>
            </ContentWrapperContainer>
          </ContentWrapperContainer>
        </ContentContainer>
      )}
    </ContentContainer>
  )
}

export default memo(ApiKeys)
