import styled from 'styled-components'
import { layoutComponentsBorderColor, lightGray } from '../../../common/styles/colors'
import { H2HeaderTitle } from '../../../common/styles/text'

export const HeaderContainer = styled.div`
  position: absolute;
  display: flex;
  align-items: center;

  width: 100%;
  top: 0;
  height: 48px;
  left: 0;
  right: 0;
  padding: 0 24px;
  box-sizing: border-box;

  border-bottom: 1px solid ${layoutComponentsBorderColor};
`

export const Title = styled(H2HeaderTitle)`
  float: left;
`

export const Subtitle = styled.div`
  position: relative;
  margin-bottom: 32px;
`

export const ResourceStatusContainer = styled.div`
  position: relative;
  margin-left: 48px;
`

export const ResourceStatusText = styled.div`
  position: relative;
  font-size: 12px;
  margin-left: 4px;
  color: ${lightGray};
`
