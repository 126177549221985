import { createSlice } from '@reduxjs/toolkit'
import { failed, resetErrors, resetStatus } from '../../reducer-helper'
import { addApiKey, getProjectApiKeys } from '../../../components/front-api/api-keys/slice'
import { deleteApiKey } from '../../../components/front-api/api-keys/delete-popup/slice'

const initialState = {
  keys: [],
  createdKey: null,
  error: null,
  status: null,
}

const apiKeysSlice = createSlice({
  name: 'frontApiApiKeys',
  initialState,
  reducers: {
    resetState: () => initialState,
    changeRequestStatus: (state, action) => {
      state.status = action.payload.status
    },
  },
  extraReducers(builder) {
    builder.addCase(getProjectApiKeys.fulfilled, (state, action) => {
      state.keys = action.payload.keys
      resetStatus(state)
      resetErrors(state)
    })
    builder.addCase(getProjectApiKeys.rejected, failed)
    builder.addCase(addApiKey.fulfilled, (state, action) => {
      state.keys.push(action.payload.apiKey)
      state.createdKey = action.payload.createdKey

      resetStatus(state)
      resetErrors(state)
    })
    builder.addCase(addApiKey.rejected, failed)
    builder.addCase(deleteApiKey.fulfilled, (state, action) => {
      const deletedItemIndex = state.keys.findIndex((key) => key.uuid === action.payload.uuid)
      if (deletedItemIndex !== -1) {
        state.keys.splice(deletedItemIndex, 1)
      }

      state.createdKey = null
      
      resetStatus(state)
      resetErrors(state)
    })
    builder.addCase(deleteApiKey.rejected, failed)
  },
})

const selectFrontApiApiKeysSlice = (state) => state.frontApiApiKeys
export const selectKeys = (state) => selectFrontApiApiKeysSlice(state).keys
export const selectCreatedKey = (state) => selectFrontApiApiKeysSlice(state).createdKey
export const selectRequestStatus = (state) => selectFrontApiApiKeysSlice(state).status

const { reducer, actions } = apiKeysSlice

export const { resetState, changeRequestStatus } = actions

export default reducer
