import styled from 'styled-components'
import { layoutComponentsBackgroundColor, lightGray, regularGray } from '../../common/styles/colors'
import { MiddleFormTopContainer } from '../../common/layouts/public-area/middle-form/styles'

export const ApiKeyContainer = styled.div`
  position: relative;
  width: fit-content;
  margin: 24px auto;
`

export const GoDashboardButtonContainer = styled.div`
  position: relative;
  width: 160px;
  margin: 48px auto;
`

export const IconContainer = styled.div`
  position: relative;
  width: fit-content;
  margin: 24px auto;
  margin-top: 64px;
`

export const IconAnimationContainer = styled.div`
  position: relative;
  width: 80px;
  height: 80px;

  -webkit-animation: spin 8s linear infinite;
  -moz-animation: spin 8s linear infinite;
  animation: spin 8s linear infinite;

  @-moz-keyframes spin {
    100% {
      -moz-transform: rotate(360deg);
    }
  }
  @-webkit-keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
    }
  }
  @keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
`

export const MiddleFormContainer = styled(MiddleFormTopContainer)`
  margin-top: 64px;
`

export const ProjectImageContainer = styled.div`
  position: relative;
  width: 80px;
  height: 80px;
  margin: 0 auto;
  margin-top: 64px;
  margin-bottom: 24px;
`

export const ProjectImagePreview = styled.img`
  position: relative;
  width: 80px;
  height: 80px;

  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
`

export const InformationRow = styled.div`
  position: relative;
  width: 100%;
  min-height: 48px;
  line-height: 48px;
`

export const InformationRowTitle = styled(InformationRow)`
  border-bottom: 1px solid ${layoutComponentsBackgroundColor};
`

export const InformationLeftCol = styled.div`
  position: relative;
  float: left;
  font-weight: 700;
  font-size: 14px;
  color: ${regularGray};
`

export const InformationRightCol = styled.div`
  position: relative;
  float: right;
  font-size: 14px;
  color: ${lightGray};
`

export const ContentTitle = styled.h3`
  position: relative;
  font-size: 18px;
  margin-top: 32px;
  color: ${regularGray};
`
