import React, { memo } from 'react'
import ServiceOverview from '../../../../../service/overview/ServiceOverview'
import { ProjectServiceOverviewContainer } from './styles'

function ProjectServiceOverview() {
  return (
    <ProjectServiceOverviewContainer>
      <ServiceOverview />
    </ProjectServiceOverviewContainer>
  )
}

export default memo(ProjectServiceOverview)
