import React, { memo, useEffect } from 'react'
import { projectsUnloaded, selectProjects, selectRequestStatus } from '../../../reducers/project/project-list'
import ContentHeader from '../../common/layouts/simple-page-header/Header'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getAllProjects } from './slice'
import { ButtonContainer, EmptyFolderIconContainer, ProjectsContainer, TextContainer } from './styles'
import ProjectItem from './ProjectItem'
import { REQUEST_STATUSES } from '../../../reducers/reducer-helper'
import Loading from '../../common/loading/Loading'
import { SecondaryActionButton } from '../../common/styles/styles'
import StyledSVG from '../../common/styled-svg/StyledSVG'
import EmptyFolderIcon from '../../../assets/images/empty-folder-icon.svg'
import { mainBlue } from '../../common/styles/colors'
import { RegularText } from '../../common/styles/text'

function ProjectList() {
  const dispatch = useDispatch()

  const requestStatus = useSelector(selectRequestStatus)

  useEffect(() => {
    const fetchProjects = dispatch(getAllProjects())

    return () => {
      dispatch(projectsUnloaded())
      fetchProjects.abort()
    }
  }, [])

  const projects = useSelector(selectProjects)

  const button = (
    <SecondaryActionButton as={Link} to={'/project/create'}>
      Create new project
    </SecondaryActionButton>
  )

  return (
    <ProjectsContainer>
      <ContentHeader
        title="Projects"
        button={requestStatus !== REQUEST_STATUSES.PENDING && projects.length != 0 && button}
      />
      {requestStatus === REQUEST_STATUSES.PENDING && <Loading />}
      {requestStatus !== REQUEST_STATUSES.PENDING && (
        <>
          {projects.length === 0 && (
            <div>
              <EmptyFolderIconContainer>
                <StyledSVG width={120} height={105} color={mainBlue} src={EmptyFolderIcon} />
              </EmptyFolderIconContainer>
              <TextContainer>
                <RegularText>There are no created projects yet.</RegularText>
              </TextContainer>
              <ButtonContainer>{button}</ButtonContainer>
            </div>
          )}
          {projects.map((project) => (
            <ProjectItem project={project} key={project.uuid} />
          ))}
        </>
      )}
    </ProjectsContainer>
  )
}

export default memo(ProjectList)
