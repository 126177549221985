import styled from 'styled-components'
import { redTextColor, regularGray } from '../../../../../common/styles/colors'

export const NodeName = styled.span`
  font-size: 15px;
  font-weight: 600;
  color: ${regularGray};
`

export const NodeNameField = styled.input`
  position: relative;
  font-size: 15px;
  font-weight: 600;

  color: ${regularGray};

  min-width: 320px;

  background: none;
  padding: 0;
  margin: 0;
  border: none;

  &::-webkit-input-placeholder {
    /* Edge */
    color: ${redTextColor};
    opacity: 0.6;
  }

  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: ${redTextColor};
    opacity: 0.6;
  }

  &::placeholder {
    color: ${redTextColor};
    opacity: 0.6;
  }
`

export const NodeNameContainer = styled.div`
  position: relative;
  width: 100%;
  height: 28px;

  display: flex;
  align-items: center;

  padding: 0 12px;
  box-sizing: border-box;
`
