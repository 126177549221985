import React, { memo, useEffect, useRef, useState } from 'react'
import {
  DescriptionColumn,
  NameColumn,
  LastColumn,
  SearchField,
  SearchFieldContainer,
  SearchIconContainer,
  ValueColumn,
  ParameterTableRow,
  CreateParameterButtonContainer,
} from './styles'
import { RegularText } from '../../../../common/styles/text'
import { ContentContainer, ContentWrapperContainer } from '../../../../common/layouts/content-container/styles'
import { TableColumn, TableContainer, TableRow } from '../../../../common/table/styles'
import { CallToActionButton, Clear } from '../../../../common/styles/styles'
import Search from '../../../../common/search/Search'
import { useDispatch, useSelector } from 'react-redux'
import MorePopover from './more-popover/MorePopover'
import { showPopup } from '../../../../../reducers/popup'
import { PopupComponentKeys } from '../../../../common/layouts/popup/Popup'
import { selectFunctionByUuid } from '../../../../../reducers/front-api/front-api'
import { useParams } from 'react-router'
import StyledSVG from '../../../../common/styled-svg/StyledSVG'
import PlusIcon from '../../../../../assets/images/add-icon.svg'

function FunctionParameters() {
  const dispatch = useDispatch()

  const { functionUuid } = useParams()

  const _function = useSelector((state) => selectFunctionByUuid(state, functionUuid))

  const parameters = _function.parameters

  const searchFieldRef = useRef(null)

  const [filteredParameters, setFilteredParameters] = useState([])
  const [parameterUuidWithPopover, setParameterUuidWithPopover] = useState(null)
  const [searchQuery, setSearchQuery] = useState('')
  const [showSearchField, setShowSearchField] = useState(false)

  const changeSearchQuery = (event) => {
    setSearchQuery(event.target.value)
  }

  const showAddParameterFormHandler = (e) => {
    e.preventDefault()

    dispatch(
      showPopup({
        component: PopupComponentKeys.FRONT_API_CREATE_FUNCTION_PARAMETER,
        extraParameters: [{ name: 'functionUuid', value: functionUuid }],
        size: {
          width: '448px',
          height: 'auto',
        },
      }),
    )
  }

  let filteredParams = []

  useEffect(() => {
    if (parameters) {
      filteredParams = parameters
      if (searchQuery) {
        filteredParams = parameters.filter(
          (parameter) => parameter.name.toLowerCase().search(searchQuery.toLowerCase()) !== -1,
        )
      }

      setFilteredParameters(filteredParams)
    }
  }, [parameters, searchQuery])

  const noParametersMessage = (
    <ContentWrapperContainer>
      <RegularText>No parameters are here... yet.</RegularText>
    </ContentWrapperContainer>
  )

  return (
    <ContentContainer padding={'24px 0'} margin={'0 24px'}>
      <CreateParameterButtonContainer>
        <CallToActionButton onClick={showAddParameterFormHandler} type={'button'}>
          <StyledSVG width={12} height={12} color={'#FFFFFF'} src={PlusIcon} />
          <span>Create new parameter</span>
        </CallToActionButton>
      </CreateParameterButtonContainer>
      <ContentWrapperContainer marginTop={'24px'}>
        <TableContainer width={'724px'}>
          <TableRow bottomMargin={'8px'} withBorder={true}>
            <TableColumn lineHeight={'32px'}>
              <NameColumn>
                <RegularText>
                  <b>Name</b>
                </RegularText>
              </NameColumn>
            </TableColumn>
            <TableColumn lineHeight={'32px'}>
              <ValueColumn>
                <RegularText>
                  <b>Is required</b>
                </RegularText>
              </ValueColumn>
            </TableColumn>
            <TableColumn lineHeight={'32px'}>
              <DescriptionColumn>
                <RegularText>
                  <b>Description</b>
                </RegularText>
              </DescriptionColumn>
            </TableColumn>
            <TableColumn>
              <LastColumn>
                <SearchIconContainer>
                  <Search
                    searchFieldComponentRef={searchFieldRef}
                    activeIcon={!!searchQuery}
                    showSearchField={showSearchField}
                    setShowSearchField={setShowSearchField}
                    backgroundColor={'#FFFFFF'}
                  />
                </SearchIconContainer>
              </LastColumn>
            </TableColumn>
          </TableRow>
          {filteredParameters.map((parameter) => (
            <ParameterTableRow hoverEffect={true} key={parameter.uuid}>
              <TableColumn>
                <NameColumn>
                  <RegularText>{parameter.name}</RegularText>
                </NameColumn>
              </TableColumn>
              <TableColumn>
                <ValueColumn>
                  <RegularText>{parameter.isRequired ? 'Required' : 'Optional'}</RegularText>
                </ValueColumn>
              </TableColumn>
              <TableColumn padding={'7px 0'} lineHeight={'18px'}>
                <DescriptionColumn>
                  <RegularText>{parameter.description}</RegularText>
                </DescriptionColumn>
              </TableColumn>
              <TableColumn padding={'4px 0'}>
                <LastColumn>
                  <MorePopover
                    parameter={parameter}
                    parameterUuidWithPopover={parameterUuidWithPopover}
                    setParameterUuidWithPopover={setParameterUuidWithPopover}
                  />
                </LastColumn>
              </TableColumn>
            </ParameterTableRow>
          ))}

          {parameters.length === 0 && noParametersMessage}

          {showSearchField && (
            <SearchFieldContainer ref={searchFieldRef}>
              <fieldset>
                <SearchField
                  type="text"
                  placeholder={'Search parameters...'}
                  name="searchValue"
                  value={searchQuery}
                  onChange={changeSearchQuery}
                />
              </fieldset>
            </SearchFieldContainer>
          )}
        </TableContainer>
      </ContentWrapperContainer>
    </ContentContainer>
  )
}

export default memo(FunctionParameters)
