import React, { memo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ContentContainer, SettingsFormContainer, Subtitle, UserSettingsGeneralContainer } from './styles'
import SendIcon from '../../../../assets/images/send-icon.svg'
import { confirmNewEmail } from './slice'
import { H2HeaderTitle, RegularText } from '../../../common/styles/text'
import {
  changeConfirmNewEmailToken,
  changeRequestStatus,
  selectError,
  selectRequestStatus,
  selectSettings,
} from '../../../../reducers/user/user-general-settings-form'
import { ButtonContainer, FieldContainer } from '../../../common/styles/form-styles'
import { REQUEST_STATUSES } from '../../../../reducers/reducer-helper'
import ErrorMessage from '../../../common/error-message/ErrorMessage'
import ButtonWithIcon from '../../../common/button-with-icon/ButtonWithIcon'
import SixDigitsCode from '../../../common/six-digits-code/SixDigitsCode'
import Loading from '../../../common/loading/Loading'
import { HeaderContainer } from '../../../common/layouts/content-block-header/styles'

const CODE_SIZE = 6

function ConfirmEmailForm() {
  const dispatch = useDispatch()

  const requestStatus = useSelector(selectRequestStatus)
  const error = useSelector(selectError)
  const settings = useSelector(selectSettings)

  const confirmNewEmailProcess = (token) => {
    dispatch(changeConfirmNewEmailToken({ token }))
    if (token.length === CODE_SIZE) {
      dispatch(changeRequestStatus({ status: REQUEST_STATUSES.PENDING }))
      dispatch(confirmNewEmail({ token, email: settings.email }))
    }
  }

  return (
    <UserSettingsGeneralContainer>
      <HeaderContainer>
        <H2HeaderTitle>General Settings | New email confirmation</H2HeaderTitle>
      </HeaderContainer>
      <ContentContainer>
        {requestStatus === REQUEST_STATUSES.PENDING && <Loading />}
        <Subtitle>
          <RegularText>Please confirm Your new email</RegularText>
        </Subtitle>
        <SettingsFormContainer>
          <ErrorMessage error={error} />
          <FieldContainer>
            <fieldset>
              <fieldset>
                <SixDigitsCode handler={confirmNewEmailProcess} />
              </fieldset>
              <ButtonContainer>
                <ButtonWithIcon text={'Resend code'} icon={SendIcon} />
              </ButtonContainer>
            </fieldset>
          </FieldContainer>
        </SettingsFormContainer>
      </ContentContainer>
    </UserSettingsGeneralContainer>
  )
}

export default memo(ConfirmEmailForm)
