import styled from 'styled-components'
import { layoutComponentsBorderColor } from '../../../../common/styles/colors'

export const AddServiceContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
`

export const AddServiceSidebarContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 320px;

  box-sizing: border-box;

  border-right: 1px solid ${layoutComponentsBorderColor};
`

export const ServiceDetailsContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 320px;
  right: 0;
`
