import React, { memo } from 'react'
import { HeaderContainer, Title } from './styles'

function Header() {
  return (
    <HeaderContainer>
      <Title>Add service</Title>
    </HeaderContainer>
  )
}

export default memo(Header)
