import styled from 'styled-components'

export const AddServiceContent = styled.div`
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  bottom: 0;
`

export const ContentContainer = styled.div`
  position: absolute;
  width: 100%;
  left: 0;
  top: 80px;
  bottom: 80px;
`

export const Content = styled.div`
  position: absolute;
  left: 240px;
  right: 0;
  top: 0;
  bottom: 0;
  padding: 24px 0;
  box-sizing: border-box;
`
