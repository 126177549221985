import React, { memo } from 'react'
import { UserPopoverContainer } from './styles'
import { useDispatch } from 'react-redux'
import { logout } from './slice'
import { PopoverDivider, PopoverNavigationButton, PopoverNavigationItem } from '../../../../popover/styles'
import { Link } from 'react-router-dom'

function UserPopover({ wrapperRef, closePopover }) {
  const dispatch = useDispatch()
  const signOutProcess = () => {
    dispatch(logout())
  }

  return (
    <UserPopoverContainer ref={wrapperRef}>
      <PopoverNavigationItem>
        <PopoverNavigationButton type={'button'}>Support</PopoverNavigationButton>
      </PopoverNavigationItem>

      <PopoverNavigationItem>
        <PopoverNavigationButton as={Link} onClick={closePopover} to={'/user/settings/general'}>
          Settings
        </PopoverNavigationButton>
      </PopoverNavigationItem>

      <PopoverDivider />
      <PopoverNavigationItem>
        <PopoverNavigationButton onClick={signOutProcess}>Log Out</PopoverNavigationButton>
      </PopoverNavigationItem>
    </UserPopoverContainer>
  )
}

export default memo(UserPopover)
