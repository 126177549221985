import { createAsyncThunk } from '@reduxjs/toolkit'
import { ProjectAgent } from '../../../api/projectAgent'

export const getAllProjects = createAsyncThunk('project-list/get-all-projects', async (undefined, thunkApi) => {
  try {
    const projects = await ProjectAgent.getAllProjects()

    return { projects }
  } catch (error) {
    return thunkApi.rejectWithValue(error)
  }
})
