import { createAsyncThunk } from '@reduxjs/toolkit'
import { FrontApiAgent } from '../../api/frontApiAgent'

export const getFrontApiData = createAsyncThunk('front-api/data', async ({ projectUuid }, thunkApi) => {
  try {
    const frontApiData = await FrontApiAgent.getFrontApiData(projectUuid)

    return { ...frontApiData }
  } catch (error) {
    return thunkApi.rejectWithValue(error)
  }
})
