import { createAsyncThunk } from '@reduxjs/toolkit'
import { FrontApiAgent } from '../../../../../../api/frontApiAgent'

export const renameFunction = createAsyncThunk(
  'front-api/function/rename',
  async ({ projectUuid, uuid, name }, thunkApi) => {
    try {
      await FrontApiAgent.renameFunction(projectUuid, uuid, name)

      return { functionUuid: uuid, name }
    } catch (error) {
      return thunkApi.rejectWithValue(error)
    }
  },
)
