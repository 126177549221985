import { createSlice } from '@reduxjs/toolkit'
import { addRedis, updateRedis } from '../../components/project/details/settings/resources/redis/slice'
import { resetErrors, resetStatus } from './../reducer-helper'

const initialState = {
  settings: {
    size: null,
  },
  error: null,
  status: null,
  formLoaded: false,
}

const projectRedisSettingsFormSlice = createSlice({
  name: 'projectRedisSettingsForm',
  initialState,
  reducers: {
    setSettings: (state, action) => {
      state.settings.size = action.payload.size
      state.formLoaded = true
    },
    changeSize: (state, action) => {
      state.settings.size = action.payload.size
    },
    changeRequestStatus: (state, action) => {
      state.status = action.payload.status
    },
    resetForm: () => initialState,
  },
  extraReducers(builder) {
    builder.addCase(updateRedis.fulfilled, (state, action) => {
      resetStatus(state)
      resetErrors(state)
    })
    builder.addCase(addRedis.fulfilled, (state, action) => {
      resetStatus(state)
      resetErrors(state)
    })
  },
})

const selectRedisSettingsFormSlice = (state) => state.projectRedisSettingsForm
export const selectRedisSettings = (state) => selectRedisSettingsFormSlice(state).settings
export const isRedisSettingsFormLoaded = (state) => selectRedisSettingsFormSlice(state).formLoaded
export const selectRequestStatus = (state) => selectRedisSettingsFormSlice(state).status

const { reducer, actions } = projectRedisSettingsFormSlice

export const { setSettings, changeSize, resetForm, changeRequestStatus } = actions

export default reducer
