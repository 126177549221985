import React, { memo, useEffect, useState } from 'react'
import Footer from './footer/Footer'
import { AddServiceContent, Content, ContentContainer } from './styles'
import Header from './header/Header'
import Navigation from './navigation/Navigation'
import { useDispatch, useSelector } from 'react-redux'
import Configurations from './content/Configurations'
import { selectStep, resetForm, changeStep } from '../../../reducers/service/add-service-form'
import Settings from './content/Settings'
import { selectServiceDetails } from '../../../reducers/service/service-details'
import { selectProject } from '../../../reducers/project/project-details'
import Resources from './content/Resources'

function AddServicePopup() {
  const dispatch = useDispatch()

  const step = useSelector(selectStep)
  const service = useSelector(selectServiceDetails)
  const project = useSelector(selectProject)

  const [resourcesToSetup, setResourcesToSetup] = useState([])

  useEffect(() => {
    const filteredResourceToSetup = []
    for (const requiredResource of service.resources) {
      const isResourceExists = project.resources.find((projectResource) => projectResource.type === requiredResource)
      if (!isResourceExists) {
        filteredResourceToSetup.push(requiredResource)
      }
    }

    setResourcesToSetup(filteredResourceToSetup)

    return () => {
      dispatch(resetForm())
    }
  }, [])

  useEffect(() => {
    dispatch(changeStep({ step: resourcesToSetup.length > 0 ? 1 : 2 }))
  }, [resourcesToSetup])

  return (
    <AddServiceContent>
      <Header />
      <ContentContainer>
        <Navigation resourcesToSetup={resourcesToSetup} />
        <Content>
          {step === 1 && <Resources resourcesToSetup={resourcesToSetup} />}
          {step === 2 && <Settings />}
          {step === 3 && <Configurations />}
        </Content>
      </ContentContainer>
      <Footer resourcesToSetup={resourcesToSetup} />
    </AddServiceContent>
  )
}

export default memo(AddServicePopup)
