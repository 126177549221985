import { createSlice } from '@reduxjs/toolkit'
import { createFunction } from '../../../components/front-api/functions/sidebar/forms/create-function/slice'
import { failed, resetErrors, resetStatus } from '../../reducer-helper'

const initialState = {
  formData: {
    name: 'New function',
  },
  createdFunctionUuid: null,
  error: null,
  status: null,
}

const createFormSlice = createSlice({
  name: 'frontApiCreateFunctionForm',
  initialState,
  reducers: {
    resetState: () => initialState,
    changeName: (state, action) => {
      state.formData.name = action.payload.name
    },
    changeRequestStatus: (state, action) => {
      state.status = action.payload.status
    },
  },
  extraReducers(builder) {
    builder.addCase(createFunction.fulfilled, (state, action) => {
      state.createdFunctionUuid = action.payload.function.uuid

      resetStatus(state)
      resetErrors(state)
    })
    builder.addCase(createFunction.rejected, failed)
  },
})

const currentSlice = (state) => state.frontApiCreateFunctionForm
export const selectRequestStatus = (state) => currentSlice(state).status
export const selectFormData = (state) => currentSlice(state).formData
export const selectCreatedFunctionUuid = (state) => currentSlice(state).createdFunctionUuid

const { reducer, actions } = createFormSlice

export const { resetState, changeName, changeRequestStatus } = actions

export default reducer
