import styled from 'styled-components'
import { RegularText } from '../../../../common/styles/text'

export const IconContainer = styled.div`
  position: relative;
`

export const ButtonContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
`

export const InfoMessage = styled(RegularText)`
  text-align: center;
`
