import { createSlice } from '@reduxjs/toolkit'
import { REQUEST_STATUSES, failed, resetErrors, resetStatus } from './../reducer-helper'
import { getAllServices } from '../../components/service/all-services/slice'

const initialState = {
  services: [],
  error: null,
  status: REQUEST_STATUSES.PENDING,
}

const serviceListSlice = createSlice({
  name: 'serviceList',
  initialState,
  reducers: {
    servicesUnloaded: () => initialState,
  },
  extraReducers(builder) {
    builder.addCase(getAllServices.fulfilled, (state, action) => {
      state.services = action.payload.services
      resetStatus(state)
      resetErrors(state)
    })
    builder.addCase(getAllServices.rejected, failed)
  },
})

const selectServiceListSlice = (state) => state.serviceList
export const selectAllServices = (state) => selectServiceListSlice(state).services
export const selectRequestStatus = (state) => selectServiceListSlice(state).status

const { reducer, actions } = serviceListSlice

export const { servicesUnloaded } = actions

export default reducer
