import React, { memo } from 'react'
import { IconContainer, ProjectCreatingContainer } from './styles'
import ProcessingIcon from '../../../assets/images/processing-icon.svg'
import StyledSVG from '../../common/styled-svg/StyledSVG'
import { mainBlue } from '../../common/styles/colors'
import { RegularText } from '../../common/styles/text'

function ProjectCreating() {
  return (
    <ProjectCreatingContainer>
      <IconContainer>
        <StyledSVG width={80} height={80} color={mainBlue} src={ProcessingIcon} />
      </IconContainer>
      <RegularText>Your project is still creating.</RegularText>
      <br/>
      <RegularText>Please wait a moment.</RegularText>
    </ProjectCreatingContainer>
  )
}

export default memo(ProjectCreating)
