import { createSlice } from '@reduxjs/toolkit'
import { updateServiceConfigurations } from '../../components/project/details/services/details/configurations/slice'

const initialState = {
  configurations: {},
  formLoaded: false,
  error: null,
  status: null,
}

const updateServiceConfigurationFormSlice = createSlice({
  name: 'updateServiceConfigurationForm',
  initialState,
  extraReducers(builder) {
    builder.addCase(updateServiceConfigurations.fulfilled, (state) => {
      console.log('Update service configuration')
    })
  },
  reducers: {
    changeConfiguration: (state, action) => {
      state.configurations[action.payload.key] = action.payload.value
    },
    setConfigurations: (state, action) => {
      state.configurations = action.payload.configurations
      state.formLoaded = true
    },
    resetForm: () => initialState,
  },
})

const selectUpdateServiceConfigurationFormSlice = (state) => state.updateServiceConfigurationForm
export const selectConfigurations = (state) => selectUpdateServiceConfigurationFormSlice(state).configurations
export const isConfigurationsFormLoaded = (state) => selectUpdateServiceConfigurationFormSlice(state).formLoaded

const { reducer, actions } = updateServiceConfigurationFormSlice

export const { changeConfiguration, setConfigurations, resetForm } = actions

export default reducer
