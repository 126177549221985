import React, { memo, useEffect, useRef, useState } from 'react'
import {
  DescriptionColumn,
  NameColumn,
  LastColumn,
  Subtitle,
  ValueColumn,
  ParameterTableRow,
  CreateParameterButtonContainer,
  NoDescriptionMessage,
} from './styles'
import { H2HeaderTitle, RegularText } from '../../common/styles/text'
import { ContentContainer, ContentWrapperContainer } from '../../common/layouts/content-container/styles'
import {
  TableColumn,
  TableContainer,
  TableRow,
  TableSearchField,
  TableSearchFieldContainer,
  TableSearchIconContainer,
} from '../../common/table/styles'
import { CallToActionButton, Clear } from '../../common/styles/styles'
import Search from '../../common/search/Search'
import { useDispatch, useSelector } from 'react-redux'
import { selectGlobalParameters } from '../../../reducers/front-api/front-api'
import MorePopover from './more-popover/MorePopover'
import { showPopup } from '../../../reducers/popup'
import { PopupComponentKeys } from '../../common/layouts/popup/Popup'
import { HeaderContainer } from '../../common/layouts/content-block-header/styles'
import StyledSVG from '../../common/styled-svg/StyledSVG'
import PlusIcon from '../../../assets/images/add-icon.svg'

function GlobalParameters() {
  const dispatch = useDispatch()

  const parameters = useSelector(selectGlobalParameters)

  const searchFieldRef = useRef(null)

  const [filteredParameters, setFilteredParameters] = useState([])
  const [parameterUuidWithPopover, setParameterUuidWithPopover] = useState(null)
  const [searchQuery, setSearchQuery] = useState('')
  const [showSearchField, setShowSearchField] = useState(false)

  const changeSearchQuery = (event) => {
    setSearchQuery(event.target.value)
  }

  const showAddParameterFormHandler = (e) => {
    e.preventDefault()

    dispatch(
      showPopup({
        component: PopupComponentKeys.FRONT_API_CREATE_GLOBAL_PARAMETER,
        size: {
          width: '448px',
          height: 'auto',
        },
      }),
    )
  }

  let filteredParams = []

  useEffect(() => {
    if (parameters) {
      filteredParams = parameters
      if (searchQuery) {
        filteredParams = parameters.filter(
          (parameter) => parameter.name.toLowerCase().search(searchQuery.toLowerCase()) !== -1,
        )
      }

      setFilteredParameters(filteredParams)
    }
  }, [parameters, searchQuery])

  const noParametersMessage = (
    <ContentWrapperContainer>
      <RegularText>No parameters are here... yet.</RegularText>
    </ContentWrapperContainer>
  )

  return (
    <ContentContainer>
      <HeaderContainer>
        <H2HeaderTitle>Global parameters</H2HeaderTitle>
      </HeaderContainer>
      <ContentContainer top={'48px'} padding={'24px'} scroll={true}>
        <Subtitle>
          <RegularText>Global parameters are available in flows in the Scenarios and Actions</RegularText>
        </Subtitle>
        <CreateParameterButtonContainer>
          <CallToActionButton onClick={showAddParameterFormHandler} type={'button'}>
            <StyledSVG width={12} height={12} color={'#FFFFFF'} src={PlusIcon} />
            <span>Create new parameter</span>
          </CallToActionButton>
        </CreateParameterButtonContainer>
        <ContentWrapperContainer marginTop={'24px'}>
          <TableContainer width={'724px'}>
            <TableRow bottomMargin={'8px'} withBorder={true}>
              <TableColumn lineHeight={'32px'}>
                <NameColumn>
                  <RegularText>
                    <b>Name</b>
                  </RegularText>
                </NameColumn>
              </TableColumn>
              <TableColumn lineHeight={'32px'}>
                <ValueColumn>
                  <RegularText>
                    <b>Value</b>
                  </RegularText>
                </ValueColumn>
              </TableColumn>
              <TableColumn lineHeight={'32px'}>
                <DescriptionColumn>
                  <RegularText>
                    <b>Description</b>
                  </RegularText>
                </DescriptionColumn>
              </TableColumn>
              <TableColumn>
                <LastColumn>
                  <TableSearchIconContainer>
                    <Search
                      searchFieldComponentRef={searchFieldRef}
                      activeIcon={!!searchQuery}
                      showSearchField={showSearchField}
                      setShowSearchField={setShowSearchField}
                      backgroundColor={'#FFFFFF'}
                    />
                  </TableSearchIconContainer>
                </LastColumn>
              </TableColumn>
            </TableRow>
            {filteredParameters.map((parameter) => (
              <ParameterTableRow hoverEffect={true} key={parameter.uuid}>
                <TableColumn>
                  <NameColumn>
                    <RegularText>{parameter.name}</RegularText>
                  </NameColumn>
                </TableColumn>
                <TableColumn>
                  <ValueColumn>
                    <RegularText>{parameter.value}</RegularText>
                  </ValueColumn>
                </TableColumn>
                <TableColumn padding={'7px 0'} lineHeight={'18px'}>
                  <DescriptionColumn>
                    <RegularText>
                      {parameter.description ? (
                        parameter.description
                      ) : (
                        <NoDescriptionMessage>No description yet...</NoDescriptionMessage>
                      )}
                    </RegularText>
                  </DescriptionColumn>
                </TableColumn>
                <TableColumn padding={'4px 0'}>
                  <LastColumn>
                    <MorePopover
                      parameter={parameter}
                      parameterUuidWithPopover={parameterUuidWithPopover}
                      setParameterUuidWithPopover={setParameterUuidWithPopover}
                    />
                  </LastColumn>
                </TableColumn>
              </ParameterTableRow>
            ))}

            {parameters.length === 0 && noParametersMessage}

            {showSearchField && (
              <TableSearchFieldContainer ref={searchFieldRef}>
                <fieldset>
                  <TableSearchField
                    type="text"
                    placeholder={'Search parameters...'}
                    name="searchValue"
                    value={searchQuery}
                    onChange={changeSearchQuery}
                  />
                </fieldset>
              </TableSearchFieldContainer>
            )}
          </TableContainer>
        </ContentWrapperContainer>
      </ContentContainer>
    </ContentContainer>
  )
}

export default memo(GlobalParameters)
