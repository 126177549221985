import { WidgetType } from '@uiw/react-codemirror'
import { colorsByType } from '../../../../helper'

export class PlaceholderWidget extends WidgetType {
  constructor(name, sourceType) {
    super()
    this.name = name
    this.sourceType = sourceType
  }

  eq(other) {
    return this.name === other.name
  }

  toDOM() {
    let elt = document.createElement('span')
    const color = colorsByType(this.sourceType)

    // Can add event listener to manage clicks on item
    // elt.addEventListener('click', () => {
    //   console.log(`click on: ${this.sourceType}`)
    // })

    elt.style.cssText = `
      padding: 0 4px;
      margin: 0 4px;
      margin-bottom: 4px;
    
      -webkit-border-radius: 4px;
      -moz-border-radius: 4px;
      border-radius: 4px;
      
      cursor: pointer;
    
      border: 1px solid ${color.border};
      background: ${color.background};
      color: ${color.border};
      `
    elt.textContent = this.name

    return elt
  }

  ignoreEvent() {
    return false
  }
}
