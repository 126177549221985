import React, { memo, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { resetForm, selectStep } from '../../../reducers/project/project-create-form'
import ProjectInformation from './ProjectInformation'
import Confirmation from './Confirmation'
import AWSConfigurations from './AWSConfigurations'
import FrontApi from './FrontApi'
import Success from './Success'

function CreateProject() {
  const dispatch = useDispatch()
  const step = useSelector(selectStep)

  // Can't be stored in the Redux as file is not serializable
  const [projectImage, setProjectImage] = useState()

  useEffect(() => {
    return () => {
      dispatch(resetForm())
    }
  }, [])

  return (
    <>
      {step === 1 && <ProjectInformation existingImage={projectImage} changeImageHandler={setProjectImage} />}
      {step === 2 && <AWSConfigurations />}
      {step === 3 && <FrontApi />}
      {step === 4 && <Confirmation projectImage={projectImage} />}
      {step === 5 && <Success />}
    </>
  )
}

export default memo(CreateProject)
