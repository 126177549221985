import React, { memo } from 'react'
import UserServiceIcon from '../../../assets/images/user-icon.svg'
import TokenServiceIcon from '../../../assets/images/security-icon.svg'
import NotificationServiceIcon from '../../../assets/images/notifications-icon.svg'
import StorageServiceIcon from '../../../assets/images/storage-icon.svg'
import ChatServiceIcon from '../../../assets/images/chat-icon.svg'
import FrontApiIcon from '../../../assets/images/front-api-icon.svg'
import StyledSVG from '../../common/styled-svg/StyledSVG'
import { regularGray } from '../../common/styles/colors'

function ServiceIcon({ serviceAlias, width, height }) {
  let icon
  switch (serviceAlias) {
    case 'front-api-builder':
      icon = FrontApiIcon
      break
    case 'user':
      icon = UserServiceIcon
      break
    case 'token':
      icon = TokenServiceIcon
      break
    case 'notification':
      icon = NotificationServiceIcon
      break
    case 'storage':
      icon = StorageServiceIcon
      break
    case 'chat':
      icon = ChatServiceIcon
      break
  }

  return <StyledSVG width={width ? width : 16} height={height ? height : 16} color={regularGray} src={icon} />
}

export default memo(ServiceIcon)
