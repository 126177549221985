import { createAsyncThunk } from '@reduxjs/toolkit'
import { ServiceAgent } from '../../../api/serviceAgent'

export const getAllServices = createAsyncThunk('service-list/get-all-services', async (undefined, thunkApi) => {
  try {
    const services = await ServiceAgent.getAllServices()

    return { services }
  } catch (error) {
    return thunkApi.rejectWithValue(error)
  }
})
