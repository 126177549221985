import React, { memo } from 'react'
import { ConfigurationsFormContainer } from './styles'
import { useSelector } from 'react-redux'
import { selectServiceDetails } from '../../../reducers/service/service-details'
import { FieldContainer } from '../../common/styles/form-styles'
import TextField from '../../common/form/text-field/TextField'
import { ConfigurationsFieldType } from '../../../utils/utils'

function ConfigurationsForm({ changeConfigurationProcess, configurations }) {
  const service = useSelector(selectServiceDetails)

  const configurationKeys = Object.keys(service.configuration)

  return (
    <ConfigurationsFormContainer>
      {configurationKeys.map((key) => {
        switch (service.configuration[key].type) {
          case ConfigurationsFieldType.STRING:
          case ConfigurationsFieldType.INPUT:
          case ConfigurationsFieldType.NUMBER:
          case ConfigurationsFieldType.PASSWORD:
            return (
              <FieldContainer key={key}>
                <fieldset>
                  <TextField
                    label={service.configuration[key].title}
                    value={configurations[key] || ''}
                    type={service.configuration[key].type === ConfigurationsFieldType.PASSWORD ? 'password' : 'text'}
                    changeEvent={(event) => {
                      changeConfigurationProcess(key, event.target.value)
                    }}
                    placeholder={service.configuration[key].title}
                  />
                </fieldset>
              </FieldContainer>
            )
        }
      })}
    </ConfigurationsFormContainer>
  )
}

export default memo(ConfigurationsForm)
