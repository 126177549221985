import React, { memo } from 'react'
import { ContentContainer, ContentWrapperContainer } from '../../../../common/layouts/content-container/styles'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import {
  selectAllFunctions,
  selectFunctionByUuid,
  selectResultObjectByUuid,
} from '../../../../../reducers/front-api/front-api'
import JsonSchemaBuilder from '../../../json-schema-builder/JsonSchemaBuilder'
import { RegularText } from '../../../../common/styles/text'
import { CallToActionButton } from '../../../../common/styles/styles'
import { NoResultObjectContainer } from './styles'
import { cloneFunctionResultObject, createFunctionResultObject, createResultObjectParameter } from './slice'
import SelectboxField from '../../../../common/form/selectbox/SelectboxField'

function ResultObject() {
  const dispatch = useDispatch()
  const { functionUuid, projectUuid } = useParams()
  const functions = useSelector(selectAllFunctions)
  const _function = useSelector((state) => selectFunctionByUuid(state, functionUuid))
  const resultObject = useSelector((state) => selectResultObjectByUuid(state, _function.resultObjectUuid))

  const addParameterHandler = ({ formData }) => {
    dispatch(
      createResultObjectParameter({
        projectUuid,
        resultObjectUuid: _function.resultObjectUuid,
        name: formData.name,
        type: formData.type,
        description: formData.description,
        parentUuid: formData.parentUuid,
      }),
    )
  }

  const createResultObjectHandler = () => {
    dispatch(createFunctionResultObject({ projectUuid, functionUuid }))
  }

  const cloneResultObject = (sourceResultObjectUuid) => {
    dispatch(cloneFunctionResultObject({ projectUuid, functionUuid, sourceResultObjectUuid }))
  }

  const resultObjectsData = [
    {
      isTitle: true,
      name: 'Functions result objects',
    },
  ]
  functions.forEach((functionObj) => {
    if (functionObj.uuid !== functionUuid) {
      resultObjectsData.push({
        name: functionObj.name,
        value: functionObj.resultObjectUuid,
      })
    }
  })

  return (
    <ContentContainer padding={'24px 0'} margin={'0 24px'}>
      <ContentWrapperContainer height={'100%'} marginTop={'0px'}>
        {!resultObject && (
          <NoResultObjectContainer>
            <RegularText>There is no created Result Object yet.</RegularText>
            <ContentWrapperContainer alignCenter={true} width={'fit-content'}>
              <CallToActionButton onClick={createResultObjectHandler}>Create new result object</CallToActionButton>
            </ContentWrapperContainer>
            <RegularText>Or </RegularText>
            <ContentWrapperContainer alignCenter={true} width={'360px'}>
              <SelectboxField
                selectedValue={null}
                placeholder={'Clone existing Result Object'}
                options={resultObjectsData}
                small={true}
                changeValueHandler={cloneResultObject}
              />
            </ContentWrapperContainer>
          </NoResultObjectContainer>
        )}
        {resultObject && (
          <JsonSchemaBuilder parameters={resultObject.parameters} addParameterHandler={addParameterHandler} />
        )}
      </ContentWrapperContainer>
    </ContentContainer>
  )
}

export default memo(ResultObject)
