import styled from 'styled-components'

export const Content = styled.div`
  position: relative;
  width: 100%;
`

export const ContentDescription = styled.div`
  position: relative;
  margin-bottom: 32px;
`
