import React, { memo, useEffect, useState } from 'react'
import { HeaderContainer, Title, Subtitle, ResourceStatusContainer, ResourceStatusText } from './../../styles'
import {
  ButtonWithText,
  ButtonWithTextContainer,
  ButtonWithTextLeftBlock,
  FieldContainer,
  FormContainer,
} from '../../../../../common/styles/form-styles'
import SelectboxField from '../../../../../common/form/selectbox/SelectboxField'
import { ResourceSizes, ResourceStatusLabels, ResourceStatuses } from '../../../../../../utils/utils'
import { useDispatch, useSelector } from 'react-redux'
import { selectProject } from '../../../../../../reducers/project/project-details'
import { H2Title, RegularText } from '../../../../../common/styles/text'
import { Clear, DangerousButton } from '../../../../../common/styles/styles'
import {
  changeRequestStatus,
  changeSize,
  isRedisSettingsFormLoaded,
  selectRedisSettings,
  selectRequestStatus,
  setSettings,
} from '../../../../../../reducers/project/project-redis-settings-form'
import { addRedis, updateRedis } from './slice'
import { ContentContainer, ContentWrapperContainer } from '../../../../../common/layouts/content-container/styles'
import StatusLabel from '../../../../../common/status-label/StatusLabel'
import { REQUEST_STATUSES } from '../../../../../../reducers/reducer-helper'

function RedisSettings() {
  const dispatch = useDispatch()

  const project = useSelector(selectProject)
  const settings = useSelector(selectRedisSettings)
  const isFormLoaded = useSelector(isRedisSettingsFormLoaded)
  const requestStatus = useSelector(selectRequestStatus)

  const [formWasChanged, changeForm] = useState(false)
  const [redisResource, setRedisResource] = useState(null)

  const changeSizeProcess = (value) => {
    dispatch(changeSize({ size: value }))
  }

  useEffect(() => {
    const redisResource = project.resources.find((resource) => resource.type === 'redis')
    setRedisResource(redisResource)

    let size = null

    if (redisResource) {
      size = redisResource.configurations.size
    }

    dispatch(setSettings({ size }))
  }, [project])

  useEffect(() => {
    if (isFormLoaded) {
      let size = null

      if (redisResource) {
        size = redisResource.configurations.size
      }

      changeForm(size !== settings.size)
    }
  }, [isFormLoaded, settings, redisResource])

  const saveSettings = (event) => {
    event.preventDefault()

    dispatch(changeRequestStatus({ status: REQUEST_STATUSES.PENDING }))

    const resourceParams = { projectUuid: project.uuid, size: settings.size }
    dispatch(redisResource ? updateRedis(resourceParams) : addRedis(resourceParams))
  }

  const resourceSizeOptions = Object.values(ResourceSizes)

  return (
    <ContentContainer>
      <HeaderContainer>
        <Title>Redis</Title>
        {requestStatus !== REQUEST_STATUSES.PENDING && (
          <>
            <ResourceStatusContainer>
              <StatusLabel status={redisResource ? redisResource.status : ResourceStatuses.NOT_CREATED} />
            </ResourceStatusContainer>
            <ResourceStatusText>
              {ResourceStatusLabels[redisResource ? redisResource.status : ResourceStatuses.NOT_CREATED]}
            </ResourceStatusText>
          </>
        )}
      </HeaderContainer>
      <ContentContainer top={'48px'} padding={'24px'}>
        <Subtitle>
          <RegularText>Here you can change a redis settings</RegularText>
        </Subtitle>
        {isFormLoaded && (
          <ContentWrapperContainer width={'360px'} height={'auto'} marginTop={'0'} marginBottom={'0'}>
            <FormContainer onSubmit={saveSettings}>
              <FieldContainer>
                <fieldset>
                  <SelectboxField
                    label={'Size'}
                    selectedValue={settings.size}
                    placeholder={'Redis size'}
                    options={resourceSizeOptions}
                    changeValueHandler={changeSizeProcess}
                  />
                </fieldset>
              </FieldContainer>

              {formWasChanged && (
                <ButtonWithTextContainer>
                  <ButtonWithTextLeftBlock>
                    <ButtonWithText type="submit">Save</ButtonWithText>
                  </ButtonWithTextLeftBlock>
                  <Clear />
                </ButtonWithTextContainer>
              )}
            </FormContainer>
          </ContentWrapperContainer>
        )}
        {redisResource && (
          <ContentWrapperContainer marginTop={'48px'}>
            <H2Title>Remove redis</H2Title>
            <ContentWrapperContainer marginTop={'24px'}>
              <RegularText>
                You can remove your Redis instance, but make sure that it is not used by any service.
              </RegularText>
            </ContentWrapperContainer>
            <ContentWrapperContainer marginTop={'24px'}>
              <ButtonWithTextLeftBlock>
                <DangerousButton type="submit">Remove</DangerousButton>
              </ButtonWithTextLeftBlock>
            </ContentWrapperContainer>
          </ContentWrapperContainer>
        )}
      </ContentContainer>
    </ContentContainer>
  )
}

export default memo(RedisSettings)
