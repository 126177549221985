import React, { memo, useState, useEffect, useRef } from 'react'
import { FieldLabel, Label } from '../styles'
import {
  ArrowIcon,
  ArrowIconContainer,
  SelectboxContainer,
  SelectboxFieldBox,
  SelectboxOptionsContainer,
  SelectedValue,
} from './styles'
import ArrowDownIcon from '../../../../assets/images/arrow-down.svg'
import { PopoverNavigationButton, PopoverNavigationItem, PopoverNavigationItemTitle } from '../../popover/styles'

const SelectboxField = ({
  label,
  selectedValue: selectedOptions,
  placeholder,
  options,
  changeValueHandler,
  small = false,
}) => {
  const wrapperRef = useRef(null)
  const [showOptions, setShowOptions] = useState(false)
  const [activeOptions, setActiveOptions] = useState([])

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setShowOptions(false)
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [wrapperRef])

  const openChildrenOptions = (event, option, triggeredLevel) => {
    event.preventDefault()

    changeValueHandler(null)

    let updatedActiveOptions = activeOptions
    updatedActiveOptions = updatedActiveOptions.slice(0, triggeredLevel)
    updatedActiveOptions.push(option)

    setActiveOptions(updatedActiveOptions)
  }

  const changeValue = (event, option, level) => {
    event.preventDefault()

    setShowOptions(false)
    const newActiveOptions = activeOptions.slice(0, level)
    setActiveOptions(newActiveOptions)

    const activeOptionsValues = newActiveOptions.map((option) => option.value)

    changeValueHandler([...activeOptionsValues, option.value].join(':'))
  }

  const selectedOptionName = () => {
    const name = activeOptions.map((activeOption) => activeOption.name).join(':')

    if (selectedOptions) {
      const selectedOptionsArr = selectedOptions.split(':')
      const finalSelectedOption = selectedOptionsArr[selectedOptionsArr.length - 1]

      const selectedOptionObj =
        activeOptions.length === 0
          ? options.find((option) => option.value === finalSelectedOption)
          : activeOptions[activeOptions.length - 1].children.find((option) => option.value === finalSelectedOption)

      return name ? `${name}:${selectedOptionObj.name}` : selectedOptionObj.name
    }

    return name
  }

  const showSelectboxOptionContainer = (optionsToShow, small, level, isForChildren = false) => {
    return (
      <SelectboxOptionsContainer
        key={`selectbox-container-level-${level}`}
        isForChildren={isForChildren}
        level={level}
        small={small}
      >
        {optionsToShow.map((option) => (
          <PopoverNavigationItem key={`${option.value}-${level}`}>
            {option?.isTitle && <PopoverNavigationItemTitle>{option.name}</PopoverNavigationItemTitle>}
            {!option?.isTitle && (
              <PopoverNavigationButton
                active={activeOptions[level] && activeOptions[level].value === option.value}
                onClick={(event) =>
                  option.children ? openChildrenOptions(event, option, level) : changeValue(event, option, level)
                }
              >
                {option.name}
              </PopoverNavigationButton>
            )}
          </PopoverNavigationItem>
        ))}
      </SelectboxOptionsContainer>
    )
  }

  return (
    <FieldLabel>
      {label && <Label>{label}</Label>}
      <SelectboxContainer ref={wrapperRef} small={small}>
        <SelectboxFieldBox
          showOptions={showOptions}
          small={small}
          onClick={(event) => {
            event.preventDefault()
            setShowOptions(true)
          }}
        >
          <SelectedValue
            showOptions={showOptions}
            small={small}
            isPlaceholder={selectedOptions || activeOptions.length > 0}
          >
            {selectedOptions || activeOptions.length > 0 ? selectedOptionName() : placeholder}
          </SelectedValue>
          <ArrowIconContainer small={small}>
            <ArrowIcon src={ArrowDownIcon} alt={'Arrow icon'} />
          </ArrowIconContainer>
        </SelectboxFieldBox>
        {showOptions && (
          <>
            {showSelectboxOptionContainer(options, small, 0)}
            {activeOptions.map((activeOption, index) => {
              return showSelectboxOptionContainer(activeOption.children, small, index + 1)
            })}
          </>
        )}
      </SelectboxContainer>
    </FieldLabel>
  )
}

export default memo(SelectboxField)
