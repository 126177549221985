export const REQUEST_STATUSES = {
  NOT_TRIGGERED: 'not-triggered',
  PENDING: 'pending',
  FAILED: 'failed',
  DONE: 'done',
}

export function failed(state, action) {
  if (action.meta.aborted) {
    state.status = REQUEST_STATUSES.NOT_TRIGGERED
  } else {
    state.status = REQUEST_STATUSES.FAILED
    state.error = {
      extra: action.payload?.extra,
      message: action.payload?.message,
      internalCode: action.payload?.internalCode,
    }
  }
}

export function setStatusDone(state) {
  state.status = REQUEST_STATUSES.DONE
}

export function resetStatus(state) {
  state.status = REQUEST_STATUSES.DONE
}

export function resetErrors(state) {
  state.error = null
}
