import styled from 'styled-components'
import {
  layoutComponentsBackgroundColor,
  layoutComponentsBorderColor,
  lightGray,
  regularGray,
} from '../../../../../common/styles/colors'
import { CallToActionButton } from '../../../../../common/styles/styles'

export const NodeTypeName = styled.span`
  font-size: 12px;
  font-weight: 500;
  color: ${lightGray};
  padding-left: 8px;
`

export const Header = styled.header`
  position: relative;
  display: flex;
  align-items: center;

  padding: 0 12px;
  box-sizing: border-box;

  width: 100%;
  height: 28px;
`

export const Content = styled.div`
  position: relative;
  width: 100%;
  height: 28px;

  display: flex;
  align-items: center;

  padding: 0 12px;
  box-sizing: border-box;
`

export const NodeConfigurationsPopup = styled.div`
  position: absolute;
  top: 0;
  left: calc(50% - 240px); // Align center

  width: 480px;

  border: 1px solid ${layoutComponentsBorderColor};
  box-sizing: border-box;

  padding: 8px 0;

  background: ${layoutComponentsBackgroundColor};
  transition: border-color 275ms ease;

  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;

  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;

  z-index: 99;
`

export const SaveButton = styled(CallToActionButton)`
  margin-left: 8px;
`

// Configurations

export const NodeConfigurationContainer = styled.div`
  position: relative;
`

export const ConfigsContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`

export const ConfigContainer = styled.div`
  position: relative;
  padding: 12px;
`

export const ConfigTitle = styled.div`
  position: relative;
  font-size: 12px;
  font-weight: 600;
  color: ${regularGray};
  padding-bottom: 8px;
`

export const EditorContainer = styled.div`
  position: relative;

  height: 320px;
  max-height: 100%;
`
