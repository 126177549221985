import React, { memo, useEffect } from 'react'
import {
  changeDescription,
  changeName,
  changeRequestStatus,
  changeIsRequired,
  resetState,
  selectFormData,
} from '../../../../../../reducers/front-api/function/parameter/add-form'
import { useDispatch, useSelector } from 'react-redux'
import { createParameter } from './slice'
import { selectProject } from '../../../../../../reducers/project/project-details'
import { Clear } from '../../../../../common/styles/styles'
import { AddParameterFormContainer, ButtonsContainer, CancelButton, CreateButton, FieldsContainer } from './styles'
import TextField from '../../../../../common/form/text-field/TextField'
import Checkbox from '../../../../../common/form/checkbox/Checkbox'
import { REQUEST_STATUSES } from '../../../../../../reducers/reducer-helper'
import { FieldContainer } from '../../../../../common/styles/form-styles'
import { H1Title, SubtitleText } from '../../../../../common/styles/text'
import { ContentWrapperContainer } from '../../../../../common/layouts/content-container/styles'
import { selectRequestStatus } from '../../../../../../reducers/front-api/global-parameter/add-form'
import { closePopup, selectPopupExtraParameters } from '../../../../../../reducers/popup'
import Loading from '../../../../../common/loading/Loading'

function AddParameterForm() {
  const dispatch = useDispatch()

  const formData = useSelector(selectFormData)
  const project = useSelector(selectProject)
  const addParameterRequestStatus = useSelector(selectRequestStatus)
  const popupExtraParameters = useSelector(selectPopupExtraParameters)

  const params = {}
  popupExtraParameters.map((param) => {
    params[param.name] = param.value
  })

  const changeNameFieldValue = (event) => {
    dispatch(changeName({ name: event.target.value }))
  }

  const changeIsRequiredFieldValue = () => {
    dispatch(changeIsRequired({ isRequired: !formData.isRequired }))
  }

  const changeDescriptionFieldValue = (event) => {
    dispatch(changeDescription({ description: event.target.value }))
  }

  const createParameterHandler = (event) => {
    event.preventDefault()
    dispatch(changeRequestStatus({ status: REQUEST_STATUSES.PENDING }))
    dispatch(
      createParameter({
        projectUuid: project.uuid,
        functionUuid: params.functionUuid,
        name: formData.name,
        isRequired: formData.isRequired,
        description: formData.description,
      }),
    )
  }

  useEffect(() => {
    return () => {
      dispatch(resetState())
    }
  }, [])

  const cancelHandler = () => {
    dispatch(closePopup())
  }

  return (
    <AddParameterFormContainer>
      <ContentWrapperContainer marginTop={'8px'} marginBottom={'24px'}>
        <H1Title>Create function parameter</H1Title>
      </ContentWrapperContainer>
      <ContentWrapperContainer marginTop={'0px'} marginBottom={'32px'}>
        <SubtitleText>Please provide information about new parameter</SubtitleText>
      </ContentWrapperContainer>
      {addParameterRequestStatus === REQUEST_STATUSES.PENDING && <Loading />}
      <FieldsContainer>
        <FieldContainer>
          <fieldset>
            <TextField
              label={'Name'}
              placeholder={'new-parameter'}
              value={formData.name}
              changeEvent={changeNameFieldValue}
            />
          </fieldset>
        </FieldContainer>
        <FieldContainer>
          <fieldset>
            <TextField
              placeholder={'Small description...'}
              label={'Description'}
              value={formData.description}
              changeEvent={changeDescriptionFieldValue}
            />
          </fieldset>
        </FieldContainer>
        <FieldContainer>
          <fieldset>
            <Checkbox
              content={'Is required'}
              isEnabled={formData.isRequired}
              onClickEvent={changeIsRequiredFieldValue}
            />
          </fieldset>
        </FieldContainer>
      </FieldsContainer>

      <ButtonsContainer>
        <CreateButton type={'button'} onClick={createParameterHandler}>
          Create parameter
        </CreateButton>
        <CancelButton type={'button'} onClick={cancelHandler}>
          Cancel
        </CancelButton>
        <Clear />
      </ButtonsContainer>
    </AddParameterFormContainer>
  )
}

export default memo(AddParameterForm)
