import { createAsyncThunk } from '@reduxjs/toolkit'
import { ProjectAgent } from '../../../../../../api/projectAgent'
import { ResourceTypes } from '../../../../../../utils/utils'

export const updateRedis = createAsyncThunk(
  'project-redis-settings-form/update',
  async ({ projectUuid, size }, thunkApi) => {
    try {
      const response = await ProjectAgent.updateResource(projectUuid, ResourceTypes.REDIS, size)

      return {resource: response}
    } catch (error) {
      return thunkApi.rejectWithValue(error)
    }
  },
)

export const addRedis = createAsyncThunk('project-redis-settings-form/add', async ({ projectUuid, size }, thunkApi) => {
  try {
    const response = await ProjectAgent.addResource(projectUuid, ResourceTypes.REDIS, size)
    
    return {resource: response}
  } catch (error) {
    return thunkApi.rejectWithValue(error)
  }
})
