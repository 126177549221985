import SkServiceIcon from '../../../assets/images/small-logo.svg'
import RequestIcon from '../../../assets/images/http-request-icon.svg'
import FunctionIcon from '../../../assets/images/function-icon.svg'
import IfConditionIcon from '../../../assets/images/if-condition-icon.svg'
import SwitchIcon from '../../../assets/images/switch-icon.svg'
import DataLoopIcon from '../../../assets/images/data-loop-icon.svg'
import ForLoopIcon from '../../../assets/images/for-loop-icon.svg'
import CodeIcon from '../../../assets/images/code-icon.svg'
import TryCatchIcon from '../../../assets/images/try-catch-icon.svg'
import SuccessResultIcon from '../../../assets/images/done-icon.svg'
import ErrorResultIcon from '../../../assets/images/error-icon.svg'

export const NODES_TYPES = {
  SK_SERVICE: 'sk_service',
  REQUEST: 'requests',
  FUNCTION: 'function',
  IF_CONDITION: 'if_condition',
  SWITCH: 'switch',
  FOR_LOOP: 'for_loop',
  DATA_LOOP: 'data_loop',
  CUSTOM_CODE: 'custom_code',
  TRY_CATCH: 'try_catch',
  RESULT: 'result',
  ERROR: 'error',
}

export const NODES_ICONS = {
  [NODES_TYPES.SK_SERVICE]: SkServiceIcon,
  [NODES_TYPES.REQUEST]: RequestIcon,
  [NODES_TYPES.FUNCTION]: FunctionIcon,
  [NODES_TYPES.IF_CONDITION]: IfConditionIcon,
  [NODES_TYPES.SWITCH]: SwitchIcon,
  [NODES_TYPES.FOR_LOOP]: ForLoopIcon,
  [NODES_TYPES.DATA_LOOP]: DataLoopIcon,
  [NODES_TYPES.CUSTOM_CODE]: CodeIcon,
  [NODES_TYPES.TRY_CATCH]: TryCatchIcon,
  [NODES_TYPES.RESULT]: SuccessResultIcon,
  [NODES_TYPES.ERROR]: ErrorResultIcon,
}

export const NODES_NAMES = {
  [NODES_TYPES.SK_SERVICE]: 'Startup Kit services',
  [NODES_TYPES.REQUEST]: 'HTTP Request',
  [NODES_TYPES.FUNCTION]: 'Function',
  [NODES_TYPES.IF_CONDITION]: 'If condition',
  [NODES_TYPES.SWITCH]: 'Switch',
  [NODES_TYPES.FOR_LOOP]: 'For loop',
  [NODES_TYPES.DATA_LOOP]: 'Data loop',
  [NODES_TYPES.CUSTOM_CODE]: 'Custom code',
  [NODES_TYPES.TRY_CATCH]: 'Try-catch',
  [NODES_TYPES.RESULT]: 'Success result',
  [NODES_TYPES.ERROR]: 'Error result',
}

export const NODES_DESCRIPTION = {
  [NODES_TYPES.SK_SERVICE]: 'Startup Kit services',
  [NODES_TYPES.REQUEST]: 'Configure HTTP request here',
  [NODES_TYPES.FUNCTION]: 'Function',
  [NODES_TYPES.IF_CONDITION]: 'If condition',
  [NODES_TYPES.SWITCH]: 'Switch',
  [NODES_TYPES.FOR_LOOP]: 'For loop',
  [NODES_TYPES.DATA_LOOP]: 'Data loop',
  [NODES_TYPES.CUSTOM_CODE]: 'Custom code',
  [NODES_TYPES.TRY_CATCH]: 'Try-catch',
  [NODES_TYPES.RESULT]: 'Success result',
  [NODES_TYPES.ERROR]: 'Error result',
}

export const AUTHENTICATION_METHODS = {
  NO_AUTHENTICATION: 'no_authentication',
  BASIC_AUTH: 'basic_auth',
  BEARER_TOKEN: 'bearer_token',
}

export const AUTHENTICATION_METHODS_NAMES = {
  [AUTHENTICATION_METHODS.NO_AUTHENTICATION]: 'No Authentication',
  [AUTHENTICATION_METHODS.BASIC_AUTH]: 'Basic Auth',
  [AUTHENTICATION_METHODS.BEARER_TOKEN]: 'Bearer Token',
}

export const HTTP_METHODS = {
  GET: 'get',
  POST: 'post',
  PATCH: 'patch',
  PUT: 'put',
  DELETE: 'delete',
  OPTIONS: 'options',
}

export const HTTP_METHODS_NAMES = {
  [HTTP_METHODS.GET]: 'GET',
  [HTTP_METHODS.POST]: 'POST',
  [HTTP_METHODS.PATCH]: 'PATCH',
  [HTTP_METHODS.PUT]: 'PUT',
  [HTTP_METHODS.DELETE]: 'DELETE',
  [HTTP_METHODS.OPTIONS]: 'OPTIONS',
}

export const BODY_FORMATS = {
  JSON: 'json',
  HTML: 'html',
  XML: 'xml',
  FORM_URL: 'form_url',
  FORM_DATA: 'form_data',
  CUSTOM: 'custom',
}

export const BODY_FORMATS_NAMES = {
  [BODY_FORMATS.JSON]: 'JSON - (application/json)',
  [BODY_FORMATS.HTML]: 'HTML',
  [BODY_FORMATS.XML]: 'XML',
  [BODY_FORMATS.FORM_URL]: 'Form URL - (application/x-www-form-urlencoded)',
  [BODY_FORMATS.FORM_DATA]: 'Form Data - (multipart/form-data)',
  [BODY_FORMATS.CUSTOM]: 'Custom',
}

export const PARAMETERS_FOR_INSERT_SOURCES = {
  GLOBAL_PARAMETERS: 'global',
  FUNCTION: 'function',
  CHAIN: 'chain',
}

export const PARAMETERS_FOR_INSERT_SOURCES_NAMES = {
  [PARAMETERS_FOR_INSERT_SOURCES.GLOBAL_PARAMETERS]: 'Global parameters',
  [PARAMETERS_FOR_INSERT_SOURCES.FUNCTION]: 'Function input parameters',
  [PARAMETERS_FOR_INSERT_SOURCES.CHAIN]: 'Chain',
}

export const EDITOR_LANGUAGES_HIGHLIGHT = {
  JSON: 'json',
  HTML: 'html',
  XML: 'xml',
}
