import styled from 'styled-components'
import { lightSilver } from './colors'
import { CallToActionButton } from './styles'

export const FormContainer = styled.form`
  position: relative;
`

export const FieldContainer = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: 24px;
`

export const TextField = styled.input`
  position: relative;
  width: 100%;
  height: 40px;
  line-height: 40px;
  padding: 0 12px;
  background: #fdfdfd;
  border: solid 1px #efefef;
  box-sizing: border-box;

  margin-top: 4px;

  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;

  font-size: 14px;
  font-weight: 400;

  transition: border 275ms ease;

  &::-webkit-input-placeholder {
    /* Edge */
    color: ${lightSilver};
  }

  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: ${lightSilver};
  }

  &::placeholder {
    color: ${lightSilver};
  }

  &:hover,
  &:focus {
    border: solid 1px ${lightSilver};
  }
`

export const ButtonContainer = styled.div`
  position: relative;
  width: 100%;
  margin-top: 24px;
  height: 40px;
`

export const ButtonWithTextContainer = styled.div`
  position: relative;
`

export const ButtonWithTextLeftBlock = styled.div`
  position: relative;
  float: left;
  text-align: right;
  width: 188px;
`

export const ButtonWithTextRightBlock = styled.div`
  position: relative;
  float: right;
  width: 160px;
  height: 40px;
`

export const ButtonWithText = styled(CallToActionButton)`
  width: 160px;
`

export const ButtonWithTextCenter = styled(CallToActionButton)`
  width: 160px;
  justify-content: center;
  margin: 0 auto;
`
