import styled from 'styled-components'
import { lightGray, lightSilver } from '../../styles/colors'
import { PopoverContainer } from '../../popover/styles'

export const SelectboxContainer = styled.div`
  position: relative;
  width: 100%;

  margin-top: ${(props) => (props.small ? '0' : '4px')};
`

export const SelectboxFieldBox = styled.div`
  position: relative;
  width: 100%;
  height: ${(props) => (props.small ? '32px' : '40px')};
  line-height: ${(props) => (props.small ? '32px' : '40px')};
  padding: 0 12px;
  background: #fdfdfd;
  border: solid 1px #efefef;
  box-sizing: border-box;

  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;

  transition: border 275ms ease;

  &:hover {
    border: solid 1px ${lightSilver};
    cursor: pointer;
  }
`

export const SelectedValue = styled.div`
  position: relative;

  font-size: 12px;
  font-weight: 600;
  line-height: ${(props) => (props.small ? '30px' : '38px')};

  color: ${lightGray};
`

export const ArrowIconContainer = styled.div`
  position: absolute;
  width: 12px;
  height: 8px;

  line-height: 0;

  right: 16px;
  top: ${(props) => (props.small ? '12px' : '16px')};
`

export const ArrowIcon = styled.img`
  position: relative;
  width: 12px;
  height: 8px;
`

export const SelectboxOptionsContainer = styled(PopoverContainer)`
  position: absolute;
  top: ${(props) => {
    if (props.isForChildren) {
      return '32px'
    }
    return props.small ? '32px' : '40px'
  }};
  left: ${(props) => {
    return props.level ? `${128 * props.level}px` : '0'
  }};
  width: 100%;
  max-width: 420px;
  max-height: 320px;

  overflow-y: scroll;
`
