import React, { memo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { closePopup, selectPopupExtraParameters } from '../../../../reducers/popup'
import { selectProject } from '../../../../reducers/project/project-details'
import { REQUEST_STATUSES } from '../../../../reducers/reducer-helper'
import { deleteApiKey } from './slice'
import { changeRequestStatus, selectRequestStatus } from '../../../../reducers/front-api/api-keys/api-keys'
import ConfirmPopup from '../../../common/confirm-popup/ConfirmPopup'

function DeleteApiKeyPopup() {
  const dispatch = useDispatch()

  const project = useSelector(selectProject)
  const popupExtraParameters = useSelector(selectPopupExtraParameters)
  const requestStatus = useSelector(selectRequestStatus)

  const params = {}
  popupExtraParameters.map((param) => {
    params[param.name] = param.value
  })

  const deleteKeyHandler = (event, uuid) => {
    event.preventDefault()

    dispatch(changeRequestStatus({ status: REQUEST_STATUSES.PENDING }))
    dispatch(deleteApiKey({ projectUuid: project.uuid, uuid: params.apiKeyUuid }))
  }

  const cancel = (event) => {
    event.preventDefault()
    dispatch(closePopup())
  }

  return (
    <ConfirmPopup
      title={'Delete parameter'}
      subtitle={'Are you sure you want to delete this API key?'}
      cancelHandler={cancel}
      deleteParameterHandler={deleteKeyHandler}
      confirmButtonText={'Delete'}
      requestStatus={requestStatus}
    />
  )
}

export default memo(DeleteApiKeyPopup)
