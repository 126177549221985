import styled from 'styled-components'

export const ContentContainer = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  top: 0;
  width: 100%;
`

export const TopContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 192px;
  width: 100%;
`

export const LogoContainer = styled.div`
  position: relative;
  width: 234px;
  height: 32px;
  margin: 0 auto;
  margin-top: 80px;
`

export const MiddleContainer = styled.div`
  position: absolute;
  left: 0;
  width: 100%;
  top: 192px;
  bottom: 80px;
  padding-top: 80px;
  box-sizing: border-box;
`

export const BottomContainer = styled.div`
  position: absolute;
  left: 0;
  width: 100%;
  height: 80px;
  bottom: 0;
`

export const MiddleFormTopContainer = styled.div`
  position: relative;
`

export const MiddleFormPageTitleContainer = styled.div`
  position: relative;
`

export const MiddleFormPageSubtitleContainer = styled.div`
  position: relative;
  margin-top: 16px;
`

export const FormContainer = styled.div`
  position: relative;
  margin-top: 32px;
`
