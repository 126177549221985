import React, { memo } from 'react'
import { ButtonContainer } from '../../common/styles/form-styles'
import {
  FormContainer as FormContentContainer,
  MiddleFormPageSubtitleContainer,
  MiddleFormPageTitleContainer,
  MiddleFormTopContainer,
} from '../../common/layouts/public-area/middle-form/styles'
import { H1TitleWithButton, SubtitleText } from '../../common/styles/text'
import PublicAreaMiddleFormLayout from '../../common/layouts/public-area/middle-form/PublicAreaMiddleFormLayout'
import { Clear, SecondaryActionLink } from '../../common/styles/styles'
import BackLink from '../../common/back-link/BackLink'

function Success() {
  return (
    <PublicAreaMiddleFormLayout>
      <MiddleFormTopContainer>
        <MiddleFormPageTitleContainer>
          <BackLink link={'/sign-in'} />
          <H1TitleWithButton>Forgot password?</H1TitleWithButton>
          <Clear />
        </MiddleFormPageTitleContainer>
        <MiddleFormPageSubtitleContainer>
          <SubtitleText>Your password has been successfully changed.</SubtitleText>
        </MiddleFormPageSubtitleContainer>
      </MiddleFormTopContainer>

      <FormContentContainer>
        <ButtonContainer>
          <SecondaryActionLink to={'/sign-in'} type="submit">
            Sign In
          </SecondaryActionLink>
        </ButtonContainer>
      </FormContentContainer>
    </PublicAreaMiddleFormLayout>
  )
}

export default memo(Success)
