import styled from 'styled-components'
import { lightGray } from '../../../common/styles/colors'

export const MoreButton = styled.button`
  width: 24px;
  height: 24px;

  display: ${(props) => (props.active ? 'block' : 'none')};

  right: 0;

  line-height: 0;
  border: none;
  background: none;
  padding: 0;
  margin: 0;

  &:hover {
    cursor: pointer;

    & svg {
      path {
        fill: ${lightGray};
      }
    }
  }
`
