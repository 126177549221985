import styled from 'styled-components'
import { lightGray, regularGray } from '../../../../common/styles/colors'

export const ButtonText = styled.div`
  padding-top: 8px;
  font-size: 13px;
  font-weight: 600;
  color: ${lightGray};

  transition: color 275ms ease;
`

export const AddNodeButtonComponent = styled.button`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  background: none;
  padding: 12px;
  margin: 0;
  border: none;

  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;

  z-index: 98;

  &:hover {
    cursor: pointer;

    ${ButtonText} {
      color: ${regularGray};
    }

    svg {
      path {
        fill: ${regularGray};
      }
    }
  }
`
