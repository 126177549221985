import { requests } from './agent'

export const FrontApiAgent = {
  getFrontApiData: (projectUuid) => {
    return requests.get(`/1_0/projects/${projectUuid}/front-api`)
  },
  createFunctionResultObject: (projectUuid, functionUuid) => {
    return requests.post(`/1_0/projects/${projectUuid}/front-api/actions/${functionUuid}/result-object/create`)
  },
  cloneResultObjectForFunction: (projectUuid, functionUuid, sourceResultObjectUuid) => {
    return requests.post(`/1_0/projects/${projectUuid}/front-api/actions/${functionUuid}/result-object/clone`, {
      sourceResultObjectUuid,
    })
  },
  createResultObjectParameter: (projectUuid, resultObjectUuid, name, type, parentUuid, description) => {
    return requests.post(
      `/1_0/projects/${projectUuid}/front-api/result-objects/${resultObjectUuid}/parameters/create`,
      {
        name,
        type,
        description,
        parentUuid,
      },
    )
  },
  updateResultObjectParameter: (projectUuid, resultObjectUuid, parameterUuid, name, description) => {
    return requests.post(
      `/1_0/projects/${projectUuid}/front-api/result-objects/${resultObjectUuid}/parameters/${parameterUuid}/update`,
      {
        name,
        description,
      },
    )
  },
  deleteResultObjectParameter: (projectUuid, resultObjectUuid, parameterUuid) => {
    return requests.post(
      `/1_0/projects/${projectUuid}/front-api/result-objects/${resultObjectUuid}/parameters/${parameterUuid}/delete`,
    )
  },
  createGlobalParameter: (projectUuid, name, value, description) => {
    return requests.post(`/1_0/projects/${projectUuid}/front-api/global-parameters/create`, {
      name,
      value,
      description,
    })
  },
  updateGlobalParameter: (projectUuid, parameterUuid, name, value, description) => {
    return requests.post(`/1_0/projects/${projectUuid}/front-api/global-parameters/${parameterUuid}/update`, {
      name,
      value,
      description,
    })
  },
  deleteGlobalParameter: (projectUuid, parameterUuid) => {
    return requests.post(`/1_0/projects/${projectUuid}/front-api/global-parameters/${parameterUuid}/delete`)
  },
  createFunction: (projectUuid, parentUuid, name) => {
    return requests.post(`/1_0/projects/${projectUuid}/front-api/actions/create`, { name, parentUuid })
  },
  renameFunction: (projectUuid, actionUuid, name) => {
    return requests.post(`/1_0/projects/${projectUuid}/front-api/actions/${actionUuid}/update-name`, { name })
  },
  updateFunctionDescription: (projectUuid, actionUuid, description) => {
    return requests.post(`/1_0/projects/${projectUuid}/front-api/actions/${actionUuid}/update-description`, {
      description,
    })
  },
  createFunctionParameter: (projectUuid, actionUuid, name, isRequired, description) => {
    return requests.post(`/1_0/projects/${projectUuid}/front-api/actions/${actionUuid}/parameters/create`, {
      name,
      isRequired,
      description,
    })
  },
  updateFunctionParameter: (projectUuid, actionUuid, parameterUuid, name, isRequired, description) => {
    return requests.post(
      `/1_0/projects/${projectUuid}/front-api/actions/${actionUuid}/parameters/${parameterUuid}/update`,
      {
        name,
        isRequired,
        description,
      },
    )
  },
  deleteFunctionParameter: (projectUuid, actionUuid, parameterUuid) => {
    return requests.post(
      `/1_0/projects/${projectUuid}/front-api/actions/${actionUuid}/input-parameters/${parameterUuid}/create`,
    )
  },
  updateFunctionFlow: () => {
    return requests.post(`/1_0/projects/${projectUuid}/front-api/actions/${actionUuid}/flow/update`)
  },
  deleteFunction: (projectUuid, actionUuid) => {
    return requests.post(`/1_0/projects/${projectUuid}/front-api/actions/${actionUuid}/delete`)
  },
  createFunctionsFolder: (projectUuid, parentUuid, name) => {
    return requests.post(`/1_0/projects/${projectUuid}/front-api/actions/folders/create-folder`, { parentUuid, name })
  },
  renameTreeFolder: (projectUuid, folderUuid, name) => {
    return requests.post(`/1_0/projects/${projectUuid}/front-api/tree-folders/${folderUuid}/rename`, { name })
  },
  deleteFunctionsFolder: (projectUuid, folderUuid) => {
    return requests.post(`/1_0/projects/${projectUuid}/front-api/actions/folders/${folderUuid}/delete`)
  },
  updateTreeFolderChildren: (projectUuid, folderUuid, newChildren) => {
    return requests.post(`/1_0/projects/${projectUuid}/front-api/tree-folders/${folderUuid}/update-children`, {
      newChildren,
    })
  },
  deleteFunctionsCollection: (projectUuid, collectionUuid) => {
    return requests.post(`/1_0/projects/${projectUuid}/front-api/actions/collections/${collectionUuid}/delete`)
  },
  createFunctionsCollection: (projectUuid, name) => {
    return requests.post(`/1_0/projects/${projectUuid}/front-api/actions/folders/create-collection`, { name })
  },
  updateFunctionCollectionDescription: (projectUuid, collectionUuid, description) => {
    return requests.post(
      `/1_0/projects/${projectUuid}/front-api/actions/collections/${collectionUuid}/update-description`,
      {
        description,
      },
    )
  },
}
