import React, { memo } from 'react'
import { SecondaryActionButton } from '../../../../../../common/styles/styles'
import { SaveButton } from '../styles'
import { FlexContainer } from '../../../../../../common/layouts/content-container/styles'

function FooterButtons({ cancelHandler, saveHandler }) {
  return (
    <FlexContainer>
      <SecondaryActionButton onClick={cancelHandler}>Cancel</SecondaryActionButton>
      <SaveButton onClick={saveHandler}>Save changes</SaveButton>
    </FlexContainer>
  )
}

export default memo(FooterButtons)
