import { createAsyncThunk } from '@reduxjs/toolkit'
import { ProjectAgent } from '../../../../../../api/projectAgent'
import { ResourceTypes } from '../../../../../../utils/utils'

export const addDatabase = createAsyncThunk(
  'project-database-settings-form/add',
  async ({ projectUuid, size }, thunkApi) => {
    try {
      const response = await ProjectAgent.addResource(projectUuid, ResourceTypes.DB, size)

      return {resource: response}
    } catch (error) {
      return thunkApi.rejectWithValue(error)
    }
  },
)

export const updateDatabase = createAsyncThunk(
  'project-database-settings-form/update',
  async ({ projectUuid, size }, thunkApi) => {
    try {
      const response = await ProjectAgent.updateResource(projectUuid, ResourceTypes.DB, size)

      return {resource: response}
    } catch (error) {
      return thunkApi.rejectWithValue(error)
    }
  },
)
