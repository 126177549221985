import styled from 'styled-components'
import {
  layoutComponentsBorderColor,
  lightGray,
  lightSilver,
  regularGray,
  superLightSilver,
} from '../../../../common/styles/colors'

export const AddNodePopupComponent = styled.div`
  position: absolute;
  top: 100%;
  left: calc(-100px + 50%);
  display: flex;
  flex-direction: column;
  max-height: 360px;
  overflow-y: scroll;

  width: 200px;
  border: 1px solid ${layoutComponentsBorderColor};

  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;

  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
`

export const TopContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const BottomContainer = styled.div`
  width: 100%;
  height: 24px;
  border-top: 1px solid ${layoutComponentsBorderColor};
  background: ${layoutComponentsBorderColor};
`

export const MoreButton = styled.button`
  width: 100%;
  height: 100%;
  background: ${superLightSilver};
  padding: 0;
  margin: 0;
  border: 0;

  font-size: 13px;
  font-weight: 600;
  color: ${lightGray};

  transition: background-color 275ms ease, color 275ms ease;

  &:hover {
    cursor: pointer;
    background: ${lightSilver};
    color: ${regularGray};
  }
`

export const ItemName = styled.div`
  position: relative;
  font-size: 13px;
  font-weight: 500;
  padding-left: 8px;
  color: ${lightGray};

  transition: color 275ms ease;
`

export const ItemButton = styled.button`
  position: relative;
  width: 100%;
  height: 32px;

  display: flex;
  justify-content: left;
  align-items: center;

  padding: 0 12px;
  box-sizing: border-box;

  background: none;

  border: none;

  transition: background-color 275ms ease;

  &:hover {
    cursor: pointer;
    background: ${superLightSilver};

    ${ItemName} {
      color: ${regularGray};
    }

    svg {
      path {
        fill: ${regularGray};
      }
    }
  }
`
