import styled from 'styled-components'

export const ContentComponent = styled.div`
  position: relative;
  width: 186px;
  height: 32px;
`

export const LogoImageComponent = styled.img`
  position: relative;
  width: 190px;
  height: 32px;
`
