import { createSlice } from '@reduxjs/toolkit'
import { failed, resetErrors, resetStatus } from './../reducer-helper'
import { getServiceDetails } from '../../components/service/overview/slice'

const initialState = {
  service: null,
  error: null,
  status: null,
}

const serviceDetailsSlice = createSlice({
  name: 'serviceDetails',
  initialState,
  reducers: {
    changeRequestStatus: (state, action) => {
      state.status = action.payload.status
    },
    serviceUnloaded: () => initialState,
  },
  extraReducers(builder) {
    builder.addCase(getServiceDetails.fulfilled, (state, action) => {
      state.service = action.payload.service
      resetStatus(state)
      resetErrors(state)
    })
    builder.addCase(getServiceDetails.rejected, failed)
  },
})

const selectServiceDetailsSlice = (state) => state.serviceDetails
export const selectRequestStatus = (state) => selectServiceDetailsSlice(state).status
export const selectServiceDetails = (state) => selectServiceDetailsSlice(state).service

const { reducer, actions } = serviceDetailsSlice

export const { changeRequestStatus, serviceUnloaded } = actions

export default reducer
