import { createSlice } from '@reduxjs/toolkit'
import { initApp } from '../components/app/slice'
import { Status } from '../utils/utils'
import { signIn } from '../components/user/sign-in/slice'
import { createProfile } from '../components/user/sign-up/slice'
import { logout } from '../components/common/layouts/private-area/header/user-popover/slice'

const initialState = {
  appLoaded: false,
  status: Status.IDLE,
  redirectTo: undefined,
  error: null,
}

function success(state) {
  state.appLoaded = true
  // state.redirectTo = '/dashboard'
  state.status = Status.SUCCESS
  delete state.errors
}

function failed(state) {
  state.appLoaded = true
  state.status = Status.FAILURE
}

const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    clearRedirect(state) {
      delete state.redirectTo
    },
  },
  extraReducers(builder) {
    builder.addCase(initApp.fulfilled, success)

    builder.addCase(initApp.rejected, failed)

    builder.addCase(signIn.fulfilled, (state, action) => {
      state.redirectTo = '/dashboard'
    })

    builder.addCase(createProfile.fulfilled, (state, action) => {
      state.redirectTo = '/dashboard'
    })

    builder.addCase(logout.fulfilled, (state, action) => {
      state.redirectTo = '/sign-in'
    })

    builder.addMatcher(
      (action) => /common\/.*\/pending/.test(action.type),
      (state) => {
        state.status = Status.LOADING
      },
    )
  },
})

const { reducer, actions } = commonSlice

export const { clearRedirect } = actions

export default reducer
