import { createAsyncThunk } from '@reduxjs/toolkit'
import { ProjectAgent } from '../../../api/projectAgent'

export const getProjectApiKeys = createAsyncThunk('project-api-keys/get-all', async ({ projectUuid }, thunkApi) => {
  try {
    const keys = await ProjectAgent.getProjectApiKeys(projectUuid)

    return { keys }
  } catch (error) {
    return thunkApi.rejectWithValue(error)
  }
})

export const addApiKey = createAsyncThunk('project-api-keys/add-key', async ({ projectUuid }, thunkApi) => {
  try {
    const key = await ProjectAgent.addApiKey(projectUuid)

    return {apiKey: key.apiKey, createdKey: key.apiKeyValue}
  } catch (error) {
    return thunkApi.rejectWithValue(error)
  }
})
