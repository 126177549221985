import { createAsyncThunk } from '@reduxjs/toolkit'
import { ProjectAgent } from '../../../../../api/projectAgent'

export const updateGeneralSettings = createAsyncThunk(
  'project-general-settings-form/update',
  async ({ uuid, name }, thunkApi) => {
    try {
      await ProjectAgent.updateGeneralSettings(uuid, name)

      return { name }
    } catch (error) {
      return thunkApi.rejectWithValue(error)
    }
  },
)

export const updateProjectImage = createAsyncThunk(
  'project-general-settings-form/update-image',
  async ({ projectUuid, image }, thunkApi) => {
    try {
      const uploadedImage = await ProjectAgent.updateProjectImage(projectUuid, image)

      return { image: uploadedImage }
    } catch (error) {
      return thunkApi.rejectWithValue(error)
    }
  },
)

export const deleteProjectImage = createAsyncThunk(
  'project-general-settings-form/delete-image',
  async ({ projectUuid }, thunkApi) => {
    try {
      await ProjectAgent.deleteProjectImage(projectUuid)
    } catch (error) {
      return thunkApi.rejectWithValue(error)
    }
  },
)
