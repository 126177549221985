import React, { memo, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  changeDescription,
  changeName,
  changeType,
  resetState,
  selectFormData,
} from '../../../../../reducers/front-api/json-schema-builder/add-parameter-form'
import { TableContainer, TableForm, TableFormFieldContainer, TableRow } from '../../../../common/table/styles'
import { DescriptionColumn, FormButtonsContainer, IconButtonContainer, NameColumn, TypeColumn } from '../../styles'
import { TextInputForList } from '../../../../common/form/text-field/styles'
import SelectboxField from '../../../../common/form/selectbox/SelectboxField'
import IconButton from '../../../../common/icon-button/IconButton'
import { lightGray, mainBlue, superLightSilver } from '../../../../common/styles/colors'
import DoneIcon from '../../../../../assets/images/done-icon.svg'
import CancelIcon from '../../../../../assets/images/delete-icon.svg'

function AddParameterForm({ typesForSelectbox, cancelCreation, parentUuid, addParameterHandler }) {
  const dispatch = useDispatch()
  const formData = useSelector(selectFormData)

  const addParameterProcess = (event) => {
    event.preventDefault()

    addParameterHandler({
      formData: {
        name: formData.name,
        type: formData.type,
        description: formData.description,
        parentUuid: parentUuid === 'root' ? null : parentUuid,
      },
    })
    cancelAddingHandler(event)
  }

  const cancelAddingHandler = (event) => {
    event.preventDefault()

    dispatch(resetState())
    cancelCreation()
  }

  const changeNameHandler = (event) => {
    event.preventDefault()
    dispatch(changeName({ name: event.target.value }))
  }

  const changeTypeHandler = (type) => {
    dispatch(changeType({ type }))
  }

  const changeDescriptionHandler = (event) => {
    event.preventDefault()
    dispatch(changeDescription({ description: event.target.value }))
  }

  useEffect(() => {
    return () => {
      dispatch(resetState())
    }
  }, [])

  return (
    <TableContainer width={'100%'}>
      <TableForm>
        <TableRow>
          <NameColumn>
            <TextInputForList
              value={formData.name}
              type="text"
              name="name"
              placeholder="Parameter name"
              onChange={changeNameHandler}
            />
          </NameColumn>

          <TypeColumn>
            <SelectboxField
              selectedValue={formData.type}
              placeholder={'Type'}
              options={typesForSelectbox}
              small={true}
              changeValueHandler={changeTypeHandler}
            />
          </TypeColumn>

          <DescriptionColumn>
            <TextInputForList
              value={formData.description}
              type="text"
              name="description"
              placeholder="Short description"
              onChange={changeDescriptionHandler}
            />
          </DescriptionColumn>

          <FormButtonsContainer>
            <TableFormFieldContainer width={'44px'}>
              <IconButtonContainer>
                <IconButton
                  icon={DoneIcon}
                  iconColor={'#FFFFFF'}
                  backgroundColor={superLightSilver}
                  iconHoverColor={superLightSilver}
                  backgroundHoverColor={mainBlue}
                  action={addParameterProcess}
                  width={12}
                  height={12}
                />
              </IconButtonContainer>
            </TableFormFieldContainer>
            <TableFormFieldContainer width={'32px'}>
              <IconButtonContainer>
                <IconButton
                  icon={CancelIcon}
                  iconColor={superLightSilver}
                  backgroundColor="none"
                  iconHoverColor={lightGray}
                  backgroundHoverColor="none"
                  width={12}
                  height={12}
                  action={cancelAddingHandler}
                />
              </IconButtonContainer>
            </TableFormFieldContainer>
          </FormButtonsContainer>
        </TableRow>
      </TableForm>
    </TableContainer>
  )
}

export default memo(AddParameterForm)
