import React, { memo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  FormContainer as FormContentContainer,
  MiddleFormPageSubtitleContainer,
  MiddleFormPageTitleContainer,
} from '../../common/layouts/public-area/middle-form/styles'
import BackLink from '../../common/back-link/BackLink'
import { H1TitleWithButton, SubtitleText } from '../../common/styles/text'
import { Clear, Content360 } from '../../common/styles/styles'
import { ButtonContainer, ButtonWithTextCenter, FieldContainer, FormContainer } from '../../common/styles/form-styles'
import TextField from '../../common/form/text-field/TextField'
import { changeName, changeStep, selectProjectInformation, changeImage } from '../../../reducers/project/project-create-form'
import { MiddleFormContainer, ProjectImageContainer } from './styles'
import UploadImageWithPreview from '../../common/form/upload-image-with-preview/UploadImageWithPreview'

function ProjectInformation({ existingImage, changeImageHandler }) {
  const dispatch = useDispatch()

  const projectInformation = useSelector(selectProjectInformation)

  const changeNameProcess = (event) => {
    event.preventDefault()
    dispatch(changeName({ name: event.target.value }))
  }

  const changeStepProcess = (step) => {
    dispatch(changeStep({ step }))
  }

  const changeImageProcess = (image) => {
    changeImageHandler(image)
  }

  return (
    <Content360>
      <MiddleFormContainer>
        <MiddleFormPageTitleContainer>
          <BackLink link={'/dashboard'} />
          <H1TitleWithButton>Create project</H1TitleWithButton>
          <Clear />
        </MiddleFormPageTitleContainer>
        <MiddleFormPageSubtitleContainer>
          <SubtitleText>Please provide a bit of information your project</SubtitleText>
        </MiddleFormPageSubtitleContainer>
      </MiddleFormContainer>

      <FormContentContainer>
        <FormContainer
          onSubmit={(event) => {
            event.preventDefault()
            changeStepProcess(2)
          }}
        >
          <ProjectImageContainer>
            <UploadImageWithPreview
              previewImage={existingImage}
              changeImageHandler={changeImageProcess}
              deleteImageHandler={() => {
                changeImageProcess(null)
              }}
            />
          </ProjectImageContainer>

          <FieldContainer>
            <fieldset>
              <TextField
                label={'Project name'}
                value={projectInformation.name}
                changeEvent={changeNameProcess}
                placeholder={'My new project'}
              />
            </fieldset>
          </FieldContainer>

          <ButtonContainer>
            <ButtonWithTextCenter type="submit">Continue</ButtonWithTextCenter>
          </ButtonContainer>
        </FormContainer>
      </FormContentContainer>
    </Content360>
  )
}

export default memo(ProjectInformation)
