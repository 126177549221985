import React, { memo, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectProject } from '../../../../../reducers/project/project-details'
import { REQUEST_STATUSES } from '../../../../../reducers/reducer-helper'
import { FieldContainer, RawInput } from '../styles'

function CreateForm({ placeholder, name, changeName, parentUuid, parentIndex, changeRequestStatus, resetState, createProcess }) {
  const dispatch = useDispatch()

  const project = useSelector(selectProject)

  const changeNameFieldValue = (event) => {
    dispatch(changeName({ name: event.target.value }))
  }

  const handleNameFieldFocus = (event) => {
    return event.target.select()
  }

  const createHandler = (event) => {
    event.preventDefault()
    dispatch(changeRequestStatus({ status: REQUEST_STATUSES.PENDING }))
    dispatch(
      createProcess({
        parentUuid,
        parentIndex,
        projectUuid: project.uuid,
        name,
      }),
    )
  }

  const handleKeyPress = (event) => {
    if(event.key === 'Enter'){
      createHandler(event)
    }
  }

  useEffect(() => {
    return () => {
      dispatch(resetState())
    }
  }, [])

  return (
    <FieldContainer>
      <RawInput
        placeholder={placeholder}
        value={name}
        onChange={changeNameFieldValue}
        onFocus={handleNameFieldFocus}
        onKeyUp={handleKeyPress}
        autoFocus
        onBlur={createHandler}
      />
    </FieldContainer>
  )
}

export default memo(CreateForm)
