export const darkGray = `#262626`
export const regularGray = `#373737`
export const lightGray = `#9d9d9d`
export const lightSilver = `#dadada`
export const superLightSilver = `#efefef`
export const mainBlue = `#0783DC`
export const lightBlue = `#7DB6DF`
export const layoutComponentsBackgroundColor = `#FAFBFC`
export const layoutComponentsBorderColor = `#E4E4E4`
export const greenTextColor = `#748f5e`
export const yellowTextColor = `#ebb700`
export const orangeTextColor = `#FF974D`
export const redTextColor = `#AC5D5D`
export const lightRedColor = `#e19191`

export const iconGray = `#767676`
