import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  formData: {
    name: '',
    type: null,
    description: '',
  },
  error: null,
  status: null,
}

const AddJsonSchemaBuilderParameterFormSlice = createSlice({
  name: 'frontApiJsonSchemaBuilderAddParameterForm',
  initialState,
  reducers: {
    resetState: () => initialState,
    changeName: (state, action) => {
      state.formData.name = action.payload.name
    },
    changeType: (state, action) => {
      state.formData.type = action.payload.type
    },
    changeDescription: (state, action) => {
      state.formData.description = action.payload.description
    },
    changeRequestStatus: (state, action) => {
      state.status = action.payload.status
    },
  },
})

const currentSlice = (state) => state.frontApiJsonSchemaBuilderAddParameterForm
export const selectRequestStatus = (state) => currentSlice(state).status
export const selectFormData = (state) => currentSlice(state).formData

const { reducer, actions } = AddJsonSchemaBuilderParameterFormSlice

export const { resetState, changeRequestStatus, changeName, changeType, changeDescription } = actions

export default reducer
