import { createSlice } from '@reduxjs/toolkit'
import { getFrontApiData } from '../../components/front-api/slice'
import { resetErrors, resetStatus } from '../reducer-helper'
import { createGlobalParameter } from '../../components/front-api/global-parameters/add-parameter-form/slice'
import { deleteGlobalParameter } from '../../components/front-api/global-parameters/delete-popup/slice'
import { updateFunctionsFolderChildren } from '../../components/front-api/functions/sidebar/slice'
import { updateFunctionNameHeader } from '../../components/front-api/functions/details/header/slice'
import { updateFunctionDescription } from '../../components/front-api/functions/details/overview/slice'
import { updateGlobalParameter } from '../../components/front-api/global-parameters/edit-parameter-form/slice'
import { createFunctionsCollection } from '../../components/front-api/functions/sidebar/forms/create-collection/slice'
import { createFunctionsFolder } from '../../components/front-api/functions/sidebar/forms/create-folder/slice'
import { createFunction } from '../../components/front-api/functions/sidebar/forms/create-function/slice'
import { renameFunctionFolder } from '../../components/front-api/functions/sidebar/forms/rename-folder-form/slice'
import { renameFunction } from '../../components/front-api/functions/sidebar/forms/rename/slice'
import { FolderTreeItemTypes } from '../../utils/utils'
import { createParameter } from '../../components/front-api/functions/details/parameters/add-parameter-form/slice'
import { updateParameter } from '../../components/front-api/functions/details/parameters/edit-parameter-form/slice'
import {
  cloneFunctionResultObject,
  createFunctionResultObject,
  createResultObjectParameter,
} from '../../components/front-api/functions/details/result-object/slice'

const initialState = {
  globalParameters: [],
  functions: {
    functions: [],
    folders: {},
  },
  resultObjects: [],
  updatedName: null,
  error: null,
  status: null,
}

const renameFunctionMethod = (state, action) => {
  const functionIndex = state.functions.functions.findIndex(
    (functionObj) => functionObj.uuid === action.payload.functionUuid,
  )

  if (functionIndex !== -1) {
    state.functions.functions[functionIndex].name = action.payload.name
    state.updatedName = {
      index: `${FolderTreeItemTypes.FUNCTION}:${action.payload.functionUuid}`,
      name: action.payload.name,
    }
  }
}

const createFunctionResultObjectProcess = (state, action) => {
  const functionIndex = state.functions.functions.findIndex(
    (functionObj) => functionObj.uuid === action.payload.functionUuid,
  )

  if (functionIndex !== -1) {
    state.resultObjects.push(action.payload.resultObject)
    state.functions.functions[functionIndex].resultObjectUuid = action.payload.resultObject.uuid
  }
}

const frontApiSlice = createSlice({
  name: 'frontApi',
  initialState,
  reducers: {
    resetState: () => initialState,
    resetUpdatedName: (state) => {
      state.updatedName = null
    },
    changeRequestStatus: (state, action) => {
      state.status = action.payload.status
    },
  },
  extraReducers(builder) {
    builder.addCase(getFrontApiData.fulfilled, (state, action) => {
      state.globalParameters = action.payload.globalParameters
      state.functions.functions = action.payload.functions.functions
      state.functions.folders = action.payload.functions.folders
      state.resultObjects = action.payload.resultObjects

      resetStatus(state)
      resetErrors(state)
    })
    builder.addCase(getFrontApiData.rejected, (state) => {
      resetStatus(state)
      resetErrors(state)
    })

    // Global parameters
    builder.addCase(deleteGlobalParameter.fulfilled, (state, action) => {
      const deletedParameterIndex = state.globalParameters.findIndex(
        (parameter) => parameter.uuid === action.payload.parameterUuid,
      )

      if (deletedParameterIndex !== -1) {
        state.globalParameters.splice(deletedParameterIndex, 1)
      }
    })
    builder.addCase(createGlobalParameter.fulfilled, (state, action) => {
      state.globalParameters.push({ ...action.payload.parameter })
    })
    builder.addCase(updateGlobalParameter.fulfilled, (state, action) => {
      const parameterIndex = state.globalParameters.findIndex(
        (parameter) => parameter.uuid === action.payload.parameterUuid,
      )
      if (parameterIndex !== -1) {
        state.globalParameters[parameterIndex] = action.payload.parameter
      }
    })

    // Functions
    builder.addCase(createFunction.fulfilled, (state, action) => {
      const parentIndex = state.functions.folders.findIndex((folder) => folder.uuid === action.payload.parentUuid)
      if (parentIndex !== -1) {
        state.functions.functions.push({ ...action.payload.function })

        const childData = {
          type: FolderTreeItemTypes.FUNCTION,
          uuid: action.payload.function.uuid,
        }

        if (state.functions.folders[parentIndex].children === null) {
          state.functions.folders[parentIndex].children = [{ ...childData }]
        } else {
          state.functions.folders[parentIndex].children.unshift(childData)
        }
      }
    })
    builder.addCase(updateFunctionNameHeader.fulfilled, renameFunctionMethod)
    builder.addCase(renameFunction.fulfilled, renameFunctionMethod)
    builder.addCase(renameFunctionFolder.fulfilled, (state, action) => {
      const folderIndex = state.functions.folders.findIndex((folder) => folder.uuid === action.payload.folderUuid)

      if (folderIndex !== -1) {
        state.functions.folders[folderIndex].name = action.payload.name
        state.updatedName = {
          index: `${state.functions.folders[folderIndex].type}:${action.payload.folderUuid}`,
          name: action.payload.name,
        }
      }
    })
    builder.addCase(updateFunctionDescription.fulfilled, (state, action) => {
      const functionIndex = state.functions.functions.findIndex(
        (functionObj) => functionObj.uuid === action.payload.functionUuid,
      )

      if (functionIndex !== -1) {
        state.functions.functions[functionIndex].description = action.payload.description
      }
    })
    builder.addCase(createFunctionResultObject.fulfilled, createFunctionResultObjectProcess)
    builder.addCase(cloneFunctionResultObject.fulfilled, createFunctionResultObjectProcess)
    builder.addCase(createFunctionsCollection.fulfilled, (state, action) => {
      const rootFolderIndex = state.functions.folders.findIndex((folder) => folder.type === FolderTreeItemTypes.ROOT)
      if (rootFolderIndex !== -1) {
        state.functions.folders.push(action.payload.collection)
        state.functions.folders[rootFolderIndex].children.unshift({
          type: FolderTreeItemTypes.COLLECTION,
          uuid: action.payload.collection.uuid,
        })
      }
    })
    builder.addCase(createFunctionsFolder.fulfilled, (state, action) => {
      const parentIndex = state.functions.folders.findIndex((folder) => folder.uuid === action.payload.parentUuid)
      if (parentIndex !== -1) {
        state.functions.folders.push(action.payload.folder)

        const childData = {
          type: FolderTreeItemTypes.FOLDER,
          uuid: action.payload.folder.uuid,
        }

        if (state.functions.folders[parentIndex].children === null) {
          state.functions.folders[parentIndex].children = [{ ...childData }]
        } else {
          state.functions.folders[parentIndex].children.unshift(childData)
        }
      }
    })
    builder.addCase(createParameter.fulfilled, (state, action) => {
      const functionIndex = state.functions.functions.findIndex(
        (functionObj) => functionObj.uuid === action.payload.functionUuid,
      )
      if (functionIndex !== -1) {
        state.functions.functions[functionIndex].parameters.push(action.payload.parameter)
      }
    })
    builder.addCase(updateParameter.fulfilled, (state, action) => {
      const functionIndex = state.functions.functions.findIndex(
        (functionObj) => functionObj.uuid === action.payload.functionUuid,
      )
      if (functionIndex !== -1) {
        const parameterIndex = state.functions.functions[functionIndex].parameters.findIndex(
          (parameter) => parameter.uuid === action.payload.parameterUuid,
        )

        if (parameterIndex !== -1) {
          state.functions.functions[functionIndex].parameters[parameterIndex] = action.payload.parameter
        }
      }
    })
    builder.addCase(updateFunctionsFolderChildren.fulfilled, (state, action) => {
      const parentIndex = state.functions.folders.findIndex((folder) => folder.uuid === action.payload.folderUuid)
      if (parentIndex !== -1) {
        state.functions.folders[parentIndex].children = action.payload.newChildren
      }
    })
    builder.addCase(createResultObjectParameter.fulfilled, (state, action) => {
      const resultObjectIndex = state.resultObjects.findIndex(
        (resultObj) => resultObj.uuid === action.payload.resultObjectUuid,
      )
      if (resultObjectIndex !== -1) {
        state.resultObjects[resultObjectIndex].parameters.push(action.payload.parameter)
      }
    })
  },
})

const selectFrontApiSlice = (state) => state.frontApi
export const selectGlobalParameters = (state) => selectFrontApiSlice(state).globalParameters
export const selectFunctionByUuid = (state, uuid) => {
  const functions = selectFrontApiSlice(state).functions.functions
  const functionIndex = functions.findIndex((_function) => _function.uuid === uuid)
  let lookingFunction = null

  if (functionIndex !== -1) {
    lookingFunction = functions[functionIndex]
  }

  return lookingFunction
}

export const selectFunction = (state) => selectFrontApiSlice(state).functions.functions
export const selectResultObjects = (state) => selectFrontApiSlice(state).resultObjects

export const selectResultObjectByUuid = (state, uuid) => {
  const resultObjects = selectFrontApiSlice(state).resultObjects

  const resultObjectIndex = resultObjects.findIndex((resultObject) => resultObject.uuid === uuid)
  let lookingResultObject = null

  if (resultObjectIndex !== -1) {
    lookingResultObject = resultObjects[resultObjectIndex]
  }

  return lookingResultObject
}
export const selectFunctionsFolders = (state) => selectFrontApiSlice(state).functions.folders
export const selectAllFunctions = (state) => selectFrontApiSlice(state).functions.functions
export const selectRequestStatus = (state) => selectFrontApiSlice(state).status
export const selectUpdatedName = (state) => selectFrontApiSlice(state).updatedName

const { reducer, actions } = frontApiSlice

export const { resetState, resetUpdatedName, changeRequestStatus } = actions

export default reducer
