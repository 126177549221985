import styled from 'styled-components'
import { TableColumn, TableRow } from '../../common/table/styles'
import { layoutComponentsBorderColor, lightGray } from '../../common/styles/colors'
import { RegularText } from '../../common/styles/text'
import { MoreButton } from './more-popover/styles'

export const ParametersContainer = styled.div`
  display: ${(props) => (props.active ? 'block' : 'none')};
  background-color: rgba(239, 239, 239, ${(props) => props.depth * 0.2});
  margin-left: ${(props) => (props.depth === 0 ? 0 : 18)}px;
  ${(props) => props.depth && `border-left: 1px solid ${layoutComponentsBorderColor}`}
`

export const ParameterContainer = styled.div`
  position: relative;
`

export const ParameterRow = styled(TableRow)`
  &:hover ${MoreButton} {
    display: block;
  }
`

export const ExpandIconContainer = styled.div`
  margin-right: 4px;
`

export const NameText = styled(RegularText)`
  float: left;
`

export const JsonSchemaBuilderContainer = styled.div`
  position: relative;
  min-width: 720px;
`

export const NameColumn = styled(TableColumn)`
  position: relative;
  display: flex;
  align-items: center;
  width: 25%;
  line-height: 32px;
  margin-right: 12px;
`

export const TypeColumn = styled(TableColumn)`
  position: relative;
  width: 25%;
  line-height: 32px;
  margin-right: 12px;
`

export const DescriptionColumn = styled(TableColumn)`
  position: relative;
  width: 40%;
  margin-right: 12px;
  box-sizing: border-box;
`

export const LastColumn = styled(TableColumn)`
  position: relative;
  display: flex;
  justify-content: end;
  width: 10%;
  height: 100%;
`

export const FormButtonsContainer = styled(LastColumn)``

export const AddParameterFormTableRow = styled(TableRow)`
  padding: 4px 12px;
`

export const NoDescriptionMessage = styled.span`
  color: ${lightGray};
`

export const IconButtonContainer = styled.div`
  position: relative;
  width: 32px;
  height: 32px;
`
