import React, { memo } from 'react'
import { Outlet, useParams } from 'react-router'
import { ContentContainer } from '../../../common/layouts/content-container/styles'
import { SubHeaderContainer } from '../../../common/layouts/content-block-header/styles'
import FunctionDetailsHeader from './header/FunctionDetailsHeader'
import {
  UnderLineNavigationLink,
  UnderlineNavigationContainer,
} from '../../../common/layouts/underline-navigation/styles'
import StyledSVG from '../../../common/styled-svg/StyledSVG'
import { lightGray } from '../../../common/styles/colors'
import ObjectIcon from '../../../../assets/images/object-icon.svg'
import { ResultObjectLink } from './styles'
import { Tooltip } from 'react-tooltip'

function FunctionDetails() {
  const { functionUuid, projectUuid } = useParams()

  return (
    <ContentContainer>
      <FunctionDetailsHeader />
      <SubHeaderContainer>
        <UnderlineNavigationContainer height={'48px'}>
          <UnderLineNavigationLink end to={`/project/${projectUuid}/front-api/functions/${functionUuid}`}>
            Overview
          </UnderLineNavigationLink>
          <UnderLineNavigationLink to={`/project/${projectUuid}/front-api/functions/${functionUuid}/parameters`}>
            Input parameters
          </UnderLineNavigationLink>
          <UnderLineNavigationLink to={`/project/${projectUuid}/front-api/functions/${functionUuid}/flow`}>
            Flow
          </UnderLineNavigationLink>
        </UnderlineNavigationContainer>
        <ResultObjectLink
          className="result-object-anchor"
          to={`/project/${projectUuid}/front-api/functions/${functionUuid}/result-object`}
        >
          <StyledSVG width={16} height={20} color={lightGray} src={ObjectIcon} />
        </ResultObjectLink>
        <Tooltip anchorSelect=".result-object-anchor" place="left">
          Result object
        </Tooltip>
      </SubHeaderContainer>
      <ContentContainer top={'96px'}>
        <Outlet />
      </ContentContainer>
    </ContentContainer>
  )
}

export default memo(FunctionDetails)
