import styled from 'styled-components'
import { layoutComponentsBorderColor, lightGray } from '../../../../../common/styles/colors'

export const PageContainer = styled.div`
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  bottom: 0;
`

export const HeaderContainer = styled.div`
  position: absolute;
  width: 100%;
  top: 0;
  height: 48px;
  left: 0;
  right: 0;
  padding: 0 24px;
  box-sizing: border-box;

  border-bottom: 1px solid ${layoutComponentsBorderColor};
`

export const PageContentContainer = styled.div`
  position: absolute;
  top: 48px;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 24px;
  box-sizing: border-box;
`

export const Title = styled.h2`
  position: relative;
  font-size: 16px;
  line-height: 48px;
  float: left;
  color: ${lightGray};
`

export const ConfigurationsFormContainer = styled.div`
  position: relative;
  width: 360px;
`

export const Subtitle = styled.div`
  position: relative;
  margin-bottom: 32px;
`
