import { createAsyncThunk } from '@reduxjs/toolkit'
import { UserAgent } from '../../../api/userAgent'

export const resetPassword = createAsyncThunk('user/account/reset-password', async ({ email }, thunkApi) => {
  try {
    const response = await UserAgent.forgotPasswordResetPassword(email)

    return response
  } catch (error) {
    return thunkApi.rejectWithValue(error.response.data)
  }
})

export const resetPasswordConfirmEmail = createAsyncThunk(
  'user/account/reset-password/confirm-email',
  async ({ token }, thunkApi) => {
    try {
      const response = await UserAgent.forgotPasswordConfirmEmail(token)

      return { token: response }
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data)
    }
  },
)

export const resendConfirmEmailToken = createAsyncThunk(
  'user/account/reset-password/confirm-email-code/resend',
  async ({ email }, thunkApi) => {
    try {
      const response = await UserAgent.forgotPasswordResendConfirmEmailToken(email)

      return response
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data)
    }
  },
)

export const changePassword = createAsyncThunk(
  'user/account/change-password',
  async ({ token, password }, thunkApi) => {
    try {
      const response = await UserAgent.forgotPasswordSetNewPassword(token, password)

      return response
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data)
    }
  },
)
