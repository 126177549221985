import { createAsyncThunk } from '@reduxjs/toolkit'
import { FrontApiAgent } from '../../../../../api/frontApiAgent'

export const updateFunctionNameHeader = createAsyncThunk(
  'front-api/function/update-name',
  async ({ projectUuid, functionUuid, name }, thunkApi) => {
    try {
      await FrontApiAgent.renameFunction(projectUuid, functionUuid, name)

      return { functionUuid, name }
    } catch (error) {
      return thunkApi.rejectWithValue(error)
    }
  },
)
