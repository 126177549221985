import React, { memo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  FormContainer as FormContentContainer,
  MiddleFormPageSubtitleContainer,
  MiddleFormPageTitleContainer,
} from '../../common/layouts/public-area/middle-form/styles'
import { H1TitleWithButton, SubtitleText } from '../../common/styles/text'
import { Clear, Content360 } from '../../common/styles/styles'
import { ButtonContainer, ButtonWithTextCenter, FieldContainer, FormContainer } from '../../common/styles/form-styles'
import SelectboxField from '../../common/form/selectbox/SelectboxField'
import { ServiceSize } from '../../../utils/utils'
import {
  changeFrontApiSize,
  changeStep,
  selectFrontApiInformation,
} from '../../../reducers/project/project-create-form'
import { MiddleFormContainer } from './styles'
import BackButton from '../../common/back-button/BackButton'

function FrontApi() {
  const dispatch = useDispatch()

  const frontApiInformation = useSelector(selectFrontApiInformation)

  const changeInstanceSizeProcess = (value) => {
    dispatch(changeFrontApiSize({ size: value }))
  }

  const changeStepProcess = (step) => {
    dispatch(changeStep({ step }))
  }

  const instanceOptions = Object.values(ServiceSize)

  return (
    <Content360>
      <MiddleFormContainer>
        <MiddleFormPageTitleContainer>
          <BackButton
            action={(event) => {
              event.preventDefault()
              changeStepProcess(2)
            }}
          />
          <H1TitleWithButton>Front API</H1TitleWithButton>
          <Clear />
        </MiddleFormPageTitleContainer>
        <MiddleFormPageSubtitleContainer>
          <SubtitleText>Please provide an Front API instance config</SubtitleText>
        </MiddleFormPageSubtitleContainer>

        <FormContentContainer>
          <FormContainer
            onSubmit={(event) => {
              event.preventDefault()
              changeStepProcess(4)
            }}
          >
            <FieldContainer>
              <fieldset>
                <SelectboxField
                  label={'Size'}
                  selectedValue={frontApiInformation.size}
                  placeholder={'Front API instance size'}
                  options={instanceOptions}
                  changeValueHandler={changeInstanceSizeProcess}
                />
              </fieldset>
            </FieldContainer>

            <ButtonContainer>
              <ButtonWithTextCenter type="submit">Continue</ButtonWithTextCenter>
            </ButtonContainer>
          </FormContainer>
        </FormContentContainer>
      </MiddleFormContainer>
    </Content360>
  )
}

export default memo(FrontApi)
