import styled from 'styled-components'
import { layoutComponentsBorderColor, lightGray, regularGray } from '../../../../../../../common/styles/colors'

export const InsertParameterPopoverContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;

  top: 0;
  left: calc(50% - 160px);

  width: 320px;
  height: 240px;
  border: 1px solid ${layoutComponentsBorderColor};
  box-sizing: border-box;

  transition: border-color 275ms ease;

  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;

  z-index: 1;

  padding: 4px 12px;

  background: #ffffff;

  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;

  &:hover {
    cursor: pointer;
    border-color: ${lightGray};
  }
`

export const SourceParametersContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const SourceName = styled.div`
  position: relative;
  display: flex;
  align-items: center;

  margin-bottom: 4px;

  font-size: 12px;
  height: 24px;
  font-weight: 600;
  color: ${lightGray};
`

export const ParametersContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: space-between;
`

export const ParameterContainer = styled.div`
  position: relative;
  display: flex;

  align-items: center;
  justify-content: center;
  font-size: 12px;
  height: 24px;

  padding: 0 4px;
  margin-right: 4px;
  margin-bottom: 4px;

  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;

  border: 1px solid ${(props) => props.colors.border};
  background: ${(props) => props.colors.background};
  color: ${(props) => props.colors.border};

  transition: opacity 275ms ease;

  cursor: pointer;

  &:hover {
    opacity: 0.5;
  }
`
