import { createAsyncThunk } from '@reduxjs/toolkit'
import { FrontApiAgent } from '../../../../api/frontApiAgent'

export const updateFunctionsFolderChildren = createAsyncThunk(
  'front-api/functions/folders/update-children',
  async ({ projectUuid, folderUuid, newChildren }, thunkApi) => {
    try {
      await FrontApiAgent.updateTreeFolderChildren(projectUuid, folderUuid, newChildren)

      return { folderUuid, newChildren }
    } catch (error) {
      return thunkApi.rejectWithValue(error)
    }
  },
)
