import React, { memo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  FormContainer as FormContentContainer,
  MiddleFormPageSubtitleContainer,
  MiddleFormPageTitleContainer,
} from '../../common/layouts/public-area/middle-form/styles'
import { H1TitleWithButton, SubtitleText } from '../../common/styles/text'
import { Clear, Content360 } from '../../common/styles/styles'
import { ButtonContainer, ButtonWithTextCenter, FieldContainer, FormContainer } from '../../common/styles/form-styles'
import SelectboxField from '../../common/form/selectbox/SelectboxField'
import { AWSRegions } from '../../../utils/utils'
import TextField from '../../common/form/text-field/TextField'
import {
  changeAWSAccessKeyId,
  changeAWSSecretAccessKey,
  changeRegion,
  changeStep,
  selectProjectInformation,
} from '../../../reducers/project/project-create-form'
import { MiddleFormContainer } from './styles'
import BackButton from '../../common/back-button/BackButton'

function AWSConfigurations() {
  const dispatch = useDispatch()

  const projectInformation = useSelector(selectProjectInformation)

  const changeAWSAccessKeyIdProcess = (event) => {
    event.preventDefault()
    dispatch(changeAWSAccessKeyId({ accessKeyId: event.target.value }))
  }

  const changeAWSSecretAccessKeyProcess = (event) => {
    event.preventDefault()
    dispatch(changeAWSSecretAccessKey({ secretAccessKey: event.target.value }))
  }

  const changeRegionProcess = (value) => {
    dispatch(changeRegion({ region: value }))
  }

  const changeStepProcess = (step) => {
    dispatch(changeStep({ step }))
  }

  const AWSRegionsOptions = [AWSRegions.LONDON]

  return (
    <Content360>
      <MiddleFormContainer>
        <MiddleFormPageTitleContainer>
          <BackButton
            action={(event) => {
              event.preventDefault()
              changeStepProcess(1)
            }}
          />
          <H1TitleWithButton>AWS configurations</H1TitleWithButton>
          <Clear />
        </MiddleFormPageTitleContainer>
        <MiddleFormPageSubtitleContainer>
          <SubtitleText>Please provide an AWS configurations to setup environment</SubtitleText>
        </MiddleFormPageSubtitleContainer>
      </MiddleFormContainer>

      <FormContentContainer>
        <FormContainer
          onSubmit={(event) => {
            event.preventDefault()
            changeStepProcess(3)
          }}
        >
          <FieldContainer>
            <fieldset>
              <SelectboxField
                label={'Region'}
                selectedValue={projectInformation.aws.region}
                placeholder={'Regions'}
                options={AWSRegionsOptions}
                changeValueHandler={changeRegionProcess}
              />
            </fieldset>
          </FieldContainer>

          <FieldContainer>
            <fieldset>
              <TextField
                label={'AWS Access key ID'}
                value={projectInformation.aws.accessKeyId}
                changeEvent={changeAWSAccessKeyIdProcess}
                placeholder={'AKIAIOSFODNN7EXAMPLE'}
              />
            </fieldset>
          </FieldContainer>

          <FieldContainer>
            <fieldset>
              <TextField
                label={'AWS secret access key'}
                value={projectInformation.aws.secretAccessKey}
                changeEvent={changeAWSSecretAccessKeyProcess}
                placeholder={'wJalrXUtnFEMI/K7MDENG/bPxRfiCYEXAMPLEKEY'}
              />
            </fieldset>
          </FieldContainer>

          <ButtonContainer>
            <ButtonWithTextCenter type="submit">Continue</ButtonWithTextCenter>
          </ButtonContainer>
        </FormContainer>
      </FormContentContainer>
    </Content360>
  )
}

export default memo(AWSConfigurations)
