import styled from 'styled-components'
import { lightSilver, superLightSilver } from '../../../common/styles/colors'

export const UserSettingsGeneralContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
`

export const UserImageContainer = styled.div`
  position: relative;
  width: 80px;
  height: 80px;
  margin: 0 auto;
  margin-top: 64px;
  margin-bottom: 24px;
  background: ${superLightSilver};
  text-align: center;

  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;

  transition: background-color 275ms ease;

  &:hover {
    cursor: pointer;
    background: ${lightSilver};
  }
`

export const ContentContainer = styled.div`
  position: absolute;
  top: 48px;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 24px;
  box-sizing: border-box;
`

export const Subtitle = styled.div`
  position: relative;
  margin-bottom: 32px;
`

export const SettingsFormContainer = styled.div`
  position: relative;
  width: 360px;
`

export const ContentBlockTitle = styled.div`
  position: relative;
  margin-top: 48px;
  margin-bottom: 32px;
`
