import React, { memo } from 'react'
import { Outlet } from 'react-router-dom'
import { ContentContainer } from '../common/layouts/content-container/styles'
import Navigation from './navigation/Navigation'

function FrontApiLayout() {
  return (
    <>
      <Navigation />
      <ContentContainer left={'240px'} right={'0px'}>
        <Outlet />
      </ContentContainer>
    </>
  )
}

export default memo(FrontApiLayout)
