import { createSlice } from '@reduxjs/toolkit'
import { deleteProjectImage, updateGeneralSettings, updateProjectImage } from '../../components/project/details/settings/general/slice'
import { resetErrors, resetStatus } from './../reducer-helper'

const initialState = {
  settings: {
    name: null,
    region: null,
    image: null,
  },
  error: null,
  status: null,
  formLoaded: false,
}

const projectGeneralSettingsFormSlice = createSlice({
  name: 'projectGeneralSettingsForm',
  initialState,
  reducers: {
    setSettings: (state, action) => {
      state.settings.name = action.payload.name
      state.settings.image = action.payload.image
      state.settings.region = action.payload.region
      state.formLoaded = true
    },
    changeImage: (state, action) => {
      state.settings.image = action.payload.image
    },
    changeName: (state, action) => {
      state.settings.name = action.payload.name
    },
    changeRegion: (state, action) => {
      state.settings.region = action.payload.region
    },
    changeRequestStatus: (state, action) => {
      state.status = action.payload.status
    },
    resetForm: () => initialState,
  },
  extraReducers(builder) {
    builder.addCase(updateGeneralSettings.fulfilled, (state, action) => {
      resetStatus(state)
      resetErrors(state)
    })
    builder.addCase(updateProjectImage.fulfilled, (state, action) => {
      resetStatus(state)
      resetErrors(state)
    })
    builder.addCase(deleteProjectImage.fulfilled, (state, action) => {
      resetStatus(state)
      resetErrors(state)
    })
  },
})

const selectProjectGeneralSettingsFormSlice = (state) => state.projectGeneralSettingsForm
export const selectGeneralSettings = (state) => selectProjectGeneralSettingsFormSlice(state).settings
export const isProjectGeneralSettingsFormLoaded = (state) => selectProjectGeneralSettingsFormSlice(state).formLoaded
export const selectRequestStatus = (state) => selectProjectGeneralSettingsFormSlice(state).status

const { reducer, actions } = projectGeneralSettingsFormSlice

export const { setSettings, changeImage, changeName, changeRegion, changeRequestStatus, resetForm } = actions

export default reducer
