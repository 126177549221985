import { createAsyncThunk } from '@reduxjs/toolkit'
import { UserAgent } from '../../../../../../api/userAgent'

export const logout = createAsyncThunk('user/log-out', async (undefined, thunkApi) => {
  try {
    const response = await UserAgent.logOut()

    return response
  } catch (error) {
    return thunkApi.rejectWithValue(error)
  }
})
