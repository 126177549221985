import styled from 'styled-components'
import { darkGray, lightSilver } from '../../../common/styles/colors'

export const HeaderContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 80px;
  top: 0;
  left: 0;

  box-sizing: border-box;

  border-bottom: 1px solid ${lightSilver};
`

export const Title = styled.h2`
  position: relative;
  margin-left: 24px;
  font-size: 24px;
  line-height: 79px;
  color: ${darkGray};
`
