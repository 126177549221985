import React, { memo, useEffect } from 'react'
import { ContentWrapperContainer } from '../../../../../common/layouts/content-container/styles'
import {
  changeDescription,
  changeName,
  changeRequestStatus,
  changeIsRequired,
  selectFormData,
  initForm,
  resetState,
} from '../../../../../../reducers/front-api/function/parameter/edit-form'
import { useDispatch, useSelector } from 'react-redux'
import { updateParameter } from './slice'
import { selectProject } from '../../../../../../reducers/project/project-details'
import { Clear } from '../../../../../common/styles/styles'
import { ButtonsContainer, CancelButton, EditParameterFormContainer, FieldsContainer, SaveButton } from './styles'
import TextField from '../../../../../common/form/text-field/TextField'
import { REQUEST_STATUSES } from '../../../../../../reducers/reducer-helper'
import { closePopup, selectPopupExtraParameters } from '../../../../../../reducers/popup'
import { FieldContainer } from '../../../../../common/styles/form-styles'
import { H1Title, SubtitleText } from '../../../../../common/styles/text'
import Checkbox from '../../../../../common/form/checkbox/Checkbox'

function EditParameterForm() {
  const dispatch = useDispatch()

  const formData = useSelector(selectFormData)
  const project = useSelector(selectProject)
  const popupExtraParameters = useSelector(selectPopupExtraParameters)

  const params = {}
  popupExtraParameters.map((param) => {
    params[param.name] = param.value
  })

  useEffect(() => {
    dispatch(
      initForm({
        name: params.name,
        isRequired: params.isRequired,
        description: params.description,
      }),
    )
  }, [])

  const changeNameFieldValue = (event) => {
    dispatch(changeName({ name: event.target.value }))
  }

  const changeIsRequiredFieldValue = () => {
    dispatch(changeIsRequired({ isRequired: !formData.isRequired }))
  }

  const changeDescriptionFieldValue = (event) => {
    dispatch(changeDescription({ description: event.target.value }))
  }

  const updateParameterHandler = (event) => {
    event.preventDefault()
    dispatch(changeRequestStatus({ status: REQUEST_STATUSES.PENDING }))
    dispatch(
      updateParameter({
        projectUuid: project.uuid,
        parameterUuid: params.parameterUuid,
        functionUuid: params.functionUuid,
        name: formData.name,
        isRequired: formData.isRequired,
        description: formData.description,
      }),
    )
  }

  useEffect(() => {
    return () => {
      dispatch(resetState())
    }
  }, [])

  const cancelHandler = () => {
    dispatch(closePopup())
  }

  return (
    <EditParameterFormContainer>
      <ContentWrapperContainer marginTop={'8px'} marginBottom={'24px'}>
        <H1Title>
          Edit <b>{formData.name}</b> parameter
        </H1Title>
      </ContentWrapperContainer>
      <ContentWrapperContainer marginTop={'0px'} marginBottom={'32px'}>
        <SubtitleText>Please provide information about this parameter</SubtitleText>
      </ContentWrapperContainer>
      <FieldsContainer>
        <FieldContainer>
          <fieldset>
            <TextField
              label={'Name'}
              placeholder={'new-parameter'}
              value={formData.name}
              changeEvent={changeNameFieldValue}
            />
          </fieldset>
        </FieldContainer>
        <FieldContainer>
          <fieldset>
            <TextField
              placeholder={'Small description...'}
              label={'Description'}
              value={formData.description}
              changeEvent={changeDescriptionFieldValue}
            />
          </fieldset>
        </FieldContainer>

        <FieldContainer>
          <fieldset>
            <Checkbox
              content={'Is required'}
              isEnabled={formData.isRequired}
              onClickEvent={changeIsRequiredFieldValue}
            />
          </fieldset>
        </FieldContainer>
      </FieldsContainer>

      <ButtonsContainer>
        <SaveButton type={'button'} onClick={updateParameterHandler}>
          Save parameter
        </SaveButton>
        <CancelButton type={'button'} onClick={cancelHandler}>
          Cancel
        </CancelButton>
        <Clear />
      </ButtonsContainer>
    </EditParameterFormContainer>
  )
}

export default memo(EditParameterForm)
