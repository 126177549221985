import styled from 'styled-components'
import { lightBlue, mainBlue, regularGray } from './colors'
import { Link } from 'react-router-dom'

export const CallToActionButton = styled.button`
  position: relative;
  display: flex;
  align-items: center;

  padding: ${(props) => (props.small ? `0 8px` : '0 16px')};
  height: ${(props) => (props.small ? `24px` : '32px')};
  line-height: ${(props) => (props.small ? `24px` : '32px')};

  text-decoration: none;
  text-align: center;
  background: ${mainBlue};
  border: none;

  font-size: 13px;
  font-weight: 600;
  color: #ffffff;

  box-sizing: border-box;

  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;

  & span {
    padding-left: 8px;
  }

  transition: background-color 275ms ease;

  ${(props) => {
    if (props.disabled) {
      return `opacity: 0.5;`
    } else {
      return `&:hover {
        cursor: pointer;
        background: ${lightBlue};
      }`
    }
  }}
`

export const SecondaryActionButton = styled.button`
  position: relative;
  display: flex;
  align-items: center;

  color: ${mainBlue};
  border: 1px solid ${mainBlue};
  box-sizing: border-box;
  margin: 0;
  padding: ${(props) => (props.small ? `0 8px` : '0 16px')};
  height: ${(props) => (props.small ? `24px` : '32px')};
  line-height: ${(props) => (props.small ? `22px` : '30px')};
  text-decoration: none;

  background: none;

  font-size: 13px;
  font-weight: 600;

  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;

  transition: background-color 275ms ease, border-color 275ms ease, color 275ms ease;

  & span {
    padding-left: 8px;
  }

  &:hover {
    cursor: pointer;
    color: ${lightBlue};
    border: 1px solid ${lightBlue};

    svg {
      path {
        fill: ${lightBlue};
      }
    }
  }
`

export const SecondaryActionLink = styled(Link)`
  position: relative;
  display: block;
  color: ${mainBlue};
  border: 2px solid ${mainBlue};
  box-sizing: border-box;
  margin: 0;
  height: 40px;
  line-height: 36px;
  text-decoration: none;

  width: 100%;

  background: none;

  padding: 0;
  text-align: center;

  font-size: 14px;
  font-weight: 500;

  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;

  transition: background-color 275ms ease, border-color 275ms ease, color 275ms ease;

  &:hover {
    cursor: pointer;
    background: ${mainBlue};
    color: #ffffff;
  }
`

export const DangerousButton = styled(SecondaryActionButton)`
  border-color: #b86c6c;
  color: #b86c6c;

  &:hover {
    border-color: #edafaf;
    background: #ffffff;
    color: #edafaf;
  }
`

export const Clear = styled.div`
  clear: both;
  width: 100%;
  height: 0;
`

export const CustomLink = styled(Link)`
  color: ${mainBlue};
  font-weight: 500;
  font-size: 14px;

  text-decoration: underline;

  transition: color 275ms ease;
  &:hover {
    cursor: pointer;
    color: ${regularGray};
  }
`

export const Content360 = styled.div`
  position: relative;
  width: 360px;
  margin: 0 auto;
`

export const Content720 = styled.div`
  position: relative;
  width: 720px;
  margin: 0 auto;
`
