import React, { memo } from 'react'
import {
  ServiceIconContainer,
  ServiceInfoContainer,
  ServiceName,
  ProjectServiceItemContainer,
  StatusContainer,
  ItemInfoBlock,
} from './styles'
import { useSelector } from 'react-redux'
import { selectProject } from '../../../../reducers/project/project-details'
import ServiceIcon from '../../../service/service-icon/ServiceIcon'
import StatusLabel from '../../../common/status-label/StatusLabel'

function ProjectServiceItem({ service = null }) {
  const project = useSelector(selectProject)

  return (
    <ProjectServiceItemContainer to={`/project/${project.uuid}/services/${service.uuid}`}>
      <ItemInfoBlock>
        <ServiceIconContainer>
          <ServiceIcon serviceAlias={service.alias} />
        </ServiceIconContainer>
        <ServiceInfoContainer>
          <ServiceName>{service.name}</ServiceName>
        </ServiceInfoContainer>
      </ItemInfoBlock>
      {service.inProject.status && (
        <StatusContainer>
          <StatusLabel status={service.inProject.status} />
        </StatusContainer>
      )}
    </ProjectServiceItemContainer>
  )
}

export default memo(ProjectServiceItem)
