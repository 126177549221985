import styled from 'styled-components'
import { darkGray } from '../../styles/colors'

export const HeaderContainer = styled.div`
  position: relative;
  width: 100%;
  height: 80px;
`

export const PageTitle = styled.h2`
  position: relative;
  color: ${darkGray};
  line-height: 80px;
  font-size: 18px;
  font-weight: 700;
  float: left;
`

export const ButtonContainer = styled.div`
  position: relative;
  float: right;
  height: 32px;
  margin-top: 24px;
`
