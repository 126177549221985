import React, { memo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ErrorMessage from '../../common/error-message/ErrorMessage'
import { ButtonContainer, ButtonWithTextCenter, FieldContainer, FormContainer } from '../../common/styles/form-styles'
import {
  FormContainer as FormContentContainer,
  MiddleFormPageSubtitleContainer,
  MiddleFormPageTitleContainer,
  MiddleFormTopContainer,
} from '../../common/layouts/public-area/middle-form/styles'
import { H1Title, SubtitleText } from '../../common/styles/text'
import PublicAreaMiddleFormLayout from '../../common/layouts/public-area/middle-form/PublicAreaMiddleFormLayout'
import { Clear } from '../../common/styles/styles'
import TextField from '../../common/form/text-field/TextField'
import { changePassword } from './slice'
import {
  selectError,
  selectForgotPasswordFormData,
  changePassword as changePasswordValue,
  selectRequestStatus,
  changeRequestStatus,
} from '../../../reducers/user/forgot-password-form'
import { REQUEST_STATUSES } from '../../../reducers/reducer-helper'
import Loading from '../../common/loading/Loading'

function ChangePasswordForm() {
  const dispatch = useDispatch()

  const requestStatus = useSelector(selectRequestStatus)
  const formData = useSelector(selectForgotPasswordFormData)
  const error = useSelector(selectError)

  const changePasswordProcess = (event) => {
    event.preventDefault()
    dispatch(changeRequestStatus({ status: REQUEST_STATUSES.PENDING }))
    dispatch(changePassword({ token: formData.token, password: formData.password }))
  }

  const changePasswordValueProcess = (event) => {
    event.preventDefault()
    dispatch(changePasswordValue({ password: event.target.value }))
  }

  return (
    <PublicAreaMiddleFormLayout>
      {requestStatus === REQUEST_STATUSES.PENDING && <Loading />}
      <MiddleFormTopContainer>
        <MiddleFormPageTitleContainer>
          <H1Title>Reset password</H1Title>
          <Clear />
        </MiddleFormPageTitleContainer>
        <MiddleFormPageSubtitleContainer>
          <SubtitleText>Set a new password</SubtitleText>
        </MiddleFormPageSubtitleContainer>
      </MiddleFormTopContainer>

      <ErrorMessage error={error} />

      <FormContentContainer>
        <FormContainer onSubmit={changePasswordProcess}>
          <FieldContainer>
            <fieldset>
              <TextField
                label={'New password'}
                value={formData.password}
                type={'password'}
                changeEvent={changePasswordValueProcess}
                placeholder={'●●●●●●'}
              />
            </fieldset>
          </FieldContainer>
          <ButtonContainer>
            <ButtonWithTextCenter type="submit">Save</ButtonWithTextCenter>
          </ButtonContainer>
        </FormContainer>
      </FormContentContainer>
    </PublicAreaMiddleFormLayout>
  )
}

export default memo(ChangePasswordForm)
