import React, { memo } from 'react'
import { ButtonContainer, HeaderContainer, PageTitle } from './styles'
import { Clear } from '../../styles/styles'

function Header({ title, button = null }) {
  return (
    <HeaderContainer>
      <PageTitle>{title}</PageTitle>
      {button && <ButtonContainer>{button}</ButtonContainer>}
      <Clear />
    </HeaderContainer>
  )
}

export default memo(Header)
