import { configureStore } from '@reduxjs/toolkit'
import { localStorageMiddleware } from './middleware'
import commonReducer from '../reducers/common'
import userReducer from '../reducers/user/user'
import projectListReducer from '../reducers/project/project-list'
import serviceListReducer from '../reducers/service/service-list'
import projectDetailsReducer from '../reducers/project/project-details'
import projectServicesReducer from '../reducers/project/project-services'
import signUpFormReducer from '../reducers/user/sign-up-form'
import signInFormReducer from '../reducers/user/sign-in-form'
import forgotPasswordFormReducer from '../reducers/user/forgot-password-form'
import projectCreateFormReducer from '../reducers/project/project-create-form'
import serviceDetailsReducer from '../reducers/service/service-details'
import addServiceFormReducer from '../reducers/service/add-service-form'
import updateServiceConfigurationFormReducer from '../reducers/service/update-service-configuration-form'
import updateServiceSettingsFormReducer from '../reducers/service/update-service-settings-form'
import projectGeneralSettingsFormReducer from '../reducers/project/project-general-settings-form'
import projectDatabaseSettingsFormReducer from '../reducers/project/project-database-settings-form'
import projectRedisSettingsFormReducer from '../reducers/project/project-redis-settings-form'
import frontApiApiKeysReducer from '../reducers/front-api/api-keys/api-keys'
import frontApiCreateFunctionFormReducer from '../reducers/front-api/function/create-form'
import frontApiCreateFunctionsCollectionFormReducer from '../reducers/front-api/function/folder/create-collection-form'
import frontApiCreateFunctionsFolderFormReducer from '../reducers/front-api/function/folder/create-form'
import frontApiAddFunctionParameterFormReducer from '../reducers/front-api/function/parameter/add-form'
import frontApiEditFunctionParameterFormReducer from '../reducers/front-api/function/parameter/edit-form'
import frontApiRenameFunctionsFolderFormReducer from '../reducers/front-api/function/folder/rename-form'
import frontApiRenameFunctionFormReducer from '../reducers/front-api/function/rename-form'
import frontApiAddGlobalParameterFormReducer from '../reducers/front-api/global-parameter/add-form'
import frontApiEditGlobalParameterFormReducer from '../reducers/front-api/global-parameter/edit-form'
import frontApiDeleteGlobalParameterReducer from '../reducers/front-api/global-parameter/delete'
import frontApiFlowBuilderContextReducer from '../reducers/front-api/flow-builder/flow-builder-context'
import frontApiDeleteFunctionReducer from '../reducers/front-api/function/delete'
import frontApiReducer from '../reducers/front-api/front-api'
import userGeneralSettingsFormReducer from '../reducers/user/user-general-settings-form'
import frontApiJsonSchemaBuilderAddParameterFormReducer from '../reducers/front-api/json-schema-builder/add-parameter-form'

import popupReducer from '../reducers/popup'

export function makeStore(preloadedState) {
  return configureStore({
    reducer: {
      common: commonReducer,
      popup: popupReducer,
      // User
      user: userReducer,
      signInForm: signInFormReducer,
      signUpForm: signUpFormReducer,
      forgotPasswordForm: forgotPasswordFormReducer,
      userGeneralSettingsForm: userGeneralSettingsFormReducer,
      // Project
      projectCreateForm: projectCreateFormReducer,
      projectList: projectListReducer,
      projectDetails: projectDetailsReducer,
      projectServices: projectServicesReducer,
      projectGeneralSettingsForm: projectGeneralSettingsFormReducer,
      projectDatabaseSettingsForm: projectDatabaseSettingsFormReducer,
      projectRedisSettingsForm: projectRedisSettingsFormReducer,
      // Services
      serviceList: serviceListReducer,
      serviceDetails: serviceDetailsReducer,
      addServiceForm: addServiceFormReducer,
      updateServiceConfigurationForm: updateServiceConfigurationFormReducer,
      updateServiceSettingsForm: updateServiceSettingsFormReducer,
      // FrontApi
      frontApi: frontApiReducer,
      frontApiApiKeys: frontApiApiKeysReducer,
      // FrontApi Functions
      frontApiCreateFunctionsCollectionForm: frontApiCreateFunctionsCollectionFormReducer,
      frontApiCreateFunctionsFolderForm: frontApiCreateFunctionsFolderFormReducer,
      frontApiRenameFunctionsFolderForm: frontApiRenameFunctionsFolderFormReducer,
      frontApiRenameFunctionForm: frontApiRenameFunctionFormReducer,
      frontApiCreateFunctionForm: frontApiCreateFunctionFormReducer,
      frontApiDeleteFunction: frontApiDeleteFunctionReducer,
      // FrontApi Function parameters
      frontApiAddFunctionParameterForm: frontApiAddFunctionParameterFormReducer,
      frontApiEditFunctionParameterForm: frontApiEditFunctionParameterFormReducer,
      // FrontApi Result objects
      frontApiJsonSchemaBuilderAddParameterForm: frontApiJsonSchemaBuilderAddParameterFormReducer,
      // FrontApi Global parameters
      frontApiAddGlobalParameterForm: frontApiAddGlobalParameterFormReducer,
      frontApiEditGlobalParameterForm: frontApiEditGlobalParameterFormReducer,
      frontApiDeleteGlobalParameter: frontApiDeleteGlobalParameterReducer,
      // FrontApi flow builder
      frontApiFlowBuilderContext: frontApiFlowBuilderContextReducer,
    },
    devTools: true,
    preloadedState,
    middleware: (getDefaultMiddleware) => [...getDefaultMiddleware(), localStorageMiddleware],
  })
}

const store = makeStore()

export default store
