import { createSlice } from '@reduxjs/toolkit'
import { createFunctionsFolder } from '../../../../components/front-api/functions/sidebar/forms/create-folder/slice'
import { failed, resetErrors, resetStatus } from '../../../reducer-helper'

const initialState = {
  formData: {
    name: 'New folder',
  },
  parentIndex: null,
  error: null,
  status: null,
}

const createFormSlice = createSlice({
  name: 'frontApiCreateFunctionsFolderForm',
  initialState,
  reducers: {
    resetState: () => initialState,
    changeName: (state, action) => {
      state.formData.name = action.payload.name
    },
    changeRequestStatus: (state, action) => {
      state.status = action.payload.status
    },
  },
  extraReducers(builder) {
    builder.addCase(createFunctionsFolder.fulfilled, (state, action) => {
      state.parentIndex = action.payload.parentIndex

      resetStatus(state)
      resetErrors(state)
    })
    builder.addCase(createFunctionsFolder.rejected, failed)
  },
})

const currentSlice = (state) => state.frontApiCreateFunctionsFolderForm
export const selectRequestStatus = (state) => currentSlice(state).status
export const selectCreatedFolderParentIndex = (state) => currentSlice(state).parentIndex
export const selectFormData = (state) => currentSlice(state).formData

const { reducer, actions } = createFormSlice

export const { resetState, changeRequestStatus, changeName } = actions

export default reducer
