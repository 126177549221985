import React, { memo, useEffect, useState } from 'react'
import { ContentWrapperContainer, FlexContainer } from '../../../../../../common/layouts/content-container/styles'
import IconButton from '../../../../../../common/icon-button/IconButton'
import DeleteIcon from '../../../../../../../assets/images/delete-icon.svg'
import CodemirrorField from '../codemirror-field/CodemirrorField'

function KeyValueParameter({}) {
  const emptyParameter = { key: '', value: '' }
  const [parameters, setParameters] = useState([emptyParameter])

  useEffect(() => {
    const lastParameter = parameters[parameters.length - 1]
    if (lastParameter.value !== '' || lastParameter.key !== '') {
      const fakeParameters = [...parameters, emptyParameter]
      setParameters(fakeParameters)
    }
  }, [parameters])

  const changeParameterKey = (value, parameterIndex) => {
    const fakeParameters = [...parameters]
    fakeParameters[parameterIndex].key = value
    setParameters(fakeParameters)
  }

  const changeParameterValue = (value, parameterIndex) => {
    const fakeParameters = [...parameters]
    fakeParameters[parameterIndex].value = value
    setParameters(fakeParameters)
  }

  const deleteParameter = (event, parameterIndex) => {
    event.preventDefault()
    const fakeParameters = [...parameters]
    fakeParameters.splice(parameterIndex, 1)
    setParameters(fakeParameters)
  }

  return (
    <>
      {parameters.map((parameter, index) => (
        <ContentWrapperContainer key={`header-${index}`} marginTop={'0px'} marginBottom={'12px'} width={'100%'}>
          <FlexContainer alignItems={'top'}>
            <ContentWrapperContainer marginTop={'0px'} marginBottom={'0px'} width={'50%'}>
              <CodemirrorField
                value={parameter.key}
                placeholder={'Key'}
                onChange={(value) => {
                  changeParameterKey(value, index)
                }}
              />
            </ContentWrapperContainer>
            <ContentWrapperContainer marginTop={'0px'} marginBottom={'0px'} marginLeft={'12px'} width={'50%'}>
              <CodemirrorField
                value={parameter.value}
                placeholder={'Value'}
                onChange={(value) => {
                  changeParameterValue(value, index)
                }}
              />
            </ContentWrapperContainer>
            {index !== parameters.length - 1 && (
              <ContentWrapperContainer
                marginTop={'4px'}
                marginBottom={'0px'}
                marginLeft={'6px'}
                width={'24px'}
                height={'24px'}
              >
                <IconButton
                  icon={DeleteIcon}
                  action={(event) => {
                    deleteParameter(event, index)
                  }}
                />
              </ContentWrapperContainer>
            )}
          </FlexContainer>
        </ContentWrapperContainer>
      ))}
    </>
  )
}

export default memo(KeyValueParameter)
