import { createSlice } from '@reduxjs/toolkit'
import { deleteFunction } from '../../../components/front-api/functions/sidebar/popups/delete/slice'

const initialState = {
  error: null,
  status: null,
}

const deleteSlice = createSlice({
  name: 'frontApiDeleteFunction',
  initialState,
  reducers: {
    resetState: () => initialState,
    changeRequestStatus: (state, action) => {
      state.status = action.payload.status
    },
  },
  extraReducers(builder) {
    builder.addCase(deleteFunction.fulfilled, () => initialState)
  },
})

const currentSlice = (state) => state.frontApiDeleteFunction
export const selectRequestStatus = (state) => currentSlice(state).status

const { reducer, actions } = deleteSlice

export const { resetState, changeRequestStatus } = actions

export default reducer
