import React, { memo } from 'react'
import RICIBs from 'react-individual-character-input-boxes'
import { codeInputProps } from './styles'

const SixDigitsCode = ({ handler }) => {
  return (
    <RICIBs amount={6} autoFocus handleOutputString={handler} inputProps={codeInputProps} inputRegExp={/^[0-9]$/} />
  )
}

export default memo(SixDigitsCode)
