import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  parametersForInsert: [],
  error: null,
  status: null,
}

const createContextSlice = createSlice({
  name: 'frontApiFlowBuilderContext',
  initialState,
  reducers: {
    resetState: () => initialState,
    setParametersForInsert: (state, action) => {
      state.parametersForInsert = action.payload.parametersForInsert
    },
  },
})

const currentSlice = (state) => state.frontApiFlowBuilderContext
export const selectParametersForInsert = (state) => currentSlice(state).parametersForInsert

const { reducer, actions } = createContextSlice

export const { resetState, setParametersForInsert } = actions

export default reducer
