import styled from 'styled-components'

export const LevelContainer = styled.div`
  position: relative;
  width: 100%;
  min-height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const VerticalContainer = styled.div`
  position: relative;
  height: 100%;
  min-height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
`
