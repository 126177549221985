import React, { memo } from 'react'
import { useSelector } from 'react-redux'
import {
  changeName,
  changeRequestStatus,
  resetState,
  selectFormData,
} from '../../../../../../reducers/front-api/function/rename-form'
import { renameFunction } from './slice'
import RenameForm from '../../../../../common/tree/form/rename-form/RenameForm'

function RenameFunctionForm({ item }) {
  const formData = useSelector(selectFormData)

  return (
    <RenameForm
      item={item}
      placeholder={'Function name...'}
      name={formData.name}
      changeName={changeName}
      changeRequestStatus={changeRequestStatus}
      resetState={resetState}
      renameProcess={renameFunction}
    />
  )
}

export default memo(RenameFunctionForm)
