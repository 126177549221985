import React, { memo, useEffect, useState } from 'react'
import { HeaderContainer, Title, Subtitle, ResourceStatusContainer, ResourceStatusText } from '../../styles'
import { ButtonWithTextLeftBlock, FieldContainer, FormContainer } from '../../../../../common/styles/form-styles'
import SelectboxField from '../../../../../common/form/selectbox/SelectboxField'
import { ResourceSizes, ResourceStatusLabels, ResourceStatuses, ResourceTypes } from '../../../../../../utils/utils'
import { useDispatch, useSelector } from 'react-redux'
import { selectProject } from '../../../../../../reducers/project/project-details'
import { H2Title, RegularText } from '../../../../../common/styles/text'
import { CallToActionButton, DangerousButton } from '../../../../../common/styles/styles'
import {
  changeRequestStatus,
  changeSize,
  isDatabaseSettingsFormLoaded,
  selectDatabaseSettings,
  selectRequestStatus,
  setSettings,
} from '../../../../../../reducers/project/project-database-settings-form'
import { addDatabase, updateDatabase } from './slice'
import {
  ContentContainer,
  ContentWrapperContainer,
  FlexContainer,
} from '../../../../../common/layouts/content-container/styles'
import StatusLabel from '../../../../../common/status-label/StatusLabel'
import Loading from '../../../../../common/loading/Loading'
import { REQUEST_STATUSES } from '../../../../../../reducers/reducer-helper'

function DatabaseSettings() {
  const dispatch = useDispatch()

  const project = useSelector(selectProject)
  const settings = useSelector(selectDatabaseSettings)
  const isFormLoaded = useSelector(isDatabaseSettingsFormLoaded)
  const requestStatus = useSelector(selectRequestStatus)

  const [formWasChanged, changeForm] = useState(false)
  const [databaseResource, setDatabaseResource] = useState(null)

  const changeSizeProcess = (value) => {
    dispatch(changeSize({ size: value }))
  }

  useEffect(() => {
    const databaseResource = project.resources.find((resource) => resource.type === ResourceTypes.DB)
    setDatabaseResource(databaseResource)

    let size = null

    if (databaseResource) {
      size = databaseResource.configurations.size
    }

    dispatch(setSettings({ size }))
  }, [project])

  useEffect(() => {
    if (isFormLoaded) {
      let size = null

      if (databaseResource) {
        size = databaseResource.configurations.size
      }

      changeForm(size !== settings.size)
    }
  }, [isFormLoaded, settings, databaseResource])

  const saveSettings = (event) => {
    event.preventDefault()
    dispatch(changeRequestStatus({ status: REQUEST_STATUSES.PENDING }))
    const resourceParams = { projectUuid: project.uuid, size: settings.size }
    dispatch(databaseResource ? updateDatabase(resourceParams) : addDatabase(resourceParams))
  }

  const databaseSizeOptions = Object.values(ResourceSizes)

  return (
    <ContentContainer>
      <HeaderContainer>
        <Title>Database</Title>
        {requestStatus !== REQUEST_STATUSES.PENDING && (
          <FlexContainer>
            <ResourceStatusContainer>
              <StatusLabel status={databaseResource ? databaseResource.status : ResourceStatuses.NOT_CREATED} />
            </ResourceStatusContainer>
            <ResourceStatusText>
              {ResourceStatusLabels[databaseResource ? databaseResource.status : ResourceStatuses.NOT_CREATED]}
            </ResourceStatusText>
          </FlexContainer>
        )}
      </HeaderContainer>
      <ContentContainer top={'48px'} padding={'24px'}>
        <Subtitle>
          <RegularText>Here you can change a database settings</RegularText>
        </Subtitle>
        {requestStatus === REQUEST_STATUSES.PENDING && <Loading />}
        {isFormLoaded && (
          <ContentWrapperContainer width={'360px'} height={'auto'} marginTop={'0'} marginBottom={'0'}>
            <FormContainer onSubmit={saveSettings}>
              <FieldContainer>
                <fieldset>
                  <SelectboxField
                    label={'Size'}
                    selectedValue={settings.size}
                    placeholder={'DB size'}
                    options={databaseSizeOptions}
                    changeValueHandler={changeSizeProcess}
                  />
                </fieldset>
              </FieldContainer>

              {formWasChanged && <CallToActionButton type="submit">Save</CallToActionButton>}
            </FormContainer>
          </ContentWrapperContainer>
        )}
        {databaseResource && (
          <ContentWrapperContainer marginTop={'48px'}>
            <H2Title>Remove DB</H2Title>
            <ContentWrapperContainer marginTop={'24px'}>
              <RegularText lineHeight={'18px'}>
                You can remove your DB, but make sure that DB is not used by any service
                <br /> and you don't have any important information there
              </RegularText>
            </ContentWrapperContainer>
            <ContentWrapperContainer marginTop={'24px'}>
              <ButtonWithTextLeftBlock>
                <DangerousButton type="submit">Remove</DangerousButton>
              </ButtonWithTextLeftBlock>
            </ContentWrapperContainer>
          </ContentWrapperContainer>
        )}
      </ContentContainer>
    </ContentContainer>
  )
}

export default memo(DatabaseSettings)
