import React, { memo, useState, useEffect, useRef } from 'react'
import {
  HeaderContainer,
  HomeLink,
  LeftContentContainer,
  LogoContainer,
  // NotificationContainer,
  // NotificationIconComponent,
  // NotificationIconContainer,
  RightContentContainer,
} from './styles'
import LogoImage from '../../../../../assets/images/small-logo.svg'
import NotificationIcon from '../../../../../assets/images/notification-icon.svg'
import { Clear } from '../../../styles/styles'
import UserBlock from './user-block/UserBlock'
import UserPopover from './user-popover/UserPopover'

function Header() {
  const wrapperRef = useRef(null)
  const [showPopover, setShowPopover] = useState(false)

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setShowPopover(false)
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [wrapperRef])

  return (
    <HeaderContainer>
      <LeftContentContainer>
        <HomeLink to={'/dashboard'}>
          <LogoContainer src={LogoImage} alt="Startup Kit Cloud" />
        </HomeLink>
      </LeftContentContainer>
      <RightContentContainer>
        <UserBlock
          showPopupCallback={(event) => {
            event.preventDefault()
            setShowPopover(!showPopover)
          }}
        />

        {showPopover && (
          <UserPopover
            width={'240px'}
            closePopover={() => {
              setShowPopover(false)
            }}
            wrapperRef={wrapperRef}
          />
        )}

        {/* <NotificationContainer>
          <NotificationIconContainer>
            <NotificationIconComponent src={NotificationIcon} alt="Notifications" />
          </NotificationIconContainer>
        </NotificationContainer> */}
        <Clear />
      </RightContentContainer>
      <Clear />
    </HeaderContainer>
  )
}

export default memo(Header)
