import React, { memo } from 'react'
import ConfigsHeader from '../configs-header/ConfigsHeader'
import { ConfigsContainer, NodeConfigurationContainer } from '../styles'
import { useSelector } from 'react-redux'
import { selectPopupExtraParameters } from '../../../../../../../reducers/popup'
import ConfigsFooter from '../configs-footer/ConfigsFooter'
import AuthenticationMethod from './AuthenticationMethod'
import RequestHeaders from './RequestHeaders'
import RequestBody from './RequestBody'

function RequestNodeConfigurations() {
  const popupExtraParameters = useSelector(selectPopupExtraParameters)

  const params = {}
  popupExtraParameters.map((param) => {
    params[param.name] = param.value
  })

  return (
    <NodeConfigurationContainer>
      <ConfigsHeader node={params.node} />
      <ConfigsContainer>
        <AuthenticationMethod />
        <RequestHeaders />
        <RequestBody />
      </ConfigsContainer>
      <ConfigsFooter
        saveHandler={() => {
          console.log('Save')
        }}
      />
    </NodeConfigurationContainer>
  )
}

export default memo(RequestNodeConfigurations)
