import React, { memo, useEffect, useRef } from 'react'
import {
  InsertParameterPopoverContainer,
  ParameterContainer,
  ParametersContainer,
  SourceName,
  SourceParametersContainer,
} from './styles'
import { useSelector } from 'react-redux'
import { selectParametersForInsert } from '../../../../../../../../reducers/front-api/flow-builder/flow-builder-context'
import { colorsByType } from '../../../../../helper'

function InsertParameterPopover({ cancelHandler, insertParameterHandler }) {
  const popoverRef = useRef(null)
  const parametersForInsert = useSelector(selectParametersForInsert)

  useEffect(() => {
    const handleClickOutside = (event) => {
      const target = event.target

      if (popoverRef.current && !popoverRef.current.contains(target)) {
        cancelHandler()
      }
    }
    // e.stopImmediatePropagation()
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [popoverRef])

  return (
    <InsertParameterPopoverContainer ref={popoverRef}>
      {parametersForInsert.map((parametersSource) => (
        <SourceParametersContainer key={`parameters-source-${parametersSource.sourceAlias}`}>
          <SourceName>{parametersSource.sourceName}</SourceName>
          <ParametersContainer>
            {parametersSource.parameters.map((parameter) => {
              const insertedParameter = `$${parametersSource.sourceAlias}.${parameter.name}`

              return (
                <ParameterContainer
                  onClick={() => {
                    insertParameterHandler(`{{${insertedParameter}}}`)
                    cancelHandler()
                  }}
                  colors={colorsByType(parametersSource.sourceAlias)}
                  key={`${parametersSource.sourceAlias}-${parameter.uuid}`}
                >
                  {insertedParameter}
                </ParameterContainer>
              )
            })}
          </ParametersContainer>
        </SourceParametersContainer>
      ))}
    </InsertParameterPopoverContainer>
  )
}

export default memo(InsertParameterPopover)
