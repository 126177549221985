import React, { memo } from 'react'
import { Content, ContentDescription } from './styles'
import { RegularText } from '../../../common/styles/text'
import { useDispatch, useSelector } from 'react-redux'
import { changeConfiguration, selectConfigurations } from '../../../../reducers/service/add-service-form'
import ConfigurationsForm from '../../configurations-form/ConfigurationsForm'

function Configurations() {
  const dispatch = useDispatch()
  const configurations = useSelector(selectConfigurations)

  const changeConfigurationProcess = (key, value) => {
    dispatch(changeConfiguration({ key, value }))
  }

  return (
    <Content>
      <ContentDescription>
        <RegularText>
          Here you need to add specific configuration for the service. You will be able to change it later.
        </RegularText>
      </ContentDescription>

      <ConfigurationsForm changeConfigurationProcess={changeConfigurationProcess} configurations={configurations} />
    </Content>
  )
}

export default memo(Configurations)
